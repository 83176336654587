import { Component, OnInit } from '@angular/core';
import {
  IOutOfOfficeStatus,
  IOutOfOfficeStatusForm,
} from '../../../../state/out-of-office-status.repository';
import { OutOfOfficeStatusService } from '../../../../state/out-of-office-status.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../../../state/users.repository';
import { UsersService } from '../../../../api/services/users.service';
import { AuthRepository } from '../../../../state/auth.repository';
import { DatePipe } from '@angular/common';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-out-of-office-status-form-page',
  templateUrl: './out-of-office-status-form-page.component.html',
  styleUrls: ['./out-of-office-status-form-page.component.scss'],
})
export class OutOfOfficeStatusFormPageComponent implements OnInit {
  submitErrors: string[] | null = null;
  editedStatus: IOutOfOfficeStatusForm | null = null;
  users: DatalistOption[] | null = null;
  timeOffset = new Date().getTimezoneOffset() / -60;
  constructor(
    private service: OutOfOfficeStatusService,
    private router: Router,
    private route: ActivatedRoute,
    public user: UsersService,
    public auth: AuthRepository,
    public statusHttpService: OutOfOfficeStatusService,
    private datePipe: DatePipe
  ) {}

  addTimeOffset = (date: Date): Date => {
    const result = new Date(date);
    result.setHours(result.getHours() - this.timeOffset);
    return result;
  };

  submitStatus(status: Partial<IOutOfOfficeStatus>) {
    this.submitErrors = null;
    status.timeOffset = new Date().getTimezoneOffset() / -60;
    this.route.params.subscribe((p) => {
      if (p['id']) {
        this.service.updateStatus(p['id'], status).subscribe(
          (i) => {
            this.router.navigate(['out-of-office-status']);
          },
          (error) => {
            this.submitErrors = error;
          }
        );
      } else {
        this.service.post(status).subscribe(
          (i) => {
            this.router.navigate(['out-of-office-status']);
          },
          (error) => {
            this.submitErrors = error;
          }
        );
      }
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe((p) => {
      if (p['id']) {
        this.statusHttpService.loadStatusById(p['id']).subscribe((res) => {
          this.editedStatus = {
            dateFrom: this.addTimeOffset(res.from),
            dateTo:
              this.datePipe.transform(
                this.addTimeOffset(res.to),
                'dd-MM-yyyy'
              ) !=
              this.datePipe.transform(
                this.addTimeOffset(res.from),
                'dd-MM-yyyy'
              )
                ? this.addTimeOffset(res.to)
                : undefined,
            timeFrom:
              this.datePipe.transform(
                this.addTimeOffset(res.to),
                'dd-MM-yyyy'
              ) ==
              this.datePipe.transform(
                this.addTimeOffset(res.from),
                'dd-MM-yyyy'
              )
                ? this.addTimeOffset(res.from)
                : undefined,
            timeTo:
              this.datePipe.transform(
                this.addTimeOffset(res.to),
                'dd-MM-yyyy'
              ) ==
              this.datePipe.transform(
                this.addTimeOffset(res.from),
                'dd-MM-yyyy'
              )
                ? this.addTimeOffset(res.to)
                : undefined,
            type: res.type,
            userId: res.userId,
          };
        });
      }
    });
    if (this.auth.isTenantAdmin$) {
      this.user.loadForDatalist().subscribe((r) => {
        this.users = r;
      });
    }
  }
}
