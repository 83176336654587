import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserGroupsService } from 'app/api/services/usergroup.service';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-usergroups-selection',
  templateUrl: './usergroups-selection.component.html',
  styleUrls: ['./usergroups-selection.component.scss'],
})
export class UserGroupsSelectionComponent {
  userGroups: DatalistOption[] = [];
  selectedOptions: any[] = [];
  constructor(
    public userGroupsService: UserGroupsService,
    public ref: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this.userGroupsService.loadForDatalist().subscribe((data) => {
      this.userGroups = data;
    });
  }

  cancel() {
    this.ref.close();
  }

  submit() {
    const selectedGroupIds = this.userGroups
      .filter((group) => group.selected)
      .map((group) => group.value);
    this.ref.close(selectedGroupIds);
  }
}
