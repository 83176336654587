import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthRepository } from '../../../../state/auth.repository';
import {
  TimelogsRepository,
  IProject,
  ITask,
  IMark,
} from '../../../../state/timelogs.repository';
import { TimelogsService } from '../../../../state/timelogs.service';
import { UiRepository } from '../../../../state/ui.repository';
import { EnvState } from '../../../shared/helpers/env-state';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-timelogs-grid',
  templateUrl: './timelogs-grid.component.html',
  styleUrls: ['./timelogs-grid.component.scss'],
})
export class TimelogsGridComponent implements OnInit {
  constructor(
    public service: TimelogsService,
    public repository: TimelogsRepository,
    public repo: AuthRepository,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState
  ) {}

  MondayGenTotal: number = 0.0;
  TuesdayGenTotal: number = 0.0;
  WednesdayGenTotal: number = 0.0;
  ThursdayGenTotal: number = 0.0;
  FridayGenTotal: number = 0.0;
  SaturdayGenTotal: number = 0.0;
  SundayGenTotal: number = 0.0;

  weekTotal: number = 0.0;
  projects: IProject[] | null = null;
  Date: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  mondayTitle: string = this.Date?.date().toString();
  tuesdayTitle: string = this.Date?.add(1, 'day').date().toString();
  wednesdayTitle: string = this.Date?.add(2, 'day').date().toString();
  thursdayTitle: string = this.Date?.add(3, 'day').date().toString();
  fridayTitle: string = this.Date?.add(4, 'day').date().toString();
  saturdayTitle: string = this.Date?.add(5, 'day').date().toString();
  sundayTitle: string = this.Date?.add(6, 'day').date().toString();

  mondayDateString: string = this.Date?.toDate().toDateString();
  sundayDateString: string = this.Date?.add(6, 'day').toDate().toDateString();

  monday: string = this.Date?.add(1, 'day').toJSON().slice(0, 10);
  tuesday: string = this.Date?.add(2, 'day').toJSON().slice(0, 10);
  wednesday: string = this.Date?.add(3, 'day').toJSON().slice(0, 10);
  thursday: string = this.Date?.add(4, 'day').toJSON().slice(0, 10);
  friday: string = this.Date?.add(5, 'day').toJSON().slice(0, 10);
  saturday: string = this.Date?.add(6, 'day').toJSON().slice(0, 10);
  sunday: string = this.Date?.add(7, 'day').toJSON().slice(0, 10);

  tasks: ITask[] = [] as ITask[];
  monday_value: string = '0.00';
  tuesday_value: string = '0.00';
  wednesday_value: string = '0.00';
  thursday_value: string = '0.00';
  friday_value: string = '0.00';
  total_value: string = '0.00';
  start: string = '23:00';

  marksForWeek: IMark[] = [];

  ngOnInit(): void {
    this.service
      .loadPersonalProjectsWithMarksForWeek(this.monday, this.sunday)
      .subscribe({
        next: (x) => {
          if (x) {
            this.projects = x;
            this.updateDate();
          } else {
          }
        },
        complete: () => {
          this.totalPerWeek();
        },
      });
  }

  addweek() {
    this.Date = this.Date.add(1, 'week');
    this.updateDate();
    this.projects?.forEach((y) => {
      this.service
        .loadPersonalProjectsWithMarksForWeek(this.monday, this.sunday)
        .subscribe({
          next: (z) => {
            this.projects = z;
          },
          complete: () => {
            this.totalPerWeek();
          },
        });
    });
  }

  minusweek() {
    this.Date = this.Date.add(-1, 'week');
    this.updateDate();
    this.projects!.forEach((y) => {
      this.service
        .loadPersonalProjectsWithMarksForWeek(this.monday, this.sunday)
        .subscribe({
          next: (z) => {
            this.projects = z;
          },
          complete: () => {
            this.totalPerWeek();
          },
        });
    });
  }
  TotalForDay(tasks: ITask[], date: number) {
    this.totalPerWeek();

    var res = 0.0;
    if (date > 7 || date < 0 || date == null || date == undefined) {
      return '0.00';
    }

    tasks?.forEach((element) => {
      res += element.marks[date]?.decimalHours;
    });
    if (res == 0) {
      return '0.00';
    }

    return ((res * 100) / 100).toFixed(2);
  }

  defineTotal(selectedProject: string, tasks: ITask[]) {
    this.totalPerWeek();
    if (tasks) {
      document.getElementById(`${selectedProject}//mondayTotal`)!.innerText =
        '0.00';
      document.getElementById(`${selectedProject}//tuesdayTotal`)!.innerText =
        '0.00';
      document.getElementById(`${selectedProject}//wednesdayTotal`)!.innerText =
        '0.00';
      document.getElementById(`${selectedProject}//thursdayTotal`)!.innerText =
        '0.00';
      document.getElementById(`${selectedProject}//fridayTotal`)!.innerText =
        '0.00';
      document.getElementById(`${selectedProject}//saturdayTotal`)!.innerText =
        '0.00';
      document.getElementById(`${selectedProject}//sundayTotal`)!.innerText =
        '0.00';

      tasks?.forEach((element) => {
        document.getElementById(
          `${selectedProject}//${element.name}//total`
        )!.innerText = String(
          (
            Math.round(
              (+(
                document.getElementById(
                  `${selectedProject}//${element.name}//monday`
                ) as HTMLInputElement
              )?.value?.replace(',', '.') +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//tuesday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.') +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//wednesday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.') +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//thursday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.') +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//friday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.') +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//saturday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.') +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//sunday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.')) *
                100
            ) / 100
          ).toFixed(2)
        );

        document.getElementById(`${selectedProject}//mondayTotal`)!.innerText =
          String(
            (
              Math.round(
                (+document.getElementById(`${selectedProject}//mondayTotal`)!
                  .innerText +
                  +(
                    document.getElementById(
                      `${selectedProject}//${element.name}//monday`
                    ) as HTMLInputElement
                  )?.value?.replace(',', '.')) *
                  100
              ) / 100
            ).toFixed(2)
          );
        document.getElementById(`${selectedProject}//tuesdayTotal`)!.innerText =
          String(
            (
              Math.round(
                (+document.getElementById(`${selectedProject}//tuesdayTotal`)!
                  .innerText +
                  +(
                    document.getElementById(
                      `${selectedProject}//${element.name}//tuesday`
                    ) as HTMLInputElement
                  )?.value?.replace(',', '.')) *
                  100
              ) / 100
            ).toFixed(2)
          );
        document.getElementById(
          `${selectedProject}//wednesdayTotal`
        )!.innerText = String(
          (
            Math.round(
              (+document.getElementById(`${selectedProject}//wednesdayTotal`)!
                .innerText +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//wednesday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.')) *
                100
            ) / 100
          ).toFixed(2)
        );
        document.getElementById(
          `${selectedProject}//thursdayTotal`
        )!.innerText = String(
          (
            Math.round(
              (+document.getElementById(`${selectedProject}//thursdayTotal`)!
                .innerText +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//thursday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.')) *
                100
            ) / 100
          ).toFixed(2)
        );
        document.getElementById(`${selectedProject}//fridayTotal`)!.innerText =
          String(
            (
              Math.round(
                (+document.getElementById(`${selectedProject}//fridayTotal`)!
                  .innerText +
                  +(
                    document.getElementById(
                      `${selectedProject}//${element.name}//friday`
                    ) as HTMLInputElement
                  )?.value?.replace(',', '.')) *
                  100
              ) / 100
            ).toFixed(2)
          );
        document.getElementById(
          `${selectedProject}//saturdayTotal`
        )!.innerText = String(
          (
            Math.round(
              (+document.getElementById(`${selectedProject}//saturdayTotal`)!
                .innerText +
                +(
                  document.getElementById(
                    `${selectedProject}//${element.name}//saturday`
                  ) as HTMLInputElement
                )?.value?.replace(',', '.')) *
                100
            ) / 100
          ).toFixed(2)
        );
        document.getElementById(`${selectedProject}//sundayTotal`)!.innerText =
          String(
            (
              Math.round(
                (+document.getElementById(`${selectedProject}//sundayTotal`)!
                  .innerText +
                  +(
                    document.getElementById(
                      `${selectedProject}//${element.name}//sunday`
                    ) as HTMLInputElement
                  )?.value?.replace(',', '.')) *
                  100
              ) / 100
            ).toFixed(2)
          );

        document.getElementById(`${selectedProject}//bottomTotal`)!.innerText =
          String(
            (
              Math.round(
                (+document
                  .getElementById(`${selectedProject}//mondayTotal`)!
                  .innerText?.replace(',', '.') +
                  +document
                    .getElementById(`${selectedProject}//tuesdayTotal`)!
                    .innerText?.replace(',', '.') +
                  +document
                    .getElementById(`${selectedProject}//wednesdayTotal`)!
                    .innerText?.replace(',', '.') +
                  +document
                    .getElementById(`${selectedProject}//thursdayTotal`)!
                    .innerText?.replace(',', '.') +
                  +document
                    .getElementById(`${selectedProject}//fridayTotal`)!
                    .innerText?.replace(',', '.') +
                  +document
                    .getElementById(`${selectedProject}//saturdayTotal`)!
                    .innerText?.replace(',', '.') +
                  +document
                    .getElementById(`${selectedProject}//sundayTotal`)!
                    .innerText?.replace(',', '.')) *
                  100
              ) / 100
            ).toFixed(2)
          );
      });
    }
  }
  updateDate() {
    this.totalPerWeek();
    this.monday = this.Date?.add(1, 'day').toJSON().slice(0, 10);
    this.tuesday = this.Date?.add(2, 'day').toJSON().slice(0, 10);
    this.wednesday = this.Date?.add(3, 'day').toJSON().slice(0, 10);
    this.thursday = this.Date?.add(4, 'day').toJSON().slice(0, 10);
    this.friday = this.Date?.add(5, 'day').toJSON().slice(0, 10);
    this.saturday = this.Date?.add(6, 'day').toJSON().slice(0, 10);
    this.sunday = this.Date?.add(7, 'day').toJSON().slice(0, 10);
    this.mondayTitle = this.Date?.date().toString();
    this.tuesdayTitle = this.Date?.add(1, 'day').date().toString();
    this.wednesdayTitle = this.Date?.add(2, 'day').date().toString();
    this.thursdayTitle = this.Date?.add(3, 'day').date().toString();
    this.fridayTitle = this.Date?.add(4, 'day').date().toString();
    this.saturdayTitle = this.Date?.add(5, 'day').date().toString();
    this.sundayTitle = this.Date?.add(6, 'day').date().toString();
    this.mondayDateString = this.Date?.toDate()
      .toDateString()
      .slice(3, this.Date?.toDate().toDateString().length);
    this.sundayDateString = this.Date?.add(6, 'day')
      .toDate()
      .toDateString()
      .slice(3, this.Date?.add(6, 'day').toDate().toDateString().length);
  }

  changeHoursForDate(
    date: string,
    task_id: string,
    task_name: string,
    day: string,
    projectSelectedId: string,
    projectSelected: string,
    tasks: ITask[]
  ) {
    this.defineTotal(projectSelected, tasks);
    const taskId = task_id;
    const dateString = date;
    const projectId = projectSelectedId;
    const hours = ((
      document.getElementById(
        `${projectSelected}//${task_name}//${day}`
      ) as HTMLInputElement
    )?.value).replace(',', '.');
    this.service.addMark({ hours, dateString, taskId, projectId }).subscribe({
      complete: () => {
        this.updateDate();
      },
    });
  }

  totalPerWeek() {
    this.MondayGenTotal = 0;
    this.TuesdayGenTotal = 0;
    this.WednesdayGenTotal = 0;
    this.ThursdayGenTotal = 0;
    this.FridayGenTotal = 0;
    this.SaturdayGenTotal = 0;
    this.SundayGenTotal = 0;

    this.weekTotal = 0;
    this.projects?.forEach((item) => {
      this.weekTotal += +item.totalHours;
      item.projectTasks.forEach((task) => {
        this.MondayGenTotal += task.marks[0].decimalHours;
        this.TuesdayGenTotal += task.marks[1].decimalHours;
        this.WednesdayGenTotal += task.marks[2].decimalHours;
        this.ThursdayGenTotal += task.marks[3].decimalHours;
        this.FridayGenTotal += task.marks[4].decimalHours;
        this.SaturdayGenTotal += task.marks[5].decimalHours;
        this.SundayGenTotal += task.marks[6].decimalHours;
      });
    });
  }
}
