<div
  class="modal fade"
  bsModal
  id="cloneModal"
  tabindex="-1"
  [config]="{ show: true }"
  #cloneModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0 br-16">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="cloneModalLabel"
          i18n="Title for confirmation dialog"
        >
          {{ "confirmYourAction" | transloco }}
        </h6>
        <button
          type="button"
          class="btn-close"
          value="{{ 'close' | transloco }}"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div *ngIf="Form">
        <form
          [formGroup]="Form"
          (ngSubmit)="submit()"
          enctype="multipart/form-data"
        >
          <div class="modal-body">
            <div class="form-floating mb-3">
              <p class="mb-1" i18n="Confirm clone message start">
                {{ "youAreAboutToCloneEntry" | transloco }}:
              </p>
              <p
                class="mb-1"
                class="form-group form-floating mb-2"
                [appValidateState]="Form.get('title')"
              >
                <input class="inpt" formControlName="title" />
              </p>
              <!-- [(ngModel)]="cloneCandidate!.title" -->
              <span
                *ngIf="
                  tenantsOptions && tenantsOptions!.length > 1 && currentTenant
                "
              >
                <p class="mb-1" i18n="Confirm clone message start">
                  {{ "pleaseChooseTenantCloneTo:" | transloco }}
                </p>
                <div
                  class="form-group form-floating mb-3"
                  [appValidateState]="Form.get('tenantId')"
                >
                  <app-datalist-select
                    class=""
                    [activeValue]="Form?.value.tenantId"
                    [options]="tenantsOptions"
                    (activeValueChange)="
                      updateValue('tenantId', $event); currentTenant = $event
                    "
                    [multiple]="false"
                  ></app-datalist-select>
                </div>
              </span>
              <div class="col-sm-6 col-6 d-flex psm0" *ngIf="isEmail">
                <input
                  class="check form-check-input"
                  type="checkbox"
                  formControlName="withUsergroups"
                />
                <span class="txt mt-1 ms-2">{{
                  "withGroups" | transloco
                }}</span>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-center">
            <button
              class="btn btn-orange me-2"
              type="submit"
              i18n="Clone button"
            >
              {{ "clone" | transloco }}
            </button>
            <button class="btn btn-d" (click)="cancel.emit()" i18n="@@cancel">
              {{ "cancel" | transloco }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
