import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  TimelogsRepository,
  daysTotal,
  ILogWeek,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-timelogs-users-overview',
  templateUrl: './timelogs-users-overview.component.html',
  styleUrls: ['./timelogs-users-overview.component.scss'],
})
export class TimelogsUsersOverviewComponent implements OnInit {
  Datejs: dayjs.Dayjs = dayjs().startOf('week');
  currentDate = new Date();
  startDate = new Date(this.currentDate.getFullYear(), 0, 1);
  days = Math.floor(
    (+this.currentDate - +this.startDate) / (24 * 60 * 60 * 1000)
  );
  daysTotals: daysTotal[] = [];
  monTotal: number = 0;
  tueTotal: number = 0;
  wedTotal: number = 0;
  thuTotal: number = 0;
  friTotal: number = 0;
  satTotal: number = 0;
  sunTotal: number = 0;
  genTotal: number = 0;
  weekNumber = Math.ceil(this.days / 7);
  year = this.currentDate.getFullYear();
  WeekDateString: string = `${this.year}-W${this.weekNumber}`;
  selectedWeek: ILogWeek | null = null;
  WeeksOptions: DatalistOption[] | null = null;
  allWeeks: ILogWeek[] = [];
  @Input() set weeks(value: ILogWeek[] | null) {
    if (!value) {
      this.WeeksOptions = null;
    } else {
      this.WeeksOptions = value.map((x) => ({
        value: x.label,
        label: x.label,
      }));
    }
  }
  constructor(
    public service: TimelogsService,
    public repo: TimelogsRepository
  ) {}

  loadData() {
    this.service.getWeeksOptions().subscribe((x) => {
      this.weeks = x;
      this.allWeeks = x;
      this.selectedWeek = x[0];
      if (this.selectedWeek) {
        var startSelectedWeek = dayjs(this.selectedWeek?.start)
          .add(11, 'hour')
          .toISOString();
        var endSelectedWeek = dayjs(this.selectedWeek?.end)
          .add(11, 'hour')
          .toISOString();
      } else {
        console.log('no curent week from database so create in angular');
        var startSelectedWeek = dayjs()
          .startOf('week')
          .add(11, 'hour')
          .toISOString();
        var endSelectedWeek = dayjs()
          .endOf('week')
          .add(11, 'hour')
          .toISOString();
      }

      this.service
        .getTimeLogsOverviewUsers(
          startSelectedWeek.slice(0, 10),
          endSelectedWeek.slice(0, 10)
        )
        .subscribe((x) => {
          this.daysTotals = x;
          this.evaluateTotal();
        });
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  changeinput(event: any) {
    if (event) {
      let weekToFind = this.allWeeks?.find((x) => x.label == event);
      if (weekToFind) this.selectedWeek = weekToFind;

      var startSelectedWeek = dayjs(this.selectedWeek?.start).toISOString();
      var endSelectedWeek = dayjs(this.selectedWeek?.end).toISOString();
      if (this.selectedWeek) {
        var startSelectedWeek = dayjs(this.selectedWeek?.start)
          .add(11, 'hour')
          .toISOString();
        var endSelectedWeek = dayjs(this.selectedWeek?.end)
          .add(11, 'hour')
          .toISOString();
        console.log('curent startSelectedWeek', startSelectedWeek.slice(0, 10));
        console.log('curent endSelectedWeek', endSelectedWeek.slice(0, 10));
      } else {
        console.log('no curent week from database so create in angular');
        var startSelectedWeek = dayjs()
          .startOf('week')
          .add(11, 'hour')
          .toISOString();
        var endSelectedWeek = dayjs()
          .endOf('week')
          .add(11, 'hour')
          .toISOString();
      }
      this.service
        .getTimeLogsOverviewUsers(
          startSelectedWeek.slice(0, 10),
          endSelectedWeek.slice(0, 10)
        )
        .subscribe((x) => {
          this.daysTotals = x;
          this.evaluateTotal();
        });
    }
  }

  evaluateTotal() {
    this.monTotal = 0;
    this.tueTotal = 0;
    this.wedTotal = 0;
    this.thuTotal = 0;
    this.friTotal = 0;
    this.satTotal = 0;
    this.sunTotal = 0;
    this.genTotal = 0;

    this.daysTotals.forEach((x) => {
      this.monTotal += x.monHours;
      this.genTotal += x.monHours;
      this.tueTotal += x.tueHours;
      this.genTotal += x.tueHours;
      this.wedTotal += x.wedHours;
      this.genTotal += x.wedHours;
      this.thuTotal += x.thuHours;
      this.genTotal += x.thuHours;
      this.friTotal += x.friHours;
      this.genTotal += x.friHours;
      this.satTotal += x.satHours;
      this.genTotal += x.satHours;
      this.sunTotal += x.sunHours;
      this.genTotal += x.sunHours;
    });
    this.genTotal = +this.genTotal.toFixed(2);
    this.monTotal = +this.monTotal.toFixed(2);
    this.tueTotal = +this.tueTotal.toFixed(2);
    this.wedTotal = +this.wedTotal.toFixed(2);
    this.thuTotal = +this.thuTotal.toFixed(2);
    this.friTotal = +this.friTotal.toFixed(2);
    this.satTotal = +this.satTotal.toFixed(2);
    this.sunTotal = +this.sunTotal.toFixed(2);
  }
}
