import { Component, HostListener, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';

import {
  DocumentsRepository,
  IDocument,
  DocumentsSortOptions,
} from '../../../../state/document.repository';
import { DocumentsService } from '../../../../state/document.service';

@Component({
  selector: 'app-admin-document-page',
  templateUrl: './admin-document-page.component.html',
  styleUrls: ['./admin-document-page.component.scss'],
})
export class AdminDocumentPageComponent implements OnInit {
  sortOptions = DocumentsSortOptions;
  innerWidth = 0;
  searchFilter: string = '';

  deleteConfirmation: IDocument | null = null;
  constructor(
    public repo: DocumentsRepository,
    public service: DocumentsService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    // this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }
  handleDeleteClick(post: IDocument) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
