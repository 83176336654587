<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  (change)="formUpdated()"
  enctype="multipart/form-data"
  *ngIf="!isEditForm$ && usersOptions; else loader"
>
  <div class="form-group col-12 d-flex row justify-content-center">
    <div class="row justify-content-center">
      <div
        class="mb-2 form-floating col-xl-4"
        [appValidateState]="editForm.get('userSelect')"
        *ngIf="repo.isTenantAdmin$ | async"
      >
        <div class="inpt-title">
          {{ "select" | transloco }} {{ "user" | transloco }}
        </div>
        <app-datalist-select
          id="adminUserSelect"
          [forUsers]="true"
          [options]="usersOptions"
          [(activeValue)]="currentUserId"
          (activeValueChange)="updateValue('userSelect', $event)"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="mb-2 form-floating col-xl-4"
        [appValidateState]="editForm.get('outType')"
      >
        <div class="inpt-title">
          {{ "status" | transloco }}
        </div>
        <app-datalist-select
          id="outType"
          [options]="outTypes"
          [(activeValue)]="editForm?.value.outType"
          (activeValueChange)="updateValue('outType', $event)"
          [searchable]="false"
          [withTranslations]="true"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="form-check form-switch d-flex align-items-center col-xl-4 ms-4 mb-2"
      >
        <input
          class="form-check-input me-2"
          type="checkbox"
          id="oneDay"
          formControlName="oneDayCheckbox"
          (change)="checkValue($event)"
        />
        <label class="form-check-label">{{ "oneDay" | transloco }}</label>
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="mb-4 form-floating col-xl-4"
        [appValidateState]="editForm.get('dateFrom')"
      >
        <div class="inpt-title">
          {{ "start" | transloco }} {{ "date" | transloco }}
        </div>
        <input
          id="dateFrom"
          formControlName="dateFrom"
          type="date"
          class="inpt"
        />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="mb-4 form-floating col-xl-4"
        [appValidateState]="editForm.get('dateTo')"
        *ngIf="!oneDay"
      >
        <div class="inpt-title">
          {{ "end" | transloco }} {{ "date" | transloco }}
        </div>
        <input id="dateTo" formControlName="dateTo" type="date" class="inpt" />
      </div>
    </div>
    <div class="row justify-content-center">
      <div
        class="mb-4 form-floating col-xl-2 col-md-6"
        [appValidateState]="editForm.get('timeFrom')"
        *ngIf="oneDay"
      >
        <div class="inpt-title">
          {{ "start" | transloco }} {{ "time" | transloco }}
        </div>
        <input
          id="timeFrom"
          formControlName="timeFrom"
          type="time"
          class="inpt"
        />
      </div>
      <div
        class="mb-4 form-floating col-xl-2 col-md-6"
        [appValidateState]="editForm.get('timeTo')"
        *ngIf="oneDay"
      >
        <div class="inpt-title">
          {{ "end" | transloco }} {{ "time" | transloco }}
        </div>
        <input id="timeTo" formControlName="timeTo" type="time" class="inpt" />
      </div>
    </div>
    <div class="form-group form-floating h-100 pb-3 col-xl-2 col-lg-6">
      <button type="submit" class="btn btn-orange shadow-sm w-100">
        {{ "save" | transloco }}
      </button>
    </div>
    <div
      class="form-group form-floating h-100 pb-3 col-xl-2 col-lg-6"
      *ngIf="statusId"
    >
      <button
        type="button"
        class="btn btn-danger shadow-sm w-100"
        (click)="deleteConfirmation = true"
      >
        {{ "delete" | transloco }}
      </button>
    </div>
  </div>
  <app-confirm-delete
    id="deleteUserModal"
    *ngIf="deleteConfirmation"
    (confirm)="deleteStatus(statusId); deleteConfirmation = false"
    (cancel)="deleteConfirmation = false"
  ></app-confirm-delete>
</form>
<ng-template #loader>
  <div class="w-100 d-flex justify-content-center p-4">
    <div class="spinner-border"></div>
  </div>
</ng-template>
