
<div class="row d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3">
    <h2 class="page-title sm-del">{{ "messages" | transloco }}</h2>
    <div class="mainSubtext mb-sm-4 sm-del">
      {{ "newTeamMessages" | transloco }}
    </div>

    <div class="" *ngFor="let message of messages; let i = index">
      <div
        *ngIf="unreadIndx == i"
        class="d-flex flex-row align-items-center mb-4"
        style="width: 100%"
      >
        <div class="line col"></div>
        <div class="unread me-2 ms-2">{{ "unread" | transloco }}</div>
        <div class="line col"></div>
      </div>
      <div
        *ngIf="readIndx == i"
        class="d-flex flex-row align-items-center mt-5"
        style="width: 100%"
      >
        <div class="line col"></div>
        <div class="read me-2 ms-2">{{ "read" | transloco }}</div>
        <div class="line col"></div>
      </div>
      <div class="d-flex flex-row flex-start me-2 mb-4 mt-1">
        <img
          class="rounded-circle me-2"
          [src]="blobService.getUserImagePath(message.imagePath ? message.imagePath : DEFAULT_IMAGE)"
          alt="avatar"
          width="40"
          height="40"
          style="background: #ff6a00"
        />
        <div class="d-flex flex-column align-items-start" style="width: 100%">
          <div class="flex-grow-1 order-1" style="width: 100%">
            <div
              class="d-flex flex-row align-items-center justify-content-between mb-1"
            >
              <div class="commname">
                {{ message.userName ? message.userName : "Admin" }}
              </div>
              <div class="commdate">
                {{ message.createdAt | date : "EEEE HH:mma" }}
              </div>
            </div>
            <div class="text-break flex-row commtext pt-2 pb-3 p-3">
              <div class="message-header pb-1">{{ message.header }}</div>
              <div>{{ message.body }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
