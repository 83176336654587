import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { getMessaging, getToken, onMessage } from '@firebase/messaging';
import { initializeApp } from 'firebase/app';
import { NotificationService } from 'app/api/services/notificationService';

@Injectable({
  providedIn: 'root',
})
export class FireBaseMessagingService {
  app = initializeApp(environment.firebase);

  constructor(public notificationsservice: NotificationService) {}

  requestPermission() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/custom-worker.js', {
          scope: '/firebase-cloud-messaging-push-scope',
        })
        .then((registration) => {
          const messaging = getMessaging(this.app);

          return getToken(messaging, {
            vapidKey: environment.firebase.vapidKey,
            serviceWorkerRegistration: registration,
          });
        })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem('firebaseToken', currentToken);
            this.notificationsservice
              .sendTokenToServer(currentToken)
              .subscribe();
          } else {
            console.log(
              'No registration token available. Request permission to generate one.'
            );
          }
        })
        .catch((error) => {
          console.error('An error occurred while retrieving token. ', error);
        });
    } else {
      console.error('Service Workers are not supported in this browser.');
    }
  }

  listen() {
    const messaging = getMessaging(this.app);

    onMessage(messaging, (payload: any) => {
      console.log('Message received. ', payload);

      const notificationTitle = payload.notification?.title || 'No Title';
      const notificationBody = payload.notification?.body || 'No Body';
      const notificationUrl = payload.data?.click_action || '/';

      const notificationOptions = {
        body: notificationBody,
        data: { click_action: notificationUrl },
      };

      setTimeout(() => {
        navigator.serviceWorker
          .getRegistration('/firebase-cloud-messaging-push-scope') // Вказуємо скоуп для отримання реєстрації
          .then((registration: any) => {
            registration.showNotification(
              notificationTitle,
              notificationOptions
            );
          });
      }, 1000);
    });
  }
}
