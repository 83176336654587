import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import * as dayjs from 'dayjs';
import {
  IDateTransmit,
  IUserStatusShown,
} from '../../../../state/out-of-office-status.repository';
import { Router } from '@angular/router';
import { AuthRepository } from '../../../../state/auth.repository';
import { Dayjs } from 'dayjs';

@Component({
  selector: 'app-out-of-office-status-grid',
  templateUrl: './out-of-office-status-grid.component.html',
  styleUrls: ['./out-of-office-status-grid.component.scss'],
})
export class OutOfOfficeStatusGridComponent implements OnInit {
  @Input() data: IUserStatusShown[] | undefined;
  @Output() transmitDates = new EventEmitter<Partial<IDateTransmit>>();

  startDate: dayjs.Dayjs = dayjs().startOf('week').add(1, 'day');
  endDate: dayjs.Dayjs = dayjs().startOf('week').add(5, 'day');
  week: Dayjs[] = [];
  timeOffset = new Date().getTimezoneOffset() / -60;
  currentUserId: string | null = null;
  isUserAdmin$: boolean = false;
  isSmallScreen$: boolean = window.innerWidth < 1067;
  todayStatusesEmpty$: boolean[] = Array(5).fill(true);

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isSmallScreen$ = window.innerWidth < 1067;
  }

  @Input() set dataSetter(value: IUserStatusShown[] | undefined) {
    this.data = value;
    this.data?.forEach((user) => {
      for (let i = 0; i < this.week.length; i++) {
        if (user.statDayRecords[i]) this.todayStatusesEmpty$[i] = false;
      }
    });
  }

  constructor(private router: Router, private auth: AuthRepository) {}

  dateTransmitter() {
    this.data = undefined;
    this.transmitDates.emit({
      startDate: this.startDate,
      endDate: this.endDate,
      timeOffset: this.timeOffset,
    });
  }

  ngOnInit(): void {
    this.currentUserId = this.auth.getId();
    this.auth.isTenantAdmin$.subscribe((res) => {
      this.isUserAdmin$ = res;
    });
    this.dateTransmitter();
    this.setWeek();
  }

  setWeek() {
    this.week = [];
    for (let i = 0; i <= this.endDate.diff(this.startDate, 'day'); i++) {
      this.week?.push(this.startDate.add(i, 'day'));
    }
  }

  changeWeek(dir: number) {
    if (this.isSmallScreen$) this.todayStatusesEmpty$ = Array(5).fill(true);
    this.startDate = this.startDate.add(dir, 'week');
    this.endDate = this.endDate.add(dir, 'week');
    this.dateTransmitter();
    this.setWeek();
  }

  redirect(userId: string, id: string) {
    if (this.isUserAdmin$ || this.currentUserId === userId)
      this.router.navigate([`/out-of-office-status/${id}`]);
  }
}
