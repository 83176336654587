<div class="d-flex flex-column">
  <div class="col-12">
    <ul class="nav nav-pills">
      <li class="nav-item" *ngFor="let item of allMainSideMenuItems">
        <a
          class="nav-link"
          aria-current="page"
          routerLinkActive="active"
          routerLink="{{ item.routerLink }}"
          (click)="menuclose()"
        >
          <div class="d-flex align-items-center justify-content-center img-box">
            <img src="{{ item.svgPath }}" />
          </div>
          {{ item.title | transloco }}
        </a>
      </li>

      <li *ngIf="allIgniteSideMenuItems.length > 0" class="nav-item pt-5">
        Ignite
      </li>

      <li class="nav-item" *ngFor="let item of allIgniteSideMenuItems">
        <a
          class="nav-link"
          routerLinkActive="active"
          aria-current="page"
          routerLink="{{ item.routerLink }}"
          (click)="menuclose()"
        >
          <div class="d-flex align-items-center justify-content-center img-box">
            <img src="{{ item.svgPath }}" />
          </div>
          <div>
            {{ item.title | transloco }}
          </div>
        </a>
      </li>

      <li *ngIf="allAdminSideMenuItems.length > 0" class="nav-item pt-5">
        {{ "adminPanel" | transloco }}
      </li>

      <li class="nav-item" *ngFor="let item of allAdminSideMenuItems">
        <a
          class="nav-link"
          routerLinkActive="active"
          aria-current="page"
          routerLink="{{ item.routerLink }}"
          (click)="menuclose()"
        >
          <div class="d-flex align-items-center justify-content-center img-box">
            <img src="{{ item.svgPath }}" />
          </div>
          <div>
            {{ item.title | transloco }}
          </div>
        </a>
      </li>
    </ul>
  </div>
</div>
<div
  *ngIf="tenantsOptions && tenantsOptions.length > 1"
  class="form-floating mb-3"
>
  <p class="ms-2 mb-0 mt-2" style="color: white">
    {{ "activeTenant" | transloco }}
  </p>
  <app-datalist-select
    class=""
    [activeValue]="tenantId"
    [options]="tenantsOptions"
    (activeValueChange)="setActiveTenant($event)"
    [multiple]="false"
  ></app-datalist-select>
</div>
