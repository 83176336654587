import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Onboarding } from '../models/onboarding/onboardingDto';
import { OnboardingComment } from '../models/onboarding/onboardingCommentDto';

const API = '/api/onboarding';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  constructor(private http: HttpClient) {}

  create(onboarding: Partial<Onboarding>) {
    return this.http.post<Onboarding>(API, onboarding);
  }

  update(onboarding: Partial<Onboarding>) {
    return this.http.patch<Onboarding>(API, onboarding);
  }

  getAll(forAdmin: boolean = false) {
    const query = forAdmin ? `?forAdmin=${forAdmin}` : '';
    return this.http.get<Onboarding[]>(`${API}${query}`);
  }

  startAsUser(id: string) {
    return this.http.get(`${API}/${id}/userStart`);
  }

  get(id: string | null) {
    return this.http.get<Onboarding>(`${API}/${id}`);
  }

  updateProgression(index: number, onboardingId: string) {
    return this.http.get(`${API}/${onboardingId}/${index}`);
  }

  remove(onboardingId: string) {
    return this.http.delete(`${API}/${onboardingId}`);
  }

  postComment(comment: Partial<OnboardingComment>) {
    return this.http.post<OnboardingComment>(`${API}/comment`, comment);
  }

  updateComment(comment: Partial<OnboardingComment>) {
    return this.http.patch<OnboardingComment>(`${API}/comment`, comment);
  }

  getComments(moduleId: string, forResponsible: boolean = false) {
    return this.http.get<OnboardingComment[]>(
      `${API}/comments/${moduleId}?forResponsible=${forResponsible}`
    );
  }

  deleteComment(commentId: string) {
    return this.http.delete(`${API}/comment/${commentId}`);
  }
}
