import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MediaFileMediaType,
  MediaLibraryFileDto,
} from '../models/mediaLibrary/mediaLibraryFileDto';
import { MatDialog } from '@angular/material/dialog';
import { MedialLibraryModalComponent } from 'app/modules/shared/components/media-library/media-library-modal/media-library-modal.component';
import { map, Observable, of } from 'rxjs';

const API = '/api/medialibrary';

@Injectable({
  providedIn: 'root',
})
export class MediaLibraryService {
  constructor(private http: HttpClient, public dialog: MatDialog) {}

  uploadFile(feedPost: FormData) {
    return this.http.post<MediaLibraryFileDto>(`${API}`, feedPost);
  }

  getMediaLibraryFilesData() {
    return this.http.get<MediaLibraryFileDto[]>(`${API}`);
  }

  selectFileFromMediaLibraryInModal(
    allowImagesSelection: boolean = false,
    allowDocumentsSelection: boolean = false,
    allowVideosSelection: boolean = false,
    allowSoundsSelection: boolean = false,
    uploadOnlyPrivateFilesModeActive: boolean = false
  ): Observable<MediaLibraryFileDto | null> {
    const dialogRef = this.dialog.open(MedialLibraryModalComponent, {
      maxWidth: 'unset',
      panelClass: ['col-12', 'py-md-4', 'col-md-9', 'col-xl-8', 'h-100'],
      data: {
        allowDocumentsSelection: allowDocumentsSelection,
        allowImagesSelection: allowImagesSelection,
        allowVideosSelection: allowVideosSelection,
        allowSoundsSelection: allowSoundsSelection,
        uploadOnlyPrivateFilesModeActive: uploadOnlyPrivateFilesModeActive,
      },
    });

    return dialogRef.afterClosed().pipe(map((result) => result || null));
  }

  getMediaLibraryFileIconDependsOnType(file: MediaLibraryFileDto) {
    switch (file.mediaType) {
      case MediaFileMediaType.Image:
        return file.fileUrl;
      case MediaFileMediaType.Video:
        return 'assets/icons/media-library/video-file.svg';
      case MediaFileMediaType.Sound:
        return 'assets/icons/media-library/sound-file.svg';
      default:
        return 'assets/icons/media-library/file.svg';
    }
  }
}
