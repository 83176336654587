import { Component, Input } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  TimelogsRepository,
  daysTotal,
  ILogWeek,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-timelogs-overview-users-month',
  templateUrl: './timelogs-overview-users-month.component.html',
  styleUrls: ['./timelogs-overview-users-month.component.scss'],
})
export class TimelogsOverviewUsersMonthComponent {
  Datejs: dayjs.Dayjs = dayjs().startOf('month');
  currentDate = new Date();
  startDate = new Date(this.currentDate.getFullYear(), 0, 1);
  days = Math.floor(
    (+this.currentDate - +this.startDate) / (24 * 60 * 60 * 1000)
  );
  daysTotals: daysTotal[] = [];
  monTotal: number = 0;
  tueTotal: number = 0;
  wedTotal: number = 0;
  thuTotal: number = 0;
  friTotal: number = 0;
  satTotal: number = 0;
  sunTotal: number = 0;
  genTotal: number = 0;
  monthNumber = dayjs().month();
  year = this.currentDate.getFullYear();
  MonthDateString: string = `${this.year}-M${this.monthNumber}`;
  selectedMonth: ILogWeek | null = null;
  MonthsOptions: DatalistOption[] | null = null;
  allMonths: ILogWeek[] = [];
  @Input() set months(value: ILogWeek[] | null) {
    if (!value) {
      this.MonthsOptions = null;
    } else {
      this.MonthsOptions = value.map((x) => ({
        value: x.label,
        label: x.label,
      }));
    }
  }
  constructor(
    public service: TimelogsService,
    public repo: TimelogsRepository
  ) {}

  loadData() {
    this.service.getMonthsOptions().subscribe((x) => {
      this.months = x;
      this.allMonths = x;
      this.selectedMonth = x[0];
      if (this.selectedMonth) {
        var startSelectedMonth = dayjs(this.selectedMonth?.start)
          .add(11, 'hour')
          .toISOString();
        var endSelectedMonth = dayjs(this.selectedMonth?.end)
          .add(11, 'hour')
          .toISOString();
      } else {
        console.log('no curent week from database so create in angular');
        var startSelectedMonth = dayjs()
          .startOf('month')
          .add(11, 'hour')
          .toISOString();
        var endSelectedMonth = dayjs()
          .endOf('month')
          .add(11, 'hour')
          .toISOString();
      }

      this.service
        .getTimeLogsOverviewUsers(
          startSelectedMonth.slice(0, 10),
          endSelectedMonth.slice(0, 10)
        )
        .subscribe((x) => {
          this.daysTotals = x;
          this.evaluateTotal();
        });
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  changeinput(event: any) {
    if (event) {
      let monthToFind = this.allMonths?.find((x) => x.label == event);
      if (monthToFind) this.selectedMonth = monthToFind;

      var startSelectedMonth = dayjs(this.selectedMonth?.start).toISOString();
      var endselectedMonth = dayjs(this.selectedMonth?.end).toISOString();
      if (this.selectedMonth) {
        var startSelectedMonth = dayjs(this.selectedMonth?.start)
          .add(11, 'hour')
          .toISOString();
        var endselectedMonth = dayjs(this.selectedMonth?.end)
          .add(11, 'hour')
          .toISOString();
      } else {
        console.log('no curent week from database so create in angular');
        var startSelectedMonth = dayjs()
          .startOf('month')
          .add(11, 'hour')
          .toISOString();
        var endselectedMonth = dayjs()
          .endOf('month')
          .add(11, 'hour')
          .toISOString();
      }
      this.service
        .getTimeLogsOverviewUsers(
          startSelectedMonth.slice(0, 10),
          endselectedMonth.slice(0, 10)
        )
        .subscribe((x) => {
          this.daysTotals = x;
          this.evaluateTotal();
        });
    }
  }

  evaluateTotal() {
    this.monTotal = 0;
    this.tueTotal = 0;
    this.wedTotal = 0;
    this.thuTotal = 0;
    this.friTotal = 0;
    this.satTotal = 0;
    this.sunTotal = 0;
    this.genTotal = 0;

    this.daysTotals.forEach((x) => {
      this.monTotal += x.monHours;
      this.genTotal += x.monHours;
      this.tueTotal += x.tueHours;
      this.genTotal += x.tueHours;
      this.wedTotal += x.wedHours;
      this.genTotal += x.wedHours;
      this.thuTotal += x.thuHours;
      this.genTotal += x.thuHours;
      this.friTotal += x.friHours;
      this.genTotal += x.friHours;
      this.satTotal += x.satHours;
      this.genTotal += x.satHours;
      this.sunTotal += x.sunHours;
      this.genTotal += x.sunHours;
    });
    this.genTotal = +this.genTotal.toFixed(2);
    this.monTotal = +this.monTotal.toFixed(2);
    this.tueTotal = +this.tueTotal.toFixed(2);
    this.wedTotal = +this.wedTotal.toFixed(2);
    this.thuTotal = +this.thuTotal.toFixed(2);
    this.friTotal = +this.friTotal.toFixed(2);
    this.satTotal = +this.satTotal.toFixed(2);
    this.sunTotal = +this.sunTotal.toFixed(2);
  }
}
