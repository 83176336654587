import { animate, style, transition, trigger } from '@angular/animations';

const ANIMATION_SPPED = '200ms';
const ENTER_FROM_TOP_HIDDEN_STATE = { transform: 'translate(-50%, -100%)' };
const ENTER_FROM_TOP_VISIBLE_STATE = { transform: 'translate(-50%, 0)' };
const ENTER_FROM_BOTTOM_HIDDEN_STATE = { transform: 'translate(-50%, 100%)' };
const ENTER_FROM_BOTTOM_VISIBLE_STATE = { transform: 'translate(-50%, 0)' };

export const enterFromTopAnimation = trigger('enterFromTop', [
  transition(':enter', [
    style(ENTER_FROM_TOP_HIDDEN_STATE),
    animate(ANIMATION_SPPED, style(ENTER_FROM_TOP_VISIBLE_STATE)),
  ]),
  transition(':leave', [
    style(ENTER_FROM_TOP_VISIBLE_STATE),
    animate(ANIMATION_SPPED, style(ENTER_FROM_TOP_HIDDEN_STATE)),
  ]),
]);

export const enterFromBottomAnimation = trigger('enterFromBottom', [
  transition(':enter', [
    style(ENTER_FROM_BOTTOM_HIDDEN_STATE),
    animate(ANIMATION_SPPED, style(ENTER_FROM_BOTTOM_VISIBLE_STATE)),
  ]),
  transition(':leave', [
    style(ENTER_FROM_BOTTOM_VISIBLE_STATE),
    animate(ANIMATION_SPPED, style(ENTER_FROM_BOTTOM_HIDDEN_STATE)),
  ]),
]);
