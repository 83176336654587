import { Component, HostListener, OnInit } from '@angular/core';
import {
  DocumentTypesRepository,
  IDocumentType,
  DocumentTypeSortOptions,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';

@Component({
  selector: 'app-documenttype-page',
  templateUrl: './documenttype-page.component.html',
  styleUrls: ['./documenttype-page.component.scss'],
})
export class DocumenttypePageComponent implements OnInit {
  sortOptions = DocumentTypeSortOptions;
  innerWidth = 0;
  searchFilter: string = '';

  deleteConfirmation: IDocumentType | null = null;
  constructor(
    public repo: DocumentTypesRepository,
    public service: DocumentTypesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.refreshDataProject();
    this.innerWidth = window.innerWidth;
  }
  refreshDataProject() {
    this.service.reloadPageCustom(this.searchFilter).subscribe();
    /*  this.repo.all$;*/
  }
  handleDeleteClick(post: IDocumentType) {
    this.service.deleteCustom(post.id).subscribe((x) => {
      this.refreshDataProject();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.docSearchRelodePage(this.searchFilter).subscribe();
  }
}
