<a
  *ngIf="!preventClick; else picture"
  routerLink="/profile"
  class="position-relative d-block"
  (click)="profileClick.emit($event)"
>
  <ng-template [ngTemplateOutlet]="picture"></ng-template>
</a>
<ng-container *ngIf="size !== 'sm'">
  <div class="clearfix"></div>
  <h5
    class="position-relative"
    [class.h6]="size === 'md'"
    [class.mb-1]="size === 'md'"
  >
    <ng-container *ngIf="repo.displayName$ | async as name; else email">{{
      name
    }}</ng-container>
  </h5>
  <p class="text-muted">{{ repo.roles$ | async | translateRoles | join }}</p>
</ng-container>
<ng-template #picture>
  <figure
    class="avatar avatar-160 rounded-circle mx-auto bg-secondary"
    [class.p-1px]="!(repo.image$ | async)"
    [class.avatar-160]="size === 'lg'"
    [class.mb-4]="size === 'lg'"
    [class.avatar-120]="size === 'md'"
    [class.mb-3]="size === 'md'"
    [class.avatar-36]="size === 'sm'"
  >
    <img
      [src]="(repo.image$ | async) || DEFAULT_IMAGE"
      [alt]="repo.displayName$ | async"
    />
  </figure>
</ng-template>
<ng-template #email>{{ repo.email$ | async }}</ng-template>
