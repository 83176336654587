<table class="w-100" *ngIf="isSmallScreen$">
  <th class="py-3 w-100">
    <div class="d-flex align-items-center justify-content-between w-100">
      <div class="p-2 nav-btn" (click)="changeWeek(-1)">
        <img src="assets/icons/chevron-left.svg" alt="Prev week" />
      </div>
      <span>Week</span>
      <div class="p-2 nav-btn" (click)="changeWeek(1)">
        <img src="assets/icons/chevron-right.svg" alt="Next week" />
      </div>
    </div>
  </th>
</table>
<table class="table table-borderless w-100" *ngIf="!isSmallScreen$">
  <thead class="hide-on-sm">
    <tr>
      <th class="hide-on-sm py-4 px-2">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="p-2 nav-btn" (click)="changeWeek(-1)">
            <img src="assets/icons/chevron-left.svg" alt="Prev week" />
          </div>
          <span>Week</span>
          <div class="p-2 nav-btn" (click)="changeWeek(1)">
            <img src="assets/icons/chevron-right.svg" alt="Next week" />
          </div>
        </div>
      </th>
      <th *ngFor="let day of week" class="status-col pb-4">
        <div class="pb-2">
          {{ day.toDate() | date : "E" | lowercase | transloco }}
          {{ day.toDate() | date : "dd.MM" }}
        </div>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="data; else loader">
    <tr *ngFor="let status of data" class="hide-on-sm">
      <td class="p-2 user-col col-2">
        <div class="text-break username">
          {{ status.userName }}
        </div>
      </td>
      <td
        *ngFor="let day of status.statDayRecords"
        class="status-col p-md-1"
        [class.filled]="day"
        [class.cursor-pointer]="
          (isUserAdmin$ || currentUserId === status.userId) && day != null
        "
        (click)="day ? redirect(status.userId, day.id) : false"
      >
        <div *ngIf="day" class="p-sm-1 ps-sm-2">
          <p class="lh-2 mb-0">{{ day.status | transloco }}</p>
          <p class="lh-2" *ngIf="day.startTime">
            {{ "from" | transloco }} {{ day.startTime | date : "HH:mm" }}
            {{ "to" | transloco }}
            {{ day.endTime | date : "HH:mm" }}
          </p>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<table class="table table-borderless w-100" *ngIf="isSmallScreen$">
  <tbody *ngIf="data; else loader">
    <tr *ngFor="let day of week; index as i">
      <td class="p-0">
        <p
          class="w-100 m-0 p-2 justify-content-center align-items-center date-mobile"
        >
          {{ day.toDate() | date : "E" | lowercase | transloco }}
          {{ day.toDate() | date : "dd.MM" }}
        </p>
        <p class="empty-label text-muted ps-2" *ngIf="todayStatusesEmpty$[i]">
          {{ "noStatuses" | transloco }}
        </p>
        <div
          *ngFor="let status of data"
          [class.cursor-pointer]="
            (isUserAdmin$ || currentUserId === status.userId) &&
            status.statDayRecords[i]
          "
          (click)="
            status.statDayRecords[i]
              ? redirect(status.userId, status.statDayRecords[i].id)
              : false
          "
        >
          <div
            *ngIf="status.statDayRecords[i]"
            class="d-flex p-2 flex-column"
            [class.filled]="status.statDayRecords[i].status"
          >
            <p class="w-100 me-2 username username-mobile">
              {{ status.userName }}
            </p>
            <div class="w-100 d-flex pt-1">
              <p class="text-start w-50">
                {{ status.statDayRecords[i].status | transloco }}
              </p>
              <p
                class="text-end w-50"
                *ngIf="status.statDayRecords[i].startTime"
              >
                {{ "from" | transloco }}
                {{ status.statDayRecords[i].startTime | date : "HH:mm" }}
                {{ "to" | transloco }}
                {{ status.statDayRecords[i].endTime | date : "HH:mm" }}
              </p>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
<ng-template #loader>
  <div class="w-100 d-flex justify-content-center p-4">
    <div class="spinner-border"></div>
  </div>
</ng-template>
