import { Component, HostListener, OnInit } from '@angular/core';

import { ApvRepository } from 'app/state/apv.repository';
import { ApvService } from 'app/state/apv.service';
import { AuthRepository } from 'app/state/auth.repository';
import {
  User,
  UsersRepository,
  userSortOptions,
} from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
@Component({
  selector: 'app-apv-answer-admin',
  templateUrl: './apv-answer-admin.component.html',
  styleUrls: ['./apv-answer-admin.component.scss'],
})
export class ApvAnswerAdminComponent implements OnInit {
  innerWidth = 0;
  searchFilter: string = '';
  sortOptions = userSortOptions;

  constructor(
    public authRepo: AuthRepository,
    public repo: ApvRepository,
    public service: ApvService,
    public userrepo: UsersRepository,
    public userservice: UsersService
  ) {}

  users: User[] = [];

  ngOnInit(): void {
    this.userservice.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.userservice.searchReloadPage(this.searchFilter).subscribe();
  }
}
