import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UrlIdResolverService } from 'app/modules/shared/services/url-id-resolver.service';
import { FeatureRepository } from 'app/state/feature.repository';
import { FeatureService } from 'app/state/feature.service';
import { Tenant, TenantsRepository } from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';
import { GeneralDataRepository } from '../../../../helpers/repository/general-data.repository';

@Component({
  selector: 'app-tenants-create-page',
  templateUrl: './tenants-create-page.component.html',
  styleUrls: ['./tenants-create-page.component.scss'],
})
export class TenantsCreatePageComponent implements OnInit {
  tenant$: Observable<Tenant> | null = null;
  tenant: Tenant | null = null;
  id?: string;
  submitErrors: string[] | null = null;
  isLoading: boolean = false;

  constructor(
    private tenantService: TenantsService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: TenantsRepository,
    private urlId: UrlIdResolverService,
    private featureService: FeatureService,
    public featureRepo: FeatureRepository,
    private sidebarRepo: GeneralDataRepository
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      var idFromrout = params.get('id');
      if (idFromrout) {
        this.tenantService.loadOne(idFromrout).subscribe((tenant) => {
          this.tenant = tenant;
          this.id = tenant.id;
          this.isLoading = false;
        });
      }
    });

    this.loadData();
  }

  loadData() {
    var currTenantId = localStorage.getItem('activeTenantId');
    if (!currTenantId) {
      currTenantId = 'empty';
    }
    this.featureService.loadFeatures(currTenantId).subscribe(() => {
      this.isLoading = false;
    });
  }

  createTenant(tenant: Partial<Tenant>) {
    this.submitErrors = null;
    let updateResult: Observable<Tenant> | undefined;
    if (
      tenant.image &&
      (tenant.imageWidth != 2000 || tenant.imageHeight != 450)
    ) {
      this.submitErrors = ['Image should be 2000x450'];
      return;
    } else {
      if (!this.id) {
        updateResult = this.tenantService.add(tenant);
      } else if (this.id) {
        updateResult = this.tenantService.update(this.id, tenant);
      }
    }

    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/tenants']);
          this.sidebarRepo.updateAllGeneralData(true);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
