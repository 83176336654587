<h2 class="mb-4" *ngIf="!id" i18n="Create post label">
  {{ "createMessage" | transloco }}
</h2>
<h2 class="mb-4" *ngIf="id" i18n="Update post label">
  {{ "updateMessage" | transloco }}
</h2>
<div *ngIf="post">
  <app-mail-form
    [editedMail]="post"
    (MailSubmit)="updatePost($event)"
  ></app-mail-form>
  <app-error-alert
    title="{{ 'postError' | transloco }}"
    i18n-title="Message post submit error alert"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
