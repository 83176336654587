<form *ngIf="Form" [formGroup]="Form" enctype="multipart/form-data">
  <div *ngIf="Form.value.useTenantMsGraphOptions">
    <div class="form-group form-floating mb-3">
      <div
        class="form-floating mb-3"
        [appValidateState]="Form.get('msGraphClientId')"
      >
        <div class="inpt-title">{{ "msGraphClientId" | transloco }}</div>
        <input
          formControlName="msGraphClientId"
          type="text"
          class="inpt"
          placeholder="{{ 'msGraphClientId' | transloco }}"
          autocomplete="off"
        />
      </div>
    </div>
    <div
      class="form-group form-floating mb-3"
      [appValidateState]="Form.get('msGraphClientSecret')"
    >
      <div class="inpt-title">{{ "msGraphClientSecret" | transloco }}</div>
      <input
        formControlName="msGraphClientSecret"
        type="text"
        class="inpt"
        placeholder="{{ 'msGraphClientSecret' | transloco }}"
        autocomplete="off"
      />
    </div>

    <div
      class="form-group form-floating mb-3"
      [appValidateState]="Form.get('msGraphFromMail')"
    >
      <div class="inpt-title">{{ "msGraphFromMail" | transloco }}</div>
      <input
        formControlName="msGraphFromMail"
        type="text"
        class="inpt"
        placeholder="{{ 'msGraphFromMail' | transloco }}"
        autocomplete="off"
      />
    </div>

    <div
      class="form-group form-floating mb-3"
      [appValidateState]="Form.get('msGraphSenderId')"
    >
      <div class="inpt-title">{{ "msGraphSenderId" | transloco }}</div>
      <input
        formControlName="msGraphSenderId"
        type="text"
        class="inpt"
        placeholder="{{ 'msGraphSenderId' | transloco }}"
        autocomplete="off"
      />
    </div>
    <div
      class="form-group form-floating mb-3"
      [appValidateState]="Form.get('msGraphTenantId')"
    >
      <div class="inpt-title">{{ "msGraphTenantId" | transloco }}</div>
      <input
        formControlName="msGraphTenantId"
        type="text"
        class="inpt"
        placeholder="{{ 'msGraphTenantId' | transloco }}"
        autocomplete="off"
      />
    </div>
  </div>

  <div class="form-group form-floating mb-3">
    <div class="inpt-title">
      {{ "useTenantMsGraphOptions" | transloco }}
    </div>
    <input
      formControlName="useTenantMsGraphOptions"
      class="form-check-input"
      type="checkbox"
    />
  </div>
</form>

<div class="row mt-4">
  <div class="col-12 col-sm-6">
    <button
      (click)="optionsSubmit.emit(formResult())"
      class="w-100 btn btn-orange"
    >
      {{ "save" | transloco }}
    </button>
  </div>
  <div class="col-12 col-sm-6">
    <button
      (click)="sendTestEmail.emit(formResult())"
      class="w-100 btn btn-dark"
    >
      {{ "test" | transloco }} {{ "email" | transloco }}
    </button>
  </div>
</div>
