<div>
  <form [formGroup]="tenant_form" novalidate>
    <div class="form-group form-floating mb-3">
      <div class="mb-3">
        <app-blob-file-uploader
          (FileSubmit)="updateFile($event)"
          (delete)="deleteLogo()"
          class="w-100 p-0"
          placeholder="{{ 'selectImage' | transloco }}"
          [fileType]="'image/*'"
          [fileTypeValidationString]="'image'"
          [showDefaultDelete]="true"
          [selectedFileNames]="blobService.getFileName(tenant_form.value.image)"
          [withCompression]="true"
          (compressImagesSubmit)="changeHasCompressedImage($event)"
        ></app-blob-file-uploader>
      </div>

      <div class="mb-3">
        <input
          formControlName="headline"
          type="text"
          class="inpt"
          placeholder="{{ 'headline' | transloco }}"
        />
      </div>

      <div class="form-floating mb-3">
        <textarea
          id="editor"
          type="text"
          placeholder="{{ 'pageHtml' | transloco }}"
          autocomplete="off"
          formControlName="pageHtml"
        >
        </textarea>
      </div>
      <div
        class="form-floating"
        [appValidateState]="tenant_form.get('videoUrl')"
      >
        <input
          formControlName="videoUrl"
          type="text"
          class="inpt"
          placeholder="{{ 'VideoUrl' | transloco }}"
          i18n-placeholder="Url placeholder @@url"
        />
        <small
          class="text-danger"
          *ngIf="tenant_form.get('videoUrl')?.hasError('pattern')"
        >
          Unsafe url.
        </small>
      </div>

      <div class="form-floating mt-3">
        <textarea
          id="editor2"
          type="text"
          placeholder="{{ 'pageHtml2' | transloco }}"
          autocomplete="off"
          formControlName="pageHtml2"
        >
        </textarea>
      </div>
    </div>
  </form>

  <div class="w-100 d-flex mt-4">
    <button
      (click)="onSubmit()"
      class="ms-2 w-100 btn btn-orange"
      [disabled]="!tenant_form.valid"
    >
      {{ "save" | transloco }}
    </button>
  </div>
</div>
