<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-xl-8 col-lg-11 col-12 row p-md-2 p-lg-0 p-0">
    <app-timelogs-navbuttons></app-timelogs-navbuttons>

    <div name="DateWeekSelect">
      <app-datalist-select
        id="weeksSelect"
        [options]="WeeksOptions"
        [multiple]="false"
        class=" "
        label=""
        [(activeValue)]="selectedWeek"
        (activeValueChange)="changeinput($event)"
      >
      </app-datalist-select>
    </div>
    <div name="w-100 px-3">
      <table
        class="mt-3 table table-borderless w-100"
        style="border-bottom: 0px !important; border-radius: 12px"
      >
        <thead class="w-100 d-flex align-items-center tabhead">
          <tr
            class="d-flex w-100 h-100 hide"
            style="padding: 0 !important; min-height: 96px"
          >
            <td
              width="20%"
              class="hideOnSmallScreen dates h-100 d-flex justify-content-center align-items-center"
              style="min-height: 96px"
            ></td>
            <td
              width="20%"
              class="hideOnMiddleScreen h-100 d-flex justify-content-center align-items-center"
              style="min-height: 96px"
            ></td>
            <th
              class="mobileSizing50 d-flex flex-nowrap p-0 justify-content-around align-content-center"
            >
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "mon" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "tue" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "wed" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "thu" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "fri" | transloco }}
                </div>
              </div>
            </th>
            <th
              class="mobileSizing20 d-flex p-0 justify-content-around"
              style="
                border-right: 1px solid #eaecf0;
                border-left: 1px solid #eaecf0;
              "
            >
              <div class="weekendTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "sat" | transloco }}
                </div>
              </div>
              <div class="weekendTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "sun" | transloco }}
                </div>
              </div>
            </th>
            <th
              class="mobileSizing10 totalTitle d-flex align-items-center justify-content-center weekendTitle"
            >
              <div
                class="w-75 d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ "total" | transloco }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <div
            class="d-flex flex-column"
            *ngFor="let project of projectsTotals; let i = index"
          >
            <tr class="col projectTitle w-100 p-0" style="color: black">
              <td class="p-2 ps-3 d-flex align-items-center my-2">
                <div
                  class="d-flex justify-content-center align-items-center icon rounded-circle me-2"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M19 21V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.07989 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V21M21 21H3M9.5 8H9.51M14.5 8H14.51M10 8C10 8.27614 9.77614 8.5 9.5 8.5C9.22386 8.5 9 8.27614 9 8C9 7.72386 9.22386 7.5 9.5 7.5C9.77614 7.5 10 7.72386 10 8ZM15 8C15 8.27614 14.7761 8.5 14.5 8.5C14.2239 8.5 14 8.27614 14 8C14 7.72386 14.2239 7.5 14.5 7.5C14.7761 7.5 15 7.72386 15 8Z"
                      stroke="#FF4405"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                {{ project.projectName }}
              </td>
              <div
                *ngFor="let task of project.tasksTotals"
                style="
                  width: 100%;
                  padding: 0 !important;
                  border-top: 1px solid #eaecf0;
                "
              >
                <tr class="d-flex hideOnBigScreen py-1">
                  <td
                    width="100%"
                    class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate"
                  >
                    <div style="color: #475467">
                      {{ task?.name | slice : 0 : 20 }}
                    </div>
                  </td>
                </tr>
                <tr class="d-flex w-100">
                  <td
                    width="20%"
                    class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen p-2"
                  >
                    <div style="color: #475467">
                      {{ task?.name | slice : 0 : 20 }}
                    </div>
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.monHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.tueHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.wedHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.thuHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.friHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.satHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.sunHours }}
                  </td>
                  <td
                    class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                    style="color: black"
                  >
                    {{ task.totHours }}
                  </td>
                </tr>
              </div>
            </tr>
            <tr class="d-flex hideOnBigScreen py-1">
              <td
                width="100%"
                class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate"
              >
                <div style="color: #475467">{{ "total" | transloco }}</div>
              </td>
            </tr>
            <tr class="d-flex p-0">
              <td
                width="20%"
                class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen"
              >
                <div style="color: #475467">{{ "total" | transloco }}</div>
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end bottomTitle"
                style="color: black"
              >
                {{ project.monTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ project.tueTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ project.wedTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ project.thuTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ project.friTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="background-color: #fff4ed; color: black"
              >
                {{ project.satTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="background-color: #fff4ed; color: black"
              >
                {{ project.sunTotal }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="background-color: #fdead7; color: black"
                [class.border_radius_bottom]="i == projectsTotals?.length! - 1"
              >
                {{ project.genTotal }}
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</div>
