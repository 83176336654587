import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  ICompanyMarker,
  IContact,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { ContactsRepository } from '../../../../state/contacts.repository';
import { ContactsService } from '../../../../state/contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-contacts-create-page',
  templateUrl: './contacts-create-page.component.html',
  styleUrls: ['./contacts-create-page.component.scss'],
})
export class ContactsCreatePageComponent {
  constructor(
    public service: ContactsService,
    public clientService: CompaniesService,
    public repo: ContactsRepository,
    public location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  contact: IContact | null = null;
  company: ICompanyMarker | null = null;
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.type = value.get('type')!;
        this.id = value.get('id')!;
        if (this.id && this.type != 'new') {
          this.service.loadOneContact(`${value.get('id')}`).subscribe((y) => {
            this.contact = y;
          });
        }
      },
    });
  }

  createContact(contact: Partial<IContact>) {
    this.submitErrors = null;
    let updateResult: Observable<IContact> | undefined;
    if (this.type === 'new') {
      contact.companyId = this.id;

      updateResult = this.service.postContact(contact);
    } else if (this.id) {
      updateResult = this.service.updateContact(contact);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.location.back();
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
