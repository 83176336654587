import { Component, HostListener, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';

import {
  CompaniesRepository,
  ICompanyMarker,
  CompaniesSortOptions,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';

@Component({
  selector: 'app-admin-companies-page',
  templateUrl: './admin-companies-page.component.html',
  styleUrls: ['./admin-companies-page.component.scss'],
})
export class AdminCompaniesPageComponent implements OnInit {
  sortOptions = CompaniesSortOptions;
  innerWidth = 0;
  searchFilter: string = '';

  deleteConfirmation: ICompanyMarker | null = null;
  constructor(
    public repo: CompaniesRepository,
    public service: CompaniesService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    //this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
  }
  refreshData() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }
  handleDeleteClick(post: ICompanyMarker) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
