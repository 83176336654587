<div class="row justify-content-center">
  <div class="row col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3 ps-0 pe-0">
    <h2 class="sm-del page-title col-sm-4 col-12">
      {{ "news" | transloco }}
    </h2>

    <div
      class="col-sm-8 col-12 mb-sm-5 mb-3 d-flex justify-content-sm-end justify-content-center"
    >
      <div class="butsect d-flex justify-content-center align-items-center">
        <button
          class="button me-1"
          [class.butActive]="active === 'All'"
          (click)="changeActive('All')"
        >
          {{ "all" | transloco }}
        </button>
        <button
          class="button me-1 d-flex flex-row align-items-center justify-content-center"
          (click)="changeActive('Unread')"
          [class.butActive]="active === 'Unread'"
        >
          {{ "unread" | transloco }}
          <span
            *ngIf="unread && unread >= 1"
            class="ms-1 d-inline d-flex justify-content-center align-items-center unreadnumber"
            >{{ unread }}
          </span>
        </button>
        <button
          class="button me-1"
          [class.butActive]="active === 'Read'"
          (click)="changeActive('Read')"
        >
          {{ "read" | transloco }}
        </button>
      </div>
    </div>
    <div class="content">
      <div *ngFor="let post of repo.page$ | async" class="mb-sm-4 mb-5">
        <div
          [routerLink]="['/posts', post.id]"
          class="post-card row p-0 m-0"
          style="width: 100%"
        >
          <div class="col-sm-auto p-sm-0 col-12">
            <img
              class="media-box"
              src="{{
                post.documents && post.documents[0] && post.documents[0].image
                  ? blobService.getCompressedImagePath(
                      post.documents[0].image,
                      320,
                      true,
                      post.documents[0].hasCompressedImage320
                    )
                  : DEFAULT_POST_IMAGE
              }}
            "
              alt="newsimg"
            />
          </div>

          <div class="col-sm col-12">
            <div class="ms-sm-3 text-div">
              <div class="news_title text-break mt-xl-3 mt-lg-2 mt-sm-0 mt-2">
                {{ post.title | slice : 0 : 50 }}
                <span *ngIf="post.title.length > 50">...</span>
              </div>
              <p class="news_content text-break mt-2">
                {{ transform(post.content) }}
              </p>
              <div
                class="d-flex flex-row align-items-center mt-lg-2 mt-xl-4 mt-2"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <img
                    class="rounded-circle me-2 profile-picture"
                    src="{{
                      blobService.getUserImagePath(
                        post.userImagePath,
                        post.userImageHasCompression
                      )
                    }}"
                    alt="avatar"
                    width="40"
                    height="40"
                    style="background: #ff6a00"
                  />
                </div>
                <div class="d-flex flex-column flex-start">
                  <div class="authorName">
                    {{ post.userName ? post.userName : "Admin" }}
                  </div>
                  <div class="date">
                    {{ post.createdAt | date : "d MMM y" }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <hr />
      <app-custom-pagination
        [innerWidth]="innerWidth"
        [page]="(repo.pageNumber$ | async) || 1"
        [total]="(repo.paginationData$ | async)?.lastPage || 0"
        (pageChange)="
          service
            .loadPageCustom(active, $event, undefined, undefined, [
              'userGroups',
              'showPeriod'
            ])
            .subscribe()
        "
      ></app-custom-pagination>
    </div>
  </div>
</div>
