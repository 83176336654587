import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'app/state/auth.repository';
import { MailService } from '../../../state/mail.service';
import {
  IMail,
  MailRepository,
  mailsSortOptions,
} from 'app/state/mail.repository';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';

@Component({
  selector: 'app-mail',
  templateUrl: './mail.component.html',
  styleUrls: ['./mail.component.scss'],
})
export class MailComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';
  constructor(
    public repo: MailRepository,
    public service: MailService,
    public authRepo: AuthRepository,
    public blobService: BlobStorageService
  ) {}
  sortOptions = mailsSortOptions;
  unreadIndx: number = -1;
  readIndx: number = -1;

  messages: IMail[] = [];
  ngOnInit(): void {
    this.service.loadAll().subscribe((x) => {
      this.messages = x;
      this.unreadIndx = this.messages.findIndex((x) => !x.isRead);
      this.readIndx = this.messages.findIndex((x) => x.isRead);
    });
  }
}
