<app-media-library
  class="h-100"
  [allowFilesSelection]="true"
  [allowImagesSelection]="allowImagesSelection"
  [allowDocumentsSelection]="allowDocumentsSelection"
  [allowVideosSelection]="allowVideosSelection"
  [allowSoundsSelection]="allowSoundsSelection"
  [uploadOnlyPrivateFilesModeActive]="uploadOnlyPrivateFilesModeActive"
  (selectedFilesConfirmation)="handleSelectedFilesConfirmation($event)"
></app-media-library>
