import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { CompressedImagesDto } from 'app/shared/azure-blob/blob-compress.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { FileSetttings } from 'app/shared/data.service';
import { TenantHomeUpdateModel } from 'app/state/tenants.repository';
declare var ClassicEditor: any;
@Component({
  selector: 'app-tenant-html-form',
  templateUrl: './tenant-html-form.component.html',
  styleUrls: ['./tenant-html-form.component.scss'],
})
export class TenantHtmlFormComponent {
  editor: any;
  editor2: any;
  tenant_form!: UntypedFormGroup;

  @Input() tenantModel!: TenantHomeUpdateModel;
  @Output() submitedValue = new EventEmitter<TenantHomeUpdateModel>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    public blobService: BlobStorageService
  ) {}

  ngOnInit() {
    this.createEmptyFormGroup();
    this.setUpForm();
  }

  setUpForm() {
    this.tenant_form.patchValue({
      image: this.tenantModel.logo ?? '',
      headline: this.tenantModel.headline ?? '',
      pageHtml: this.tenantModel.pageHtml ?? '',
      videoUrl: this.tenantModel.videoUrl ?? '',
      pageHtml2: this.tenantModel.pageHtml2 ?? '',
      hasCompressedImage320: this.tenantModel.hasCompressedImage320 ?? false,
      hasCompressedImage800: this.tenantModel.hasCompressedImage800 ?? false,
      isFileOnRightBlob: this.tenantModel.isFileOnRightBlob ?? false,
    });
  }

  createEmptyFormGroup(): void {
    this.tenant_form = this.formBuilder.group({
      image: new FormControl(``),
      headline: new FormControl(``),
      pageHtml: new FormControl(``),
      videoUrl: new FormControl(``, [Validators.pattern('(https://.*)')]),
      pageHtml2: new FormControl(``),
      hasCompressedImage320: new FormControl(``),
      hasCompressedImage800: new FormControl(``),
      isFileOnRightBlob: new FormControl(``),
    });
  }

  changeHasCompressedImage(value: CompressedImagesDto) {
    this.tenant_form.controls['hasCompressedImage320'].setValue(
      value.hasCompressedImage320
    );
    this.tenant_form.controls['hasCompressedImage800'].setValue(
      value.hasCompressedImage800
    );
  }

  ngAfterViewInit() {
    if (typeof ClassicEditor !== 'function') {
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/assets/js/ckeditor.js';
      this.document.body.appendChild(script);
      script.onload = () => {
        this.initEditor('editor');
        this.initEditor('editor2');
      };
    } else {
      this.initEditor('editor');
      this.initEditor('editor2');
    }
  }

  private initEditor(editorName: string) {
    ClassicEditor.create(document.querySelector('#' + editorName), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      if (editorName == 'editor') {
        newEditor.setData(this.tenantModel.pageHtml);
        this.editor = newEditor;
      }

      if (editorName == 'editor2') {
        newEditor.setData(this.tenantModel.pageHtml2);
        this.editor2 = newEditor;
      }
    });
  }

  onSubmit() {
    const pageHtml = this.editor.getData();
    const pageHtml2 = this.editor2.getData();
    this.tenant_form.patchValue({
      pageHtml: pageHtml,
      pageHtml2: pageHtml2,
    });

    const model: TenantHomeUpdateModel = {
      logo: this.tenant_form.controls['image'].value ?? null,
      pageHtml: this.tenant_form.controls['pageHtml'].value ?? null,
      videoUrl: this.tenant_form.controls['videoUrl'].value ?? null,
      pageHtml2: this.tenant_form.controls['pageHtml2'].value ?? null,
      headline: this.tenant_form.controls['headline'].value ?? null,
      hasCompressedImage320:
        this.tenant_form.controls['hasCompressedImage320'].value,
      hasCompressedImage800:
        this.tenant_form.controls['hasCompressedImage800'].value,
      isFileOnRightBlob: this.tenant_form.controls['isFileOnRightBlob'].value,
    };

    this.submitedValue.emit(model);
  }

  updateFile(settings: Partial<FileSetttings>) {
    this.tenant_form.patchValue({
      image: settings.uploadedFileName,
      isFileOnRightBlob: true,
    });
  }

  deleteLogo() {
    this.tenant_form.patchValue({
      image: '',
    });
  }
}
