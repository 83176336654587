<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-xl-8 col-lg-11 col-12 row p-md-2 p-lg-0 p-0">
    <app-timelogs-navbuttons></app-timelogs-navbuttons>

    <div name="DateWeekSelect">
      <app-datalist-select
        id="weeksSelect"
        [options]="WeeksOptions"
        [multiple]="false"
        class=" "
        label=""
        [(activeValue)]="selectedWeek"
        (activeValueChange)="changeinput($event)"
      >
      </app-datalist-select>
    </div>
    <div class="w-100 px-3">
      <table
        class="mt-3 table table-borderless w-100"
        style="border-bottom: 0px !important; border-radius: 12px"
      >
        <thead class="w-100 d-flex align-items-center tabhead">
          <tr
            class="d-flex w-100 h-100 hide"
            style="padding: 0 !important; min-height: 96px"
          >
            <td
              width="20%"
              class="hideOnSmallScreen dates h-100 d-flex justify-content-center align-items-center"
              style="min-height: 96px"
            ></td>
            <td
              width="20%"
              class="hideOnMiddleScreen h-100 d-flex justify-content-center align-items-center"
              style="min-height: 96px"
            ></td>
            <th
              class="mobileSizing50 d-flex p-0 justify-content-around align-content-center tabhead"
            >
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "mon" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "tue" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "wed" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "thu" | transloco }}
                </div>
              </div>
              <div class="dayTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "fri" | transloco }}
                </div>
              </div>
            </th>

            <th
              class="mobileSizing20 d-flex p-0 justify-content-around"
              style="
                border-right: 1px solid #eaecf0;
                border-left: 1px solid #eaecf0;
              "
            >
              <div class="weekendTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "sat" | transloco }}
                </div>
              </div>
              <div class="weekendTitle d-flex" style="color: black">
                <div class="d-flex justify-content-around align-items-center">
                  {{ "sun" | transloco }}
                </div>
              </div>
            </th>
            <th
              class="mobileSizing10 totalTitle d-flex align-items-center justify-content-center weekendTitle"
            >
              <div
                class="w-75 d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ "total" | transloco }}
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <div
            *ngFor="let item of daysTotals"
            style="
              width: 100%;
              padding: 0 !important;
              border-top: 1px solid #eaecf0;
            "
          >
            <tr class="d-flex hideOnBigScreen py-1">
              <td
                width="100%"
                class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate text-break"
              >
                <div style="color: #475467">
                  {{ item?.name | slice : 0 : 20 }}
                </div>
              </td>
            </tr>
            <tr class="d-flex w-100 p-0">
              <td
                width="20%"
                class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen p-2 text-break"
              >
                <div style="color: #475467">
                  {{ item?.name | slice : 0 : 20 }}
                </div>
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.monHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.tueHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.wedHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.thuHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.friHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.satHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.sunHours }}
              </td>
              <td
                class="p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ item.totHours }}
              </td>
            </tr>
          </div>

          <tr class="d-flex hideOnBigScreen py-1">
            <td
              width="100%"
              class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate"
            >
              <div style="color: #475467">
                {{ "total" | transloco }}
              </div>
            </td>
          </tr>
          <tr class="d-flex p-0" style="border-top: 1px solid #eaecf0">
            <td
              width="20%"
              class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen"
            >
              <div style="color: #475467">
                {{ "total" | transloco }}
              </div>
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end bottomTitle"
              style="color: black"
            >
              {{ monTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="color: black"
            >
              {{ tueTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="color: black"
            >
              {{ wedTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="color: black"
            >
              {{ thuTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="color: black"
            >
              {{ friTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="background-color: #fff4ed; color: black"
            >
              {{ satTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="background-color: #fff4ed; color: black"
            >
              {{ sunTotal }}
            </td>
            <td
              class="p-3 col dayTitle d-flex align-items-center justify-content-end"
              style="
                background-color: #fdead7;
                border-bottom-right-radius: 12px;
                color: black;
              "
            >
              {{ genTotal }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
