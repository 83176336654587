import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private readonly key: string = 'main';

  constructor(
    private messageService: MessageService,
    private translocoService: TranslocoService
  ) {}

  public showSuccess(message: string): void {
    this.showMessage('success', 'Success', message);
  }

  public showWarning(message: string): void {
    this.showMessage('warn', 'Warning', message);
  }

  public showError(message: string): void {
    this.showMessage('error', 'Error', message);
  }

  public showInformation(message: string): void {
    this.showMessage('info', 'Info', message);
  }

  private showMessage(
    severity: string,
    summary: string,
    message: string
  ): void {
    this.messageService.add({
      key: this.key,
      severity: severity,
      summary: summary,
      detail: this.translocoService.translate(message),
      life: 4000,
    });
  }
}
