<div class="row page-header d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-950">
    <div class="col-6 mt-sm-4 mb-sm-3 p-0">
      <h2 class="page-title">
        {{ "users" | transloco }}
      </h2>
    </div>
    <div
      style="display: flex; justify-content: end"
      *ngIf="auth.isAnyAdmin$ | async"
      [hidden]="innerWidth < 1050"
    >
      <p-splitButton
        label="{{ 'actions' | transloco }}"
        [model]="items"
        class="me-2"
        styleClass="p-button-raised mr-2 mb-2"
      ></p-splitButton>

      <app-import-export
        *ngIf="activeTenant && (auth.isTenantAdmin$ | async)"
        class="me-2"
        filenamePrefix="users"
        [importFactory]="importFactory"
        [exportFactory]="exportFactory"
        (outputError)="showError($event)"
        (submitImport)="submitImport($event)"
        [waitingForImportResponseSet]="waitingForImportResponse$"
      ></app-import-export>

      <p-button
        label="{{ 'add' | transloco }}"
        icon="pi pi-plus"
        [routerLink]="['/users/new']"
      />
    </div>

    <app-error-alert
      *ngIf="hasErrors"
      class="mt-3 px-0"
      title="{{ 'couldNotImportUsers' | transloco }}"
      [errors]="submitErrors"
    ></app-error-alert>

    <app-users-list
      class="sm-m p-0 mb-2 m-0"
      style="width: 100%"
      [innerWidth]="innerWidth"
      [users]="users"
      [clearSelectedUsers]="emptySelectedUsers"
      [isDeleteDisabled]="!(auth.isSuperAdmin$ | async)"
      (delete)="handleDeleteClick($event)"
      (onUserSelection)="handleUserSelection($event)"
    ></app-users-list>

    <p-confirmDialog [style]="{ width: '50vw' }">
      <ng-template pTemplate="footer">
        <button
          pButton
          type="button"
          label="{{ 'yes' | transloco }}"
          class="p-button-secondary"
          (click)="confirmationService.close()"
        ></button>

        <button
          pButton
          type="button"
          label="{{ 'no' | transloco }}"
          (click)="deleteSelectedUsers()"
        ></button>
      </ng-template>
    </p-confirmDialog>
  </div>
</div>
