import { Injectable } from '@angular/core';
import { select } from '@ngneat/elf';
import { withProps } from '@ngneat/elf';
import { Store, createState } from '@ngneat/elf';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';

export interface UIProps {
  darkMode: boolean;
  isMenuOpened: boolean;
  isSessionOver: boolean;
  isMenuExpanded: boolean;
  isInstallPromptDismissed: boolean;
}

const { state, config } = createState(
  withProps<UIProps>({
    darkMode: false,
    isSessionOver: false,
    isMenuOpened: false,
    isMenuExpanded: true,
    isInstallPromptDismissed: false,
  })
);
const store = new Store({ name: 'ui', state, config });
persistState(store, {
  storage: localStorageStrategy,
  source: (store) =>
    store.pipe(
      select((state) => ({
        ...state,
        isSessionOver: false,
        isMenuOpened: false,
      }))
    ),
});

@Injectable({ providedIn: 'root' })
export class UiRepository {
  isDarkMode$ = store.pipe(select((state) => state.darkMode));
  isSessionOver$ = store.pipe(select((state) => state.isSessionOver));
  isMenuOpened$ = store.pipe(select((state) => state.isMenuOpened));
  isMenuExpanded$ = store.pipe(select((state) => state.isMenuExpanded));
  isInstallPromptDismissed$ = store.pipe(
    select((state) => state.isInstallPromptDismissed)
  );

  dismissInstallPrompt() {
    store.update((state) => ({
      ...state,
      isInstallPromptDismissed: true,
    }));
  }

  toggleDarkMode() {
    store.update((state) => ({
      ...state,
      darkMode: !state.darkMode,
    }));
  }

  setSessionOver() {
    store.update((state) => ({
      ...state,
      isSessionOver: true,
    }));
  }

  toggleMenuOpened(isOpen?: boolean) {
    store.update((state) => ({
      ...state,
      isMenuOpened: isOpen !== undefined ? isOpen : !state.isMenuOpened,
    }));
  }

  toggleMenuExpanded(isOpen?: boolean) {
    store.update((state) => ({
      ...state,
      isMenuExpanded: isOpen !== undefined ? isOpen : !state.isMenuExpanded,
    }));
  }
}
