<div>
  <img src="assets/img/icon-gdpr.svg" alt="GDPR Icon" />
  <div class="my-4">
    <h6>{{ IgnitePrivacyAndCookieConfirmation }}</h6>
    <h6>{{ IgnitePrivacyAndCookieConfirmation2 }}</h6>

    <h6>
      <a href="{{ redirectGDPRUrl }}" target="_blank">{{ here }}</a>
    </h6>
  </div>

  <div style="display: flex; justify-content: space-between">
    <button (click)="submitGdpr()" class="btn accept">Accept</button>
    <button (click)="rejectGdpr()" class="btn decline">Decline</button>
  </div>
</div>
