<h2 class="mb-4" *ngIf="id === 'new'">
  {{ "createPost" | transloco }}
</h2>
<h2 class="mb-4" *ngIf="id !== 'new'">
  {{ "editPost" | transloco }}
</h2>
<div class="row mb-4" *ngIf="post || id === 'new'">
  <app-news-form
    [editedPost]="post"
    (newsSubmit)="updatePost($event)"
    [groups]="groups"
    [deletedGroups]="deletedGroups"
  ></app-news-form>
  <app-error-alert
    title="{{ 'postError' | transloco }}"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
