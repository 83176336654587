import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  ICompanyMarker,
  IContact,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import {
  ContactsRepository,
  ContactsSortOptions,
} from '../../../../state/contacts.repository';
import { ContactsService } from '../../../../state/contacts.service';
import { ICompanyLocation } from '../../../../state/unauthorized-companies.repository';

@Component({
  selector: 'app-admin-contacts-page',
  templateUrl: './admin-contacts-page.component.html',
  styleUrls: ['./admin-contacts-page.component.scss'],
})
export class AdminContactsPageComponent implements OnInit {
  sortOptions = ContactsSortOptions;
  innerWidth = 0;
  searchFilter: string = '';
  contact: IContact | null = null;
  client: ICompanyMarker | null = null;
  id: string | null = null;
  deleteConfirmation: IContact | null = null;

  constructor(
    public repo: ContactsRepository,
    public service: ContactsService,
    public clientService: CompaniesService,
    private activatedRoute: ActivatedRoute
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.repo.page$.subscribe();
    this.innerWidth = window.innerWidth;
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');

        if (this.id) {
          this.service
            .reloadPageCustom(this.searchFilter, undefined, this.id)
            .subscribe();

          this.clientService
            .loadOneCompany(this.id)
            .subscribe((x) => (this.client = x));
        }
      },
    });
  }

  refreshData() {
    this.service
      .reloadPageCustom(this.searchFilter, undefined, `${this.id}`)
      .subscribe();
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service
      .searchRelodePageCustom(this.searchFilter, undefined, `${this.id}`)
      .subscribe();
  }

  handleDeleteClick(post: IContact) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }
}
