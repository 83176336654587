import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthRepository } from 'app/state/auth.repository';
import {
  IConfirm,
  tenantSortOptions,
  TenantsRepository,
} from 'app/state/tenants.repository';
import { TenantsService } from 'app/state/tenants.service';
import { TenantsListPageDto } from 'app/api/models/tenant/tenantsListPageDto';

@Component({
  selector: 'app-tenants-page',
  templateUrl: './tenants-page.component.html',
  styleUrls: ['./tenants-page.component.scss'],
})
export class TenantsPageComponent implements OnInit {
  sortOptions = tenantSortOptions;
  innerWidth = 0;
  allTenants: TenantsListPageDto[] = [];

  constructor(
    public repo: TenantsRepository,
    public service: TenantsService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.updateTenantsList();
  }

  updateTenantsList() {
    this.service.getAllTenants().subscribe((data) => {
      this.allTenants = data;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.updateTenantsList();
  }

  delete(id: string) {
    this.service.delete(id).subscribe(() => {
      this.refreshData();
    });
  }

  handleCloneClick(event: IConfirm) {
    this.service.clone(event.id!, event.title).subscribe(() => {
      this.refreshData();
    });
  }
}
