import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import { FoldersRepository, IFolder } from './folders.repository';

const API = '/api/folders';

@Injectable({ providedIn: 'root' })
export class FoldersService extends BaseService<IFolder> {
  constructor(http: HttpClient, repo: FoldersRepository) {
    super(API, http, repo);
  }

  loadAll() {
    return this.http.get<IFolder[]>(API + `/getfolders`);
  }
  loadAllExcept(id: string) {
    return this.http.get<IFolder[]>(API + `/getfoldersExcept/` + id);
  }
  postFolder(entity: Partial<IFolder>) {
    return this.http.post<any>(API + '/postfolder', entity);
  }

  deleteCustom(id: string) {
    return this.http.delete<any>(API + `/deleteFolder/` + id);
  }
  editFolder(entity: Partial<IFolder>) {
    return this.http.patch<any>(API + `/updateFolder`, entity);
  }
  loadOneFolder(id: string) {
    return this.http.get<IFolder>(API + `/loadOneFolder/` + id);
  }
}
