import { Component, OnInit } from '@angular/core';
import { SimpleCardAction } from '../../../shared/components/simple-card/simple-card.component';

@Component({
  selector: 'app-session-over-state',
  templateUrl: './session-over-state.component.html',
  styleUrls: ['./session-over-state.component.scss'],
})
export class SessionOverStateComponent implements OnInit {
  testsLocation = 'localhost:9876';
  actions: SimpleCardAction[] = [
    {
      text: $localize`:Refresh page button:Refresh`,
      routerLink: ['/signin'],
    },
  ];

  constructor() {}

  ngOnInit(): void {
    if (
      !localStorage.getItem('refreshed') &&
      window.location.host !== this.testsLocation
    ) {
      localStorage['refreshed'] = true;
      window.location.reload();
    } else {
      localStorage.removeItem('refreshed');
    }
  }
}
