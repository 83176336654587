import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  withEntities,
  selectAllEntities,
  addEntities,
  selectEntitiesCount,
  getAllEntities,
  updateAllEntities,
  deleteEntities,
  getEntity,
  deleteAllEntities,
  updateEntities,
} from '@ngneat/elf-entities';
import { localStorageStrategy, persistState } from '@ngneat/elf-persist-state';
import {
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestStatus,
  withRequestsStatus,
} from '@ngneat/elf-requests';
import { map, MonoTypeOperatorFunction } from 'rxjs';

export interface Request {
  id: number;
  body: any;
  url: string;
  method: string;
  userId: string | null;
  createdAt: Date;
  responseIdPath?: string;
}

const ID_PROMISE = 'id-promise';
const ID_PROMISE_REGEX = new RegExp(`${ID_PROMISE}~\d+~`);
export function hasIdPromise(url: string) {
  return ID_PROMISE_REGEX.test(url);
}
export function buildIdPromise(id: number) {
  return `${ID_PROMISE}~${id}~`;
}

const store = createStore(
  { name: 'requests' },
  withEntities<Request>(),
  withRequestsStatus()
);
persistState(store, { storage: localStorageStrategy });

@Injectable({ providedIn: 'root' })
export class RequestsRepository {
  name = store.name;

  all$ = store.pipe(selectAllEntities());
  count$ = store.pipe(selectEntitiesCount());

  private trackOperator = createRequestsStatusOperator(store);
  track(id?: number): MonoTypeOperatorFunction<any> {
    return this.trackOperator(id?.toString() || this.name);
  }
  isLoading$ = store.pipe(
    selectRequestStatus(this.name),
    map((x) => x.value === 'pending')
  );
  statusOne = (id: number) => store.pipe(selectRequestStatus(id.toString()));
  isLoadingOne = (id: number) =>
    this.statusOne(id).pipe(map((x) => x.value === 'pending'));

  add(request: Omit<Request, 'id'>): Request {
    const ids = store.getValue().ids;
    const maxId = ids.length ? Math.max(...ids) : 0;
    const entity = {
      ...request,
      id: maxId + 1,
    };
    store.update(addEntities([entity]));
    return entity;
  }

  update(id: number, data: Partial<Request>) {
    store.update(updateEntities(id, data));
  }

  getAllForUser(userId: string): Request[] {
    return store.query(getAllEntities()).filter((x) => x.userId === userId);
  }

  getNextForUser(userId: string): Request | undefined {
    return this.getAllForUser(userId)[0];
  }

  getOne(id: number): Request | undefined {
    return store.query(getEntity(id));
  }

  resolveId(requestId: number, realId: string) {
    store.update(
      updateAllEntities((entity) => ({
        ...entity,
        url: entity.url.replace(buildIdPromise(requestId), realId),
      }))
    );
  }

  remove(id: number) {
    store.update(
      deleteEntities(id),
      updateRequestStatus(id.toString(), 'success'),
      updateRequestStatus(this.name, 'success')
    );
  }

  clear() {
    store.update(deleteAllEntities());
  }
}
