<div class="col">
  <agm-map
    [latitude]="lat"
    [longitude]="lng"
    [zoom]="zoom"
    [disableDefaultUI]="false"
    [zoomControl]="false"
    (mapClick)="mapClicked($event)"
    class="mapFullScreen"
  >
    <agm-marker
      *ngFor="let m of markers; let i = index"
      (markerClick)="clickedMarker(m.label, i)"
      [latitude]="m.lat"
      [longitude]="m.lng"
      [label]="m.label"
      [markerDraggable]="m.draggable"
      (dragEnd)="markerDragEnd(m, $event)"
    >
      <agm-info-window class="">
        <div>{{ m.lastEventDesc }}</div>
        <div>{{ m.lastEventDate }}</div>
      </agm-info-window>
    </agm-marker>
    <agm-circle
      *ngFor="let c of circles; let i = index"
      [latitude]="c.lat"
      [longitude]="c.lng"
      [radius]="c.radius"
      [circleDraggable]="c.draggable"
      [fillColor]="c.fillColor"
    >
    </agm-circle>
  </agm-map>
</div>
