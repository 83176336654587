import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { OutOfOfficeStatusPageComponent } from './pages/out-of-office-status-page/out-of-office-status-page.component';
import { OutOfOfficeStatusFormComponent } from './components/out-of-office-status-form/out-of-office-status-form.component';
import { BaseModule } from '../base/base.module';
import { CoreModule } from '../core/core.module';
import { TranslocoRootModule } from '../../transloco-root.module';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { OutOfOfficeStatusGridComponent } from './components/out-of-office-status-grid/out-of-office-status-grid.component';
import { OutOfOfficeStatusFormPageComponent } from './pages/out-of-office-status-form-page/out-of-office-status-form-page.component';

@NgModule({
  declarations: [
    OutOfOfficeStatusPageComponent,
    OutOfOfficeStatusFormComponent,
    OutOfOfficeStatusGridComponent,
    OutOfOfficeStatusFormPageComponent,
  ],
  imports: [
    CommonModule,
    BaseModule,
    CoreModule,
    TranslocoRootModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
  exports: [OutOfOfficeStatusPageComponent],
})
export class OutOfOfficeStatusModule {}
