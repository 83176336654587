export const environment = {
  production: true,
  name: 'Igntr',
  apiUrl: '/core',
  features: {
    news: true,
    offlineChanges: true,
    mail: true,
    whistleblow: true,
    calendar: true,
    apv: true,
    mwevents: true,
    timelogs: true,
    maps: true,
    marketplace: true,
    usergroup: true,
    toolBox: true,
    personalPlan: true,
    outOfOfficeStatus: true,
  },
  firebase: {
    apiKey: 'AIzaSyBV2fxBYTQaZTpRr_A_OYY2wfc6OcdavY8',
    authDomain: 'a2apptest-94eed.firebaseapp.com',
    projectId: 'a2apptest-94eed',
    storageBucket: 'a2apptest-94eed.appspot.com',
    messagingSenderId: '736617729971',
    appId: '1:736617729971:web:7548',
    vapidKey:
      'BKKklJ7ULfRPmoev9ko71xq7Mn5_fm42UfXea2b_F_HhpqKH_BAXDKfeQj717kQ3fpGzjNkLi3GjPIc74azBkYg',
  },
  locale: 'en-gb',
};
