<h2 class="mb-4" *ngIf="id === 'new'" i18n="Create user label">
  {{ "createUser" | transloco }}
</h2>
<h2 class="mb-4" *ngIf="id !== 'new'" i18n="Edit user label">
  {{ "editUser" | transloco }}
</h2>
<div>
  <app-user-basic-info-form
    *ngIf="id !== 'new'"
    [mode]="'edit'"
    [tenants]="tenantOptions"
    [userGroups]="userGroupsRepository.userGroups$ | async"
    [showPasswordField]="id === 'new'"
    (userSubmit)="onInfoSubmit($event)"
  >
  </app-user-basic-info-form>
  <app-user-basic-info-form
    *ngIf="id === 'new'"
    [mode]="'new'"
    [tenants]="tenantOptions"
    [userGroups]="userGroupsRepository.userGroups$ | async"
    [showPasswordField]="id === 'new'"
    (userSubmit)="onInfoSubmit($event)"
  >
  </app-user-basic-info-form>
  <app-error-alert
    title="{{ 'couldNotSubmitTheUsersProfile' | transloco }}"
    i18n-title="User profile submit error alert"
    [errors]="submitErrors"
  ></app-error-alert>
  <app-change-password-form
    *ngIf="
      id !== 'new' &&
      (authRepo.isInRole('Superadmin') || authRepo.isInRole('Administrator'))
    "
    (passwordSubmit)="onPasswordSubmit($event)"
    [isReset]="true"
  ></app-change-password-form>
</div>
