import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AuthRepository } from 'app/state/auth.repository';
import { TenantsRepository } from 'app/state/tenants.repository';
import { User, UsersRepository } from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { Table } from 'primeng/table';
import { UserForListDto } from 'app/api/models/user/userForListDto';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  @Output() pageChange = new EventEmitter<number>();
  @Output() delete = new EventEmitter<string>();
  @Output() onUserSelection = new EventEmitter<UserForListDto[]>();

  @Input()
  set clearSelectedUsers(value: UserForListDto[]) {
    this.selectedUsers = value;
    this.clear();
  }
  @Input() innerWidth = 0;
  @Input() users: UserForListDto[] | null = null;
  @Input() isDeleteDisabled = true;

  @ViewChild('dt1') dt1!: Table;

  public selectedUsers: UserForListDto[] = [];
  public actionConfirmation: User | null = null;
  public roles: any[];
  public userGroups: any[];

  constructor(
    public repo: UsersRepository,
    public tenantRepo: TenantsRepository,
    public service: UsersService,
    public auth: AuthRepository,
    public blobService: BlobStorageService
  ) {
    this.userGroups = [];
    this.roles = [
      { label: 'User', value: 'user' },
      { label: 'Admin', value: 'admin' },
      { label: 'Super', value: 'super' },
      { label: 'Partner', value: 'partner' },
    ];
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.users && this.users) {
      this.updateUserGroups();
    }
  }

  ngOnInit(): void {
    this.clear();
  }

  clear() {
    if (this.dt1) {
      this.dt1.clear();
    }
  }

  handleActionClick(user: User) {
    this.delete.emit(user.id);
  }

  onGlobalFilter(table: Table, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    table.filterGlobal(inputElement.value, 'contains');
  }

  getSeverity(status: any) {
    switch (status.toLowerCase()) {
      case 'user':
        return 'danger';

      case 'admin':
        return 'success';

      case 'super':
        return 'info';

      case 'partner':
        return 'warning';

      default:
        return 'info';
    }
  }

  getFieldValue(array: any[]) {
    if (array.length > 1) {
      return array[0] + '...';
    }
    return array[0];
  }

  updateUserGroups() {
    const groupsSet = new Set<string>();
    if (!this.users) return;
    this.users.forEach((user) => {
      if (user.groupsNames && Array.isArray(user.groupsNames)) {
        user.groupsNames.forEach((group) => {
          groupsSet.add(group);
        });
      }
    });

    this.userGroups = Array.from(groupsSet).map((group) => ({
      label: group,
      value: group,
    }));
  }

  onSelectionChange(event: any): void {
    this.onUserSelection.emit(this.selectedUsers);
  }
}
