import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-appinnerlayout',
  templateUrl: './appinnerlayout.component.html',
  styleUrls: ['./appinnerlayout.component.scss'],
})
export class AppinnerlayoutComponent implements OnInit {
  page: string | null = null;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.page = this.router.url;
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    let header = document.getElementsByTagName('app-headerback')[0];
    let main = document.getElementsByTagName('html')[0];

    if (main.scrollTop > 15) {
      header.classList.add('active');
    } else {
      header.classList.remove('active');
    }
  }
}
