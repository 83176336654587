<div
  class="modal fade"
  bsModal
  [config]="{ show: true, backdrop: false }"
  id="deleteModal"
  tabindex="-1"
  #deleteModal
  aria-hidden="true"
  (onHidden)="cancel.emit()"
  style="background-color: rgba(0, 0, 0, 0.5); backdrop-filter: blur(5px)"
>
  <div class="modal-dialog modal-dialog-centered modal-sm">
    <div class="modal-content bg-lights border-0 br-16">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="deleteModalLabel"
          i18n="Title for confirmation dialog"
        >
          {{ "confirmYourAction" | transloco }}
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          i18n-aria-label="Close label @@close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="modal-body">
        <div class="form-floating mb-3">
          <div *ngIf="deleteCandidate">
            <p class="mb-1" i18n="Confirm delete message start">
              {{ preConfirmText | transloco }}
            </p>
            <p class="mb-1">
              <strong class="text-break"
                >{{ deleteCandidate | slice : 0 : 100 }}...</strong
              >
            </p>
          </div>

          <p class="mb-0" i18n="Confirm delete message end">
            {{ confirmText | transloco }}
          </p>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          class="btn btn-orange me-2"
          (click)="confirm.emit()"
          i18n="Delete button"
        >
          {{ confirmBtnText | transloco }}
        </button>
        <button class="btn btn-d" (click)="cancel.emit()" i18n="@@cancel">
          {{ "cancel" | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
