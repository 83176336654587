import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  MediaFileMediaType,
  MediaLibraryFileDto,
} from 'app/api/models/mediaLibrary/mediaLibraryFileDto';
import { MediaLibraryService } from 'app/api/services/mediaLibrary.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { AuthRepository } from 'app/state/auth.repository';

@Component({
  selector: 'app-media-library',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-library.component.scss'],
})
export class MediaLibraryComponent implements OnInit {
  constructor(
    public blobService: BlobStorageService,
    public authRepository: AuthRepository,
    public mediaFilesLibrary: MediaLibraryService,
    public dialog: MatDialog
  ) {}

  @Input() allowFilesSelection: boolean = false;
  @Input() allowImagesSelection: boolean = false;
  @Input() allowVideosSelection: boolean = false;
  @Input() allowSoundsSelection: boolean = false;
  @Input() allowDocumentsSelection: boolean = false;
  @Input() uploadOnlyPrivateFilesModeActive: boolean = false;
  @Output() selectedFilesConfirmation = new EventEmitter<
    MediaLibraryFileDto[]
  >();

  public filterOptions: any[] = [];

  public selectedFiles: MediaLibraryFileDto[] = [];
  public allValidFileMediaTypes: MediaFileMediaType[] = [];

  uploadedFile: File | null = null;
  fileInvalid: boolean = false;
  fileName: string = '';
  priviteFilesUploadModeActive: boolean = false;
  priviteFilesUploadModeSvitchDisabled: boolean = false;
  showPrivateFiles: boolean = false;
  selectedFilesFilter: number = 99;

  availableLibraryObjects: MediaLibraryFileDto[] = [];
  allLibraryObjects: MediaLibraryFileDto[] = [];
  filteredLibraryObjects: MediaLibraryFileDto[] = [];

  ngOnInit(): void {
    this.priviteFilesUploadModeActive = this.uploadOnlyPrivateFilesModeActive;
    this.priviteFilesUploadModeSvitchDisabled =
      this.uploadOnlyPrivateFilesModeActive;
    this.setFilterButtons();
    this.mediaFilesLibrary.getMediaLibraryFilesData().subscribe((data) => {
      this.allLibraryObjects = data;
      this.setAvailableFiles();
    });
  }

  toggleSelectFile(object: any) {
    this.selectedFiles = [];
    if (this.allowFilesSelection) {
      if (!object.isSelected) {
        this.allLibraryObjects.forEach((object) => (object.isSelected = false));
        object.isSelected = !object.isSelected;
        this.selectedFiles.push(object);
      } else {
        object.isSelected = false;
      }
    }
  }

  getFileImege(file: MediaLibraryFileDto) {
    return this.mediaFilesLibrary.getMediaLibraryFileIconDependsOnType(file);
  }

  confirmFileSelection() {
    this.selectedFilesConfirmation.emit(this.selectedFiles);
  }

  onUploadFileSelect(event: any): void {
    if (this.allowFilesSelection) {
      this.allLibraryObjects.forEach((object) => (object.isSelected = false));
      this.selectedFiles = [];
    }
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length > 0) {
      const file = input.files[0];

      this.uploadedFile = file;
      this.fileInvalid = false;
      this.fileName = this.uploadedFile.name;
    }
    this.uploadFile();
  }

  setFilterButtons() {
    this.filterOptions = [
      {
        name: 'All',
        value: 99,
      },
      {
        name: 'Image',
        value: MediaFileMediaType.Image,
        disabled: !this.allowImagesSelection && this.allowFilesSelection,
        selected: true,
      },
      {
        name: 'Video',
        value: MediaFileMediaType.Video,
        disabled: !this.allowVideosSelection && this.allowFilesSelection,
      },
      {
        name: 'Sound',
        value: MediaFileMediaType.Sound,
        disabled: !this.allowSoundsSelection && this.allowFilesSelection,
      },
      {
        name: 'Files',
        value: MediaFileMediaType.Other,
        disabled: !this.allowDocumentsSelection && this.allowFilesSelection,
      },
    ];
  }

  changePrivatePublickMode() {
    this.showPrivateFiles = !this.showPrivateFiles;
    this.applyAllFilters();
  }

  applyAllFilters() {
    if (!this.showPrivateFiles) {
      this.filteredLibraryObjects = this.availableLibraryObjects;
    } else {
      this.filteredLibraryObjects = this.availableLibraryObjects.filter(
        (obj) => obj.isPersonaFile === this.showPrivateFiles
      );
    }

    if (this.selectedFilesFilter != 99) {
      this.filteredLibraryObjects = this.filteredLibraryObjects.filter(
        (obj) => obj.mediaType === this.selectedFilesFilter
      );
    }
  }

  setAvailableFiles() {
    if (!this.allowFilesSelection) {
      this.availableLibraryObjects = this.allLibraryObjects;
      this.filteredLibraryObjects = this.allLibraryObjects;
      return;
    }
    let filesArray: MediaLibraryFileDto[] = [];

    if (this.allowImagesSelection) {
      let data = this.allLibraryObjects.filter(
        (obj) => obj.mediaType === MediaFileMediaType.Image
      );
      filesArray.push(...data);
    }

    if (this.allowVideosSelection) {
      let data = this.allLibraryObjects.filter(
        (obj) => obj.mediaType === MediaFileMediaType.Video
      );
      filesArray.push(...data);
    }

    if (this.allowSoundsSelection) {
      let data = this.allLibraryObjects.filter(
        (obj) => obj.mediaType === MediaFileMediaType.Sound
      );
      filesArray.push(...data);
    }

    if (this.allowDocumentsSelection) {
      let data = this.allLibraryObjects.filter(
        (obj) => obj.mediaType === MediaFileMediaType.Other
      );
      filesArray.push(...data);
    }
    this.availableLibraryObjects = filesArray;
    this.filteredLibraryObjects = filesArray;
    this.selectedFilesFilter = 99;
  }

  uploadFile() {
    this.authRepository.id$.subscribe((userId) => {
      const activeTenantId = localStorage.getItem('activeTenantId');

      const formData = new FormData();

      formData.append('userId', userId!);
      formData.append('tenantId', activeTenantId!);
      formData.append(
        'isPersonaFile',
        this.priviteFilesUploadModeActive ? 'true' : 'false'
      );

      if (this.uploadedFile) {
        formData.append('file', this.uploadedFile, this.uploadedFile.name);
      }

      this.mediaFilesLibrary.uploadFile(formData).subscribe((data) => {
        this.uploadedFile = null;
        this.allLibraryObjects.unshift(data);
        this.setAvailableFiles();
        this.applyAllFilters();
      });
    });
  }
}
