import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { NotificationsRepository } from './notifications.repository';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';

const API = '/api/notification';

@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseService<any> {
  constructor(http: HttpClient, repo: NotificationsRepository) {
    super(API, http, repo);
  }

  loadAll() {
    return this.http.get<any[]>(API + '/getNotificationsTypes');
  }

  load(): Observable<PaginationData<number> & { data: any[] }> {
    this.repo.clear();

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<PaginationData & { data: any[] }>(API + '/getNotificationsTypes')
      .pipe(
        tap((res) => this.repo.set(res.data)),
        this.repo.track()
        //this.repo.skipWhileCached()
      );
  }

  loadPage(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string = ''
  ): Observable<PaginationData & { data: any[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: any[] }>(
        `${API + '/getNotificationsTypes'}?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadFilterPage(
    search: string = '',
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: any[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: any[] }>(
        `${API + '/getNotificationsTypes'}?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }
}
