<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-4" [appValidateState]="editForm.get('title')">
      <div class="inpt-title">{{ "title" | transloco }}</div>
      <input
        id="title"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="{{ 'title' | transloco }}"
        autocomplete="off"
      />
    </div>
    <div class="mb-3">
      <div class="inpt-title">{{ "description" | transloco }}</div>
      <textarea
        id="editor"
        formControlName="editor"
        type="text"
        class="form-control"
        placeholder="{{ 'description' | transloco }}"
        autocomplete="off"
      >
      </textarea>
    </div>
    <app-image-video-component
      [entity]="editedPost?.documents && (editedPost?.documents)![0]"
    ></app-image-video-component>
    <app-period-select [defaultValues]="timestamp"></app-period-select>
    <div class="form-group form-floating mb-3">
      <app-datalist-select
        [activeValue]="editForm?.value?.userGroupIds"
        [options]="groupsOptions"
        [optionsDeleted]="groupsOptionsDeleted"
        (activeValueChange)="updateValue('userGroupIds', $event)"
        [multiple]="true"
        label="{{ 'userGroups' | transloco }}"
      ></app-datalist-select>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm">
      {{ "save" | transloco }}
    </button>
  </div>
</form>
