import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';

import { WhistleblowComponent } from './whistleblow/whistleblow.component';

import { WhistleblowFormComponent } from './whistleblow-form/whistleblow-form.component';
import { WhistleblowPostComponent } from './whistleblow-post/whistleblow-post.component';
import { WhistleblowAdminComponent } from './whistleblow-admin/whistleblow-admin.component';
import { WhistleblowInfoComponent } from './whistleblow-info/whistleblow-info.component';
import { TranslocoRootModule } from '../../transloco-root.module';

@NgModule({
  declarations: [
    WhistleblowComponent,
    WhistleblowFormComponent,
    WhistleblowPostComponent,
    WhistleblowAdminComponent,
    WhistleblowInfoComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    TranslocoRootModule,
  ],
  exports: [WhistleblowComponent],
})
export class WhistleblowModule {}
