import { Component, Inject, OnInit } from '@angular/core';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-media-library-modal',
  templateUrl: './media-library-modal.component.html',
  styleUrls: ['./media-library-modal.component.scss'],
})
export class MedialLibraryModalComponent implements OnInit {
  public allowDocumentsSelection: boolean = false;
  public allowImagesSelection: boolean = false;
  public allowVideosSelection: boolean = false;
  public allowSoundsSelection: boolean = false;
  public uploadOnlyPrivateFilesModeActive: boolean = false;

  constructor(
    public blobService: BlobStorageService,
    public dialogRef: MatDialogRef<MedialLibraryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.allowDocumentsSelection = data.allowDocumentsSelection;
    this.allowImagesSelection = data.allowImagesSelection;
    this.allowVideosSelection = data.allowVideosSelection;
    this.allowSoundsSelection = data.allowSoundsSelection;
    this.uploadOnlyPrivateFilesModeActive = data.uploadOnlyPrivateFilesModeActive;
  }

  ngOnInit(): void {}

  handleSelectedFilesConfirmation($event: any) {
    this.dialogRef.close($event[0]);
  }
}
