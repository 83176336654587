<div
  *ngIf="installPrompt && !(ui.isInstallPromptDismissed$ | async)"
  [@enterFromBottom]
  class="bg-orange text-white text-start pwa-install-label p-3 shadow-sm"
>
  <div class="row">
    <div class="col-auto" role="button" (click)="install()">
      <i class="bi bi-bookmark-plus-fill h2"></i>
    </div>
    <div class="col" role="button" (click)="install()">
      <p class="m-0" i18n="Label to install app">{{ "install" | transloco }}</p>
      <small i18n="Button allow offline using">{{
        "useOffline" | transloco
      }}</small>
    </div>
    <div class="col-auto p-0">
      <div class="vr h-100"></div>
    </div>
    <div
      class="col-auto text-center"
      role="button"
      (click)="ui.dismissInstallPrompt()"
    >
      <p class="m-0">
        <i class="bi bi-x-lg"></i>
      </p>
      <small i18n="Button to dismiss installation">{{
        "dismiss" | transloco
      }}</small>
    </div>
  </div>
</div>
