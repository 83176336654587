import { DatalistOption } from 'app/state/abstract/base.repository';
import { Tenant } from 'app/state/tenants.repository';

export function getTenantsAsDataList(dataArray: Tenant[]): DatalistOption[] {
  let dataOptions: DatalistOption[] = [];
  for (const tenant of dataArray) {
    const option: DatalistOption = {
      label: tenant.name,
      value: tenant.id,
    };
    dataOptions.push(option);
  }
  return dataOptions;
}
