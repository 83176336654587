import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  combineLatest,
  filter,
  map,
  Observable,
  skipWhile,
  switchMap,
  tap,
} from 'rxjs';
import { IApv, ApvRepository } from 'app/state/apv.repository';
import { ApvService } from 'app/state/apv.service';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-apv-edit',
  templateUrl: './apv-edit.component.html',
  styleUrls: ['./apv-edit.component.scss'],
})
export class ApvEditComponent implements OnInit {
  post$: Observable<IApv> | null = null;
  post: IApv | null = null;
  id!: string;
  submitErrors: string[] | null = null;
  constructor(
    private service: ApvService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: ApvRepository,
    private urlId: UrlIdResolverService
  ) {}

  ngOnInit(): void {
    this.post$ = this.urlId.resolve(
      this.activatedRoute.paramMap,
      (id) => this.service.loadOneForAdmin(id),
      (id) => this.repo.post(id),
      (id) => this.repo.status(id),
      (id) => (this.id = id)
    );
    this.loadevent(this.id);
  }

  loadevent(id: string) {
    this.service.loadOneForAdmin(id).subscribe((x) => {
      this.post = x;
    });
  }

  updatePost(post: Partial<IApv>) {
    this.submitErrors = null;
    let updateResult: Observable<IApv> | undefined;
    if (this.id === 'new') {
      updateResult = this.service.createArea(post);
    } else if (this.id) {
      updateResult = this.service.update(this.id, post);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['admin/apv']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
