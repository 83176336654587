import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IContact } from '../../../../state/companies.repository';

@Component({
  selector: 'app-contacts-editform',
  templateUrl: './contacts-editform.component.html',
  styleUrls: ['./contacts-editform.component.scss'],
})
export class ContactsEditformComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  constructor(private formBuilder: UntypedFormBuilder) {}
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      name: [this.editedContact?.name, Validators.required],
      email: [this.editedContact?.email, Validators.required],
      phoneNumber: this.editedContact?.phoneNumber,
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    const name = this.editForm.value?.name?.toString();
    const email = this.editForm.value?.email?.toString();
    const phoneNumber = this.editForm.value?.phoneNumber?.toString();
    this.ContactSubmit.emit({
      id: this.editedContact?.id,
      companyId: this.editedContact?.companyId,
      name,
      email,
      phoneNumber,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  @Input() editedContact?: IContact | null;
  @Output() ContactSubmit = new EventEmitter<Partial<IContact>>();
}
