import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import {
  CirclesOptions,
  ICompanyMarker,
  IMapInfo,
  CirclesOptionsKm,
  ColorsOptions,
  IInvoice,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';
import { Location } from '@angular/common';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-companies-editform',
  templateUrl: './companies-editform.component.html',
  styleUrls: ['./companies-editform.component.scss'],
})
export class CompaniesEditformComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  @Input() editedCompany?: ICompanyMarker | null;
  submitErrors: string[] | null = null;
  locationIndex: number = 1;
  formattedaddress = ' ';
  myOptions: any;
  CirclesOptions: DatalistOption[] = CirclesOptions;
  ColorsOptions: DatalistOption[] = ColorsOptions;

  testsLocation = 'localhost:9876';
  mapInfo: IMapInfo | null = null;
  @Output() ClientSubmit = new EventEmitter<Partial<ICompanyMarker>>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,

    private service: CompaniesService
  ) {
    this.myOptions = new Options({
      //componentRestrictions: {
      //  country: 'AU'
      //}
      // if restrictions needed
    });
  }

  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      title: [this.editedCompany?.title, Validators.required],
      longitude: [this.editedCompany?.longitude],
      latitude: [this.editedCompany?.latitude],
      address: [this.editedCompany?.address],
      clientSince: [this.editedCompany?.formatedClientSince],
      acquiredBy: [this.editedCompany?.acquiredBy],
      description: [this.editedCompany?.description],
      aeAdvisor: [this.editedCompany?.aeAdvisor],
      clientCode: [this.editedCompany?.clientCode],
      pricing: [this.editedCompany?.pricing],
      invoice: this.formBuilder.group({
        id: this.editedCompany?.invoice?.id,
        billTo: this.editedCompany?.invoice?.billTo,
        email: this.editedCompany?.invoice?.email,
      }),
      notes: [this.editedCompany?.notes],
      fillColor: [this.editedCompany?.fillColor],
    });

    this.service.loadMapInfo().subscribe((x) => {
      this.mapInfo = x;
      if (this.mapInfo.useKmAsValues) {
        this.CirclesOptions = CirclesOptionsKm;
      }
    });
  }

  locationCheck() {
    if (window.location.host !== this.testsLocation) {
      return true;
    }
    return false;
  }

  onSubmit() {
    const id = this.editedCompany?.id?.toString();
    const title = this.editForm.value?.title?.toString();
    const description = this.editForm.value?.description?.toString();
    const address = this.editForm.value?.address?.toString();
    const parsedClientSince = this.editForm.value?.clientSince;
    const aeAdvisor = this.editForm.value?.aeAdvisor;
    const longitude = this.editForm.value?.longitude;
    const latitude = this.editForm.value?.latitude;
    const fillColor = this.editForm.value?.fillColor;
    const clientSince =
      parsedClientSince === '' ? undefined : parsedClientSince;
    const acquiredBy = this.editForm.value?.acquiredBy?.toString();
    const tenantId = this.editedCompany?.tenantId?.toString();
    const clientCode = this.editForm.value?.clientCode;
    const pricing = this.editForm.value?.pricing;
    const invoice: IInvoice = this.editForm.value?.invoice;
    const notes = this.editForm.value?.notes;

    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    var addressNotSet = false;

    if (addressNotSet) {
      this.editForm.markAllAsTouched();
      return;
    }

    this.ClientSubmit.emit({
      id,
      title,
      description,
      address,
      clientSince,
      acquiredBy,
      tenantId,
      aeAdvisor,
      longitude,
      latitude,
      /*cellPhones,*/ clientCode,
      /*contacts,*/ pricing,
      invoice,
      notes,
      fillColor,
    });
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  updateCompanyLocation(address: any) {
    const lng = this.editForm?.get('longitude');
    lng?.setValue(address.geometry.location.lng());
    lng?.markAsTouched();
    const lat = this.editForm?.get('latitude');
    lat?.setValue(address.geometry.location.lat());
    lat?.markAsTouched();
    const addressobject = this.editForm?.get('address');
    addressobject?.setValue(address.formatted_address);
    addressobject?.markAsTouched();
  }
}
