import { Component, HostListener, OnInit } from '@angular/core';
import { AuthRepository, UserRoles } from 'app/state/auth.repository';
import { TenantsRepository } from 'app/state/tenants.repository';
import {
  errorInterface,
  ImportedUser,
  userSortOptions,
  UsersRepository,
} from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
import {
  ConfirmationService,
  ConfirmEventType,
  MenuItem,
  MessageService,
} from 'primeng/api';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { UserForListDto } from 'app/api/models/user/userForListDto';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserGroupsSelectionComponent } from 'app/modules/shared/components/data-selection/userGroup-selection-modal/usergroups-selection.component';
import { UserGroupsService } from 'app/api/services/usergroup.service';
import { TranslocoService } from '@ngneat/transloco';
import { ToastService } from 'app/helpers/services/toastService';

@Component({
  selector: 'app-users-list-page',
  templateUrl: './users-list-page.component.html',
  styleUrls: ['./users-list-page.component.scss'],
  providers: [DialogService, ConfirmationService, MessageService],
})
export class UsersListPageComponent implements OnInit {
  sortOptions = userSortOptions;
  innerWidth = 0;
  searchFilter: string = '';
  submitErrors: string[] | null = null;
  hasErrors: boolean = false;
  waitingForImportResponse$ = false;
  users: Array<UserForListDto> = [];
  importFactory = (file: File) => this.service.importUsers(file);
  exportFactory = () => this.service.export();

  items: MenuItem[] = [];
  selectedUsers: UserForListDto[] = [];
  emptySelectedUsers: UserForListDto[] = [];

  ref: DynamicDialogRef | undefined;
  constructor(
    public tenantRepo: TenantsRepository,
    public repo: UsersRepository,
    public service: UsersService,
    public auth: AuthRepository,
    public blobService: BlobStorageService,
    public dialogService: DialogService,
    public userGroupsService: UserGroupsService,
    public confirmationService: ConfirmationService,
    public translocoService: TranslocoService,
    public toastService: ToastService
  ) {
    this.fillActions();
  }

  activeTenant = localStorage.getItem('activeTenantId');
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.getAllUsers();
    this.innerWidth = window.innerWidth;
  }

  getAllUsers() {
    this.service.loadAllUsers().subscribe((data) => {
      this.users = data;
      this.selectedUsers = [];
      this.emptySelectedUsers = [];
      this.items.forEach(
        (item) => (item.disabled = this.selectedUsers.length === 0)
      );
      //this.toastService.showError('test');
    });
  }

  showError(error: errorInterface) {
    this.hasErrors = error.errorState;
    this.submitErrors = [error.errorMessage];
  }

  handleDeleteClick(id: string) {
    this.service.delete(id).subscribe(() => {
      this.getAllUsers();
    });
  }

  handleRestoreClick(id: string) {
    this.service.restore(id).subscribe(() => {
      this.getAllUsers();
    });
  }

  submitImport(importedUsers: ImportedUser[]) {
    this.waitingForImportResponse$ = true;
    this.service.confirmUsersImport(importedUsers).subscribe((r) => {
      this.waitingForImportResponse$ = false;
      this.service.reloadPage(this.searchFilter).subscribe();
    });
  }

  handleUserSelection(users: any) {
    this.selectedUsers = users;
    this.items.forEach(
      (item) => (item.disabled = this.selectedUsers.length === 0)
    );
  }

  deleteSelectedUsers() {
    const ids = this.selectedUsers.map((user) => user.id);
    this.service.deleteUsersByIds(ids).subscribe((complete) => {
      this.getAllUsers();
      this.confirmationService.close();
    });
  }

  asignUserToExistingGroup() {
    const dialogWidth = window.innerWidth > 768 ? '50%' : '90%';
    const dialogHeight = window.innerWidth > 768 ? '70%' : '80%';

    this.ref = this.dialogService.open(UserGroupsSelectionComponent, {
      header: 'Select groups',
    });

    this.ref.onClose.subscribe((selectedData) => {
      if (selectedData) {
        const selectedUserIds = this.selectedUsers.map((user) => user.id);
        this.service
          .assіgnUserGroupsToUser(selectedUserIds, selectedData)
          .subscribe(() => {
            this.getAllUsers();
          });
      }
    });
  }

  unassіgnUserGroupsAndUser() {
    const dialogWidth = window.innerWidth > 768 ? '50%' : '90%';
    const dialogHeight = window.innerWidth > 768 ? '70%' : '80%';

    this.ref = this.dialogService.open(UserGroupsSelectionComponent, {
      header: 'Select groups',
    });

    this.ref.onClose.subscribe((selectedData) => {
      if (selectedData) {
        const selectedUserIds = this.selectedUsers.map((user) => user.id);
        this.service
          .unassіgnUserGroupsAndUser(selectedUserIds, selectedData)
          .subscribe(() => {
            this.getAllUsers();
          });
      }
    });
  }

  confirmUsersDelete() {
    const message =
      this.translocoService.translate(`deleteConfirmationQuestion`) +
      ' ' +
      this.translocoService.translate(`selectedUsers`) +
      '?';

    const header = this.translocoService.translate('deleteConfirmation');

    this.confirmationService.confirm({
      message: message,
      header: header,
      icon: 'pi pi-exclamation-triangle',
    });
  }

  fillActions() {
    this.items = [
      {
        label: this.translocoService.translate(`assignToGroup`),
        icon: 'pi pi-plus',
        command: () => {
          this.asignUserToExistingGroup();
        },
        disabled: true,
      },
      {
        label: this.translocoService.translate(`unassignFromGroup`),
        icon: 'pi pi-times',
        command: () => {
          this.unassіgnUserGroupsAndUser();
        },
        disabled: true,
      },
      {
        label: this.translocoService.translate(`delete`),
        icon: 'pi pi-trash',
        command: () => {
          this.confirmUsersDelete();
        },
        disabled: true,
      },
    ];
  }
}
