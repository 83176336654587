import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IApv,
  ApvRepository,
  skipWhilePostsCached,
  trackPostRequestsStatus,
} from './apv.repository';
import { tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

const API = '/api/apv';
@Injectable({
  providedIn: 'root',
})
export class ApvService extends BaseService<IApv> {
  constructor(http: HttpClient, repo: ApvRepository) {
    super(API, http, repo);
  }

  load() {
    return this.http.get<PaginationData & { data: IApv[] }>(API);
  }

  loadAll() {
    return this.http.get<IApv[]>(API + '/all');
  }

  loadPersonal() {
    return this.http.get<IApv[]>('/api/apvAnswer/personal');
  }
  loadPersonalById(id?: string) {
    return this.http.get<IApv[]>(`/api/apvAnswer/user/${id}`);
  }

  loadOneForAdmin(id: string) {
    return this.http.get<IApv>(`${API}/${id}`);
  }

  addanswer(post: Partial<IApv>) {
    return this.http.post<any>('/api/apvAnswer/answer', post);
    //.pipe(
    //  tap((post) => this.repo.upsertPost(post)),
    //  trackPostRequestsStatus(`${this.repo.name}_add`)
    //);
  }

  createArea(post: Partial<IApv>) {
    return this.http.post<IApv>(API, post);
    //.pipe(
    //  tap((post) => this.repo.upsertPost(post)),
    //  trackPostRequestsStatus(`${this.repo.name}_add`)
    //);
  }

  update(id: string, post: Partial<IApv>) {
    return this.http.put<IApv>(`/api/apv/${id}`, post);
  }
}
