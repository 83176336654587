import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'app/state/auth.service';
import { User, UsersRepository } from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
import {
  AuthRepository,
  UserRoles,
  getStoredToken,
} from 'app/state/auth.repository';
import { TenantsService } from 'app/state/tenants.service';
import { TenantsRepository } from 'app/state/tenants.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralDataRepository } from 'app/helpers/repository/general-data.repository';

@Component({
  selector: 'app-impersonation-modal',
  templateUrl: './impersonation-modal.component.html',
  styleUrls: ['./impersonation-modal.component.scss'],
})
export class ImpersonationModalComponent implements OnInit {
  console = console;
  tenantsOptions: DatalistOption[] | null = null;
  selectedUserId: string | undefined;
  selectedTenantId: string | null = null;
  userOptions: DatalistOption[] | null = null;

  constructor(
    public repo: UsersRepository,
    private service: UsersService,
    private auth: AuthService,
    private authRepo: AuthRepository,
    private tenantsService: TenantsService,
    private tenantsRepo: TenantsRepository,
    private translocoService: TranslocoService,
    private generalDataRepo: GeneralDataRepository,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.load();
    this.service.loadForDatalist().subscribe((x) => (this.userOptions = x));
  }

  load() {
    if (this.authRepo.isInRole(UserRoles.Superadmin)) {
      this.service.load().subscribe();
      this.tenantsService.load().subscribe();
    }
  }

  setActiveTenant(): void {
    let isSuperadmin = localStorage.getItem('isSuperadmin');
    if (this.selectedTenantId) {
      localStorage.setItem('activeTenantId', this.selectedTenantId);
    } else {
      localStorage.removeItem('activeTenantId');
    }
    let token = getStoredToken();

    this.auth.setActiveTenant(token!, this.selectedTenantId).subscribe({
      complete: () => {
        localStorage.removeItem('userExists');
        localStorage.removeItem('isSuperadmin');
      },
    });
  }

  getTenantsForImpersonator(id: string) {
    this.tenantsService.loadForImpersonator(id).subscribe((value) => {
      if (!value) {
        this.tenantsOptions = null;
      } else {
        this.tenantsOptions = value.map((x) => ({
          value: x.id,
          label: x.name,
        }));
        if (value.length && value.length === 1) {
          this.selectedTenantId = value[0].id;
        }
      }
    });
  }

  impersonate() {
    this.auth
      .impersonate(this.selectedUserId!, this.selectedTenantId)
      .subscribe({
        next: (responce) => {
          if (responce.user) {
            this.authRepo.setUser(responce.user);
            this.translocoService.setActiveLang(
              responce?.user?.language ?? 'en'
            );
            if (this.selectedTenantId) {
              localStorage.setItem('activeTenantId', this.selectedTenantId);
            } else {
              localStorage.removeItem('activeTenantId');
            }
          }
          this.authRepo.setToken(responce.token);
          window.location.reload();
          //this.generalDataRepo.updateAllGeneralData(true);
          //this.router.navigate(['/home']);
        },
      });
  }
}
