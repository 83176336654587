import { Injectable } from '@angular/core';
import { BaseService } from './abstract/base.service';
import OutOfOfficeStatusRepository, {
  IOutOfOfficeStatus,
  IUserStatusShown,
} from './out-of-office-status.repository';
import { HttpClient } from '@angular/common/http';
import { Post } from './posts.repository';
import { Dayjs } from 'dayjs';

const API = '/api/OutOfOfficeStatus';

@Injectable({
  providedIn: 'root',
})
export class OutOfOfficeStatusService extends BaseService<IOutOfOfficeStatus> {
  constructor(http: HttpClient, repo: OutOfOfficeStatusRepository) {
    super(API, http, repo);
  }
  post(post: Partial<IOutOfOfficeStatus>) {
    return this.http.post<Post>(`${API}`, post);
  }
  loadAllStatuses(startDate?: Dayjs, endDate?: Dayjs, timeOffset?: number) {
    const query = [
      `from=${startDate}`,
      `to=${endDate}`,
      `timeOffset=${timeOffset}`,
    ];
    return this.http.get<IUserStatusShown[]>(
      `${API}/getAllUserStatuses?${query.join('&')}`
    );
  }
  loadStatusById(id: string) {
    return this.http.get<IOutOfOfficeStatus>(`${API}/${id}`);
  }

  updateStatus(id: string, body: Partial<IOutOfOfficeStatus>) {
    return this.http.patch(`${API}/${id}`, body);
  }
  removeStatus(id: string) {
    return this.http.delete(`${API}/${id}`);
  }
}
