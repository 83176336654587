import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import {
  skipWhileTenantsCached,
  Tenant,
  TenantDocument,
  TenantHomeUpdateModel,
  TenantDocSettings,
  TenantsRepository,
  trackTenantRequestsStatus,
} from './tenants.repository';
import { BaseService } from './abstract/base.service';
import { DatalistOption } from './abstract/base.repository';
import { TenantsListPageDto } from 'app/api/models/tenant/tenantsListPageDto';
import { TenantMsGraphOptions } from 'app/api/models/tenant/TenantMsGraphOptions';

const API = '/api/tenants';

@Injectable({ providedIn: 'root' })
export class TenantsService {
  constructor(private http: HttpClient, private repo: TenantsRepository) {}

  loadTenantDocument(id: string) {
    const query = [`id=${id}`];

    return this.http.get<TenantDocument>(
      `${API}/loadTenantDocument?${query.join('&')}`
    );
  }

  getAllTenants() {
    return this.http.get<TenantsListPageDto[]>(API);
  }

  load() {
    return this.http.get<Tenant[]>(API).pipe(
      tap((tenants) => {
        this.repo.setTenants(tenants);
      }),
      trackTenantRequestsStatus(this.repo.name)
      //skipWhileTenantsCached(this.repo.name)
    );
  }

  clone(id: string, name?: string) {
    return this.http.get(`${API}/clone/${id}?newName=${name}`);
  }

  loadTenantHomePageModel(id: string) {
    return this.http.get<TenantHomeUpdateModel>(`${API}/home/${id}`);
  }

  loadMsGraphOptions(id: string) {
    const query = [`id=${id}`];
    return this.http.get<TenantMsGraphOptions>(
      `${API}/msGraphOptions?${query.join('&')}`
    );
  }

  updateMsGraphOptions(id: string, options: Partial<TenantMsGraphOptions>) {
    const query = [`id=${id}`];
    return this.http.put<void>(
      `${API}/msGraphOptions?${query.join('&')}`,
      options
    );
  }

  sendTestEmail(options: Partial<TenantMsGraphOptions>) {
    return this.http.post(`${API}/sendTestEmail`, options);
  }

  loadForDatalist() {
    return this.http.get<DatalistOption[]>(API + '/forDatalist');
  }

  loadOneWithoutTrack(id: string) {
    return this.http.get<Tenant>(`${API}/${id}`);
  }

  loadForUser() {
    return this.http.get<Tenant[]>(API + '/foruser');
  }

  loadForImpersonator(id: string) {
    return this.http
      .get<Tenant[]>(API + `/forimpersonator/${id}`)
      .pipe(trackTenantRequestsStatus(this.repo.name));
  }

  loadOne(id: string) {
    return this.http.get<Tenant>(`${API}/${id}`);
  }

  add(tenant: Partial<Tenant>) {
    const formData = new FormData();
    if (tenant.image) {
      formData.append('file', tenant.image);
    }

    formData.append(
      'deleteRequired',
      (tenant.tenantDocumentDeleteRequired ?? false).toString()
    );
    return this.http.post<Tenant>(API, tenant).pipe(
      switchMap((tenant1) =>
        this.http.post<Tenant>(`${API}/${tenant1.id}/tenantDocument`, formData)
      ),
      tap((tenant) => this.repo.upsertTenant(tenant)),

      trackTenantRequestsStatus(`${this.repo.name}_add`)
    );
  }

  update(id: string, tenant: Partial<Tenant>) {
    const formData = new FormData();
    if (tenant.image) {
      formData.append('file', tenant.image);
    }

    formData.append(
      'deleteRequired',
      (tenant.tenantDocumentDeleteRequired ?? false).toString()
    );

    return this.http.patch<Tenant>(`${API}/${id}`, tenant).pipe(
      switchMap((tenant1) =>
        this.http.post<Tenant>(`${API}/${id}/tenantDocument`, formData)
      ),
      tap((tenant) => this.repo.upsertTenant(tenant))
    );
  }

  delete(id: string): Observable<void> {
    return this.http
      .delete<void>(`${API}/${id}`)
      .pipe(tap(() => this.repo.remove(id)));
  }

  updateTenantContent(id: string, model: TenantHomeUpdateModel) {
    return this.http
      .put<Tenant>(`${API}/pageHtml/${id}`, model)
      .pipe(tap((tenant) => this.repo.upsertTenant(tenant)));
  }
}
