import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';

import { PaginationData } from '@ngneat/elf-pagination';
import { Observable } from 'rxjs';
import { SprintDto } from 'app/api/models/sprint/SprintDto';
import { IIdea } from 'app/api/models/sprint/SprintIdea';
import { IComment } from 'app/api/models/sprint/SprintComment';
import {
  ExportRequest,
  PathContainer,
  PeopleRatings,
  IdeaRating,
  PrioritizedDismised,
  EditCommentIdeaDto,
  CollaborationInfo,
  EventMembersDto,
  SprintModuleQAnswerDto,
} from 'app/state/sprint.repository';
import { IConfirm } from 'app/state/tenants.repository';
import { User } from 'app/state/users.repository';
import { SprintListDto } from '../models/sprint/sprintListDto';
import { SprintQuestionDto } from '../models/sprint/SprintQuestionDto';
import { SprintCommentIdeaDto } from '../models/sprint/SprintCommentIdeaDto';
import { SprintUserProgressionDto } from '../models/sprint/SprintUserProgressionDto';
const API = '/api/sprints';
const APIIdea = '/api/idea';
const APIComment = '/api/comment';
const APIRating = '/api/rateideas';
@Injectable({
  providedIn: 'root',
})
export class SprintService {
  // constructor(http: HttpClient, repo: MWEventsRepository) {
  //   super(API, http, repo);
  // }
  constructor(private http: HttpClient) {}

  loadAllForUser() {
    return this.http.get<SprintListDto[]>(`${API}/forUser`);
  }

  loadOneForAdmin(id: string) {
    const query = [`id=${id}`];
    return this.http.get<SprintDto>(`${API}/loadone?${query.join('&')}`);
  }

  deletePreview(id: string) {
    return this.http.delete(`${API}/deletePreview/${id}`);
  }

  updateProgression(index: number, sprintId: string) {
    return this.http.get<SprintUserProgressionDto[]>(
      `${API}/${sprintId}/${index}`
    );
  }

  delete(id: string) {
    return this.http.delete(`${API}/${id}`);
  }

  loadAllForAdmin(search?: string) {
    return this.http.get<SprintListDto[]>(`${API}/foradmin`);
  }

  addPreview(event: Partial<SprintDto>): Observable<string> {
    return this.http.put<string>(`${API}/addPreview`, event);
  }

  exportPDF(request: ExportRequest) {
    return this.http.put<PathContainer>(`${API}/pdf`, request);
  }

  prioritizeDismiss(id: string, action: string) {
    return this.http.put(`${API}/prioritizedDismissed`, {
      id: id,
      status: action,
    });
  }

  removePreviewTag(
    id: string,
    entity: Partial<SprintDto>
  ): Observable<SprintDto> {
    return this.http.put<SprintDto>(`${API}/removePreviewTag/${id}`, entity);
  }

  loadPeopleRatings(id: string) {
    const query = [`id=${id}`];
    return this.http.get<PeopleRatings>(`${API}/people?${query.join('&')}`);
  }

  getIndex(id: string, n: number) {
    const query = [`id=${id}`, `k=${n}`];
    return this.http.put<User>(`${API}/userposition?${query.join('&')}`, {});
  }

  exportEvent(id: string) {
    const query = [`id=${id}`];
    return this.http.get(`${API}/export?${query.join('&')}`);
  }

  postRating(rate: IdeaRating) {
    return this.http.post<IdeaRating>(APIRating, rate);
  }

  clone(entity: IConfirm) {
    return this.http.put<SprintDto>(`${API}/clone`, entity);
  }

  loadIdeasRating(id: string) {
    const query = [`id=${id}`];
    return this.http.get<PrioritizedDismised>(
      `${API}/ideas?${query.join('&')}`
    );
  }
  getForOptions() {
    return this.http.get<SprintDto[]>(`${API}/getForOptions`);
  }
  getForDiffTenant(tenantId: string) {
    return this.http.get<SprintDto[]>(
      `${API}/getForDiffTennant/${tenantId}/WithoutTenant`
    );
  }

  loadIdeas(id: string) {
    return this.http.get<IIdea>(`${APIIdea}/${id}`);
  }

  // addIdea(idea: IIdea) {
  //   const activeTenantId = localStorage.getItem('activeTenantId');
  //   return this.http.post<IIdea>(
  //     `${APIIdea}/addIdea?activeTenantId=${activeTenantId}`,
  //     idea
  //   );
  // }

  // updateIdea(id: string, body: EditCommentIdeaDto) {
  //   const activeTenantId = localStorage.getItem('activeTenantId');
  //   const query = [`id=${id}`, `activeTenantId=${activeTenantId}`];
  //   return this.http.put<IComment>(`${API}/editIdea?${query.join('&')}`, body);
  // }

  addAnswer(answer: Partial<SprintModuleQAnswerDto>) {
    return this.http.post<SprintQuestionDto>(`${API}/addAnswer`, answer);
  }

  // addComment(comment: IComment) {
  //   const activeTenantId = localStorage.getItem('activeTenantId');
  //   return this.http.post<IComment>(
  //     `${APIComment}/addComment?activeTenantId=${activeTenantId}`,
  //     comment
  //   );
  // }

  // updateComment(id: string, body: EditCommentIdeaDto) {
  //   const activeTenantId = localStorage.getItem('activeTenantId');
  //   const query = [`id=${id}`, `activeTenantId=${activeTenantId}`];
  //   return this.http.put<IComment>(
  //     `${API}/editComment?${query.join('&')}`,
  //     body
  //   );
  // }

  postComment(body: Partial<SprintCommentIdeaDto>, sprintId: string) {
    return this.http.post<SprintCommentIdeaDto[]>(
      `${API}/postComment?sprintId=${sprintId}`,
      body
    );
  }

  updateComment(body: Partial<SprintCommentIdeaDto>, sprintId: string) {
    return this.http.patch<SprintCommentIdeaDto[]>(
      `${API}/editCommentIdea?sprintId=${sprintId}`,
      body
    );
  }

  setClosed(id: string) {
    return this.http.get<string>(`${API}/close/${id}`);
  }

  load() {
    return this.http.get<PaginationData & { data: SprintDto[] }>(API);
  }

  add(post: Partial<SprintDto>): Observable<SprintDto> {
    return this.http.post<SprintDto>(API, post);
  }

  update(post: Partial<SprintDto>): any {
    return this.http.patch<SprintDto>(`${API}`, post);
  }

  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    const req = new HttpRequest('POST', `${API}/upload`, formData, {
      reportProgress: true,
      responseType: 'json',
    });
    return this.http.request(req);
  }

  getFiles(): Observable<any> {
    return this.http.get(`${API}/files`);
  }

  deleteIdea(id: string): Observable<void> {
    const query = [`id=${id}`];
    return this.http.delete<void>(`${API}/idea?${query.join('&')}`);
  }

  deleteComment(
    id: string,
    sprintId: string,
    moduleId: string
  ): Observable<SprintCommentIdeaDto[]> {
    const query = [`id=${id}`, `sprintId=${sprintId}`, `moduleId=${moduleId}`];
    return this.http.delete<SprintCommentIdeaDto[]>(
      `${API}/comment?${query.join('&')}`
    );
  }

  loadInfo(id: string) {
    return this.http.get<CollaborationInfo[]>(`${API}/tenantinfo/${id}`);
  }

  getEventMembers(id: string) {
    const query = [`id=${id}`];
    return this.http.get<EventMembersDto>(
      `${API}/eventmembers?${query.join('&')}`
    );
  }

  updateIdeaRatings(
    ideaRating: IdeaRating,
    sprintId: string,
    sprintModuleId: string
  ) {
    const query = [`sprintId=${sprintId}`, `sprintModuleId=${sprintModuleId}`];
    return this.http.post<SprintCommentIdeaDto[]>(
      `${API}/sprintIdeaRating?${query.join('&')}`,
      ideaRating
    );
  }
}
