import { Component, OnInit, ViewChild } from '@angular/core';
import { AgmMap, MouseEvent } from '@agm/core';
import { CompaniesService } from '../../../../state/companies.service';
import {
  CompaniesRepository,
  ICompanyMarker,
  ICompanyLocation,
  IMapInfo,
  CirclesOptions,
  CirclesOptionsKm,
} from '../../../../state/companies.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-maps-page',
  templateUrl: './maps-page.component.html',
  styleUrls: ['./maps-page.component.scss'],
})
export class MapsPageComponent implements OnInit {
  selectedCompany: ICompanyMarker | null = null;
  formattedaddress = ' ';
  myOptions: any;
  zoomInfo: IMapInfo | null = null;
  locations: ICompanyLocation[] = [];
  locationIndex: number = -1;
  companyIndex: number = -1;
  zoom: number = 4;
  searchText: any;
  useKmAsValues: boolean = true;
  maploaded = false;
  //USA
  lat: number = 37.09024;
  lng: number = -95.712891;
  CirclesOptions: DatalistOption[] = CirclesOptionsKm;
  markers: Marker[] = [];
  circles: Circle[] = [];
  enableEditing: boolean = false;
  allSelected: boolean = true;

  constructor(
    public service: CompaniesService,
    public repository: CompaniesRepository
  ) {}
  hidden: boolean = false;
  companies?: ICompanyMarker[] = undefined;
  @ViewChild('myMap') map: AgmMap | undefined;
  ngOnInit(): void {}
  ClearMapSearch() {
    let val = (document.getElementById('mapSearch') as HTMLInputElement)?.value;
    if (!val) {
      let searchMarker = this.markers.find((x) => x.id == 'SearchMarker');
      if (searchMarker) {
        var marindex = this.markers.indexOf(searchMarker);
        this.markers.splice(marindex, 1);
      }
    }
  }
  ChangeLocationMapBySearch(address: any) {
    let searchMarker = this.markers.find((x) => x.id == 'SearchMarker');
    if (searchMarker) {
      var marindex = this.markers.indexOf(searchMarker);
      this.markers.splice(marindex, 1);
    }

    if (address?.geometry?.location?.lng()) {
      this.lng = address.geometry.location.lng();
      let defrad;
      if (this.useKmAsValues) {
        defrad = 3000.0;
      } else {
        defrad = 4828.03;
      }
      this.markers.push({
        id: 'SearchMarker',
        label: ' ',
        lng: address.geometry.location.lng(),
        lat: address.geometry.location.lat(),
        draggable: false,
        lastEventDate: '',
        lastEventDesc: '',
        radius: defrad,
      });
      this.zoom = 10;
    }
    if (address?.geometry?.location?.lat()) {
      this.lat = address.geometry.location.lat();
    }
  }
  pushMarker(location: ICompanyLocation) {
    if (this.markers) {
      if (!this.markers.find((z) => z.id == location.id)) {
        this.markers.push({
          id: location.id,
          label: location.title,
          lat: location.latitude,
          lng: location.longitude,
          lastEventDate: location.formatedLastEventDate,
          lastEventDesc: location.lastEventDescription,
          draggable: false,
          radius: location.radius,
        });
      }
    }
  }

  pushCircle(location: ICompanyLocation) {
    if (this.circles) {
      let company = this.companies?.find((q) => q.id == location.companyId);
      if (!this.circles.find((z) => z.id == location.id)) {
        this.circles.push({
          id: location.id,
          radius: location.radius,
          lat: location.latitude,
          lng: location.longitude,
          fillColor: company?.fillColor ? company?.fillColor : 'red',
          draggable: false,
        });
      }
    }
  }

  ShowCompanyAllLocations(event: boolean, comIndex: number) {
    if (this.companies) {
      this.companies[comIndex].allCompaniesSelected = event;
      if (event) {
        this.HandleAllCompanies();
        var locs = this.companies[comIndex].companyLocations;
        locs.forEach((loc) => {
          loc.checked = true;
          this.pushMarker(loc);
          this.pushCircle(loc);
        });
      } else {
        this.HandleAllCompanies();
        var locs = this.companies[comIndex].companyLocations;
        locs.forEach((x) => {
          x.checked = false;

          var cir = this.circles.find((y) => y.id == x.id);
          if (cir) {
            var cirindex = this.circles.indexOf(cir);
            this.circles.splice(cirindex, 1);
          }
          var mar = this.markers.find((y) => y.id == x.id);
          if (mar) {
            var marindex = this.markers.indexOf(mar);
            this.markers.splice(marindex, 1);
          }
        });
      }
    }
  }

  hidePopUps(enableEditing: boolean) {
    if (!enableEditing) {
      this.locationIndex = -1;
      this.companyIndex = -1;
    }
  }

  selectAll(value: boolean) {
    if (value) {
      if (this.companies) {
        this.companies.forEach((x) => {
          x.allCompaniesSelected = true;
          if (x.companyLocations) {
            this.locations = this.locations.concat(x.companyLocations);
            x.companyLocations.forEach((y) => {
              y.checked = true;
            });
          }
        });

        this.locations.forEach((z) => {
          z.checked = false;
          if (!z.defaultRadius) {
            if (this.useKmAsValues) {
              z.defaultRadius = 3000.0;
            } else {
              z.defaultRadius = 4828.03;
            }
          }
          if (!z.radius) {
            if (this.useKmAsValues) {
              z.radius = 3000.0;
            } else {
              z.radius = 4828.03;
            }
          }
          z.checked = true;
          let loc = this.locations.find((x) => x.id == z.id);
          if (loc) {
            this.pushMarker(loc);
            this.pushCircle(loc);
          }
        });
      }
    } else if (!value) {
      this.markers = [];
      this.circles = [];
      if (this.companies) {
        this.companies.forEach((x) => {
          x.allCompaniesSelected = false;
          if (x.companyLocations) {
            x.companyLocations.forEach((y) => {
              y.checked = false;
            });
          }
        });
      }
    }
  }

  showCompanyLocation(id: string) {
    if (this.companies) {
      if (this.companies.find((x) => x.id == id)) {
        this.companies.find((x) => x.id == id)!.locationsHiden = true;
      }
    }
  }

  hideCompanyLocation(id: string) {
    if (this.companies) {
      if (this.companies.find((x) => x.id == id)) {
        this.companies.find((x) => x.id == id)!.locationsHiden = false;
      }
    }
  }

  loadAllAndSetValue() {
    if (!this.maploaded) {
      this.service.loadAll().subscribe((x) => {
        this.companies = x;
        this.service.loadMapInfo().subscribe((y) => {
          this.zoomInfo = y;
          if (y.zoom) {
            this.zoom = y.zoom;
          }
          this.lat = y.startingLat;
          this.lng = y.startingLng;
          this.useKmAsValues = y.useKmAsValues;
          if (y.useKmAsValues) {
            this.CirclesOptions = CirclesOptionsKm;
          } else {
            this.CirclesOptions = CirclesOptions;
          }
        });
        this.companies.forEach((y) => {
          if (!y.fillColor) {
            y.fillColor = 'red';
          }
          y.allCompaniesSelected = true;
          y.locationsHiden = true;
          if (y.companyLocations) {
            this.locations = this.locations.concat(y.companyLocations);
          }
        });
        this.locations.forEach((z) => {
          z.checked = false;
          if (!z.defaultRadius) {
            if (this.useKmAsValues) {
              z.defaultRadius = 3000.0;
            } else {
              z.defaultRadius = 4828.03;
            }
          }
          if (!z.radius) {
            if (this.useKmAsValues) {
              z.radius = 3000.0;
            } else {
              z.radius = 4828.03;
            }
          }
          z.checked = true;
          let loc = this.locations.find((x) => x.id == z.id);
          if (loc) {
            this.pushMarker(loc);
            this.pushCircle(loc);
          }
        });
      });
      this.maploaded = true;
    }
  }

  handleMapFullScreen() {
    this.hidden = !this.hidden;
  }

  changeSelectedCompany(index: number) {
    if (this.companies) {
      if (this.companies[index]) {
        this.selectedCompany = this.companies[index];
        if (!this.zoom) {
          this.zoom = 8;
        }
        if (this.companies[index].longitude) {
          this.lng = this.companies[index].longitude;
        }
        if (this.companies[index].latitude) {
          this.lat = this.companies[index].latitude;
        }
      }
    }
    return;
  }

  clearSearch() {
    this.companies = [];
    this.locations = [];
    this.markers = [];
    this.locations = [];
    this.companyIndex = -1;
    this.locationIndex = -1;
    (document.getElementById('searchInput') as HTMLInputElement).value = '';
    this.service.loadAll().subscribe((x) => {
      this.allSelected = true;
      this.companies = x;
      this.companies.forEach((y) => {
        y.allCompaniesSelected = true;
        this.locations = this.locations.concat(y.companyLocations);
        this.locations.forEach((z) => {
          this.pushMarker(z);
          this.pushCircle(z);

          z.checked = true;
          if (!z.defaultRadius) {
            if (this.useKmAsValues) {
              z.defaultRadius = 3000.0;
            } else {
              z.defaultRadius = 4828.03;
            }
          }
          if (!z.radius) {
            if (this.useKmAsValues) {
              z.radius = 3000.0;
            } else {
              z.radius = 4828.03;
            }
          }
        });
      });
    });
  }

  searchChange() {
    this.companyIndex = -1;
    this.locationIndex = -1;
    var searchString = (
      document.getElementById('searchInput') as HTMLInputElement
    ).value;
    if (searchString) {
      this.locations = [];
      this.companies = [];
      this.markers = [];
      this.circles = [];

      this.service.search(searchString).subscribe((x) => {
        this.companies = x;
        this.allSelected = true;

        this.companies.forEach((y) => {
          this.locations = this.locations.concat(y.companyLocations);
          y.allCompaniesSelected = true;
        });
        this.locations.forEach((z) => {
          z.checked = true;
          this.pushCircle(z);
          this.pushMarker(z);

          if (!z.defaultRadius) {
            z.defaultRadius = 4828.03;
          }
          if (!z.radius) {
            z.radius = 4828.03;
          }
        });
      });
    } else {
      this.clearSearch();
    }
  }

  HandleAllCompanyLocations(companyIndex: number) {
    if (this.companies) {
      var company = this.companies[companyIndex];
      if (company) {
        var companylocations = this.locations.filter(
          (x) => x.companyId == company.id
        );
        var allCompaniesSelected = true;
        companylocations.forEach((y) => {
          if (y.checked == false) {
            allCompaniesSelected = false;
          }
        });
        this.companies[companyIndex].allCompaniesSelected =
          allCompaniesSelected;
      }
    }
  }

  HandleAllCompanies() {
    var allCompaniesSelected = true;
    if (this.companies) {
      this.companies.forEach((x) => {
        if (!x.allCompaniesSelected) {
          allCompaniesSelected = false;
        }
      });
    } else {
      allCompaniesSelected = false;
    }
    this.allSelected = allCompaniesSelected;
  }

  changeCircleRadius(locId: string, event: any) {
    let circle = this.circles.find((x) => x.id == locId);
    let location = this.locations.find((x) => x.id == locId);

    if (circle && location) {
      location.radius = event;
      this.service.updateLocaion(location).subscribe();
      location.radius = event;
      let indexcircles = this.circles.indexOf(circle);
      this.circles.splice(indexcircles, 1);
      this.pushCircle(location);
    }
  }

  clickchange(ci: number, li: number) {
    if (this.locationIndex === li) {
      if (this.companyIndex == ci) {
        this.locationIndex = -1;
      }
    } else {
      this.locationIndex = li;
    }
    this.companyIndex = ci;
    if (this.companies) {
      this.selectedCompany = this.companies[ci];
    }
  }

  public ShowLocation(event: any, locationId: string, companyindex: number) {
    if (event) {
      let loc = this.locations.find((x) => x.id == locationId);
      if (loc) {
        this.pushMarker(loc);
        this.pushCircle(loc);
      }
      this.HandleAllCompanyLocations(companyindex);
      this.HandleAllCompanies();
    } else {
      let loc = this.markers.find((x) => x.id == locationId);
      if (loc) {
        let index = this.markers.indexOf(loc);
        let cir = this.circles.find((x) => x.id == locationId);
        if (cir) {
          let indexcircles = this.circles.indexOf(cir);
          this.circles.splice(indexcircles, 1);
        }
        this.markers.splice(index, 1);
      }
      this.HandleAllCompanyLocations(companyindex);
      this.HandleAllCompanies();
    }
  }

  public handleAddressChange(address: any) {
    //setting address from API to local variable
    this.formattedaddress = address.formatted_address;
  }

  clickedMarker(label: string, index: number) {
    //label || index
  }

  mapClicked($event: MouseEvent) {}

  markerDragEnd(m: Marker, $event: MouseEvent) {
    //m, $event
  }
}

// just an interfaces for type safety.
interface Marker {
  id: string;
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
  lastEventDesc: string;
  lastEventDate: string;
  radius: number;
}

interface Circle {
  id: string;
  lat: number;
  lng: number;
  radius: number;
  draggable: boolean;
  fillColor: string;
}
