export const CLIENT_PATHS = {
  reports: 'reports',
  personalPlan: 'personalplan',
  personalCoach: 'personalcoach',
  deleting: 'deleting',
  emails: 'notifications',
  usergroups: 'usergroups',
  sprints: 'sprints',
  calendar: 'calendar',
  feed: 'feed',
  onboarding: 'onboarding',
  toolbox: 'toolbox',
  mediaLibrary: 'media-library'
};
export const SUB_PATHS = {};
