import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

const API = '/api/blobstorage';

@Injectable({ providedIn: 'root' })
export class BlobCompressService {
  constructor(private http: HttpClient) {}

  compressImage(file: File, fileName: string) {
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    return this.http.post<CompressedImagesDto>(
      `${API}/compress/image`,
      formData
    );
  }

  getCompressedImagePath(
    imagePath: string,
    width: number,
    isCompressedExcist?: boolean
  ) {
    if (isCompressedExcist) {
      return `compressed-${width}wth-${imagePath}`;
    } else {
      return imagePath;
    }
  }
}

export interface CompressedFileContainer {
  fileName: string;
  isSuccess: boolean;
  errorMessage: string;
}

export interface CompressedImagesDto {
  hasCompressedImage320?: boolean;
  hasCompressedImage800?: boolean;
  errorMessage?: string;
}
