<p-table
  #dt1
  [value]="initTenants"
  [globalFilterFields]="['name', 'createdAt']"
>
  <ng-template pTemplate="header">
    <tr>
      <th colspan="2">
        <div class="flex">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="onGlobalFilter(dt1, $event)"
              placeholder="{{ 'search' | transloco }}"
            />
          </span>
        </div>
      </th>
    </tr>
    <tr>
      <th pSortableColumn="name">
        {{ "name" | transloco }} <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th pSortableColumn="createdAt">
        {{ "created" | transloco }} <p-sortIcon field="createdAt"></p-sortIcon>
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-tenant>
    <tr>
      <td>
        <div class="d-flex align-items-center">
          <app-entity-avatar
            [img]=""
            [alt]="tenant.name"
            icon="bi-building"
          ></app-entity-avatar>
          <p>
            <span
              class="txt-name text-break ms-1"
              [routerLink]="['/tenants', tenant.id]"
              >{{ tenant.name }}</span
            >
            <br />
            <small>
              <span class="txt-sub text-break ms-1">
                {tenant.countOfUsers, plural, =1 {1 user} other
                {{{tenant.countOfUsers}} users}}</span
              >
            </small>
          </p>
        </div>
      </td>
      <td>{{ tenant.createdAt | date : "dd-MM-yyyy hh:mm" }}</td>
      <td>
        <div
          *ngIf="auth.isAnyAdmin$ | async"
          class="d-flex justify-content-end"
        >
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/tenants/html', tenant.id]"
            >
              <img src="assets/icons/homeHtml.svg" />
            </a>
          </div>
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/tenants/info', tenant.id]"
            >
              <img
                title="{{ 'info' | transloco }}"
                src="assets/icons/info.svg"
              />
            </a>
          </div>
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/tenants', tenant.id]"
            >
              <img src="assets/img/edit.svg" />
            </a>
          </div>
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/deleting', tenant.id]"
            >
              <img src="assets/icons/permanently-delete.svg" />
            </a>
          </div>
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/tenants/msGraphOptions', tenant.id]"
            >
              <img src="assets/icons/mail-01.svg" />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#cloneusermodal"
              (click)="cloneConfirmation = tenant"
              title="{{ 'clone' | transloco }}"
            >
              <img src="assets/img/clone.svg" />
            </button>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = tenant"
              title="Delete"
            >
              <img src="assets/img/delete.svg" />
            </button>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<app-confirm-delete
  id="deleteusermodal"
  *ngIf="deleteConfirmation"
  [deleteCandidate]="deleteConfirmation.name"
  (confirm)="handleDeleteClick(deleteConfirmation); deleteConfirmation = null"
  (cancel)="deleteConfirmation = null"
></app-confirm-delete>
<app-clone-pop-up
  id="cloneusermodal"
  *ngIf="cloneConfirmation"
  [cloneCandidateId]="cloneConfirmation.id"
  [cloneCandidateName]="cloneConfirmation.name"
  (confirm)="clone.emit($event); cloneConfirmation = null"
  (cancel)="cloneConfirmation = null"
></app-clone-pop-up>
