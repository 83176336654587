import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ITask, TimelogsRepository } from '../../../state/timelogs.repository';
import {
  TaskSortOptions,
  TimelogsTasksRepository,
} from '../../../state/timelogsTasks.repository';
import { TimelogsTasksService } from '../../../state/timelogsTasks.service';

@Component({
  selector: 'app-timelogs-tasks-admin',
  templateUrl: './timelogs-tasks-admin.component.html',
  styleUrls: ['./timelogs-tasks-admin.component.scss'],
})
export class TimelogsTasksAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = TaskSortOptions;
  searchFilter: string = '';

  constructor(
    public service: TimelogsTasksService,
    public repository: TimelogsRepository,
    public repo: TimelogsTasksRepository
  ) {}
  tasks: ITask[] = [];
  deleteConfirmation: ITask | null = null;

  deleteTaskConfirmation: ITask | null = null;

  ngOnInit(): void {
    this.service.reloadPage(this.searchFilter).subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }
  refreshDataTask() {
    this.service.reloadPage(this.searchFilter).subscribe();
  }

  handleDeleteTaskClick(post: ITask) {
    this.service.deleteTask(post.id).subscribe((x) => {
      this.refreshDataTask();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
