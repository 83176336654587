import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
  @Input() items?: any[] | null;
  @Input() searchProperties: string[] | null = null;
  @Output() foundItems = new EventEmitter<any[]>();
  @ViewChild('searchInput') searchInput!: ElementRef;
  constructor() {}

  setFilter(filter: string) {
    if (this.items?.length) {
      const copiedItems: any[] = JSON.parse(JSON.stringify(this.items));
      this.foundItems.emit(
        copiedItems?.filter((item) =>
          this.searchProperties?.some((prop) => {
            const value = this.getNestedValue(item, prop);
            return value?.toLowerCase().includes(filter.toLowerCase());
          })
        )
      );
    }
  }

  reset() {
    this.searchInput.nativeElement.value = '';
  }

  getNestedValue(obj: any, path: string): any {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  }
}
