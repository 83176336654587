<h2 class="mb-4" *ngIf="type === 'new'" i18n="Create tenant label">
  {{ "create" | transloco }} {{ "contact" | transloco }}
</h2>
<h2 class="mb-4" *ngIf="type !== 'new'" i18n="Edit tenant label">
  {{ "edit" | transloco }} {{ "contact" | transloco }}
</h2>
<div *ngIf="type === 'new' || contact" class="row mb-4">
  <div class="col-12">
    <app-contacts-editform
      [editedContact]="contact"
      (ContactSubmit)="createContact($event)"
    ></app-contacts-editform>
    <h2 *ngIf="repo.isLoading$ | async"><app-spinner></app-spinner></h2>
    <app-error-alert
      title="Could not submit the contact profile"
      [errors]="submitErrors"
    ></app-error-alert>
  </div>
</div>
<!--<div>
  <app-error-alert *ngIf="type !== 'new' && !(contact) " title="Could not submit the contact profile"
                   [errors]="['Contact is null']"></app-error-alert>
</div>-->
