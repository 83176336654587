<div
  style="width: 100% !important"
  class="d-flex justify-content-end closemenu"
  i18n="Close menu label"
>
  {{ "closeMenu" | transloco }}
  <div class="closeIcon text-white" (click)="menuclose()">
    <i class="bi bi-x"></i>
  </div>
</div>
<div
  class="sidebar bg-dark d-flex flex-column sidebartext"
  [class.disableEvent]="isUploading"
>
  <div>
    <div class="d-flex flex-column">
      <div class="d-flex col mt-3 pb-4">
        <app-logo></app-logo>
      </div>
      <app-sidebar-items></app-sidebar-items>
    </div>
    <!-- <app-spiner></app-spiner> -->
  </div>
  <div class="d-flex col">
    <app-menuuser-card
      class="col-12 d-flex align-items-end"
    ></app-menuuser-card>
  </div>
</div>
