<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "news" | transloco }}
      </h2>
    </div>
    <div
      *ngIf="innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <div class="d-flex">
        <a
          routerLink="/admin/posts/new"
          class="btn btn-lg orange-dark text-white"
        >
          <img src="assets/img/plus_add.svg" />
          <span class="text-btn-add">{{ "add" | transloco }}</span>
        </a>
      </div>
    </div>

    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="
          service.sort($event, searchFilter, ['userGroups']).subscribe()
        "
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-6 col-6 mb-0 p-0 d-flex">
            <span class="txt mt-1 ms-2">{{ "name" | transloco }}</span>
          </div>

          <div class="col-sm-6 col-6">
            <span class="txt me-1">{{ "description" | transloco }}</span>
          </div>
        </div>
      </div>

      <br />
      <div
        *ngFor="let post of repo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-6 col-6 pt-3 pb-3 d-flex align-items-center psm0">
          <div class="txt-name">
            <div [routerLink]="['/admin/posts', post.id]" class="text-break">
              {{ post.title | slice : 0 : 50 }}
              <span *ngIf="post.title.length > 50">...</span>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-6 d-flex align-items-center">
          <span class="role text-break">{{ transform(post.content) }} </span>
        </div>
        <div
          *ngIf="innerWidth > 575"
          class="col-2 d-flex align-items-center p-0 justify-content-end"
        >
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/admin/posts', post.id]"
            >
              <img src="assets/img/edit.svg" />
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = post"
              title="{{ 'delete' | transloco }}"
              i18n-title="Delete button"
            >
              <img src="assets/img/delete.svg" />
            </button>
          </div>
        </div>
      </div>

      <br />
      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service
              .loadFilterPageCustom('All', searchFilter, $event, undefined, [
                'userGroups'
              ])
              .subscribe()
          "
        ></app-custom-pagination>

        <div
          class="w-100 d-flex align-items-center justify-content-end"
          *ngIf="innerWidth < 1050"
        >
          <div class="d-flex">
            <a
              routerLink="/admin/posts/new"
              class="btn btn-lg orange-dark text-white"
            >
              <img src="assets/img/plus_add.svg" />
              <span class="text-btn-add">{{ "add" | transloco }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
  </div>
</div>
