<div class="navcard">
  <a
    class="d-flex align-items-center justify-content-center flex-column h-100 w-100"
    [class.disabled]="item.disabled"
    [href]="item.link"
  >
    <div class="d-flex justify-content-center align-items-center mt-2">
      <img class="menu-img" [src]="item.imageLink" />
    </div>
    <span
      class="d-flex justify-content-center align-items-center mt-2 cardtext"
    >
      {{ item.name | transloco }}</span
    >
  </a>
</div>
