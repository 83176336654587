//import { HttpClient } from '@angular/common/http';
//import { Injectable } from '@angular/core';
//import { Observable, of } from "rxjs";
//import { switchMap, tap } from 'rxjs/operators';
//import { PaginationData } from "@ngneat/elf-pagination";
//import { IWhistleblow,WhistleblowRepository } from './whistleblow.repository';
//import { BaseService } from './abstract/base.service';

//const API = '/api/whistleblow';

//@Injectable({
//  providedIn: 'root'
//})
//export class WhistleblowService extends BaseService<IWhistleblow>{
//  constructor(
//    http: HttpClient,
//    repo: WhistleblowRepository
//  ) {
//    super(API, http, repo);
//  }

//  load() {
//    return this.http.get<PaginationData & { data: IWhistleblow[] }>(API)
//  }

//  getAllWhistleblows(): Observable<IWhistleblow[] > {
//    return this.http.get<IWhistleblow[]>(API);
//  }

//  add(post: Partial<IWhistleblow>) {
//    const formData = new FormData();
//    formData.append("file", post.file!);
//    return this.http.post<IWhistleblow>(API + `/new`, post).pipe(
//      switchMap((post) => this.http.post<IWhistleblow>(`${API}/${post.id}/file`, formData))
//    );
//  }
//  CustomDelete(id: string) {
//    return this.http.delete<IWhistleblow>(`${API}/delete/${id}`)
//  }

//  resolve(id: string) {
//    return this.http.put<any>(`${API}/resolve/${id}`, id);
//  }

//  loadOne(id: string) {
//    return this.http.get<IWhistleblow>(`${API}/${id}`)
//  }

//  }

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { switchMap, tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import { IWhistleblow, WhistleblowRepository } from './whistleblow.repository';

const API = '/api/whistleblow';

@Injectable({ providedIn: 'root' })
export class WhistleblowService extends BaseService<IWhistleblow> {
  constructor(http: HttpClient, repo: WhistleblowRepository) {
    super(API, http, repo);
  }

  load() {
    return this.http.get<PaginationData & { data: IWhistleblow[] }>(API);
  }

  getAllWhistleblows(): Observable<IWhistleblow[]> {
    return this.http.get<IWhistleblow[]>(API);
  }

  createArea(post: Partial<IWhistleblow>) {
    const formData = new FormData();
    formData.append('file', post.file!);
    return this.http
      .post<IWhistleblow>(API + `/new`, post)
      .pipe(
        switchMap((post) =>
          this.http.post<IWhistleblow>(`${API}/${post.id}/file`, formData)
        )
      );
  }
  CustomDelete(id: string) {
    return this.http.delete<IWhistleblow>(`${API}/delete/${id}`);
  }

  resolve(id: string) {
    return this.http.put<any>(`${API}/resolve/${id}`, id);
  }

  loadAll(): Observable<IWhistleblow[]> {
    return this.http.get<IWhistleblow[]>(API + '/all').pipe(
      tap((posts) => {
        this.repo.clear();
        this.repo.set(posts);
      })
    );
  }

  customLoadOne(id: string) {
    return this.http.get<IWhistleblow>(`${API}/custom/${id}`, {});
  }
}
