import { Injectable } from '@angular/core';
import { combineLatest, map } from 'rxjs';
import {
  sortBy,
  SortOption,
  SortState,
} from 'app/modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { Store, createState, withProps, select } from '@ngneat/elf';
import {
  createRequestsCacheOperator,
  createRequestsStatusOperator,
  selectRequestStatus,
  updateRequestCache,
  updateRequestsStatus,
  withRequestsCache,
  withRequestsStatus,
} from '@ngneat/elf-requests';
export interface UserGroupEntity {
  id: string;
  userGroupId: string;
  entityId: string;
}
import {
  withEntities,
  selectAllEntities,
  upsertEntities,
  selectEntity,
  deleteEntities,
} from '@ngneat/elf-entities';

export interface UserGroup {
  id: string;
  name: string;
  description: string;
  userIds: string[];
  createdAt?: Date;
  deletedAt?: Date;
  isSubscribed?: boolean;
  uniqIdentifier?: number;
}
export const userGroupSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
];
export interface UserGroupProps {
  sortBy: SortState;
}
const { state, config } = createState(
  withProps<UserGroupProps>({
    sortBy: { parameter: userGroupSortOptions[0], direction: 'asc' },
  }),
  withEntities<UserGroup>(),
  withRequestsCache(),
  withRequestsStatus()
);

const store = new Store({ name: 'userGroups', state, config });

@Injectable({ providedIn: 'root' })
export class UserGroupsRepository extends BaseRepository<UserGroup> {
  userGroups$ = store.pipe(selectAllEntities());
  clearStore() {
    store.reset();
  }
  setUserGroups(posts: UserGroup[]) {
    if (posts) {
      store.reset();
      store.update(
        updateRequestCache(store.name),
        upsertEntities(posts),
        updateRequestsStatus([store.name], 'success')
      );
    }
  }
  constructor() {
    super('usergroup', userGroupSortOptions);
  }
}
