<div class="col-12 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
  <h2 class="" i18n="Label users">
    {{ "timeLogsOverview" | transloco }}
  </h2>
</div>

<div class="btn-group btn-group-lg mb-4 w-100" role="group">
  <button
    class="btn btn-orange"
    routerLink="/admin/overview/timelogs"
    [class.disabled]="url$ == 'admin/overview/timelogs'"
  >
    {{ "usersOverviewWeek" | transloco }}
  </button>
  <button
    class="btn btn-orange"
    routerLink="/admin/overview/timelogs/month"
    [class.disabled]="url$ == 'admin/overview/timelogs/month'"
  >
    {{ "usersOverviewMonth" | transloco }}
  </button>
  <button
    class="btn btn-orange"
    routerLink="/admin/overview/timelogs/tasks"
    [class.disabled]="url$ == 'admin/overview/timelogs/tasks'"
  >
    {{ "tasksOverview" | transloco }}
  </button>
  <button
    class="btn btn-orange"
    routerLink="/admin/overview/timelogs/tasks/month"
    [class.disabled]="url$ == 'admin/overview/timelogs/tasks/month'"
  >
    {{ "tasksOverviewbyMonth" | transloco }}
  </button>
</div>
