<div *ngIf="post">
  <app-apv-answer-form
    [editedAPV]="post"
    (ApvSubmit)="CreateAnswer($event)"
  ></app-apv-answer-form>
  <app-error-alert
    title="{{ 'postError' | transloco }}"
    i18n-title="Message post submit error alert"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
