import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TenantsListPageDto } from 'app/api/models/tenant/tenantsListPageDto';
import { SortProperty } from 'app/interfaces/sort-property';
import { AuthRepository } from 'app/state/auth.repository';
import { IConfirm, Tenant } from 'app/state/tenants.repository';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-tenant-list',
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss'],
})
export class TenantListComponent implements OnInit {
  @Input() innerWidth = 0;
  @Input() set tenants(items: TenantsListPageDto[]) {
    this.initTenants = items;
  }
  @Input() isDeleteDisabled = true;
  @Output() delete = new EventEmitter<string>();
  @Output() clone = new EventEmitter<IConfirm>();
  deleteConfirmation: TenantsListPageDto | null = null;
  cloneConfirmation: TenantsListPageDto | null = null;
  initTenants: TenantsListPageDto[] = [];

  constructor(public auth: AuthRepository) {}

  ngOnInit(): void {}

  handleDeleteClick(tenant: TenantsListPageDto) {
    if (!this.isDeleteDisabled) {
      this.delete.emit(tenant.id);
    }
  }

  onGlobalFilter(table: Table, event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    table.filterGlobal(inputElement.value, 'contains');
  }
}
