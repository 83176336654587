import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {
  CompaniesRepository,
  ICompanyMarker,
} from '../../../../state/companies.repository';
import { CompaniesService } from '../../../../state/companies.service';

@Component({
  selector: 'app-clients-create-page',
  templateUrl: './clients-create-page.component.html',
  styleUrls: ['./clients-create-page.component.scss'],
})
export class ClientsCreatePageComponent {
  constructor(
    public service: CompaniesService,
    public repo: CompaniesRepository,

    private route: ActivatedRoute,
    private router: Router
  ) {}
  id?: string;
  type!: string;
  submitErrors: string[] | null = null;

  company: ICompanyMarker | null = null;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id && this.id != 'new') {
          this.service.loadOneCompany(`${value.get('id')}`).subscribe((x) => {
            this.company = x;
          });
        }
      },
    });
  }

  createClient(client: Partial<ICompanyMarker>) {
    this.submitErrors = null;
    let updateResult: Observable<ICompanyMarker> | undefined;
    if (this.id === 'new') {
      updateResult = this.service.postCompany(client);
    } else if (this.id) {
      updateResult = this.service.updateCompany(client);
    }
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['/admin/clients']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
