import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
export const ProjectSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  // { label: $localize`:Sort label Updated Date:Updated Date`, property: 'updatedAt' },
  { label: $localize`:Sort label Date: Date`, property: 'startDate' },
];

@Injectable({ providedIn: 'root' })
export class NotificationsRepository extends BaseRepository<any> {
  constructor() {
    super('notification', ProjectSortOptions);
  }
}
