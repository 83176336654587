import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailComponent } from './mail/mail.component';
import { MailFormComponent } from './mail-form/mail-form.component';
import { MailPostComponent } from './mail-post/mail-post.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedModule } from '../shared/shared.module';
import { MailEditComponent } from './mail-edit/mail-edit.component';
import { MailAdminComponent } from './mail-admin/mail-admin.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [
    MailComponent,
    MailFormComponent,
    MailPostComponent,
    MailEditComponent,
    MailAdminComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    TranslocoRootModule,
  ],
})
export class MailModule {}
