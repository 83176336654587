import { formatDate } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GeneralAppDataDto } from 'app/api/models/auth/sideBarInfoDto';
import { UserEditPageDto } from 'app/api/models/user/userEditPageDto';
import { GeneralDataRepository } from 'app/helpers/repository/general-data.repository';
import { ROLE_NAMES } from 'app/modules/shared/pipes/translate-roles.pipe';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { AuthRepository, UserRoles } from 'app/state/auth.repository';
import { UserGroup } from 'app/state/usergroup.repository';
import { User, UsersRepository } from 'app/state/users.repository';
import {
  UserCreateUpdateDto,
  UsersService,
} from 'app/api/services/users.service';
import { FileSetttings } from 'app/shared/data.service';
import { CompressedImagesDto } from 'app/shared/azure-blob/blob-compress.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { List } from 'echarts';

export const LanguageOptions: DatalistOption[] = [
  { label: 'English', value: 'en' },
  { label: 'Danish', value: 'da' },
];

@Component({
  selector: 'app-user-basic-info-form',
  templateUrl: './user-basic-info-form.component.html',
  styleUrls: ['./user-basic-info-form.component.scss'],
})
export class UserBasicInfoFormComponent implements OnInit {
  readonly ROLE_DESCRIPTIONS = {
    [UserRoles.User]: $localize`:Role description - User:Common User`,
    [UserRoles.TenantAdmin]: $localize`:Role description - Administrator:Can view and edit all data inside a tenant`,
    [UserRoles.Superadmin]: $localize`:Role description - Superadmin:Can edit system data and create tenants`,
    [UserRoles.PartnerManager]: $localize`:Role description - PartnerManager: Partner Manager`,
  };

  @Input() set tenants(value: DatalistOption[] | null) {
    if (!value) {
      this.tenantOptions = null;
    } else {
      this.tenantOptions = value;
    }
  }
  @Input() set userGroups(value: UserGroup[] | null) {
    if (!value) {
      this.userGroupsOptions = null;
    } else {
      this.userGroupsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  @Input() mode?: string = '';
  @Input() showPasswordField?: boolean = false;

  @Output() userSubmit = new EventEmitter<UserCreateUpdateDto>();

  ROLE_NAMES = ROLE_NAMES;
  isSuperAdmin?: boolean;
  langOptions: DatalistOption[] | null = LanguageOptions;
  tenantOptions: DatalistOption[] | null = null;
  userGroupsOptions: DatalistOption[] | null = null;
  userCoachOptions: DatalistOption[] | null = null;
  roleOptions: UserRoles[] = [];
  form!: UntypedFormGroup;
  isActiveTenantSet: boolean = false;
  generalUserData: GeneralAppDataDto = new GeneralAppDataDto();
  activeTenantId = localStorage.getItem('activeTenantId');

  updateValue(control: string, value: any) {
    const controlObject = this.form?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  public pageData: UserEditPageDto = new UserEditPageDto();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public authRepo: AuthRepository,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    public repo: UsersRepository,
    private generalDataRepo: GeneralDataRepository,
    public blobService: BlobStorageService
  ) {
    if (this.authRepo.isInRole(UserRoles.Superadmin)) {
      this.roleOptions = Object.values(UserRoles);
    } else if (this.authRepo.isInRole(UserRoles.TenantAdmin)) {
      this.roleOptions = Object.values(UserRoles).filter(
        (r) => r !== UserRoles.Superadmin
      );
    }
  }

  ngOnInit(): void {
    this.initForm();

    this.generalDataRepo.allGeneralData$.subscribe((data) => {
      this.generalUserData = data;
    });

    this.isActiveTenantSet = !!localStorage.getItem('activeTenantId');

    if (this.mode == 'new') {
      this.usersService.getUser().subscribe((pageData) => {
        if (pageData) {
          this.setUpForm(pageData);
        }
      });
    }

    if (this.mode == 'edit') {
      this.activatedRoute.paramMap.subscribe((params) => {
        var idFromrout = params.get('id');
        this.usersService.getUser(idFromrout!).subscribe((pageData) => {
          if (pageData) {
            this.setUpForm(pageData);
          }
        });
      });
    }

    if (this.mode == 'profile') {
      this.authRepo.id$.subscribe((id) => {
        this.usersService.getUser(id!).subscribe((pageData) => {
          if (pageData) {
            this.setUpForm(pageData);
          }
        });
      });
    }
  }

  setUpForm(pageData: UserEditPageDto) {
    this.userCoachOptions = pageData.coachesList.map((x) => ({
      value: x.id,
      label: `${x.name} ${x.surname}`,
    }));
    this.pageData = pageData;
    var tenantIdFromLocaleStorage = localStorage.getItem('activeTenantId');
    this.isActiveTenantSet = !!tenantIdFromLocaleStorage;
    const tenantId = tenantIdFromLocaleStorage
      ? tenantIdFromLocaleStorage
      : null;

    var val = pageData.user;

    const birthday =
      val && val.birthday ? formatDate(val.birthday, 'yyyy-MM-dd', 'en') : '';
    this.isSuperAdmin = val?.roles[0] === 'Superadmin';

    this.form = this.formBuilder.group({
      name: [val?.name || ''],
      surname: [val?.surname || ''],
      email: [val?.email, [Validators.required, Validators.email]],
      phone: [val?.phoneNumber || ''],
      imagePath: [val?.imagePath],
      hasCompressedImage: [val?.hasCompressedImage],
      location: [val?.location || ''],
      language: [val?.language || 'en'],

      title: [val?.title || ''],
      birthday: [birthday],
      password: [''],
      roles: this.formBuilder.array(
        this.roleOptions.map((r) => val && pageData.roles.indexOf(r) >= 0)
      ),
      tenantIds: [val?.tenantIds || [tenantId], [Validators.required]],
      userGroupsIds: [val?.userGroupsIds],
      userCoachIds: [val?.userCoachIds?.length ? val?.userCoachIds : []],
      isCoach: [val?.isCoach ?? false],
    });

    this.setDefaultRoleUser();

    if (this.mode == 'edit' || this.mode == 'profile') {
      this.form.controls['email'].disable();
    }
  }

  setDefaultRoleUser() {
    const rolesArray = this.form.get('roles') as FormArray;
    const roleIndex = this.roleOptions.indexOf(UserRoles.User);

    if (roleIndex !== -1) {
      const roleControl = rolesArray.at(roleIndex);
      roleControl.setValue(true);
      roleControl.disable({ onlySelf: true, emitEvent: false });
    }
  }

  updateFile(settings: Partial<FileSetttings>) {
    this.form.controls['imagePath'].setValue(settings.uploadedFilePath);
    this.form.controls['hasCompressedImage'].setValue(false);
  }

  changeHasCompressedImage(value: CompressedImagesDto) {
    this.form.controls['hasCompressedImage'].setValue(
      value.hasCompressedImage320
    );
  }

  initForm() {
    var tenantIdFromLocaleStorage = localStorage.getItem('activeTenantId');
    const tenantId = tenantIdFromLocaleStorage
      ? tenantIdFromLocaleStorage
      : null;
    this.form = this.formBuilder.group({
      name: [''],
      surname: [''],
      email: ['', [Validators.required, Validators.email]],
      phone: [''],
      imagePath: [''],
      hasCompressedImage: [''],
      location: [''],
      language: ['en'],
      title: [''],
      birthday: [''],
      password: [''],
      roles: this.formBuilder.array(
        this.roleOptions.map(
          (r) => this.pageData.user && this.pageData.user.roles.indexOf(r) >= 0
        )
      ),
      tenantIds: [[tenantId], [Validators.required]],
      userGroupsIds: [[]],
      userCoachIds: [[]],
      isCoach: [false],
    });
  }

  addRequired(control: AbstractControl | undefined) {
    control?.addValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  removeRequired(control: AbstractControl | undefined) {
    control?.removeValidators(Validators.required);
    control?.updateValueAndValidity();
  }

  deleteUserImage() {
    this.form.controls['imagePath'].setValue('');
    this.form.controls['hasCompressedImage'].setValue(false);
  }

  onSubmit() {
    if (this.activeTenantId) {
      let currentUserTenants = [
        ...this.form.value.tenantIds,
        this.activeTenantId,
      ];
      this.updateValue('tenantIds', currentUserTenants);
    }

    if (this.mode != 'new') {
      this.removeRequired(this.form?.controls['password']);
    }

    if (this.form && !this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    //temporary solution(when i disable user role for editing js removes it form array, that is why i need to add in manualy as TRUE)
    const rolesArray = this.form.get('roles') as FormArray;
    rolesArray.insert(0, new FormControl(true));

    const roles = (this.form?.value?.roles as boolean[])
      .map((x, i) => (x && this.roleOptions[i]) as UserRoles)
      .filter((x) => !!x);

    this.userSubmit.emit({
      email: this.form.controls['email'].value,
      phoneNumber: this.form?.value?.phone,
      name: this.form?.value?.name,
      surname: this.form?.value?.surname,
      title: this.form?.value?.title,
      location: this.form?.value?.location,
      language: this.form?.value?.language,
      birthday: this.form?.value?.birthday || null,
      imagePath: this.form?.value?.imagePath ?? '',
      hasCompressedImage: !!this.form?.value?.hasCompressedImage,
      password: this.form?.value?.password,
      tenantIds: this.form?.value?.tenantIds || null,
      userGroupsIds: this.form?.value?.userGroupsIds || null,
      userCoachIds: this.form?.value?.userCoachIds || null,
      isCoach: this.form?.value.isCoach ?? false,
      roles: roles,
    });
    this.form?.markAsUntouched();
  }
}
