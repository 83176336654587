<h2 class="mb-4">
  {{ "setMsGraphOptions" | transloco }}
</h2>
<div class="mb-4" *ngIf="msGraphOptions">
  <app-tenant-ms-graph-edit-form
    [msGraphOptions]="msGraphOptions"
    (optionsSubmit)="updateMsGraphOptions($event)"
    (sendTestEmail)="sendTestEmail($event)"
  >
  </app-tenant-ms-graph-edit-form>
  <app-error-alert
    title="{{ 'couldNotSubmit' | transloco }}"
    [errors]="submitErrors"
  ></app-error-alert>
</div>
