import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, switchMap, tap } from 'rxjs/operators';
import { IMail, MailRepository } from 'app/state/mail.repository';
import { MailService } from 'app/state/mail.service';

@Component({
  selector: 'app-mail-post',
  templateUrl: './mail-post.component.html',
  styleUrls: ['./mail-post.component.scss'],
})
export class MailPostComponent implements OnInit {
  post$!: Observable<IMail>;
  submitErrors: string[] | null = null;

  constructor(
    private service: MailService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private repo: MailRepository
  ) {}

  ngOnInit(): void {}

  post($event: Partial<IMail>) {
    this.submitErrors = null;
    let updateResult: Observable<IMail> | undefined;
    updateResult = this.service.createArea($event);
    if (updateResult) {
      updateResult.subscribe({
        complete: () => {
          this.router.navigate(['admin/messages']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }
}
