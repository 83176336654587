import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IApv, ApvRepository } from 'app/state/apv.repository';
import { ApvService } from 'app/state/apv.service';
import { AuthRepository } from 'app/state/auth.repository';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-apv-answer-form',
  templateUrl: './apv-answer-form.component.html',
  styleUrls: ['./apv-answer-form.component.scss'],
})
export class ApvAnswerFormComponent implements OnInit {
  userEmail: string | null = null;
  ApvForm: UntypedFormBuilder | any;
  @Input() editedAPV?: IApv | null;
  @Output() ApvSubmit = new EventEmitter<Partial<IApv>>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public authRepo: AuthRepository,
    private repo: ApvRepository,
    private service: ApvService,
    private autrepo: AuthRepository
  ) {}
  ngOnInit() {
    this.ApvForm = this.formBuilder.group({
      apvQuestions: this.formBuilder.array([]),
    });
    this.setApvQuestion();
  }
  getQuestions() {
    if (this.ApvForm) {
      return (this.ApvForm.get('apvQuestions') as UntypedFormArray).controls;
    }
    return [];
  }

  setApvQuestion() {
    let control = <UntypedFormArray>this.ApvForm?.controls.apvQuestions;
    this.editedAPV?.apvQuestions.forEach((x) => {
      control.push(
        this.formBuilder.group({
          title: [x.title, Validators.required],
          answer: [x.answer, Validators.required],
        })
      );
    });
  }

  onSubmit() {
    if (this.ApvForm && !this.ApvForm.valid) {
      this.ApvForm.markAllAsTouched();
      return;
    }
    const apvId = this.editedAPV?.id;
    const apvTitle = this.editedAPV?.title;
    const description = this.editedAPV?.description;
    const apvQuestions = this.ApvForm.value?.apvQuestions;
    this.authRepo.email$.subscribe((x) => (this.userEmail = x));
    const userEmail = this.userEmail;
    this.ApvSubmit.emit({
      apvId,
      apvTitle,
      description,
      apvQuestions,
      userEmail,
    });
  }
}
