<div *ngFor="let group of userGroups" style="display: block">
  <div style="display: flex; align-items: center">
    <p-checkbox [(ngModel)]="group.selected" [binary]="true" inputId="binary" />
    <div style="align-items: center" class="ms-2">
      <p-avatarGroup>
        <p-avatar size="small" shape="circle">
          <i class="pi pi-user"></i>
        </p-avatar>
        <p-avatar size="small" shape="circle">
          <i class="pi pi-user"></i>
        </p-avatar>
        <p-avatar size="small" shape="circle">
          <i class="pi pi-user"></i>
        </p-avatar>
      </p-avatarGroup>
    </div>

    <span>{{ group.label }}</span>
  </div>
</div>
<div
  class="mt-4"
  style="display: flex; justify-content: space-between; width: 100%"
>
  <p-button
    label="{{ 'cancel' | transloco }}"
    styleClass="p-button-secondary"
    (click)="cancel()"
  ></p-button>
  <p-button
    label="{{ 'submit' | transloco }}"
    styleClass="p-button-primary"
    (click)="submit()"
  >
  </p-button>
</div>
