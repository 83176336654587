<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-12 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "whistleblowList" | transloco }}
      </h2>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="service.sort($event, searchFilter).subscribe()"
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-6 col-6 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">
              {{ "name" | transloco }}
            </span>
          </div>
          <div class="col-sm-3 col">
            <span class="txt me-1">{{ "description" | transloco }}</span
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.05992 5.99967C6.21665 5.55412 6.52602 5.17841 6.93322 4.9391C7.34042 4.69978 7.81918 4.6123 8.2847 4.69215C8.75022 4.772 9.17246 5.01402 9.47664 5.37536C9.78081 5.7367 9.94729 6.19402 9.94659 6.66634C9.94659 7.99967 7.94659 8.66634 7.94659 8.66634M7.99992 11.333H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z"
                stroke="#98A2B3"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="col-sm-3 col-3 text-end" [hidden]="innerWidth < 575">
            <span class="txt me-1">Resolve</span>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let whistl of repo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-6 col-6 pt-3 pb-3 d-flex align-items-center psm0">
          <input
            class="check form-check-input me-2"
            type="checkbox"
            style="margin-left: 0.1rem"
          />
          <div class="txt-name">
            <div [routerLink]="['/admin/whistleblow', whistl.id]">
              {{ whistl.title | slice : 0 : 50 }}
              <span *ngIf="whistl.title.length > 50">...</span>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-6 d-flex align-items-center">
          <span class="role">
            {{ whistl.description | slice : 0 : 50
            }}<span *ngIf="whistl.description.length > 50">...</span>
          </span>
        </div>
        <div
          *ngIf="innerWidth > 575"
          class="col-2 d-flex align-items-center p-0 justify-content-end"
        >
          <div></div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = whistl"
              title="{{ 'delete' | transloco }}"
              i18n-title="Delete button"
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3333 5.00033V4.33366C12.3333 3.40024 12.3333 2.93353 12.1517 2.57701C11.9919 2.2634 11.7369 2.00844 11.4233 1.84865C11.0668 1.66699 10.6001 1.66699 9.66667 1.66699H8.33333C7.39991 1.66699 6.9332 1.66699 6.57668 1.84865C6.26308 2.00844 6.00811 2.2634 5.84832 2.57701C5.66667 2.93353 5.66667 3.40024 5.66667 4.33366V5.00033M7.33333 9.58366V13.7503M10.6667 9.58366V13.7503M1.5 5.00033H16.5M14.8333 5.00033V14.3337C14.8333 15.7338 14.8333 16.4339 14.5608 16.9686C14.3212 17.439 13.9387 17.8215 13.4683 18.0612C12.9335 18.3337 12.2335 18.3337 10.8333 18.3337H7.16667C5.76654 18.3337 5.06647 18.3337 4.53169 18.0612C4.06129 17.8215 3.67883 17.439 3.43915 16.9686C3.16667 16.4339 3.16667 15.7338 3.16667 14.3337V5.00033"
                  stroke="#475467"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
          <input
            type="checkbox"
            class="form-check-input"
            [checked]="whistl.resolve"
            (change)="Resolve(whistl.id)"
            id="resolveCheck"
          />
        </div>
      </div>
      <br />

      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service.loadFilterPage(searchFilter, $event).subscribe()
          "
        ></app-custom-pagination>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.title"
      (confirm)="
        handleDeleteClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
  </div>
</div>
