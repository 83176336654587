<div class="docform">
  <div class="toolbar">
    <div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          [checked]="videoType === 'upload'"
          (change)="setVideotype('upload')"
        />
        <label class="form-check-label">{{ "upload" | transloco }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          [checked]="videoType === 'url'"
          (change)="setVideotype('url')"
        />
        <label class="form-check-label">{{ "Url" | transloco }}</label>
      </div>
    </div>
  </div>
  <div class="p10px">
    <div>
      <div class="form-group form-floating mb-3">
        <form [formGroup]="Form" enctype="multipart/form-data">
          <div
            *ngIf="videoType == 'upload'"
            class="col-12 row mb-3 p-0 m-0 mt-3"
          >
            <div class="inpt-title p-0 mb-2">
              {{ "Video/Sound" | transloco }}
            </div>
            <div
              class="rel_container"
              [class.notValidVideo]="
                !Form.get('video').valid && Form.get('video').touched
              "
            >
              <app-blob-file-uploader
                class="w-100 p-0"
                (FileSubmit)="updateFile($event, 'video')"
                placeholder="{{ 'Select Video/Sound' | transloco }}"
                [fileType]="'video/*,.mp3'"
                [fileTypeValidationString]="'video'"
              ></app-blob-file-uploader>

              <div class="mainfont imgfont ms-1 mb-2">
                <div
                  *ngIf="Form.get('video').value"
                  [class.pointer]="Form.get('video').value"
                  (click)="
                    blobService.openFileFromBlob(
                      Form.get('video').value,
                      Form.get('isFileOnRightBlob').value
                    )
                  "
                >
                  {{
                    Form.get("video").value.length > 36
                      ? Form.get("video").value.slice(
                          36,
                          Form.get("video").value.length
                        )
                      : Form.get("video").value
                  }}
                  <img
                    class="ps-2 cursor-pointer"
                    (click)="deleteFile('video')"
                    src="assets/img/trash.svg"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="videoType === 'url'"
            class="form-floating"
            [appValidateState]="Form.get('videoUrl')"
          >
            <div class="inpt-title">{{ "videoUrl" | transloco }}</div>
            <input
              id="videoUrl"
              formControlName="videoUrl"
              type="text"
              class="inpt"
              placeholder="{{ 'videoUrl' | transloco }}"
              autocomplete="off"
            />
          </div>

          <div>
            <div class="form-group form-floating mb-3">
              <div class="col-12 row mb-3 p-0 m-0 mt-3">
                <div class="inpt-title p-0 mb-2">
                  {{ "image" | transloco }}
                </div>
                <div
                  class="rel_container"
                  [class.notValidVideo]="
                    !Form.get('image').valid && Form.get('image').touched
                  "
                >
                  <app-blob-file-uploader
                    class="w-100 p-0"
                    (FileSubmit)="updateFile($event, 'image')"
                    (compressImagesSubmit)="changeHasCompressedImage($event)"
                    [placeholder]="'Select Image'"
                    [fileType]="'image/*'"
                    [fileTypeValidationString]="'image'"
                    [withCompression]="true"
                  ></app-blob-file-uploader>

                  <div class="mainfont imgfont ms-1 mb-2">
                    <div
                      *ngIf="Form.get('image').value"
                      [class.pointer]="Form.get('image').value"
                      (click)="
                        blobService.openFileFromBlob(
                          Form.get('image').value,
                          Form.get('isFileOnRightBlob').value
                        )
                      "
                    >
                      {{
                        Form.get("image").value.length > 36
                          ? Form.get("image").value.slice(
                              36,
                              Form.get("image").value.length
                            )
                          : Form.get("image").value
                      }}
                      <img
                        class="ps-2 cursor-pointer"
                        (click)="deleteFile('image')"
                        src="assets/img/trash.svg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-floating" [appValidateState]="Form.get('altText')">
              <div class="inpt-title">{{ "altText" | transloco }}</div>
              <input
                id="altText"
                formControlName="altText"
                type="text"
                class="inpt"
                placeholder="{{ 'altText' | transloco }}"
                autocomplete="off"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
