import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import { BaseService } from './abstract/base.service';
import { IMark, IProject, ITask } from './timelogs.repository';
import {
  IProjectTotalByMonth,
  TimelogsTasksRepository,
} from './timelogsTasks.repository';

const API = '/api/timelogsTasks';
export const DEFAULT_ENTITIES_PER_PAGE = 10;

@Injectable({ providedIn: 'root' })
export class TimelogsTasksService extends BaseService<ITask> {
  constructor(http: HttpClient, repo: TimelogsTasksRepository) {
    super(API, http, repo);
  }

  reloadPageTasks(
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: ITask[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageTasks(data.currentPage, data.perPage);
    }
    return this.loadPageTasks(1, defaultTake);
  }

  loadPageTasks(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: ITask[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: ITask[] }>(
        `/api/timelogs/gettasks?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadAll() {
    TimelogsTasksRepository;
    return this.http.get<IProject[]>(API + '/getprojects');
  }
  // [deprecated]
  loadPersonalProjects() {
    return this.http.get<any[]>(API + '/getpersonalprojects');
  }
  loadPersonalProjectsWithMarksForWeek(start_date: string, end_date: string) {
    return this.http.get<IProject[]>(
      API + `/getPersonalProjectsWithMarksForWeek/${start_date}/${end_date}`
    );
  }
  loadPersonalTasks(id: string) {
    return this.http.get<ITask[]>(API + `/getpersonaltasks/${id}`);
  }
  loadTasks() {
    return this.http.get<ITask[]>(API + `/gettasks`);
  }
  addProject(entity: Partial<IProject>) {
    return this.http.post<Partial<IProject>>(API + '/project', entity);
  }
  addMark(entity: Partial<IMark>) {
    return this.http.post<any>(API + '/mark', entity);
  }
  addTask(entity: Partial<ITask>) {
    return this.http.post<Partial<IProject>>(API + '/task', entity);
  }
  // [deprecated]
  getMarksForWeek(project_id: string, start_date: string, end_date: string) {
    return this.http.get<IMark[]>(
      API + `/getMarksForweek/${project_id}/${start_date}/${end_date}`
    );
  }
  deleteProject(ProjectId: string) {
    return this.http.delete<any>(API + `/project/${ProjectId}`);
  }
  deleteTask(ProjectId: string) {
    return this.http.delete<any>(API + `/task/${ProjectId}`);
  }
  updateProject(entity: Partial<IProject>) {
    return this.http.patch<Partial<IProject>>(API + '/updateProject', entity);
  }
  updateTask(entity: Partial<ITask>) {
    return this.http.patch<Partial<IProject>>(API + '/updateTask', entity);
  }
  loadOneProject(ProjectId: string) {
    return this.http.get<any>(API + `/loadOneProject/${ProjectId}`);
  }
  loadOneTask(ProjectId: string) {
    return this.http.get<any>(API + `/loadOneTask/${ProjectId}`);
  }
  loadTaskMarksForTeam(
    project_id: string,
    task_id: string,
    start_date: string,
    end_date: string
  ) {
    return this.http.get<IMark[]>(
      API +
        `/loadTaskMarksForTeam/${project_id}/${task_id}/${start_date}/${end_date}`
    );
  }
  loadTaskMarksForTeamHours(
    project_id: string,
    start_date: string,
    end_date: string
  ) {
    return this.http.get<IProject>(
      API +
        `/LoadProjectsWithTotalHours/${project_id}/${start_date}/${end_date}`
    );
  }

  getTotalTimeMonth(start: string, end: string) {
    return this.http.get<IProjectTotalByMonth[]>(
      `${API}/tasksTotalByMonth/${start}/${end}`
    );
  }
}
