<div class="row page-header d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "chooseUser" | transloco }}
      </h2>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="userrepo.sort$ | async"
        (sortingChange)="userservice.sort($event, searchFilter).subscribe()"
      ></app-sort-dropdown>
    </div>
    <div class="mb-3 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-xl-8 col-md-7 col-sm-8 col-9 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">{{ "name" | transloco }}</span>
          </div>
          <div class="col-xl-4 col-md-5 col-sm-4 col-3">
            <span class="txt me-1">{{ "role" | transloco }}</span
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.05992 5.99967C6.21665 5.55412 6.52602 5.17841 6.93322 4.9391C7.34042 4.69978 7.81918 4.6123 8.2847 4.69215C8.75022 4.772 9.17246 5.01402 9.47664 5.37536C9.78081 5.7367 9.94729 6.19402 9.94659 6.66634C9.94659 7.99967 7.94659 8.66634 7.94659 8.66634M7.99992 11.333H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z"
                stroke="#98A2B3"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let user of userrepo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem; cursor: pointer"
        [routerLink]="['/admin/apv/personal', user.id]"
      >
        <div
          class="col-xl-8 col-md-7 col-sm-8 col-9 pt-3 pb-3 d-flex align-items-center psm0"
        >
          <input
            class="check form-check-input me-2"
            type="checkbox"
            style="margin-left: 0.1rem"
          />
          <app-entity-avatar-init
            class="ms-1 me-2"
            [img]="user.imagePath"
            [name]="user.name"
            [surname]="user.surname"
          ></app-entity-avatar-init>
          <p>
            <span class="txt-name">{{ user.name }} {{ user.surname }}</span>
            <br />
            <small>
              <span class="txt-email">{{ user.email }}</span>
            </small>
          </p>
        </div>
        <div class="col-sm-2 col-3 d-flex align-items-center">
          <span class="role ms-1" *ngIf="user.roles.length">{{
            user.roles[0].substring(0, 5)
          }}</span>
        </div>
      </div>
      <br />
      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(userrepo.pageNumber$ | async) || 1"
          [total]="(userrepo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            userservice.loadFilterPage(searchFilter, $event).subscribe()
          "
        ></app-custom-pagination>
      </div>
    </div>
  </div>
</div>
