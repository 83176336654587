import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-timelogs-navbuttons',
  templateUrl: './timelogs-navbuttons.component.html',
  styleUrls: ['./timelogs-navbuttons.component.scss'],
})
export class TimelogsNavbuttonsComponent {
  url$: string = '';

  constructor(public route: ActivatedRoute) {
    const url: Observable<string> = route.url.pipe(
      map((segments) => segments.join('/'))
    );
    url.subscribe((x) => (this.url$ = x));
  }
}
