import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { AuthRepository } from 'app/state/auth.repository';
import {
  NotificationsRepository,
  ProjectSortOptions,
} from 'app/state/notifications.repository';
import { NotificationsService } from 'app/state/notifications.service';

@Component({
  selector: 'app-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
})
export class NotificationsListComponent implements OnInit {
  constructor(
    public repo: NotificationsRepository,
    public service: NotificationsService,
    public authRepo: AuthRepository,
    public http: HttpClient
  ) {
    this.repo.clearPages();
    this.service.loadPage(1, 10, '').subscribe();

    this.http.get('/api/notification/getUserSubscriptions').subscribe((x) => {
      this.userSubscriptions = x;
    });
  }
  innerWidth = 0;
  sortOptions = ProjectSortOptions;
  searchFilter: string = '';
  userSubscriptions: any;
  options: DatalistOption[] = [
    { value: -1, label: 'Disabled' },
    { value: 1, label: 'Immediately' },
    { value: 2, label: 'Once A Day' },
    { value: 3, label: 'Once A Week' },
    { value: 4, label: 'Once A Moth' },
  ];

  sss = true;

  userOptions: DatalistOption[] = [{ value: -1, label: 'Disabled' }];
  activeOption: any = 'Disabled';
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  ngAfterViewInit() {
    this.http
      .get('/api/notification/getUsersForSubscriptions')
      .subscribe((x: any) => {
        if (x) {
          x?.forEach((element: any) => {
            this.userOptions?.push({
              label: element.userName,
              value: element.userId,
            });
          });
        }
      });
  }

  getSubStatues(id: any) {
    return (
      this.userSubscriptions?.find((x: any) => x.notificationEventTypeId == id)
        ?.notificationEventInterval ?? -1
    );
  }

  curPageType: number = 1;
  changePageType(type: any) {
    this.curPageType = type;
  }

  changeOpt(event: any, id: any) {
    this.http
      .put('/api/notification/changeNotificationInterval', {
        typeId: id,
        intervalType: event,
      })
      .subscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service.searchReloadPage(this.searchFilter).subscribe();
  }
}
