import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import {
  AuthInfo,
  AuthRepository,
  Office365Settings,
  getStoredToken,
} from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { HttpClient } from '@angular/common/http';
import { getTenantsAsDataList } from 'app/helpers/arrayToDataListOptonConverter';
import { GeneralDataRepository } from '../../../../helpers/repository/general-data.repository';
import { GdprConfirmationComponent } from 'app/modules/shared/components/gdpr-confirmation/gdpr-confirmation.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { UsersService } from 'app/api/services/users.service';

@Component({
  selector: 'app-active-tenant-page',
  templateUrl: './active-tenant-page.component.html',
  styleUrls: ['./active-tenant-page.component.scss'],
})
export class ActiveTenantPageComponent implements OnInit {
  title: any;
  error: string | null = null;
  testsLocation = 'localhost:9876';
  settings$: Observable<Office365Settings> | null = null;
  tenantsOptions: Array<DatalistOption> = [];
  userExists = false;
  loginForm = this.formBuilder.group({
    tenantId: [''],
  });
  URL: string = '';
  Scope: string = '';
  ClientID: string = '';
  showPageContent: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private modalService: NgbModal,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public env: EnvState,
    private http: HttpClient,
    private generalDataRepo: GeneralDataRepository,
    public userservice: UsersService
  ) {
    this.loadSettings();
  }

  ngOnInit(): void {
    this.getUserInfo();
    //this.generalDataRepo.updateAllGeneralData(true);
  }

  chooseRedirectAction(responce: AuthInfo) {
    if (responce.tenants.length == 0) {
      this.deepLinkNavigation();
    } else if (responce.tenants.length == 1) {
      this.setActiveTenant(this.tenantsOptions[0].value);
    } else if (responce.isSuperadmin && responce.tenants.length == 0) {
      this.deepLinkNavigation();
    } else {
      this.showPageContent = true;
    }
  }

  deepLinkNavigation() {
    this.generalDataRepo.updateAllGeneralData(true);
    this.repo.returnUrl$?.subscribe((url) => {
      this.router.navigate([url]);
    });

    if (this.repo.returnUrl$ == undefined) {
      this.router.navigate(['/home']);
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.loginForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  getUserInfo() {
    this.auth.getUserInfo().subscribe((responce) => {
      if (responce) {
        if (!responce.gdprConfirmed) {
          this.showGdprConfirmation();
        }
        localStorage.setItem('curUserLang', responce.language);
        this.isSuperAdmin = responce.isSuperadmin;
        this.tenantsOptions = getTenantsAsDataList(responce.tenants);
        this.chooseRedirectAction(responce);
      }
    });
  }

  setActiveTenant(tenantId: string): void {
    localStorage.setItem('activeTenantId', tenantId);
    let token = getStoredToken();
    this.auth.setActiveTenant(token!, tenantId).subscribe({
      complete: () => {
        this.deepLinkNavigation();
      },
    });
  }

  skip() {
    this.deepLinkNavigation();
  }

  showGdprConfirmation() {
    const modalRef = this.modalService.open(GdprConfirmationComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-dialog-centered custom-modal',
    });
  }

  loadSettings() {
    this.settings$ = this.http.get<Office365Settings>('/api/auth/office');
    this.http.get<Office365Settings>('/api/auth/office').subscribe((x) => {
      this.ClientID = x.clientId;
      this.Scope = x.scope;
      this.URL = x.url;
    });
  }
}
