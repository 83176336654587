import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { MapsPageComponent } from '../modules/unauthorized/pages/maps-page/maps-page.component';

import { UserRoles } from '../state/auth.repository';

import { TenantFeatures } from '../state/feature.repository';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [],
  },
  {
    path: 'unauthorized/maps/:tenant',
    component: MapsPageComponent,
  },
  {
    path: '',
    component: AppinnerlayoutComponent,
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class UnauthorizedRoutingModule {}
