<div class="docform">
  <form [formGroup]="editForm" enctype="multipart/form-data" class="p-2">
    <div class="form-group form-floating row">
      <div class="col-6 row p-0 m-0">
        <div
          class="form-group form-floating mb-2"
          [class.is-invalid]="!datesValid"
        >
          <div class="inpt-title">{{ "startDate" | transloco }}</div>
          <div class="input-group">
            <input
              formControlName="from"
              type="datetime-local"
              class="inpt-group-pre"
            />
            <button
              (click)="updateValue('from', null)"
              class="btn button-grop-append bg-gray"
              title="Clear"
            >
              <span>X</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-6 row p-0 m-0">
        <div
          class="form-group form-floating mb-2"
          [class.is-invalid]="!datesValid"
        >
          <div class="inpt-title">{{ "endDate" | transloco }}</div>

          <div class="input-group">
            <input
              formControlName="till"
              type="datetime-local"
              class="inpt-group-pre"
            />
            <button
              (click)="updateValue('till', null)"
              class="btn button-grop-append bg-gray"
              title="Clear"
            >
              <span>X</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
