import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { UserRoles } from '../state/auth.repository';
import { ApvAdminComponent } from '../modules/apv/apv-admin/apv-admin.component';
import { TimelogsComponent } from '../modules/timelogs/timelogs/timelogs.component';
import { TimelogsAdminComponent } from '../modules/timelogs/timelogs-admin/timelogs-admin.component';

import { TimelogsFormComponent } from '../modules/timelogs/timelogs-form/timelogs-form.component';
import { TimelogsEditComponent } from '../modules/timelogs/timelogs-edit/timelogs-edit.component';
import { TimelogsOverviewComponent } from '../modules/timelogs/timelogs-overview/timelogs-overview.component';
import { TimelogsUsersOverviewComponent } from '../modules/timelogs/timelogs-users-overview/timelogs-users-overview.component';
import { TimelogsProjectsOverviewComponent } from '../modules/timelogs/timelogs-projects-overview/timelogs-projects-overview.component';

import { TimelogsTasksAdminComponent } from '../modules/timelogs/timelogs-tasks-admin/timelogs-tasks-admin.component';
import { TenantFeatures } from '../state/feature.repository';
import { TimelogsOverviewUsersMonthComponent } from 'app/modules/timelogs/timelogs-overview-users-month/timelogs-overview-users-month.component';
import { TimelogsProjectsOverviewByMonthComponent } from 'app/modules/timelogs/timelogs-projects-overview-by-month/timelogs-projects-overview-by-month.component';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'timelogs',
        component: TimelogsComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/timelogs',
        component: TimelogsAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/timelogs/task',
        component: TimelogsTasksAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/timelogs/:type/:action/:id',
        component: TimelogsEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/timelogs/:type/:action',
        component: TimelogsFormComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/overview/timelogs',
        component: TimelogsUsersOverviewComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/overview/timelogs/tasks',
        component: TimelogsProjectsOverviewComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/overview/timelogs/month',
        component: TimelogsOverviewUsersMonthComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
      {
        path: 'admin/overview/timelogs/tasks/month',
        component: TimelogsProjectsOverviewByMonthComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.TimeLogs,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class TimelogsRoutingModule {}
