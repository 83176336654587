<h2 class="mb-4">
  <ng-container *ngIf="action === 'new'" i18n="Start {{type}} label">
    {{ "create" | transloco }} {{ type }}</ng-container
  >
  <ng-container *ngIf="action === 'edit'" i18n="Edit {{type}} label">
    {{ "edit" | transloco }} {{ type }}</ng-container
  >
</h2>

<div *ngIf="type === 'project'">
  <form
    [formGroup]="editForm"
    (ngSubmit)="onSubmit()"
    enctype="multipart/form-data"
  >
    <div class="form-group form-floating mb-3">
      <div class="form-floating mb-4" [appValidateState]="editForm.get('name')">
        <div class="inpt-title">{{ "title" | transloco }}</div>
        <input
          id="name"
          formControlName="name"
          type="text"
          class="inpt"
          placeholder="Title"
          i18n-placeholder="@@title"
          autocomplete="off"
        />
      </div>
      <div>
        <div class="inpt-title">{{ "description" | transloco }}</div>
        <textarea
          id="description"
          formControlName="description"
          type="text"
          class="inpt inpt-lg"
          placeholder="{{ 'description' | transloco }}"
          i18n-placeholder="Label description"
          autocomplete="off"
        >
        </textarea>
      </div>
    </div>
    <div class="my-4">
      <app-datalist-select
        [forUsers]="true"
        id="usersSelect"
        [options]="userOptions"
        [multiple]="true"
        [appValidateState]="editForm.get('applicationUsersIds')"
        class="form-floating"
        label="{{ 'users:' | transloco }}"
        [(activeValue)]="editForm?.value.applicationUsersIds"
        (activeValueChange)="updateValue('applicationUsersIds', $event)"
      />
    </div>
    <div class="my-4">
      <app-datalist-select
        id="tasksSelect"
        [options]="taskOptions"
        [multiple]="true"
        [appValidateState]="editForm.get('tasksIds')"
        class="form-floating"
        label="{{ 'tasks:' | transloco }}"
        [(activeValue)]="editForm?.value.tasksIds"
        (activeValueChange)="updateValue('tasksIds', $event)"
      >
      </app-datalist-select>
    </div>
    <div
      [appValidateState]="editForm.get('dateString')"
      class="form-floating my-2"
    >
      <input
        id="dateString"
        formControlName="dateString"
        type="date"
        class="inpt"
      />
    </div>
    <div class="form-group form-floating h-100 pb-3 d-grid">
      <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
        {{ "save" | transloco }}
      </button>
    </div>
  </form>
</div>
<div *ngIf="type === 'task'">
  <form
    [formGroup]="editForm"
    (ngSubmit)="onSubmit()"
    enctype="multipart/form-data"
  >
    <div class="form-group form-floating mb-3">
      <div class="form-floating mb-4" [appValidateState]="editForm.get('name')">
        <div class="inpt-title">{{ "title" | transloco }}</div>

        <input
          id="name"
          formControlName="name"
          type="text"
          class="inpt"
          placeholder="{{ 'title' | transloco }}"
          i18n-placeholder="@@title"
          autocomplete="off"
        />
      </div>
    </div>
    <div class="form-group form-floating h-100 pb-3 d-grid">
      <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
        {{ "save" | transloco }}
      </button>
    </div>
  </form>
</div>
