import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'app/api/services/users.service';
import { AuthService } from 'app/state/auth.service';

@Component({
  selector: 'app-gdpr-confirmation',
  templateUrl: './gdpr-confirmation.component.html',
  styleUrls: ['./gdpr-confirmation.component.scss'],
})
export class GdprConfirmationComponent implements OnInit {
  gdprConfirmed: boolean = false;
  redirectGDPRUrl: string = 'https://igntr.me/gdpr-english/';

  IgnitePrivacyAndCookieConfirmation: string = 'Yes, I accept the Igntr ';
  IgnitePrivacyAndCookieConfirmation2: string = 'Privacy and Cookie Policy.';
  here: string = 'Click here to read more';

  constructor(
    private userservice: UsersService,
    private auth: AuthService,
    private activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    const userLang = localStorage.getItem('curUserLang');
    if (userLang == 'da') {
      this.redirectGDPRUrl = 'https://igntr.me/gdpr-danish/';

      this.IgnitePrivacyAndCookieConfirmation = 'Ja tak, jeg accepterer Igntr';
      this.IgnitePrivacyAndCookieConfirmation2 =
        'Privatlivs- og cookiepolitik.';
      this.here = 'For at læse mere klik her';
    }
  }

  rejectGdpr() {
    this.auth.logout();
    this.activeModal.close();
    return false;
  }

  submitGdpr() {
    this.userservice.confirmGdpr().subscribe(() => {
      this.activeModal.close();
    });
  }

  getRedirectGDPRUrl() {
    return '/home';
  }
}
