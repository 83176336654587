<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-xl-8 col-lg-11 col-12 row p-md-2 p-lg-0 p-0">
    <div class="mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="" i18n="Label users">
        {{ "apvAnswers" | transloco }} {{ "list" | transloco }}
      </h2>
    </div>

    <div class="box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-6 col-6 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">{{ "nameOfApv" | transloco }}</span>
          </div>

          <div class="col-sm-6 col-6">
            <span class="txt me-1"> {{ "questions" | transloco }}</span
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.05992 5.99967C6.21665 5.55412 6.52602 5.17841 6.93322 4.9391C7.34042 4.69978 7.81918 4.6123 8.2847 4.69215C8.75022 4.772 9.17246 5.01402 9.47664 5.37536C9.78081 5.7367 9.94729 6.19402 9.94659 6.66634C9.94659 7.99967 7.94659 8.66634 7.94659 8.66634M7.99992 11.333H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z"
                stroke="#98A2B3"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <br />
      <div
        *ngFor="let answer of answers"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-6 col-6 pt-3 pb-3 d-flex align-items-center psm0">
          <input
            class="check form-check-input me-2"
            type="checkbox"
            style="margin-left: 0.1rem"
          />

          <div class="txt-name text-break">
            <div>
              {{ answer.apvTitle | slice : 0 : 50 }}
              <span *ngIf="answer.apvTitle.length > 50">...</span>
            </div>
          </div>
        </div>
        <div class="col-sm-6 col-6 pb-2 pt-2">
          <div *ngFor="let question of answer.apvQuestions" class="role mb-1">
            {{ question.title | slice : 0 : 200 }}
            <!-- prettier-ignore -->
            <span class="txt-bold">
              - {{ question.answer == true ? "{{ 'yes' | transloco }}" : "{{
                'no' | transloco
              }}" }}</span
            >
          </div>
        </div>
      </div>

      <br />
      <!--<div class="box-pagination-footer">
       
      </div>-->
    </div>
  </div>
</div>
