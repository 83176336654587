<div
  *ngIf="importedUsers"
  class="modal fade border-0"
  bsModal
  [config]="{ show: true }"
  tabindex="-1"
  aria-hidden="true"
  (onHidden)="cancel.emit()"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-lights border-0 br-16 col-6 p-2">
      <div class="modal-header border-0">
        <h6
          class="modal-title"
          id="deleteModalLabel"
          i18n="Title for confirmation dialog"
        >
          {{ "confirmYourAction" | transloco }}
        </h6>
        <button
          type="button"
          class="btn-close"
          aria-label="Close"
          (click)="cancel.emit()"
        ></button>
      </div>
      <div class="col-lg-2 col-md-4 p-2">
        <app-datalist-select
          label="Filter by:"
          placeholder="None"
          [options]="importFilter"
          (activeValueChange)="applySort($event)"
        >
        </app-datalist-select>
      </div>
      <div class="table-container">
        <table>
          <thead>
            <th class="px-3 py-2">Name</th>
            <th class="px-3 py-2">Surname</th>
            <th class="px-3 py-2">Location</th>
            <th class="px-3 py-2">Phone number</th>
            <th class="px-3 py-2">Language</th>
            <th class="px-3 py-2">Title</th>
            <th class="px-3 py-2">Password</th>
            <th class="px-3 py-2">Email</th>
            <th class="px-3 py-2">Groups</th>
            <th class="px-3 py-2">Is coach</th>
            <th class="px-3 py-2">Coach emails</th>
          </thead>
          <tbody>
            <tr
              *ngFor="let user of filteredUsers"
              [class.IsEdited]="user.isEdited"
              [class.HasErrors]="user.hasErrors"
            >
              <td class="px-3 py-2" *ngIf="user.name; else emptyFieldError">
                {{ user.name }}
              </td>
              <td class="px-3 py-2" *ngIf="user.surname; else emptyFieldError">
                {{ user.surname }}
              </td>
              <td class="px-3 py-2">{{ user.location }}</td>
              <td class="px-3 py-2">{{ user.phoneNumber }}</td>
              <td class="px-3 py-2">{{ user.language }}</td>
              <td class="px-3 py-2">{{ user.title }}</td>
              <td
                class="px-3 py-2"
                *ngIf="user.password || user.isEdited; else emptyFieldError"
              >
                {{ user.password }}
              </td>
              <td class="px-3 py-2" *ngIf="user.email; else emptyFieldError">
                {{ user.email }}
              </td>
              <td class="px-3 py-2">{{ user.userGroupIdentifiers }}</td>
              <td class="px-3 py-2" style="text-align: center">
                {{ user.isUserCoach }}
              </td>
              <td class="px-3 py-2">{{ user.coachEmails }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="d-flex"
        [class.invalid]="waitingForImportResponse$"
        style="justify-content: end"
      >
        <button
          class="btn btn-lg orange-dark text-white mt-2 me-2"
          [class.invalid]="withErrors"
          (click)="!withErrors ? submitImport.emit(importedUsers) : null"
        >
          Import
        </button>
        <button class="btn btn-lg mt-2 btn-cancel" (click)="cancel.emit()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<div class="loader" *ngIf="waitingForImportResponse$">
  <div class="spinner-border"></div>
</div>

<ng-template #emptyFieldError>
  <td class="errorField"><p>Error!</p></td>
</ng-template>
