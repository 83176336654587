import { Component, OnInit } from '@angular/core';
import { AuthRepository } from 'app/state/auth.repository';

import { Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  tripStats = new Array<number>(3);
  productStats = new Array<number>(3);
  isLoaded = new Array<boolean>(3).fill(false);
  subsctiptions = new Array<Subscription>();
  items = ['today', 'thisweek', 'thismonth'];

  constructor(public repo: AuthRepository) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subsctiptions.forEach((s) => s.unsubscribe());
  }
}
