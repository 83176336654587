<!-- Begin page -->
<main class="has-header inner-layout" id="maincontainer">
  <!-- Header -->
  <app-headerback
    class="container-fluid header bg-dark"
    [page]="page"
  ></app-headerback>
  <!-- Header ends -->

  <!-- main page content -->
  <div class="main-container container-fluid">
    <router-outlet></router-outlet>
  </div>
  <!-- main page content ends -->
</main>
<!-- Page ends-->

<!-- fluid footer at bottom of page -->
<app-footerinfo class="container-fluid d-block"></app-footerinfo>
<!-- fluid footer at bottom of page ends -->
