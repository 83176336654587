import { Component, OnInit } from '@angular/core';
import { AuthRepository } from '../../../../state/auth.repository';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit {
  constructor() {}
  ngOnInit(): void {}

  ngAfterContentChecked() {}
}
