import { Component, HostListener, OnInit } from '@angular/core';

import { AuthRepository, UserRoles } from 'app/state/auth.repository';
import {
  Post,
  PostsRepository,
  postSortOptions,
} from 'app/state/posts.repository';
import { PostsService } from 'app/state/posts.service';

@Component({
  selector: 'app-news-admin',
  templateUrl: './news-admin.component.html',
  styleUrls: ['./news-admin.component.scss'],
})
export class NewsAdminComponent implements OnInit {
  innerWidth = 0;
  sortOptions = postSortOptions;
  searchFilter: string = '';

  deleteConfirmation: Post | null = null;
  constructor(
    public repo: PostsRepository,
    public service: PostsService,
    public auth: AuthRepository
  ) {}

  ngOnInit(): void {
    this.service
      .reloadPage(this.searchFilter, undefined, ['userGroups'])
      .subscribe();
    this.innerWidth = window.innerWidth;
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  refreshData() {
    this.service
      .reloadPage(this.searchFilter, undefined, ['userGroups'])
      .subscribe();
  }

  handleDeleteClick(post: Post) {
    this.service.delete(post.id).subscribe((x) => {
      this.refreshData();
    });
  }

  setFilter(filter?: string) {
    filter ? (this.searchFilter = filter) : (this.searchFilter = '');
    this.service
      .searchReloadPageCustom('All', this.searchFilter, undefined, [
        'userGroups',
      ])
      .subscribe();
  }

  transform(value?: string): any {
    var string = value
      ? value.replace(/<.*?>/g, '').replace(/&nbsp;/g, '')
      : '';
    return string.length > 30 ? string.slice(0, 30) + '...' : string;
  }
}
