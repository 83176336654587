import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-header-image',
  templateUrl: './user-header-image.component.html',
  styleUrls: ['./user-header-image.component.scss'],
})
export class UserHeaderImageComponent implements OnInit {
  readonly DEFAULT_BACKGROUND = 'assets/img/background_default.webp';

  @Input() background: string | null = null;

  constructor() {}

  ngOnInit(): void {}

  onProfileClick($event: Event) {
    $event.stopPropagation();
  }
}
