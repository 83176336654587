import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';

import { UserRoles } from '../state/auth.repository';
import { WhistleblowComponent } from '../modules/whistleblows/whistleblow/whistleblow.component';
import { WhistleblowAdminComponent } from '../modules/whistleblows/whistleblow-admin/whistleblow-admin.component';
import { WhistleblowInfoComponent } from '../modules/whistleblows/whistleblow-info/whistleblow-info.component';
import { TenantFeatures } from '../state/feature.repository';
import { EnvironmentFeatureGuard } from 'app/guards/environment.features.guard';
import { CLIENT_PATHS } from 'app/helpers/constants/paths.core.constants';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'whistleblow',
        component: WhistleblowComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.User],
          feature: TenantFeatures.Whistleblow,
        },
      },
      {
        path: 'admin/whistleblow',
        component: WhistleblowAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Whistleblow,
        },
      },
      {
        path: 'admin/whistleblow/:id',
        component: WhistleblowInfoComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Whistleblow,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class WhistleblowRoutingModule {}
