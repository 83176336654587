<form
  [formGroup]="MailForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group form-floating mb-3">
    <div class="form-floating mb-3" [appValidateState]="MailForm.get('title')">
      <div class="inpt-title">{{ "title" | transloco }}</div>
      <input
        id="header"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="{{ 'title' | transloco }}"
        i18n-placeholder="@@header"
        autocomplete="off"
      />
    </div>
    <div>
      <div class="inpt-title">{{ "description" | transloco }}</div>
      <textarea
        id="body"
        formControlName="body"
        type="text"
        class="inpt inpt-lg"
        placeholder="{{ 'description' | transloco }}"
        i18n-placeholder="@@body"
        autocomplete="off"
      ></textarea>
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
      {{ "save" | transloco }}
    </button>
  </div>
</form>
