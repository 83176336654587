import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import { AuthRepository } from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { TenantFeatures } from 'app/state/feature.repository';
import { UiRepository } from 'app/state/ui.repository';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import { TenantsRepository } from 'app/state/tenants.repository';
import { DataService } from 'app/shared/data.service';
import { UserGroupsRepository } from 'app/state/usergroup.repository';
import { LogoComponent } from '../logo/logo.component';
import { ClearCacheService } from 'app/helpers/clearCacheValuesService';
import { OnboardingService } from 'app/api/services/onboarding.service';
import { dA } from '@fullcalendar/core/internal-common';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  TenantFeatures = TenantFeatures;
  features = this.env.features;

  curLang: string = 'en';
  langSub: any;
  uploadSubscribe: any;
  isUploading: boolean = false;

  IsTenantsLoad: boolean = false;
  IsDocTypesLoad: boolean = false;
  isCoach: boolean = false;

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    public tenantRepo: TenantsRepository,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState,
    public doctypesRepo: DocumentTypesRepository,
    public docttpeService: DocumentTypesService,
    private dataService: DataService,
    public userGroupRepo: UserGroupsRepository,
    private clearCacheService: ClearCacheService,
    private obService: OnboardingService
  ) {
    const service_worker_chache_was_cleaned = localStorage.getItem(
      'service_worker_chache_was_cleaned'
    );
    if (!service_worker_chache_was_cleaned) {
      clearCacheService.clearServiceWorkerCache();
    }

    this.uploadSubscribe = this.dataService.isUploading$.subscribe((x: any) => {
      this.isUploading = x;
    });
    this.langSub = this.dataService.curUserLang.subscribe(
      (x) => (this.curLang = x)
    );
  }

  ngOnInit(): void {}

  menuclose() {
    this.ui.toggleMenuOpened(false);
  }

  logout() {
    this.menuclose();
    //this.auth.logout().subscribe(() => this.router.navigate(['/signin']));
    this.auth.logout();
    
    return false; 
  }

  resetLoading() {
    this.IsDocTypesLoad = false;
  }

  unimpersonate() {
    this.menuclose();
    this.auth.unimpersonate().subscribe({
      complete: () => {
        this.router.navigate(['/']);
        return false; // prevent default
      },
    });
  }

  ngOnDestroy() {
    this.uploadSubscribe.unsubscribe();
    this.langSub.unsubscribe();
  }

  showPage(pageName: string) {
    const tenId = localStorage.getItem('activeTenantId');
    let admin = this.repo.isAnyAdmin$;
    switch (pageName) {
      case 'reports': {
        if (tenId && admin) {
          return true;
        }
        return false;
      }
      default: {
        return false;
        break;
      }
    }
  }
}
