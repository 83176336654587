import { Component, OnInit } from '@angular/core';
import { SideBarItem } from './sideMenuItem';
import { AuthRepository, getStoredToken } from 'app/state/auth.repository';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import { UiRepository } from 'app/state/ui.repository';
import { GeneralAppDataDto } from 'app/api/models/auth/sideBarInfoDto';
import { AuthService } from 'app/state/auth.service';
import { GeneralDataRepository } from '../../../../../helpers/repository/general-data.repository';
import { DocumentTypesRepository } from 'app/state/documenttype.repository';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-items',
  templateUrl: './sidebarItems.component.html',
  styleUrls: ['./sidebarItems.component.scss'],
})
export class SidebarItemsComponent implements OnInit {
  allMainSideMenuItems: Array<SideBarItem> = [];
  allIgniteSideMenuItems: Array<SideBarItem> = [];
  allAdminSideMenuItems: Array<SideBarItem> = [];

  sidebarData: GeneralAppDataDto = new GeneralAppDataDto();
  tenantsOptions: Array<DatalistOption> = [];

  tenantId: string | null = null;

  constructor(
    public env: EnvState,
    public ui: UiRepository,
    private auth: AuthService,
    public repo: AuthRepository,
    public generalDataRepo: GeneralDataRepository,
    public doctypesRepo: DocumentTypesRepository,
    public router: Router,
    //private location: Location
  ) {}
  ngOnInit(): void {
    this.generalDataRepo.updateAllGeneralData();

    this.generalDataRepo.allGeneralData$.subscribe((data) => {
      
    });

    this.generalDataRepo.allMainSideMenuItems$.subscribe((items) => {
      this.allMainSideMenuItems = items;
    });

    this.generalDataRepo.allIgniteSideMenuItems$.subscribe((items) => {
      this.allIgniteSideMenuItems = items;
    });

    this.generalDataRepo.allAdminSideMenuItems$.subscribe((items) => {
      this.allAdminSideMenuItems = items;
    });

    this.generalDataRepo.tenantsOptions$.subscribe((items) => {
      this.tenantsOptions = items;
    });
    this.tenantId = localStorage.getItem('activeTenantId');
  }

  menuclose() {
    this.ui.toggleMenuOpened(false);
  }

  setActiveTenant(tenantId?: any) {
    this.tenantId = tenantId;
    if (this.tenantId) {
      localStorage.setItem('activeTenantId', this.tenantId);
    } else {
      localStorage.removeItem('activeTenantId');
    }
    let token = getStoredToken();
    this.auth.setActiveTenant(token!, tenantId).subscribe(() => {
      this.generalDataRepo.updateAllGeneralData(true);
      this.router.navigate(['/home']).then(() => {
        window.location.reload();
      });
    });
  }
}
