import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DayjsService } from '../../services/dayjs.service';
import { ImportedUser, errorInterface } from 'app/state/users.repository';

@Component({
  selector: 'app-import-export',
  templateUrl: './import-export.component.html',
  styleUrls: ['./import-export.component.scss'],
})
export class ImportExportComponent implements OnInit {
  waitingForImportResponse$: boolean = false;

  @Input() exportFactory?: () => Observable<string>;
  @Input() importFactory?: (file: File) => Observable<ImportedUser[]>;
  @Input() filenamePrefix: string = '';
  @Input() filenameExtension: string = '.csv';
  @Input() set waitingForImportResponseSet(val: boolean) {
    if (this.waitingForImportResponse$ && !val) {
      this.resetImport();
    }
    this.waitingForImportResponse$ = val;
  }

  isExporting = false;
  isImporting = false;
  isImportError = false;
  importedUsers: ImportedUser[] | undefined;

  @Output() outputError = new EventEmitter<errorInterface>();
  @Output() submitImport = new EventEmitter<ImportedUser[]>();
  @ViewChild('importExportLink') importExportLink!: ElementRef;
  @ViewChild('fileImport') fileImport!: ElementRef;

  constructor(public ngDay: DayjsService) {}

  ngOnInit(): void {}

  export() {
    if (!this.exportFactory) {
      return;
    }
    this.isExporting = true;
    this.exportFactory().subscribe({
      next: (url) => {
        this.importExportLink.nativeElement.href = url;
        const filename = `${this.filenamePrefix}-${this.ngDay
          .dayjs()
          .format('YYYY-MM-DD-HH-mm-ss')}${this.filenameExtension}`;
        this.importExportLink.nativeElement.setAttribute('download', filename);
        this.importExportLink.nativeElement.click();
        this.isExporting = false;
      },
      error: () => (this.isExporting = false),
    });
  }

  import(event: Event) {
    this.isImportError = false;
    if (!this.importFactory) {
      return;
    }
    const target = event.target as HTMLInputElement;
    if (!target.files || !target.files.length) {
      return;
    }
    this.isImporting = true;

    this.importFactory(target.files[0]).subscribe({
      next: (res) => {
        this.importedUsers = res;
      },
      error: (err: any) => {
        this.isImportError = true;
        this.outputError.emit({
          errorMessage: err.error,
          errorState: this.isImportError,
        });
        this.resetImport();
      },
    });
  }

  resetImport() {
    this.fileImport.nativeElement.value = null;
    this.isImporting = false;
    this.isImportError = false;
    this.importedUsers = undefined;
  }
}
