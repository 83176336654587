import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filterNil } from '@ngneat/elf';
import { Observable, switchMap, tap } from 'rxjs';
import {
  AuthRepository,
  PasswordChangeRequest,
} from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { User, UsersRepository } from 'app/state/users.repository';
import { UsersService } from 'app/api/services/users.service';
import { TranslocoService } from '@ngneat/transloco';
import { DataService } from '../../../../shared/data.service';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss'],
})
export class ProfileEditComponent implements OnInit {
  userData$?: Observable<User | undefined>;
  id?: string;
  submitErrors: string[] | null = null;
  currTenantId: string | undefined = undefined;

  constructor(
    public repo: AuthRepository,
    private auth: AuthService,
    private users: UsersRepository,
    private service: UsersService,
    private router: Router,
    private translocoService: TranslocoService,
    private dataService: DataService
  ) {}

  ngOnInit(): void {
    this.repo.tenant$.subscribe((x) => (this.currTenantId = x ?? undefined));
    this.repo.id$
      .pipe(
        filterNil(),
        switchMap((id) => this.service.loadOneWithQueryId(id))
      )
      .subscribe();
    this.userData$ = this.repo.id$.pipe(
      filterNil(),
      switchMap((id) => this.users.one(id)),
      tap((user) => (this.id = user?.id))
    );
  }

  onInfoSubmit(user: Partial<User>) {
    this.submitErrors = null;
    if (!this.id) {
      throw 'Can not identify current user';
    }
    this.service.update(this.id, user).subscribe({
      complete: () => {
        this.auth.refresh(undefined, this.currTenantId).subscribe(() => {
          this.router.navigate(['/profile']);
          localStorage.setItem('curUserLang', user.language ?? 'en');
          this.translocoService.setActiveLang(user.language ?? 'en');
          this.dataService.changeVal('curUserLang', user.language ?? 'en');
        });
      },
      error: (data) => (this.submitErrors = data),
    });
  }

  onPasswordSubmit(dto: PasswordChangeRequest) {
    this.submitErrors = null;
    this.auth.changePassword(dto).subscribe({
      complete: () => {
        this.router.navigate(['/profile']);
      },
      error: (data) => (this.submitErrors = data),
    });
  }
}
