<p-table
  #dt1
  selectionMode="multiple"
  [(selection)]="selectedUsers"
  [metaKeySelection]="false"
  dataKey="id"
  [value]="users!"
  (selectionChange)="onSelectionChange($event)"
  [globalFilterFields]="['name', 'email', 'fullName']"
>
  <ng-template pTemplate="header">
    <!-- -->
    <tr>
      <th></th>

      <th style="min-width: 170px">
        <div class="flex">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="onGlobalFilter(dt1, $event)"
              placeholder="{{ 'search' | transloco }}"
            />
          </span>
        </div>
      </th>

      <th>
        <p-columnFilter
          field="rolesNames"
          matchMode="contains"
          [showMenu]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown
              [ngModel]="value"
              [options]="roles"
              (onChange)="filter($event.value)"
              placeholder="{{ 'select' | transloco }}"
              [showClear]="true"
            >
              <ng-template let-option pTemplate="item">
                <p-tag
                  [value]="option.label"
                  [severity]="getSeverity(option.value)"
                ></p-tag>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>

      <th>
        <p-columnFilter
          field="groupsNames"
          matchMode="contains"
          [showMenu]="false"
        >
          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
            <p-dropdown
              [ngModel]="value"
              [options]="userGroups"
              (onChange)="filter($event.value)"
              placeholder="{{ 'select' | transloco }}"
              [showClear]="true"
              [filter]="true"
            >
              <ng-template let-option pTemplate="item">
                <p-tag
                  [value]="option.label"
                  [severity]="getSeverity(option.value)"
                ></p-tag>
              </ng-template>
            </p-dropdown>
          </ng-template>
        </p-columnFilter>
      </th>

      <th></th>
    </tr>
    <!-- -->
    <tr>
      <!-- HEADERS -->
      <th><p-tableHeaderCheckbox /></th>
      <th pSortableColumn="name">
        {{ "name" | transloco }} <p-sortIcon field="name"></p-sortIcon>
      </th>
      <th>{{ "roles" | transloco }}</th>
      <th>{{ "groups" | transloco }}</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
    <tr>
      <!-- DATA -->
      <td><p-tableCheckbox [value]="user" /></td>
      <td>
        <div style="display: flex">
          <app-entity-avatar-init
            class="mt-1 me-2"
            [img]="
              blobService.getUserImagePath(
                user.imagePath,
                user.hasCompressedImage
              )
            "
            [name]="user.name"
            [surname]="user.surname"
          ></app-entity-avatar-init>
          <div>
            <div [routerLink]="['/users', user.id]">
              {{ user.name | slice : 0 : 15 }}
              {{ user.surname | slice : 0 : 15 }}
            </div>
            <small>
              <span class="txt-email">{{ user.email }}</span>
            </small>
          </div>
        </div>
      </td>
      <td>
        <span pTooltip="{{ user.rolesNames }}" tooltipPosition="top">
          {{ getFieldValue(user.rolesNames) }}</span
        >
      </td>
      <td>
        <span pTooltip="{{ user.groupsNames }}" tooltipPosition="top">{{
          getFieldValue(user.groupsNames)
        }}</span>
      </td>
      <td>
        <div style="display: flex; justify-content: end">
          <div *ngIf="!user.deletedAt" class="me-3">
            <a [routerLink]="['/users', user.id]">
              <img src="assets/icons/pencil.svg" alt="Edit" />
            </a>
          </div>
          <div *ngIf="!user.deletedAt">
            <a (click)="actionConfirmation = user" style="cursor: pointer">
              <img src="assets/icons/trash.svg" alt="Delete" />
            </a>
          </div>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
<app-confirm-delete
  id="deleteusermodal"
  *ngIf="actionConfirmation"
  [deleteCandidate]="actionConfirmation.name"
  (confirm)="handleActionClick(actionConfirmation); actionConfirmation = null"
  (cancel)="actionConfirmation = null"
></app-confirm-delete>
