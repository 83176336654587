<div class="">
  <div class="top-box backgr-gr">
    <div class="p-4 d-flex flex-wrap txt">
      <div class="d-flex">
        <div>Tenant:</div>
        <div class="ps-1 bold">{{ tenantName }}</div>
      </div>
      <div class="d-flex ps-4">
        <div>{{ "totalCollaborations" | transloco }}:</div>
        <div class="ps-1 bold">{{ collabs?.length ?? 0 }}</div>
      </div>
    </div>
  </div>

  <div class="top-box mt-4">
    <div class="row p-0 m-0 p-3 header-box">
      <div class="col-6 p-0 pe-1 d-flex align-items-center">
        <div>{{ "name" | transloco }}</div>
      </div>
      <div class="col-2 p-0 pe-1 d-flex align-items-center">
        <div>{{ "totalUsers" | transloco }}</div>
      </div>
      <div class="col-2 p-0 pe-1 d-flex align-items-center">
        <div>{{ "totalIdeas" | transloco }}</div>
      </div>
      <div class="col-2 p-0 pe-1 d-flex align-items-center">
        <div>{{ "totalComments" | transloco }}</div>
      </div>
    </div>

    <div *ngIf="collabs">
      <div *ngFor="let collab of collabs" class="row txt p-4 m-0 bott-border">
        <div class="col-6 p-0 text-break">
          <div class="bold">{{ collab.name }}</div>
        </div>

        <div class="col-2 p-0 ps-1">
          <div class="">{{ collab.usersCount }}</div>
        </div>
        <div class="col-2 p-0 ps-1">
          <div class="">{{ collab.ideasCount }}</div>
        </div>
        <div class="col-2 p-0 ps-1">
          <div class="">{{ collab.commentsCount }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
