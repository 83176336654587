<div class="row h-100">
  <div
    class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center d-grid gap-2 align-self-center"
  >
    <h4 class="mb-4" i18n="Unauthorized label">
      {{ "Unauthorized" | transloco }}
    </h4>
    <p class="text-muted mb-4" i18n="Message for unauthorised user">
      {{ "messageForUnauthorisedUser" | transloco }}
    </p>

    <a
      routerLink="/"
      class="btn btn-orange btn-block"
      i18n="Back home page button @@backHome"
      >{{ "backToHome" | transloco }}</a
    >
  </div>
</div>
