<form
  *ngIf="form"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="row">
    <div class="col-12 col-md-6 col-lg-4">
      <div class="inpt-title">
        {{ "tenant" | transloco }} {{ "name" | transloco }}
      </div>
      <div
        [appValidateState]="form.get('name')"
        class="form-group form-floating mb-3"
      >
        <input
          formControlName="name"
          type="text"
          class="inpt"
          placeholder="Tenant name"
          i18n-placeholder="Tenant name placeholder @@tenantName"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "cvr" | transloco }}</div>
        <input
          formControlName="cvr"
          type="text"
          class="inpt"
          placeholder="CVR number"
          i18n-placeholder="Tenant CVR number placeholder @@cvr"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "address" | transloco }}</div>
        <input
          formControlName="address"
          type="text"
          class="inpt"
          placeholder="Tenant address"
          i18n-placeholder="Tenant address placeholder @@address"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "mainMail" | transloco }}</div>
        <input
          formControlName="mainMail"
          type="text"
          class="inpt"
          placeholder="Main mail"
          i18n-placeholder="Main mail placeholder @@mainMail"
        />
      </div>
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "phone" | transloco }}</div>
        <input
          formControlName="phoneNumber"
          type="text"
          class="inpt"
          placeholder="Phone number"
          i18n-placeholder="Tenant phone number placeholder @@phoneNumber"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3 mb-lg-5">
        <app-datalist-select
          class="app-datalist-select-double-height"
          [options]="featureOptions"
          [multiple]="true"
          [activeValue]="form.value.featureIds"
          (activeValueChange)="updateValue('featureIds', $event)"
          label="Features"
          i18n-label="Features label"
        ></app-datalist-select>
      </div>
      <div class="form-group form-floating mb-3" *ngIf="calendarHere$">
        <div class="inpt-title">{{ "showCalendarAsList" | transloco }}</div>
        <input
          formControlName="showCalendarAsList"
          class="form-check-input"
          type="checkbox"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4 mt-md-0 mt-5">
      <div class="form-group form-floating">
        <div class="inpt-title">
          {{ "tenant" | transloco }} {{ "remarks" | transloco }}
        </div>
        <textarea
          rows="4"
          style="height: 100%"
          formControlName="remarks"
          type="text"
          class="inpt inpt-lg"
          placeholder="Tenant Remarks"
          i18n-placeholder="Tenant remarks placeholder @@remarks"
        ></textarea>
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating">
        <div class="d-flex p-0">
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
          >
            <img src="assets/img/fileInptIco.svg" />
          </div>
          <div
            class="d-flex form-floating col file-inpt displayNoneOnSmallScreens"
          >
            <input
              id="fileInput"
              type="file"
              class="form-control"
              [class.is-invalid]="
                file && img.width != 2000 && img.height != 450
              "
              placeholder="Tenant picture"
            />
            <label for="image" i18n="@@ImageFile">
              {{ "clickToUploadImage" | transloco }} (2000x450)
            </label>
          </div>
        </div>
        <div
          *ngIf="currentFileName"
          class="d-flex align-items-center pt-1 pb-2"
        >
          <div class="file-name pe-2">{{ currentFileName }}</div>

          <img
            class="cursor-pointer"
            (click)="deleteDocument()"
            src="assets/img/trash.svg"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">
          {{ "map" | transloco }} {{ "address" | transloco }}
        </div>
        <input
          formControlName="startingAddress"
          ngx-google-places-autocomplete
          [options]="myOptions"
          #palceRef="ngx-places"
          (onAddressChange)="handleAddressChange($event)"
          class="inpt"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">
          {{ "map" | transloco }} {{ "zoom" | transloco }} (0-20)
        </div>
        <input formControlName="mapZoom" class="inpt" type="number" />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "useKm" | transloco }}</div>
        <input
          formControlName="useKmAsValues"
          class="form-check-input"
          type="checkbox"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 col-lg-4">
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "allowUnauth" | transloco }}</div>
        <input
          formControlName="allowUnauthorizedAccess"
          class="form-check-input"
          type="checkbox"
        />
      </div>
    </div>

    <div
      *ngIf="authRepo.isSuperAdmin$ | async"
      class="col-12 col-md-6 col-lg-4"
    >
      <div class="form-group form-floating mb-3">
        <div class="inpt-title">{{ "partnerTenant" | transloco }}</div>
        <input
          formControlName="isPartnerTenant"
          class="form-check-input"
          type="checkbox"
          (change)="changeIsPartner($event)"
        />
      </div>
    </div>
    <div
      *ngIf="isPartner && (authRepo.isSuperAdmin$ | async)"
      class="row p-0 m-0 partnerBox"
    >
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="form-group form-floating mb-3"
          [class.is-invalid]="
            form.get('maxUsersCanCreate')?.hasError('min') ||
            form.get('maxUsersCanCreate')?.hasError('pattern')
          "
        >
          <div class="inpt-title">{{ "maxUsers" | transloco }}</div>
          <input
            formControlName="maxUsersCanCreate"
            class="inpt"
            type="number"
            min="0"
          />
        </div>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <div
          class="form-group form-floating mb-3"
          [class.is-invalid]="
            form.get('maxTenantsCanCreate')?.hasError('min') ||
            form.get('maxTenantsCanCreate')?.hasError('pattern')
          "
        >
          <div class="inpt-title">{{ "maxTenants" | transloco }}</div>
          <input
            formControlName="maxTenantsCanCreate"
            class="inpt"
            type="number"
            min="0"
          />
        </div>
      </div>
    </div>

    <div class="col-12 col-md-6 col-lg-4 mt-3">
      <div class="form-group form-floating pb-3 d-grid">
        <button
          type="submit"
          class="btn btn-orange shadow-sm"
          i18n="Save button @@save"
        >
          {{ "save" | transloco }}
        </button>
      </div>
    </div>
  </div>
</form>
