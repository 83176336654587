<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div
    class="form-group form-floating mb-3"
    [appValidateState]="editForm.get('title')"
  >
    <div class="inpt-title">{{ "title" | transloco }}</div>
    <input
      id="locationTitle"
      formControlName="title"
      class="inpt"
      placeholder="Title"
      i18n-placeholder="Title placeholder @@title"
      autocomplete="off"
    />
  </div>
  <div
    class="form-group form-floating mb-3"
    [class.is-invalid]="
      (!editForm.value?.latitude || !editForm.value?.latitude) &&
      editForm.get('address')?.touched
    "
    *ngIf="locationCheck()"
  >
    <div class="inpt-title">{{ "address" | transloco }}</div>
    <input
      id="location"
      formControlName="address"
      class="inpt"
      ngx-google-places-autocomplete
      [options]="myOptions"
      placeholder="Address"
      #palceRef="ngx-places"
      (onAddressChange)="handleAddressChange($event)"
      i18n-placeholder="Title placeholder @@CompanyAddress"
    />
  </div>
  <div
    class="col-12 col-md-12 form-group form-floating mb-2"
    [class.is-invalid]="
      editForm.get('defaultRadius')?.hasError('required') &&
      editForm.get('defaultRadius')?.touched
    "
  >
    <app-datalist-select
      class=""
      [activeValue]="editForm.get('defaultRadius')?.value"
      [options]="CirclesOptions"
      (activeValueChange)="updateValue('defaultRadius', $event)"
      [multiple]="false"
      label="Default radius on map"
      i18n-label="Default radius on map"
    ></app-datalist-select>
  </div>
  <div class="form-floating mb-2">
    <div class="inpt-title" for="" i18n="@@LastEventNotes">
      {{ "notes" | transloco }}
    </div>
    <textarea
      id="LastEventDescription"
      class="inpt inpt-lg"
      formControlName="lastEventDescription"
      type="text"
      placeholder="Notes"
      i18n-placeholder="@@LastEventNotes"
      autocomplete="off"
    ></textarea>
  </div>
  <div class="form-floating mb-2">
    <div class="inpt-title" for="LastEventDate" i18n="@@LastEventDate">
      {{ "lastEvDate" | transloco }}
    </div>
    <input
      id="LastEventDate"
      class="inpt"
      formControlName="lastEventDate"
      type="date"
      placeholder="Last Event Date"
      i18n-placeholder="@@LastEventDate"
      autocomplete="off"
    />
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
      {{ "save" | transloco }}
    </button>
  </div>
</form>
