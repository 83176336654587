import { Component, HostListener, OnInit } from '@angular/core';
import {
  IDocument,
  DocumentsRepository,
} from '../../../../state/document.repository';

import { ActivatedRoute, Router } from '@angular/router';
import { DocumentsService } from '../../../../state/document.service';
import { DocumentTypesRepository } from '../../../../state/documenttype.repository';
import { DocumentTypesService } from '../../../../state/documenttype.service';
@Component({
  selector: 'app-documenttype-from-SideMenu',
  templateUrl: './documenttype-from-SideMenu.component.html',
  styleUrls: ['./documenttype-from-SideMenu.component.scss'],
})
export class DocumenttypeFromSideMenuComponent implements OnInit {
  innerWidth = 0;
  documents: IDocument[] | null = null;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private repo: DocumentsRepository,
    private service: DocumentsService,
    public documentypesRepo: DocumentTypesRepository,
    public documenttypesService: DocumentTypesService
  ) {}

  id: string | null = null;

  ngOnInit() {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        if (this.id) {
          this.loadDocuments(this.id);
        }
      },
    });
  }

  loadDocuments(id: string) {
    this.service.getAllFrom(id).subscribe((x) => {
      this.documents = x;
    });
  }
}
