import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AuthGuard } from '../guards/auth.guard';
import { OutOfOfficeStatusPageComponent } from '../modules/out-of-office-status/pages/out-of-office-status-page/out-of-office-status-page.component';
import { TenantFeatures } from '../state/feature.repository';
import { UserRoles } from '../state/auth.repository';
import { OutOfOfficeStatusFormPageComponent } from '../modules/out-of-office-status/pages/out-of-office-status-form-page/out-of-office-status-form-page.component';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'out-of-office-status',
        component: OutOfOfficeStatusPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.User, UserRoles.TenantAdmin],
          feature: TenantFeatures.OutOfOfficeStatus,
        },
      },
      {
        path: 'out-of-office-status/new',
        component: OutOfOfficeStatusFormPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.User, UserRoles.TenantAdmin],
          feature: TenantFeatures.OutOfOfficeStatus,
        },
      },
      {
        path: 'out-of-office-status/:id',
        component: OutOfOfficeStatusFormPageComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.User, UserRoles.TenantAdmin],
          feature: TenantFeatures.OutOfOfficeStatus,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class OutOfOfficeStatusRoutingModule {}
