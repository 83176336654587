<div class="d-flex flex-column h-100" style="positon: relative">
  <div class="d-flex justify-content-between align-items-center px-2 pt-2">
    <h2 class="page-title">
      {{ "mediaLibrary" | transloco }}
    </h2>
    <button
      *ngIf="allowFilesSelection"
      type="button"
      class="btn-close"
      tabIndex="-1"
      (click)="selectedFilesConfirmation.emit([])"
    ></button>
  </div>

  <div class="d-flex flex-wrap justify-content-between px-2 mb-2">
    <div style="display: flex">
      <div class="file-upload-wrapper justify-content-between">
        <label for="file-upload" class="custom-file-upload">
          <img src="assets/img/fileInptIco.svg" alt="Upload Icon" />
          <span>{{ "uploadFile" | transloco }}</span>
        </label>
        <input
          id="file-upload"
          accept=""
          type="file"
          (change)="onUploadFileSelect($event)"
          required
        />
      </div>
      
      <div class="privateInputwraper ms-3">
        <span>{{ "privateFile" | transloco }}</span>
        <p-inputSwitch
          class="ms-2"
          [(ngModel)]="priviteFilesUploadModeActive"
          [disabled]="priviteFilesUploadModeSvitchDisabled"
        />
      </div>
    </div>
    <div class="me-4 ms-4" style="display: flex">
      <p-toggleButton
        class="me-2"
        onLabel="Private"
        offLabel="Public and Private"
        (click)="changePrivatePublickMode()"
      ></p-toggleButton>
      <p-selectButton
        [options]="filterOptions"
        optionLabel="name"
        optionValue="value"
        [(ngModel)]="selectedFilesFilter"
        (onChange)="applyAllFilters()"
      ></p-selectButton>
    </div>
  </div>

  <div class="d-flex flex-wrap pb-5" style="overflow-y: scroll">
    <div
      class="col-6 col-sm-4 col-lg-3 col-xl-2 p-2"
      *ngFor="let object of filteredLibraryObjects"
      (click)="toggleSelectFile(object)"
    >
      <div
        class="file-card"
        [class.selected]="object.isSelected"
        [class.pointer]="allowFilesSelection"
      >
      <div>
        <i *ngIf="object.isPersonaFile" class="pi pi-eye-slash privacyFile"></i>
        <span></span>
      </div>
        
        <img
          [src]="getFileImege(object)"
          class="file-icon"
          [class.h-50]="object.mediaType !== 1"
          [class.img-cover]="object.mediaType === 1"
        />
        <div class="file-info" *ngIf="object.mediaType !== 1">
          <h3>{{ object.fileName }}</h3>
          <p>{{ object.size }} KB</p>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="allowFilesSelection"
    style="position: absolute; bottom: 0; background-color: white"
    class="w-100 d-flex justify-content-end p-2"
  >
    <p-button
      label="Submit"
      icon="pi pi-check"
      (click)="confirmFileSelection()"
      [disabled]="!selectedFiles.length"
    ></p-button>
  </div>
</div>
