import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

import { UrlIdResolverService } from '../../../shared/services/url-id-resolver.service';
import { DocumentsService } from '../../../../state/document.service';
import {
  DocumentsRepository,
  IDocument,
} from '../../../../state/document.repository';

@Component({
  selector: 'app-document-editpage',
  templateUrl: './document-editpage.component.html',
  styleUrls: ['./document-editpage.component.scss'],
})
export class DocumentEditpageComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private repo: DocumentsRepository,
    private service: DocumentsService,
    private location: Location,
    private urlId: UrlIdResolverService
  ) {}

  id!: string;
  document: IDocument | null = null;
  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        this.service.loadOneDocument(`${value.get('id')}`).subscribe((x) => {
          this.document = x;
        });
      },
    });
  }
}
