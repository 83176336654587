import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as localeData from 'dayjs/plugin/localeData';
import * as utc from 'dayjs/plugin/utc';
import * as calendar from 'dayjs/plugin/calendar';
import * as fromNow from 'dayjs/plugin/relativeTime';
import * as duration from 'dayjs/plugin/duration';
import * as enGb from 'dayjs/locale/en-gb';
import * as da from 'dayjs/locale/da';
import { EnvState } from '../helpers/env-state';

const SUPPORTED = ['en-gb', 'da'];

@Injectable({
  providedIn: 'root',
})
export class DayjsService {
  constructor(env: EnvState) {
    const locale = env.locale;
    if (!SUPPORTED.includes(locale)) {
      throw `Locale <${locale}> is not included in dayjs.service.ts`;
    }
    dayjs.extend(duration);
    dayjs.extend(fromNow);
    dayjs.extend(calendar);
    dayjs.extend(utc);
    dayjs.extend(localeData);
    switch (locale) {
      case 'en-gb':
        dayjs.locale(enGb, undefined, true);
        break;
      case 'da':
        dayjs.locale(da, undefined, true);
        break;
    }
    dayjs.locale(locale);
  }

  dayjs = dayjs;
}
