import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

import { IContact } from './companies.repository';

export const ContactsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Name' },
  { label: $localize`:Sort label Email:Email`, property: 'Email' },
];

@Injectable({ providedIn: 'root' })
export class ContactsRepository extends BaseRepository<IContact> {
  constructor() {
    super('ContactsSortOptions', ContactsSortOptions);
  }
}
