import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Post,
  PostsRepository,
  skipWhilePostsCached,
  trackPostRequestsStatus,
} from './posts.repository';
import { switchMap, tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, of } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';
import {
  DocumentTypesRepository,
  IDocumentType,
} from './documenttype.repository';
import { IDefaultDocumentType } from './defaultdocumenttype.repository';
import { SortState } from '../modules/shared/pipes/sort.pipe';

const API = '/api/documenttypes';

@Injectable({ providedIn: 'root' })
export class DocumentTypesService extends BaseService<IDocumentType> {
  constructor(http: HttpClient, repo: DocumentTypesRepository) {
    super(API, http, repo);
  }

  sortCustom(sortBy: SortState, search?: string) {
    this.repo.setSort(sortBy);
    return this.reloadPageCustom(search);
  }

  postDocumentType(entity: Partial<IDocumentType>) {
    return this.http.post<any>(API + '/postDocumentType', entity);
  }
  loadDefaultDoctype(moduleName: string) {
    return this.http.get<IDefaultDocumentType>(
      API + `/loadDefaultDoctype/${moduleName}`
    );
  }
  loadAll() {
    return this.http.get<IDocumentType[]>(API + `/getDocumentTypes`);
  }
  loadShown() {
    return this.http
      .get<IDocumentType[]>(API + `/getDocumentTypesShown`)
      .pipe(tap((x) => this.repo.set(x)));
  }
  loadNonSys() {
    return this.http.get<PaginationData & { data: IDocumentType[] }>(
      API + `/getDocumentTypesNonSys`
    );
  }
  loadSys() {
    return this.http.get<IDocumentType[]>(API + `/getDocumentTypesSys`);
  }
  deleteCustom(id: string) {
    return this.http.delete<any>(API + `/deleteDocumentType/` + id);
  }
  loadOneDocumenttype(id: string) {
    return this.http.get<any>(API + `/loadOneDocumenttype/` + id);
  }
  updateDocumenttype(entity: Partial<IDocumentType>) {
    return this.http.patch<any>(API + `/updateDocumentType`, entity);
  }

  loadPageCustom(
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE,
    search: string
  ): Observable<PaginationData & { data: IDocumentType[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: IDocumentType[] }>(
        `/api/documenttypes/getDocumentTypesNonSys?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  loadPageSearchCustom(
    search: string,
    page: number,
    take: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<PaginationData & { data: IDocumentType[] }> {
    const sortOrder = this.repo.getSort();
    const query = [
      `page=${page}`,
      `take=${take}`,
      `sort=${sortOrder.parameter.property}`,
      `direction=${sortOrder.direction}`,
      `search=${search}`,
    ];
    this.repo.setPage(page);
    return this.http
      .get<PaginationData & { data: IDocumentType[] }>(
        `/api/documenttypes/getDocumentTypesNonSys?${query.join('&')}`
      )
      .pipe(
        tap((res) => this.repo.addPage(res)),
        this.repo.track(),
        this.repo.skipWhilePageCached(page)
      );
  }

  reloadPageCustom(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: IDocumentType[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(data.currentPage, data.perPage, search);
    }
    return this.loadPageCustom(1, defaultTake, search);
  }
  docSearchRelodePage(
    search: string = '',
    defaultTake: number = DEFAULT_ENTITIES_PER_PAGE
  ): Observable<(PaginationData & { data: IDocumentType[] }) | null> {
    const data = this.repo.getPaginationData();
    if (data && Object.keys(data.pages).length) {
      this.repo.clearPages();
      return this.loadPageCustom(1, data.perPage, search);
    }
    return this.loadPageCustom(1, defaultTake, search);
  }
}
