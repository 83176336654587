import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';

export interface IFolder {
  name: string;
  id: string;
  parentFolder: string;
  updatedAt: string;
  startDate: string;
  parentFolderId: string;
}

export const FoldersSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
  {
    label: $localize`:Sort label Updated Date:Updated Date`,
    property: 'updatedAt',
  },
  {
    label: $localize`:Sort label Started Date:Started Date`,
    property: 'startDate',
  },
];

@Injectable({ providedIn: 'root' })
export class FoldersRepository extends BaseRepository<IFolder> {
  constructor() {
    super('projects', FoldersSortOptions);
  }
}
