import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { MailComponent } from '../modules/mail/mail/mail.component';
import { MailAdminComponent } from '../modules/mail/mail-admin/mail-admin.component';

import { MailPostComponent } from '../modules/mail/mail-post/mail-post.component';
import { UserRoles } from '../state/auth.repository';
import { MailEditComponent } from '../modules/mail/mail-edit/mail-edit.component';
import { TenantFeatures } from '../state/feature.repository';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'messages',
        component: MailComponent,
        canActivate: [AuthGuard],

        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Messages,
        },
      },
      {
        path: 'admin/messages/new',
        component: MailPostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Messages,
        },
      },
      {
        path: 'admin/messages/edit/:id',
        component: MailEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Messages,
        },
      },
      {
        path: 'admin/messages',
        component: MailAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Messages,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class MailRoutingModule {}
