<figure class="avatar avatar-40 rounded-circle">
  <img
    *ngIf="img"
    src="{{ img }}"
    onerror="this.style.display='alt'"
    src="{{ img }}"
    [alt]="name"
  />
  <i class="initials" *ngIf="!img && !initialSurName && initialsName">{{
    initialsName
  }}</i>
  <i class="initials" *ngIf="!img && initialSurName && initialsName">{{
    initialsName + initialSurName
  }}</i>
</figure>
