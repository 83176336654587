<div *ngIf="post">
  <div class="row justify-content-center">
    <div
      class="row col-12 col-xl-10 col-lg-11 pb-3 pt-0 pt-sm-3 ps-0 pe-0 d-flex justify-content-center"
    >
      <div>
        <div class="date mb-2">
          {{ post.createdAt | date : "d MMM y" }}
        </div>
        <div class="mainTitle text-breaktext-break pb-4">
          {{ post.title }}
        </div>
        <div *ngIf="post.documents && post.documents[0]" class="mb-3 mb-md-5">
          <video
            class="video"
            *ngIf="post.documents[0].video"
            muted
            preload="true"
            controls
          >
            <source
              [src]="
                blobService.getFilePathFromBlob(post.documents[0].video, true)
              "
              type="video/mp4"
            />
            <source
              [src]="
                blobService.getFilePathFromBlob(post.documents[0].video, true)
              "
              type="video/ogg"
            />
            {{ "yourBrowserDoesNotSupportTheVideoTag" | transloco }}
          </video>
          <iframe
            *ngIf="!post.documents[0].video && sanitizedUrl"
            class="urlvideo"
            [src]="sanitizedUrl"
            allowscriptaccess="always"
            frameborder="0"
            playsinline
          >
          </iframe>
          <img
            *ngIf="!post.documents[0].video && !sanitizedUrl"
            class="img"
            src="{{
              post.documents && post.documents[0] && post.documents[0].image
                ? blobService.getCompressedImagePath(
                    post.documents[0].image,
                    800,
                    true,
                    post.documents[0].hasCompressedImage800
                  )
                : DEFAULT_POST_IMAGE
            }}"
            alt="Image Title"
          />
        </div>
        <div>
          <p class="text-break content" [innerHTML]="post.content"></p>
        </div>
      </div>
      <!--<div>
        <div class="d-flex  align-items-center p-0 mt-5 " style="background: #EAECF0; border-radius: 22px;">
          <div class="d-flex col-auto align-items-center justify-content-center circle p-0">
            <svg width="15" height="11" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 6.5L16 6.5M16 6.5L10.375 1M16 6.5L10.375 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>
          <div class="d-flex col justify-content-center ">
            Mark as read
          </div>
        </div>

      </div>-->
      <!-- <hr class="mt-sm-5 mt-4" />
      <div>
        <div class="d-flex flex-row align-items-center">
          <div class="comments">{{ "comments" | transloco }}</div>
          <div
            class="ms-2 d-flex align-items-center justify-content-center purpcircle"
          >
            {{ post.comments.length }}
          </div>
        </div>
        <div
          *ngFor="let card of post.comments"
          class="d-flex flex-row align-items-start"
        >
          <img
            class="rounded-circle me-2"
            src="{{ card.imagePath }} || DEFAULT_IMAGE "
            alt="avatar"
            width="48"
            height="48"
          />
        </div>
      </div> -->
    </div>
  </div>
</div>
