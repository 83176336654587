import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthRepository } from 'app/state/auth.repository';
import { AuthService } from '../../../../state/auth.service';
import { UiRepository } from '../../../../state/ui.repository';
import { EnvState } from '../../../shared/helpers/env-state';
import { GeneralDataRepository } from '../../../../helpers/repository/general-data.repository';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { GeneralAppDataDto } from 'app/api/models/auth/sideBarInfoDto';
import { ActiveUserData } from 'app/api/models/auth/activeUserData';

@Component({
  selector: 'app-menuuser-card',
  templateUrl: './menuuser-card.component.html',
  styleUrls: ['./menuuser-card.component.scss'],
})
export class MenuUserCardComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';

  public activeUserData: ActiveUserData = new ActiveUserData();
  public generalAppData: GeneralAppDataDto = new GeneralAppDataDto();
  @Output() profileClick = new EventEmitter<Event>();

  constructor(
    private auth: AuthService,
    public ui: UiRepository,
    private router: Router,
    public env: EnvState,
    private sidebarRepo: GeneralDataRepository,
    public blobService: BlobStorageService,
    public generalDataRepo: GeneralDataRepository
  ) {
    this.generalDataRepo.allGeneralData$.subscribe((data) => {
      this.generalAppData = data;
    });
  }

  ngOnInit(): void {}

  logout() {
    this.auth.logout();
    this.generalDataRepo.updateAllGeneralData(true);
    return false;
  }

  handleClick(event: any) {
    this.profileClick.emit(event);
    this.menuclose();
  }

  menuclose() {
    this.ui.toggleMenuOpened(false);
  }

  getUserName() {
    return this.activeUserData.displayName;
  }
}
