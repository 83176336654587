import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  Form: FormBuilder | any;
  @Output() searchChange = new EventEmitter<string>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      filter: null,
    });
  }

  search() {
    this.searchChange.emit(this.Form?.value.filter);
  }
}
