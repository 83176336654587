import { Pipe, PipeTransform } from '@angular/core';
import { Duration } from 'dayjs/plugin/duration';
import { DayjsService } from '../services/dayjs.service';

@Pipe({
  name: 'timing',
})
export class TimingPipe implements PipeTransform {
  constructor(public ngDay: DayjsService) {}

  transform(value: Date | Duration | number | null): string {
    if (typeof value === 'number') {
      value = this.ngDay.dayjs.duration(value, 'minutes');
    }
    if (!value) {
      return '';
    }
    if (this.ngDay.dayjs.isDuration(value)) {
      return value.humanize();
    }
    return this.ngDay.dayjs.utc(value).fromNow();
  }
}
