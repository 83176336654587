import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { DatalistOption } from './abstract/base.repository';

export interface ICompanyLocation {
  id: string;
  title: string;
  locationTitle: string;
  order: number;
  address: string;
  latitude: number;
  longitude: number;
  checked: boolean;
  circleSizeLabel: string;
  circleSize: number;
  tenantId: string;
  defaultRadius: number;
  radius: number;
  companyId: string;
  lastEventDate: Date | null;
  lastEventDescription: string;
  formatedLastEventDate: string;
  shown: boolean;
}

export interface ICompanyMarker {
  id: string;
  title: string;
  description: string;
  updatedAt: Date;
  companyLocations: ICompanyLocation[];
  address: string;
  clientSince: Date;
  acquiredBy: string;
  formatedClientSince: string;
  tenantId: string;
  allCompaniesSelected: boolean;
  aeAdvisor: boolean;
  locationsHiden: boolean;
}

export interface IMapInfo {
  id: string;
  zoom: number;
  startingAddress: string;
  startingLat: number;
  startingLng: number;
  useKmAsValues: boolean;
}

export const CirclesOptions: DatalistOption[] = [
  { label: '3 miles', value: 4828.03 },
  { label: '4 miles', value: 6437.37 },
  { label: '5 miles', value: 8046.72 },
  { label: '6 miles', value: 9656.06 },
  { label: '7 miles', value: 11265.4 },
  { label: '8 miles', value: 12874.75 },
  { label: '9 miles', value: 14484.09 },
  { label: '10 miles', value: 16093.44 },
];

export const CirclesOptionsKm: DatalistOption[] = [
  { label: '3 KM', value: 3000.0 },
  { label: '4 KM', value: 4000.0 },
  { label: '5 KM', value: 5000.0 },
  { label: '6 KM', value: 6000.0 },
  { label: '7 KM', value: 7000.0 },
  { label: '8 KM', value: 8000.0 },
  { label: '9 KM', value: 9000.0 },
  { label: '10 KM', value: 10000.0 },
];

export const CompaniesOptions: SortOption[] = [
  { label: $localize`:Sort label Title:Title`, property: 'Title' },
  {
    label: $localize`:Sort label Updated Date:Updated Date`,
    property: 'updatedAt',
  },
];

@Injectable({ providedIn: 'root' })
export class UnauthorizedCompaniesRepository {
  constructor() {}
}
