import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { ImportedUser } from 'app/state/users.repository';

@Component({
  selector: 'app-import-users-modal',
  templateUrl: './import-users-modal.component.html',
  styleUrls: ['./import-users-modal.component.scss'],
})
export class ImportUsersModalComponent implements OnInit {
  importedUsers: ImportedUser[] | undefined;
  filteredUsers: ImportedUser[] | undefined;
  withErrors: boolean | null = null;
  waitingForImportResponse$: boolean = false;
  @Input()
  set importedUsersSet(value: ImportedUser[] | undefined) {
    this.importedUsers = value;
    this.filteredUsers = value;
    this.withErrors = !!value?.find((x) => x.hasErrors);
  }
  @Output() cancel = new EventEmitter();
  @Output() submitImport = new EventEmitter<ImportedUser[]>();
  @Input() set waitingForImportResponseSet(val: boolean) {
    this.waitingForImportResponse$ = val;
  }

  importFilter: DatalistOption[] = [
    { label: 'Has Errors', value: 'HasErrors' },
    { label: 'Is Edited', value: 'IsEdited' },
  ];

  constructor() {}

  ngOnInit() {}

  applySort(value: any) {
    if (typeof value == 'string') {
      switch (value) {
        case 'HasErrors':
          this.filteredUsers = this.filteredUsers = this.importedUsers?.filter(
            (x) => x.hasErrors
          );
          break;
        case 'IsEdited':
          this.filteredUsers = this.filteredUsers = this.importedUsers?.filter(
            (x) => x.isEdited
          );
      }
    } else {
      this.filteredUsers = this.importedUsers;
    }
  }
}
