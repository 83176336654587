import { Component, Input, OnInit } from '@angular/core';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { ILogWeek } from 'app/state/timelogs.repository';
import { TimelogsService } from 'app/state/timelogs.service';
import { IProjectTotalByMonth } from 'app/state/timelogsTasks.repository';
import { TimelogsTasksService } from 'app/state/timelogsTasks.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-timelogs-projects-overview-by-month',
  templateUrl: './timelogs-projects-overview-by-month.component.html',
  styleUrls: ['./timelogs-projects-overview-by-month.component.scss'],
})
export class TimelogsProjectsOverviewByMonthComponent implements OnInit {
  MonthsOptions: DatalistOption[] | null = null;
  selectedMonth: ILogWeek | null = null;
  allMonths: ILogWeek[] = [];
  projects: IProjectTotalByMonth[] | null = null;

  @Input() set months(value: ILogWeek[] | null) {
    if (!value) {
      this.MonthsOptions = null;
    } else {
      this.MonthsOptions = value.map((x) => ({
        value: x.label,
        label: x.label,
      }));
    }
  }

  constructor(
    private service: TimelogsTasksService,
    private timelogsService: TimelogsService
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.timelogsService.getMonthsOptions().subscribe((x) => {
      this.months = x;
      this.allMonths = x;
      this.selectedMonth = x[0];
      if (this.selectedMonth) {
        var startSelectedMonth = dayjs(this.selectedMonth?.start)
          .add(11, 'hour')
          .toISOString();
        var endSelectedMonth = dayjs(this.selectedMonth?.end)
          .add(11, 'hour')
          .toISOString();
      } else {
        console.log('no curent week from database so create in angular');
        var startSelectedMonth = dayjs()
          .startOf('month')
          .add(11, 'hour')
          .toISOString();
        var endSelectedMonth = dayjs()
          .endOf('month')
          .add(11, 'hour')
          .toISOString();
      }

      this.service
        .getTotalTimeMonth(
          startSelectedMonth.slice(0, 10),
          endSelectedMonth.slice(0, 10)
        )
        .subscribe((x) => {
          this.projects = x;
        });
    });
  }

  changeinput(event: any) {
    if (event) {
      let monthToFind = this.allMonths?.find((x) => x.label == event);
      if (monthToFind) this.selectedMonth = monthToFind;

      var startSelectedMonth = dayjs(this.selectedMonth?.start).toISOString();
      var endselectedMonth = dayjs(this.selectedMonth?.end).toISOString();
      if (this.selectedMonth) {
        var startSelectedMonth = dayjs(this.selectedMonth?.start)
          .add(11, 'hour')
          .toISOString();
        var endselectedMonth = dayjs(this.selectedMonth?.end)
          .add(11, 'hour')
          .toISOString();
      } else {
        console.log('no curent week from database so create in angular');
        var startSelectedMonth = dayjs()
          .startOf('month')
          .add(11, 'hour')
          .toISOString();
        var endselectedMonth = dayjs()
          .endOf('month')
          .add(11, 'hour')
          .toISOString();
      }
      this.service
        .getTotalTimeMonth(
          startSelectedMonth.slice(0, 10),
          endselectedMonth.slice(0, 10)
        )
        .subscribe((x) => {
          this.projects = x;
        });
    }
  }
}
