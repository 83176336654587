import { TenantFeatures } from 'app/state/feature.repository';
import { Tenant } from 'app/state/tenants.repository';
import { ActiveUserData } from './activeUserData';

export class GeneralAppDataDto {
  isTenantAdmin: boolean = false;
  isAnyAdmin: boolean = false;
  isSuperAdmin: boolean = false;
  isCoach: boolean = false;
  features: Array<TenantFeatures> = [];
  tenants: Array<Tenant> = [];
  tenantSet: boolean = false;
  gdprConfirmed: boolean = false;
  imageLogosrc: string = '/assets/img/header_logo.png';
  logoDownloaded: boolean = false;

  activeUserData: ActiveUserData = new ActiveUserData();
}

