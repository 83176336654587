import { Directive, HostBinding, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appValidateState]',
})
export class ValidateStateDirective {
  @Input() appValidateState: AbstractControl | null = null;

  @HostBinding('class.is-valid')
  get isValid(): boolean {
    return (
      !!this.appValidateState &&
      this.appValidateState.touched &&
      this.appValidateState.valid
    );
  }

  @HostBinding('class.is-invalid')
  get isInvalid(): boolean {
    return (
      !!this.appValidateState &&
      this.appValidateState.touched &&
      !this.appValidateState.valid
    );
  }
}
