<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "projectsList" | transloco }}
      </h2>
    </div>
    <div
      *ngIf="innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <a
        routerLink="/admin/timelogs/project/new"
        class="btn btn-lg orange-dark text-white"
      >
        <img src="assets/icons/icon-add.svg" alt="Add" />
        <span class="text-btn-add">{{ "add" | transloco }}</span>
      </a>
    </div>

    <div class="btn-group btn-group-lg mb-4" role="group">
      <button disabled class="btn btn-orange">
        {{ "projects" | transloco }}
      </button>
      <button class="btn btn-orange" routerLink="/admin/timelogs/task">
        {{ "tasks" | transloco }}
      </button>
    </div>
    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 50%"
        (searchChange)="setFilter($event)"
      ></app-search>
      <app-sort-dropdown
        class="col-auto"
        [sortingOptions]="sortOptions"
        [sorting]="repo.sort$ | async"
        (sortingChange)="service.sort($event, searchFilter).subscribe()"
      ></app-sort-dropdown>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-6 col-6 d-flex psm0">
            <input class="check form-check-input" type="checkbox" />
            <span class="txt mt-1 ms-2">{{ "name" | transloco }} </span>
          </div>
          <div class="col-sm-6 col-6">
            <span class="txt me-1">{{ "description" | transloco }} </span
            ><svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.05992 5.99967C6.21665 5.55412 6.52602 5.17841 6.93322 4.9391C7.34042 4.69978 7.81918 4.6123 8.2847 4.69215C8.75022 4.772 9.17246 5.01402 9.47664 5.37536C9.78081 5.7367 9.94729 6.19402 9.94659 6.66634C9.94659 7.99967 7.94659 8.66634 7.94659 8.66634M7.99992 11.333H8.00659M14.6666 7.99967C14.6666 11.6816 11.6818 14.6663 7.99992 14.6663C4.31802 14.6663 1.33325 11.6816 1.33325 7.99967C1.33325 4.31778 4.31802 1.33301 7.99992 1.33301C11.6818 1.33301 14.6666 4.31778 14.6666 7.99967Z"
                stroke="#98A2B3"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <br />
      <div
        *ngFor="let project of repo.page$ | async"
        class="row brd"
        style="margin-left: 0.1rem"
      >
        <div class="col-sm-6 col-6 pt-3 pb-3 d-flex align-items-center psm0">
          <input class="check form-check-input me-2" type="checkbox" />

          <div class="txt-name">
            <div
              class="text-break"
              [routerLink]="['/admin/timelogs/project/edit/', project.id]"
            >
              {{ project.name && project.name | slice : 0 : 50 }}
              <span *ngIf="project.name && project.name.length > 50">...</span>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-6 d-flex align-items-center text-break">
          <span class="role"
            >{{ project.description && project.description | slice : 0 : 50
            }}<span
              *ngIf="project.description && project.description.length > 50"
              >...</span
            >
          </span>
        </div>
        <div
          *ngIf="innerWidth > 575"
          class="col-2 d-flex align-items-center justify-content-end"
        >
          <div>
            <a
              class="btn btn-md btn-link w-100"
              [routerLink]="['/admin/timelogs/project/edit/', project.id]"
            >
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.39662 15.0963C1.43491 14.7517 1.45405 14.5794 1.50618 14.4184C1.55243 14.2755 1.61778 14.1396 1.70045 14.0142C1.79363 13.8729 1.91621 13.7503 2.16136 13.5052L13.1666 2.49992C14.0871 1.57945 15.5795 1.57945 16.4999 2.49993C17.4204 3.4204 17.4204 4.91279 16.4999 5.83326L5.49469 16.8385C5.24954 17.0836 5.12696 17.2062 4.98566 17.2994C4.86029 17.3821 4.72433 17.4474 4.58146 17.4937C4.42042 17.5458 4.24813 17.5649 3.90356 17.6032L1.08325 17.9166L1.39662 15.0963Z"
                  stroke="#475467"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
          <div>
            <button
              type="button"
              class="btn btn-md btn-link w-100 text-danger"
              data-bs-target="#deleteusermodal"
              (click)="deleteConfirmation = project"
              title="{{ 'delete' | transloco }}"
              i18n-title="Delete button"
            >
              <svg
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3333 5.00033V4.33366C12.3333 3.40024 12.3333 2.93353 12.1517 2.57701C11.9919 2.2634 11.7369 2.00844 11.4233 1.84865C11.0668 1.66699 10.6001 1.66699 9.66667 1.66699H8.33333C7.39991 1.66699 6.9332 1.66699 6.57668 1.84865C6.26308 2.00844 6.00811 2.2634 5.84832 2.57701C5.66667 2.93353 5.66667 3.40024 5.66667 4.33366V5.00033M7.33333 9.58366V13.7503M10.6667 9.58366V13.7503M1.5 5.00033H16.5M14.8333 5.00033V14.3337C14.8333 15.7338 14.8333 16.4339 14.5608 16.9686C14.3212 17.439 13.9387 17.8215 13.4683 18.0612C12.9335 18.3337 12.2335 18.3337 10.8333 18.3337H7.16667C5.76654 18.3337 5.06647 18.3337 4.53169 18.0612C4.06129 17.8215 3.67883 17.439 3.43915 16.9686C3.16667 16.4339 3.16667 15.7338 3.16667 14.3337V5.00033"
                  stroke="#475467"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <br />

      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service.loadFilterPage(searchFilter, $event).subscribe()
          "
        ></app-custom-pagination>

        <div
          class="w-100 d-flex justify-content-end add"
          *ngIf="innerWidth < 1050"
        >
          <a
            routerLink="/admin/timelogs/project/new"
            class="btn btn-lg orange-dark text-white"
          >
            <img src="assets/icons/icon-add.svg" alt="Add" />
            <span class="text-btn-add">{{ "add" | transloco }}</span>
          </a>
        </div>
      </div>
    </div>
    <app-confirm-delete
      id="deleteusermodal"
      *ngIf="deleteConfirmation"
      [deleteCandidate]="deleteConfirmation.name"
      (confirm)="
        handleDeleteProjectClick(deleteConfirmation); deleteConfirmation = null
      "
      (cancel)="deleteConfirmation = null"
    ></app-confirm-delete>
  </div>
</div>
