import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TenantMsGraphOptions } from 'app/api/models/tenant/TenantMsGraphOptions';

@Component({
  selector: 'app-tenant-ms-graph-edit-form',
  templateUrl: './tenant-ms-graph-edit-form.component.html',
  styleUrls: ['./tenant-ms-graph-edit-form.component.scss'],
})
export class TenantMsGraphEditFormComponent {
  @Input() msGraphOptions: TenantMsGraphOptions | null = null;
  @Output() optionsSubmit = new EventEmitter<Partial<TenantMsGraphOptions>>();
  @Output() sendTestEmail = new EventEmitter<Partial<TenantMsGraphOptions>>();

  Form?: UntypedFormGroup;
  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.Form = this.formBuilder.group({
      msGraphClientId: [
        this.msGraphOptions?.msGraphClientId,
        Validators.required,
      ],
      msGraphClientSecret: [
        this.msGraphOptions?.msGraphClientSecret,
        Validators.required,
      ],
      msGraphFromMail: [
        this.msGraphOptions?.msGraphFromMail,
        Validators.required,
      ],
      msGraphSenderId: [
        this.msGraphOptions?.msGraphSenderId,
        Validators.required,
      ],
      msGraphTenantId: [
        this.msGraphOptions?.msGraphTenantId,
        Validators.required,
      ],
      useTenantMsGraphOptions: [
        this.msGraphOptions?.useTenantMsGraphOptions ?? false,
        Validators.required,
      ],
    });
  }

  formResult() {
    if (this.Form && !this.Form.valid) {
      this.Form.markAllAsTouched();
      return;
    }
    return {
      msGraphClientId: this.Form?.value.msGraphClientId,
      msGraphClientSecret: this.Form?.value.msGraphClientSecret,
      msGraphFromMail: this.Form?.value.msGraphFromMail,
      msGraphSenderId: this.Form?.value.msGraphSenderId,
      msGraphTenantId: this.Form?.value.msGraphTenantId,
      useTenantMsGraphOptions: this.Form?.value.useTenantMsGraphOptions,
    };
  }
}
