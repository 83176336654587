import { Component, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import {
  TimelogsRepository,
  daysTotal,
  projectTasksTotal,
  ILogWeek,
} from '../../../state/timelogs.repository';
import { TimelogsService } from '../../../state/timelogs.service';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-timelogs-projects-overview',
  templateUrl: './timelogs-projects-overview.component.html',
  styleUrls: ['./timelogs-projects-overview.component.scss'],
})
export class TimelogsProjectsOverviewComponent implements OnInit {
  selectedWeek: ILogWeek | null = null;
  daysTotals: daysTotal[] = [];
  projectsTotals: projectTasksTotal[] = [];
  WeeksOptions: DatalistOption[] | null = null;
  allWeeks: ILogWeek[] = [];

  @Input() set weeks(value: ILogWeek[] | null) {
    if (!value) {
      this.WeeksOptions = null;
    } else {
      this.WeeksOptions = value.map((x) => ({
        value: x.label,
        label: x.label,
      }));
    }
  }

  constructor(
    public service: TimelogsService,
    public repo: TimelogsRepository
  ) {}

  ngOnInit(): void {
    this.service.getWeeksOptions().subscribe((x) => {
      this.weeks = x;
      this.allWeeks = x;
      this.selectedWeek = x[0];
      if (this.selectedWeek) {
        var startSelectedWeek = dayjs(this.selectedWeek?.start)
          .add(11, 'hour')
          .toISOString();
        var endSelectedWeek = dayjs(this.selectedWeek?.end)
          .add(11, 'hour')
          .toISOString();
      } else {
        var startSelectedWeek = dayjs()
          .startOf('week')
          .add(11, 'hour')
          .toISOString();
        var endSelectedWeek = dayjs()
          .endOf('week')
          .add(11, 'hour')
          .toISOString();
      }
      this.service
        .getTimeLogsOverviewProjects(
          startSelectedWeek.slice(0, 10),
          endSelectedWeek.slice(0, 10)
        )
        .subscribe((x) => {
          this.projectsTotals = x;
        });
    });
  }

  changeinput(event: any) {
    if (event) {
      let weekToFind = this.allWeeks?.find((x) => x.label == event);
      if (weekToFind) this.selectedWeek = weekToFind;
      var startSelectedWeek = dayjs(this.selectedWeek?.start)
        .add(11, 'hour')
        .toISOString();
      var endSelectedWeek = dayjs(this.selectedWeek?.end)
        .add(11, 'hour')
        .toISOString();
      this.service
        .getTimeLogsOverviewProjects(
          startSelectedWeek.slice(0, 10),
          endSelectedWeek.slice(0, 10)
        )
        .subscribe((x) => (this.projectsTotals = x));
    }
  }
}
