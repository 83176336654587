import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationData } from '@ngneat/elf-pagination';
import { Observable, tap } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

import {
  CompaniesRepository,
  ICompanyLocation,
  ICompanyMarker,
  IMapInfo,
} from './companies.repository';

const API = '/api/companies';

@Injectable({ providedIn: 'root' })
export class CompaniesService extends BaseService<ICompanyMarker> {
  constructor(http: HttpClient, repo: CompaniesRepository) {
    super(API, http, repo);
  }

  postCompany(entity: Partial<ICompanyMarker>) {
    return this.http.post<ICompanyMarker>(API + '/postCompany', entity);
  }

  search(searchString: string) {
    return this.http.get<ICompanyMarker[]>(API + '/search/' + searchString);
  }

  loadAll() {
    return this.http.get<ICompanyMarker[]>(API + '/loadAll');
  }

  loadMapInfo() {
    return this.http.get<IMapInfo>(API + '/loadMapInfo');
  }

  loadOneCompany(id: string) {
    return this.http.get<ICompanyMarker>(API + '/loadOneCompany/' + id);
  }

  updateCompany(entity: Partial<ICompanyMarker>) {
    return this.http.patch<any>(API + '/updateCompany', entity);
  }

  updateLocaion(entity: Partial<ICompanyLocation>) {
    return this.http.patch<any>(API + '/UpdateLocationRadius', entity);
  }
}
