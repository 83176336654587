import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IMail,
  MailRepository,
  skipWhilePostsCached,
  trackPostRequestsStatus,
} from './mail.repository';
import { tap } from 'rxjs/operators';
import { PaginationData } from '@ngneat/elf-pagination';
import { User } from './users.repository';
import { Observable } from 'rxjs';
import {
  BaseService,
  DEFAULT_ENTITIES_PER_PAGE,
} from './abstract/base.service';

const API = '/api/messages';

@Injectable({ providedIn: 'root' })
export class MailService extends BaseService<IMail> {
  constructor(http: HttpClient, repo: MailRepository) {
    super(API, http, repo);
  }

  load() {
    return this.http.get<PaginationData & { data: IMail[] }>(API);
  }

  loadAll() {
    return this.http.get<IMail[]>(API + '/all');
  }

  loadOneForAdmin(id: string) {
    return this.http.get<IMail>(`${API}/${id}`);
  }

  createArea(post: Partial<IMail>) {
    return this.http.post<IMail>(API, post);
  }

  update(id: string, post: Partial<IMail>) {
    return this.http.put<IMail>(`/api/messages/edit/${id}`, post);
  }
}
