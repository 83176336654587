import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { SearchInputComponent } from '../search-input/search-input.component';

export type MultipleOptions = {
  options: DatalistOption[] | null;
  activeValue: string[] | null;
};

export type Option = {
  option: DatalistOption;
  checked: boolean;
};

@Component({
  selector: 'app-multiple-datalist-modal',
  templateUrl: './multiple-datalist-modal.component.html',
  styleUrls: ['./multiple-datalist-modal.component.scss'],
})
export class MultipleDatalistModalComponent
  implements AfterViewInit, OnDestroy
{
  options: Option[] | null = null;
  modalOptions: Option[] | null = null;
  filteredModalOptions: Option[] | null = null;
  activeOptions: Option[] | null = null;
  activeOptionsString: string | null = null;
  allChecked: boolean | undefined = false;
  readonly DEFAULT_IMAGE = 'assets/img/user_picture.png';
  maxTilesAllowed: number = 0;
  @ViewChild(SearchInputComponent) searchInputComponent!: SearchInputComponent;

  @ViewChild('activeOptionsConatiner', { static: false })
  activeOptionsContainer!: ElementRef;

  @Input() forUsers: boolean = false;
  @Input() set inputOptions(input: MultipleOptions | null) {
    if (input && input.options) {
      this.options = input.options!.map((option) => ({
        option,
        checked:
          input.activeValue && input.activeValue.includes(option.value)
            ? true
            : false,
      }));
      this.setOptions();
    }
  }
  @Input() datalistId: string | null = null;
  @Input() withTranslations: boolean = false;
  @Input() forFilter = false;
  @Output() emitSelectedOptions: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();
  resizeObserver!: ResizeObserver;

  constructor(public blobService: BlobStorageService) {}

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          this.setMaxTiles();
        }
      }
    });

    if (this.activeOptionsContainer) {
      this.resizeObserver.observe(this.activeOptionsContainer.nativeElement);
    }
  }

  setMaxTiles() {
    this.maxTilesAllowed = Math.floor(
      (this.activeOptionsContainer.nativeElement.offsetWidth - 75) / 127
    );
  }

  setOptions() {
    this.modalOptions = JSON.parse(JSON.stringify(this.options));
    this.filteredModalOptions = JSON.parse(JSON.stringify(this.options));
    if (this.options) {
      this.activeOptions = this.options!.filter((x) => x.checked);
      this.allChecked = this.modalOptions?.every((x) => x.checked);
      this.activeOptionsString = this.activeOptions
        .slice(this.maxTilesAllowed, this.maxTilesAllowed + 30)
        .map((x) => x.option.label)
        .join(', ');
      this.sortOptions();
    }
  }

  sortOptions() {
    this.filteredModalOptions?.sort((a, b) => {
      if (a.checked === b.checked && a.option.label && b.option.label) {
        return a.option.label.localeCompare(b.option.label);
      }
      return a.checked ? -1 : 1;
    });
  }

  saveOptions() {
    this.options = this.modalOptions;
    this.activeOptions = this.options!.filter((x) => x.checked);
    let res = this.options
      ?.filter((x) => x.checked)
      .map((x) => {
        return x.option.value;
      });
    this.emitSelectedOptions.emit(res);
    this.resetSearch();
  }

  checkEvery(checked: boolean) {
    this.modalOptions = this.filteredModalOptions = this.modalOptions!.map(
      (option) => ({
        ...option,
        checked,
      })
    );
    this.sortOptions();
  }

  check(item: Option, status: boolean) {
    if (this.modalOptions) {
      let i1 = this.modalOptions.find(
        (x) => x.option.value == item.option.value
      );
      if (i1) {
        i1.checked = status;
      }
    }
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  resetSearch() {
    this.searchInputComponent.reset();
  }
}
