import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { User } from './users.repository';

export interface IProject {
  id: string;
  name: string;
  description: string;
  applicationUsers: User[];
  applicationUsersIds: string[];
  tasksIds: string[];
  createdAt: Date;
  startDate: Date;
  updatedAt: Date;
  deletedAt: Date;
  tasks: ITask[];
  projectTasks: ITask[];
  taskNames: String[];
  dateString: string;
  totalHours: string;
  usersTotals: IUsersHours[];
}

export interface ITask {
  id: string;
  name: string;
  marks: IMark[];
  total: number;
  totalMinutes: number;
}
export interface IMark {
  id: string;
  date: string;
  hours: any;
  dateString: string;
  taskName: string;
  taskId: string;
  projectId: string;
  hoursTest: Date;
  decimalHours: number;
}
export interface IUsersHours {
  id: string;
  name: string;
  hours: number;
}

export interface IProjectTotalByMonth {
  projectName: string;
  projectTotal: number;
  projectTasks: ITaskTotal[];
}

export interface ITaskTotal {
  taskName: string;
  taskTotalTime: number;
}

export const TaskSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'name' },
];

@Injectable({ providedIn: 'root' })
export class TimelogsTasksRepository extends BaseRepository<ITask> {
  constructor() {
    super('tasks', TaskSortOptions);
  }
}
