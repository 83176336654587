<div *ngIf="type === 'project'; else taskblock">
  <app-timelogs-form
    *ngIf="project"
    [editedProject]="project"
  ></app-timelogs-form>
</div>

<ng-template #taskblock>
  <app-timelogs-form *ngIf="task" [editedTask]="task"></app-timelogs-form>
</ng-template>
