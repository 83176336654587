<div
  class="d-flex responsiveMedia"
  *ngIf="
    (authRepo.isUser$ | async) || (authRepo.isTenantAdmin$ | async);
    else elseBlock
  "
>
  <div
    class="page d-flex flex-column"
    style="overflow-y: scroll"
    #scrollableDiv
    (scroll)="onScroll($event)"
  >
    <div class="flex-grow-1">
      <div *ngIf="menuItems" class="row m-0 p-0">
        <app-menu-item
          *ngFor="let item of menuItems"
          class="d-flex justify-content-center col-6 col-sm-4 m-0 p-2"
          style="aspect-ratio: 1"
          [item]="item"
          (subMenuOpen)="openSubMenu($event, item.name)"
        ></app-menu-item>
      </div>
    </div>

    <img src="assets/img/header_logo.png" alt="Logo" class="w-75 p-3" />

    <div
      *ngIf="menuItems"
      class="w-100 bottom-gradient"
      [class.transparent]="isScrolledToBottom"
    ></div>
  </div>
</div>
<div class="showOnBigScreen">
  <div class="row justify-content-center">
    <div class="row col-12 col-lg-11 col-xl-10 max-900 pb-3 pt-sm-3 ps-0 pe-0">
      <div *ngIf="tenantHasNoInfo(); else elseBlock">
        <h2 class="sm-del page-title col-sm-4 col-12">
          {{ "home" | transloco }}
        </h2>

        <div class="useTheMenu">{{ "useMenu" | transloco }}</div>
      </div>
      <ng-template #elseBlock>
        <div>
          <img *ngIf="homeTenantModel.logo" class="img" [src]="imageSafe" />
          <h1 class="mb-4 mt-4">{{ homeTenantModel.headline }}</h1>

          <div [innerHtml]="homeTenantModel.pageHtml"></div>

          <div class="col-lg-9 col-12" *ngIf="homeTenantModel.videoUrl">
            <div class="">
              <div>
                <iframe
                  frameBorder="0"
                  [src]="videpUrlSafe"
                  class="mainVideo"
                  allowscriptaccess="always"
                ></iframe>
              </div>
            </div>
          </div>

          <div [innerHtml]="homeTenantModel.pageHtml2"></div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
<ng-template #elseBlock>
  <!--head administrator-->
</ng-template>
