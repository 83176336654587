import { Injectable } from '@angular/core';
import { fromEvent, mapTo, merge, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConnectionService {
  isOnline$: Observable<boolean>;

  constructor() {
    this.isOnline$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
  }

  isOnline() {
    return navigator.onLine;
  }
}
