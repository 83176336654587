import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionOverStateComponent } from './components/session-over-state/session-over-state.component';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthlayoutComponent } from './layouts/authlayout/authlayout.component';
import { ApphomelayoutComponent } from './layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from './layouts/appinnerlayout/appinnerlayout.component';
import { SigninComponent } from './pages/signin/signin.component';
import { ForgetpasswordComponent } from './pages/forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { VerifyComponent } from './layouts/authlayout/verify/verify.component';
import { FooterinfoComponent } from './components/footerinfo/footerinfo.component';
import { HeaderbackComponent } from './components/headerback/headerback.component';
import { PagenotfoundComponent } from './pages/pagenotfound/pagenotfound.component';
import { PagenotauthorizedComponent } from './pages/pagenotauthorized/pagenotauthorized.component';
import { TenantsCreatePageComponent } from './pages/tenants-create-page/tenants-create-page.component';
import { ProfileEditComponent } from './pages/profile-edit/profile-edit.component';
import { UsersCreatePageComponent } from './pages/users-create-page/users-create-page.component';
import { HeadermenuComponent } from './components/headermenu/headermenu.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TenantsPageComponent } from './pages/tenants-page/tenants-page.component';
import { UsersListPageComponent } from './pages/users-list-page/users-list-page.component';
import { ImpersonationModalComponent } from './components/impersonation-modal/impersonation-modal.component';
import { TenantFormComponent } from './components/tenant-form/tenant-form.component';
import { UserBasicInfoFormComponent } from './components/user-basic-info-form/user-basic-info-form.component';
import { UserHeaderImageComponent } from './components/user-header-image/user-header-image.component';
import { TenantListComponent } from './components/tenant-list/tenant-list.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { LogoComponent } from './components/logo/logo.component';
import { SharedModule } from '../shared/shared.module';
import { OfflineLabelComponent } from './components/offline-label/offline-label.component';
import { PwaInstallButtonComponent } from './components/pwa-install-button/pwa-install-button.component';
import { UnsyncedChangesComponent } from './components/unsynced-changes/unsynced-changes.component';
import { ChangePasswordFormComponent } from './components/change-password-form/change-password-form.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { TimelogsGridComponent } from './components/timelogs-grid/timelogs-grid.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Office365authComponent } from './components/office365auth/office365auth.component';
import { MenuComponent } from './components/menu/menu.component';
import { DocumentPostComponent } from './components/document-post/document-post.component';
import { MenuUserCardComponent } from './components/menuuser-card/menuuser-card.component';
import { FolderPostComponent } from './components/folder-post/folder-post.component';
import { FolderpageComponent } from './pages/folderpage/folderpage.component';
import { DocumenttypePostComponent } from './components/documenttype-post/documenttype-post.component';
import { DocumenttypePageComponent } from './pages/documenttype-page/documenttype-page.component';
import { DocumenttypeEditpageComponent } from './pages/documenttype-editpage/documenttype-editpage.component';
import { DocumentEditpageComponent } from './pages/document-editpage/document-editpage.component';
import { FolderEditpageComponent } from './pages/folder-editpage/folder-editpage.component';
import { FolderEditformComponent } from './components/folder-editform/folder-editform.component';
import { DocumentEditformComponent } from './components/document-editform/document-editform.component';
import { DocumenttypeEditformComponent } from './components/documenttype-editform/documenttype-editform.component';
import { AdminDocumentPageComponent } from './pages/admin-document-page/admin-document-page.component';
import { DocumenttypeFromSideMenuComponent } from './components/documenttype-from-SideMenu/documenttype-from-SideMenu.component';
import { MapsPageComponent } from './pages/maps-page/maps-page.component';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AdminCompaniesPageComponent } from './pages/admin-companies-page/admin-companies-page.component';
import { CompaniesEditformComponent } from './components/companies-editform/companies-editform.component';
import { ClientsCreatePageComponent } from './pages/clients-create-page/clients-create-page.component';
import { AdminLocationsPageComponent } from './pages/admin-locations-page/admin-locations-page.component';
import { AdminContactsPageComponent } from './pages/admin-contacts-page/admin-contacts-page.component';
import { ContactsCreatePageComponent } from './pages/contacts-create-page/contacts-create-page.component';
import { LocationsCreatePageComponent } from './pages/locations-create-page/locations-create-page.component';
import { LocationsEditformComponent } from './components/locations-editform/locations-editform.component';
import { ContactsEditformComponent } from './components/contacts-editform/contacts-editform.component';
import { ActiveTenantPageComponent } from './pages/active-tenant-page/active-tenant-page.component';
import { TranslocoRootModule } from 'app/transloco-root.module';
import { NotificationsListComponent } from './pages/notifications-list/notifications-list.component';
import { TenantInfoPageComponent } from './pages/tenant-info-page/tenant-info-page.component';
import { TenantInfoComponentComponent } from './components/tenant-info-component/tenant-info-component.component';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { TenantHtmlFormComponent } from './components/tenant-html-form/tenant-html-form.component';
import { TenantEdithtmlPageComponent } from './pages/tenant-edithtml-page/tenant-edithtml-page.component';
import { BaseModule } from '../base/base.module';
import { CoreRoutingModule } from 'app/routing/core-routing.module';
import { SidebarItemsComponent } from './components/sidebar/sidebarItems/sidebarItems.component';
import { TenantMsGraphEditPageComponent } from './pages/tenant-ms-graph-edit-page/tenant-ms-graph-edit-page.component';
import { TenantMsGraphEditFormComponent } from './components/tenant-ms-graph-edit-form/tenant-ms-graph-edit-form.component';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';

@NgModule({
  declarations: [
    SigninComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    SessionOverStateComponent,
    AuthlayoutComponent,
    ApphomelayoutComponent,
    AppinnerlayoutComponent,
    FooterinfoComponent,
    HeaderbackComponent,
    PagenotfoundComponent,
    PagenotauthorizedComponent,
    TenantsCreatePageComponent,
    ProfileEditComponent,
    UsersCreatePageComponent,
    HeadermenuComponent,
    SidebarComponent,
    ProfileComponent,
    TenantsPageComponent,
    UsersListPageComponent,
    ImpersonationModalComponent,
    TenantFormComponent,
    UserBasicInfoFormComponent,
    UserHeaderImageComponent,
    TenantListComponent,
    UserCardComponent,
    UsersListComponent,
    LogoComponent,
    OfflineLabelComponent,
    PwaInstallButtonComponent,
    UnsyncedChangesComponent,
    ChangePasswordFormComponent,
    HomepageComponent,
    TimelogsGridComponent,
    Office365authComponent,
    MenuComponent,
    DocumentPostComponent,
    MenuUserCardComponent,
    FolderPostComponent,
    FolderpageComponent,
    DocumenttypePostComponent,
    DocumenttypePageComponent,
    DocumenttypeEditpageComponent,
    DocumentEditpageComponent,
    FolderEditpageComponent,
    FolderEditformComponent,
    DocumentEditformComponent,
    DocumenttypeEditformComponent,
    DocumenttypeFromSideMenuComponent,
    AdminDocumentPageComponent,
    MapsPageComponent,
    AdminCompaniesPageComponent,
    CompaniesEditformComponent,
    ClientsCreatePageComponent,
    AdminLocationsPageComponent,
    AdminContactsPageComponent,
    ContactsCreatePageComponent,
    LocationsCreatePageComponent,
    LocationsEditformComponent,
    ContactsEditformComponent,
    ActiveTenantPageComponent,
    NotificationsListComponent,
    TenantInfoPageComponent,
    TenantInfoComponentComponent,
    MenuItemComponent,
    TenantHtmlFormComponent,
    TenantEdithtmlPageComponent,
    SidebarItemsComponent,
    TenantMsGraphEditPageComponent,
    TenantMsGraphEditFormComponent,
  ],
  exports: [
    SidebarComponent,
    SigninComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    VerifyComponent,
    SessionOverStateComponent,
    AuthlayoutComponent,
    ApphomelayoutComponent,
    AppinnerlayoutComponent,
    FooterinfoComponent,
    HeaderbackComponent,
    PagenotfoundComponent,
    PagenotauthorizedComponent,
    TenantsCreatePageComponent,
    ProfileEditComponent,
    UsersCreatePageComponent,
    HeadermenuComponent,
    ProfileComponent,
    TenantsPageComponent,
    UsersListPageComponent,
    OfflineLabelComponent,
    PwaInstallButtonComponent,
    TimelogsGridComponent,
    UnsyncedChangesComponent,
    DocumentPostComponent,
    ActiveTenantPageComponent,
    SidebarItemsComponent,
  ],
  imports: [
    CoreRoutingModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatProgressBarModule,
    MatListModule,
    GooglePlaceModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgCircleProgressModule.forRoot(),
    SharedModule,
    FormsModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDOzV5scbP1PWye-hm6iiqyufG8p1-7Ew4',
    }),
    TranslocoRootModule,
    BaseModule,
    SplitButtonModule,
    ButtonModule,
    TableModule,
    TagModule,
    DropdownModule,
    InputTextModule,
    TooltipModule,
    SelectButtonModule,
    ButtonModule,
    SplitButtonModule,
    TableModule,
    TagModule,
    DropdownModule,
    DynamicDialogModule,
    ConfirmDialogModule,
    ToastModule,
  ],
})
export class CoreModule {}
