<div
  *ngIf="isDisplayed$ | async"
  [@enterFromBottom]
  role="button"
  data-bs-toggle="modal"
  data-bs-target="#syncmodal"
  class="bg-warning text-white text-start unsynced-label p-3 shadow-sm"
>
  <div class="row">
    <div class="col-auto">
      <i
        *ngIf="!(repo.isLoading$ | async)"
        class="bi bi-exclamation-diamond h2"
      ></i>
      <span *ngIf="repo.isLoading$ | async" class="h2">
        <app-spinner></app-spinner>
      </span>
    </div>
    <div class="col" role="button">
      <p
        class="m-0"
        *ngVar="repo.count$ | async as count"
        i18n="Changes counter label"
      >
        {count, plural, =1 {1 unsynced change} other {{{count}} unsynced
        changes}}
      </p>
      <small i18n="Details view button">{{ "viewDetails" | transloco }}</small>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  *ngIf="isDisplayed$ | async"
  class="modal fade"
  id="syncmodal"
  tabindex="-1"
  aria-labelledby="syncmodal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-lights border-0">
      <div class="modal-header">
        <h6
          class="modal-title"
          id="syncmodalLabel"
          i18n="Title unsynced changes"
        >
          {{ "unsyncedChanges" | transloco }}
        </h6>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          i18n-aria-label="@@close"
        ></button>
      </div>
      <div class="modal-body p-0">
        <ul class="list-group list-group-flush bg-none">
          <li
            *ngFor="let request of requests$ | async"
            class="list-group-item px-3"
          >
            <p class="mb-1">
              <i
                *ngIf="!(repo.isLoadingOne(request.id) | async)"
                [ngClass]="['bi me-1', request.icon]"
              ></i>
              <app-spinner
                *ngIf="repo.isLoadingOne(request.id) | async"
              ></app-spinner>
              {{ request.title }}
            </p>
            <p class="small text-secondary mb-0">
              {{ request.date | timing }}
              <span *ngIf="request.hasDependency" class="text-primary ms-1">
                <i class="bi bi-link-45deg"></i>
                <ng-container i18n="Linked to update label">{{
                  "linked" | transloco
                }}</ng-container>
              </span>
            </p>
            <p
              *ngIf="!(auth.isAuthenticated$ | async)"
              class="small text-danger mt-1 mb-0"
            >
              <i class="bi bi-exclamation-triangle"></i>
              <ng-container i18n="Sign in to sync label">{{
                "signToSync" | transloco
              }}</ng-container>
            </p>
            <p
              *ngIf="(auth.isAuthenticated$ | async) && !request.isCurrentUser"
              class="small text-danger mt-1 mb-0"
            >
              <i class="bi bi-exclamation-triangle"></i>
              <ng-container i18n="Another user changed that message">{{
                "changeByAnotherUser" | transloco
              }}</ng-container>
            </p>
          </li>
        </ul>
      </div>
      <div class="modal-footer d-flex">
        <button
          [disabled]="repo.isLoading$ | async"
          (click)="dismissAll()"
          class="btn btn-sm btn-danger w-100"
        >
          <i class="bi bi-trash me-1"></i>
          <ng-container i18n="Dismiss all button">{{
            "dismissAll" | transloco
          }}</ng-container>
        </button>
        <button
          [disabled]="repo.isLoading$ | async"
          (click)="sync()"
          class="btn btn-sm btn-success w-100"
        >
          <i
            *ngIf="!(repo.isLoading$ | async)"
            class="bi bi-arrow-repeat me-1"
          ></i>
          <app-spinner
            *ngIf="repo.isLoading$ | async"
            class="me-1"
          ></app-spinner>
          Sync
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal end -->
