import { Component, OnInit } from '@angular/core';
import { OutOfOfficeStatusService } from '../../../../state/out-of-office-status.service';
import {
  IDateTransmit,
  IUserStatusShown,
} from '../../../../state/out-of-office-status.repository';
@Component({
  selector: 'app-out-of-office-status-page',
  templateUrl: './out-of-office-status-page.component.html',
  styleUrls: ['./out-of-office-status-page.component.scss'],
})
export class OutOfOfficeStatusPageComponent {
  data: IUserStatusShown[] | undefined;

  constructor(private service: OutOfOfficeStatusService) {}

  transmitDates(data: Partial<IDateTransmit>) {
    this.service
      .loadAllStatuses(data.startDate, data.endDate, data.timeOffset)
      .subscribe((res) => {
        this.data = res.sort((a, b) => a.userName.localeCompare(b.userName));
      });
  }
}
