import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { enterFromBottomAnimation } from 'app/modules/shared/helpers/animaions';
import { UiRepository } from 'app/state/ui.repository';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

@Component({
  selector: 'app-pwa-install-button',
  templateUrl: './pwa-install-button.component.html',
  styleUrls: ['./pwa-install-button.component.scss'],
  animations: [enterFromBottomAnimation],
})
export class PwaInstallButtonComponent implements OnInit {
  installPrompt?: BeforeInstallPromptEvent;
  hasEventTriggered = false;

  constructor(public ui: UiRepository, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    window.addEventListener('beforeinstallprompt', (event) => {
      this.hasEventTriggered = true;
      event.preventDefault();
      this.installPrompt = event as BeforeInstallPromptEvent;
      this.cdr.detectChanges();
    });
  }

  async install() {
    if (!this.installPrompt) {
      return;
    }
    this.installPrompt.prompt();
    const result = await this.installPrompt.userChoice;
    if (result.outcome === 'accepted') {
      console.log('installed');
    }
    delete this.installPrompt;
  }
}
