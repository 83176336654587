import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { getStoredToken } from 'app/state/auth.repository';
import { AppComponent } from './app.component';
import { environment } from 'environments/environment';
import { NewsModule } from './modules/news/news.module';
import { UnauthorizedModule } from './modules/unauthorized/unauthorized.module';
import { WhistleblowModule } from './modules/whistleblows/whistleblow.module';
import { TimelogsModule } from './modules/timelogs/timelogs.module';
import { ApvModule } from './modules/apv/apv.module';
import { MailModule } from './modules/mail/mail.module';
import { SessionInterceptor } from './modules/core/interceptors/session.interceptor';
import { DateInterceptor } from './modules/core/interceptors/date.interceptor';
import { ImageInterceptor } from './modules/core/interceptors/image.interceptor';
import { BaseUrlInterceptor } from './modules/core/interceptors/base-url.interceptor';
import { CoreModule } from './modules/core/core.module';
import { UnauthorizedRoutingModule } from './routing/unauthorized-routing.module';
import { ApvRoutingModule } from './routing/apv-routing.module';
import { NewsRoutingModule } from './routing/news-routing.module';
import { WhistleblowRoutingModule } from './routing/whistleblow-routing.module';
import { TimelogsRoutingModule } from './routing/timelogs-routing.module';
import { MailRoutingModule } from './routing/mail-routing.module';

import { RouterModule } from '@angular/router';
import { PagenotfoundComponent } from './modules/core/pages/pagenotfound/pagenotfound.component';
import { RetryInterceptor } from './modules/core/interceptors/retry.interceptor';
import { EnvState } from './modules/shared/helpers/env-state';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

// import { MarketplaceRoutingModule } from './routing/marketplace-routing.module';
// import { MarketplaceModule } from './modules/marketplace/marketplace.module';
import { TranslocoRootModule } from './transloco-root.module';
import { BLOB_STORAGE_TOKEN } from './shared/azure-blob/azureStorage';
import { BlobStorageService } from './shared/azure-blob//blob-storage.service';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { OutOfOfficeStatusModule } from './modules/out-of-office-status/out-of-office-status.module';
import { OutOfOfficeStatusRoutingModule } from './routing/out-of-office-status-routing.module';
import { AppRoutingModule } from './app-routing.module';
import { BaseModule } from './modules/base/base.module';
import { SharedModule } from './modules/shared/shared.module';
import { CommonModule } from '@angular/common';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';

const optionalModules = [];
const optionalProviders = [];

optionalModules.push(WhistleblowModule);
optionalModules.push(WhistleblowRoutingModule);

optionalModules.push(ApvModule);
optionalModules.push(ApvRoutingModule);

// optionalModules.push(MarketplaceModule);
// optionalModules.push(MarketplaceRoutingModule);

optionalModules.push(TimelogsModule);
optionalModules.push(TimelogsRoutingModule);

optionalModules.push(MailModule);
optionalModules.push(MailRoutingModule);

optionalModules.push(NewsModule);
optionalModules.push(NewsRoutingModule);

optionalModules.push(UnauthorizedModule);
optionalModules.push(UnauthorizedRoutingModule);

if (environment.features.offlineChanges) {
  optionalProviders.push({
    provide: HTTP_INTERCEPTORS,
    useClass: RetryInterceptor,
    multi: true,
  });
}
if (environment.features.outOfOfficeStatus) {
  optionalModules.push(OutOfOfficeStatusModule);
  optionalModules.push(OutOfOfficeStatusRoutingModule);
}

//initializeApp(environment.firebase);
@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedModule,
    ToastModule,
    BrowserModule,
    CommonModule,
    GooglePlaceModule,
    NoopAnimationsModule,
    TranslocoRootModule,
    MatDialogModule,
    HttpClientModule,
    CoreModule,
    BaseModule,
    AppRoutingModule,
    PopoverModule.forRoot(),
    RouterModule.forRoot([{ path: '**', component: PagenotfoundComponent }]),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDOzV5scbP1PWye-hm6iiqyufG8p1-7Ew4',
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: () => getStoredToken(),
        allowedDomains: [window.location.origin],
      },
    }),
    ...optionalModules,
  ],
  providers: [
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ImageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true,
    },
    {
      provide: EnvState,
      useValue: environment,
    },
    BlobStorageService,
    {
      provide: BLOB_STORAGE_TOKEN,
      //@ts-ignore
      useValue: window['AzureStorage']?.Blob,
    },
    ...optionalProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
