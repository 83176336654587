import { Injectable } from '@angular/core';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { BaseRepository } from './abstract/base.repository';
import { IDocumentType } from './documenttype.repository';
import { IFolder } from './folders.repository';

export interface IDocument {
  id: string;
  title: string;
  updatedAt: Date;
  type: string;
  image: string;
  video: string;
  videoUrl: string;
  altText: string;
  documentType: IDocumentType;
  documentTypeId: string;
  folder: IFolder;
  folderId: string;
  document: File | null;
  documentPath: string;
  isCreatedWithSystemType: boolean;
  hasCompressedImage320?: boolean;
  hasCompressedImage800?: boolean;
  isFileOnRightBlob?: boolean;
}

export const DocumentsSortOptions: SortOption[] = [
  { label: $localize`:Sort label Name:Name`, property: 'Title' },
  { label: $localize`:Sort label Updated:Updated`, property: 'updatedAt' },
];

@Injectable({ providedIn: 'root' })
export class DocumentsRepository extends BaseRepository<IDocument> {
  constructor() {
    super('DocumentsOptions', DocumentsSortOptions);
  }
}
