<div class="row h-100 d-flex align-items-center">
  <div class="col-10 col-md-6 col-lg-5 col-xl-3 mx-auto pt-4 text-center">
    <img
      class="mw-100 mb-4"
      [alt]="env.name"
      src="/assets/img/header_logo.webp"
    />
    <h3 class="mb-1" i18n="Button forgot password">
      {{ "forgotYourPassword" | transloco }}
    </h3>
    <p
      *ngIf="state === 'idle' || state === 'processing'"
      class="text-muted mb-4"
      i18n="Provide email label"
    >
      {{ "pleaseProvideYour" | transloco }}<br />{{
        "registeredEmailAddress" | transloco
      }}
    </p>
    <form
      *ngIf="state === 'idle' || state === 'processing'"
      class="d-grid gap-2"
      [formGroup]="form"
      (ngSubmit)="reset()"
    >
      <div
        class="form-group form-floating"
        [appValidateState]="form.get('email')"
      >
        <input
          formControlName="email"
          type="text"
          class="inpt"
          id="email"
          placeholder="{{ 'email' | transloco }}"
          i18n-placeholder="Email placeholder @@email"
        />
      </div>
      <button
        type="submit"
        class="d-grid w-100 btn btn-orange d-flex align-items-center justify-content-center"
        [disabled]="state === 'processing'"
      >
        <ng-container *ngIf="state === 'idle'" i18n="Label reset password">{{
          "resetYourPassword" | transloco
        }}</ng-container>
        <app-spinner *ngIf="state === 'processing'"></app-spinner>
      </button>
    </form>
    <p
      class="h1 text-center my-3"
      *ngIf="state === 'success' || state === 'error'"
    >
      <i
        *ngIf="state === 'success'"
        class="bi bi-patch-check-fill text-success"
      ></i>
      <i
        *ngIf="state === 'error'"
        class="bi bi-patch-exclamation-fill text-danger"
      ></i>
    </p>
    <p
      *ngIf="state === 'success'"
      class="text-muted mb-0"
      i18n="Check password reset email instruction"
    >
      {{ "checkPasswordResetEmailInstruction" | transloco }}
    </p>
    <p
      *ngIf="state === 'error'"
      class="text-muted mb-0"
      i18n="Unknown error message in Angular UI"
    >
      {{ "unknownErrorMessageInAngularUI" | transloco }}
    </p>
  </div>
</div>
