<h1 class="m-0 p-0 ms-1" *ngIf="generalAppData.logoDownloaded">
  <a
    class="text-primary d-flex justify-content-center"
    style="min-width: 250px"
    routerLink="/"
  >
    <img
      style="height:{{ imgHeight }}px"
      [alt]="env.name"
      src="{{ imagesrc }}"
    />
  </a>
</h1>
