<table
  class="table table-borderless w-100"
  style="border-bottom: 0px !important; border-radius: 12px"
>
  <tr style="border-bottom: 1px solid #eaecf0" class="tabhead">
    <td
      width="100%"
      class="dates h-100 d-flex justify-content-center align-items-center"
    >
      <div
        class="container-fluid d-flex justify-content-around align-items-center smallTemplate"
        style="
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
          color: black;
          min-height: 70px;
        "
      >
        <div class="mx-2 pointer" (click)="minusweek()">
          <svg
            style="color: #667085"
            width="20"
            height="20"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 13L1 7L7 1"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="dateFonSize me-1 d-flex justify-content-center">
          {{ mondayDateString }}
        </div>
        <div class="text-muted dateFonSize me-1">till</div>
        <div class="dateFonSize me-1">{{ sundayDateString }}</div>
        <div class="mx-2 pointer" (click)="addweek()">
          <svg
            style="color: #667085"
            width="20"
            height="20"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 13L7 7L1 1"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </td>
  </tr>
  <thead
    class="w-100 d-flex align-items-center tabhead"
    style="border-bottom: 1px solid #eaecf0 !important"
  >
    <tr
      class="d-flex w-100 h-100 hide"
      style="padding: 0 !important; min-height: 96px"
    >
      <td
        width="20%"
        class="hideOnMiddleScreen h-100 d-flex ps-2 align-items-center"
        style="min-height: 96px"
      >
        <div
          class="d-flex justify-content-around align-items-center smallTemplate datesFont"
          style="
            min-height: 48px;
            border: 1px solid #d0d5dd;
            border-radius: 12px;
            background-color: white;
            color: black;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          "
        >
          <div class="mx-2 pointer" (click)="minusweek()">
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L1 7L7 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="dateFonSize me-1 d-flex justify-content-center">
            {{ mondayDateString }}
          </div>
          <div class="text-muted dateFonSize me-1">till</div>
          <div class="dateFonSize me-1">{{ sundayDateString }}</div>
          <div class="mx-2 pointer" (click)="addweek()">
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 13L7 7L1 1"
                stroke="black"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </td>
      <th
        class="mobileSizing50 d-flex p-0 justify-content-around align-content-center"
      >
        <div class="dayTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "mon" | transloco }}
          </div>
        </div>
        <div class="dayTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "tue" | transloco }}
          </div>
        </div>
        <div class="dayTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "wed" | transloco }}
          </div>
        </div>
        <div class="dayTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "thu" | transloco }}
          </div>
        </div>
        <div class="dayTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "fri" | transloco }}
          </div>
        </div>
      </th>

      <th
        class="mobileSizing20 d-flex p-0 justify-content-around"
        style="border-right: 1px solid #eaecf0; border-left: 1px solid #eaecf0"
      >
        <div class="weekendTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "sat" | transloco }}
          </div>
        </div>
        <div class="weekendTitle d-flex" style="color: black">
          <div class="d-flex justify-content-around align-items-center">
            {{ "sun" | transloco }}
          </div>
        </div>
      </th>
      <th
        class="mobileSizing10 totalTitle d-flex align-items-center justify-content-center weekendTitle"
      >
        <div
          class="w-75 d-flex align-items-center justify-content-end"
          style="color: black"
        >
          {{ "total" | transloco }}
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <div *ngFor="let project of projects; let i = index" class="">
      <tr
        class="d-flex projectTitle"
        style="background-color: white"
        style="border-bottom: 1px solid #eaecf0 !important"
      >
        <td class="ps-2 d-flex align-items-center my-2">
          <div
            class="d-flex justify-content-center align-items-center icon rounded-circle me-2"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 21V15.6C15 15.0399 15 14.7599 14.891 14.546C14.7951 14.3578 14.6422 14.2049 14.454 14.109C14.2401 14 13.9601 14 13.4 14H10.6C10.0399 14 9.75992 14 9.54601 14.109C9.35785 14.2049 9.20487 14.3578 9.10899 14.546C9 14.7599 9 15.0399 9 15.6V21M19 21V6.2C19 5.0799 19 4.51984 18.782 4.09202C18.5903 3.71569 18.2843 3.40973 17.908 3.21799C17.4802 3 16.9201 3 15.8 3H8.2C7.07989 3 6.51984 3 6.09202 3.21799C5.71569 3.40973 5.40973 3.71569 5.21799 4.09202C5 4.51984 5 5.0799 5 6.2V21M21 21H3M9.5 8H9.51M14.5 8H14.51M10 8C10 8.27614 9.77614 8.5 9.5 8.5C9.22386 8.5 9 8.27614 9 8C9 7.72386 9.22386 7.5 9.5 7.5C9.77614 7.5 10 7.72386 10 8ZM15 8C15 8.27614 14.7761 8.5 14.5 8.5C14.2239 8.5 14 8.27614 14 8C14 7.72386 14.2239 7.5 14.5 7.5C14.7761 7.5 15 7.72386 15 8Z"
                stroke="#FF4405"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div style="color: black">{{ project!.name }}</div>
        </td>
      </tr>

      <div
        *ngFor="let item of project.projectTasks"
        style="
          background-color: white !important;
          padding: 0 !important;
          border-bottom: 1px solid #eaecf0 !important;
        "
      >
        <tr class="d-flex hideOnBigScreen py-1">
          <td
            width="100%"
            class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate"
          >
            <div style="color: #475467">{{ item?.name | slice : 0 : 20 }}</div>
          </td>
        </tr>
        <tr class="d-flex" style="background-color: white !important">
          <td
            width="20%"
            class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen"
          >
            <div class="ps-2" style="color: #475467">
              {{ item?.name | slice : 0 : 20 }}
            </div>
          </td>
          <td class="mobileSizing50 d-flex justify-content-between p-0">
            <div class="col d-flex justify-content-end me-2">
              <div
                class="col d-flex justify-content-end"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  class="col-12"
                  placeholder="0"
                  id="{{ project.name }}//{{ item.name }}//monday"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0,3);
                   }if (+this.value.replace(',', '.' )>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  (change)="
                    changeHoursForDate(
                      monday,
                      item!.id,
                      item!.name,
                      'monday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                  [ngModel]="
                    item.marks[0]?.decimalHours?.toFixed(2) &&
                    item.marks[0]?.decimalHours?.toFixed(2) !== 'ToolBox'
                      ? item.marks[0]?.decimalHours?.toFixed(2)
                      : ''
                  "
                />
              </div>
            </div>

            <div class="col d-flex justify-content-end me-2">
              <div
                class="col text-center d-flex align-middle justify-content-center"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  placeholder="0"
                  [ngModel]="
                    item.marks[1]?.decimalHours &&
                    item.marks[1]?.decimalHours?.toFixed(2) !== '0.00'
                      ? item.marks[1]?.decimalHours?.toFixed(2)
                      : ''
                  "
                  class="col-12"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0, 3);
                   }if (+this.value.replace(',', '.' )>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  id="{{ project!.name }}//{{ item?.name }}//tuesday"
                  (change)="
                    changeHoursForDate(
                      tuesday,
                      item!.id,
                      item!.name,
                      'tuesday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                />
              </div>
            </div>

            <div class="col d-flex justify-content-end me-2">
              <div
                class="col text-center d-flex align-middle justify-content-center"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  placeholder="0"
                  [ngModel]="
                    item.marks[2]?.decimalHours &&
                    item.marks[2]?.decimalHours?.toFixed(2) !== '0.00'
                      ? item.marks[2]?.decimalHours?.toFixed(2)
                      : ''
                  "
                  class="col-12"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0, 2);
                   }if (+this.value.replace(',', '.' )>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  id="{{ project!.name }}//{{ item?.name }}//wednesday"
                  (change)="
                    changeHoursForDate(
                      wednesday,
                      item!.id,
                      item!.name,
                      'wednesday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                />
              </div>
            </div>

            <div class="col d-flex justify-content-end me-2">
              <div
                class="col text-center d-flex align-middle justify-content-center"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  placeholder="0"
                  [ngModel]="
                    item.marks[3]?.decimalHours &&
                    item.marks[3]?.decimalHours?.toFixed(2) !== '0.00'
                      ? item.marks[3]?.decimalHours?.toFixed(2)
                      : ''
                  "
                  class="col-12"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0, 3);
                   }if (+this.value.replace(',', '.' )>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  id="{{ project!.name }}//{{ item?.name }}//thursday"
                  (change)="
                    changeHoursForDate(
                      thursday,
                      item!.id,
                      item!.name,
                      'thursday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                />
              </div>
            </div>

            <div class="col d-flex justify-content-end me-2">
              <div
                class="col text-center d-flex align-middle justify-content-center"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  placeholder="0"
                  [ngModel]="
                    item.marks[4]?.decimalHours &&
                    item.marks[4]?.decimalHours?.toFixed(2) !== '0.00'
                      ? item.marks[4]?.decimalHours?.toFixed(2)
                      : ''
                  "
                  class="col-12"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0, 3);
                   }if (+this.value.replace(',', '.' )>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  id="{{ project!.name }}//{{ item?.name }}//friday"
                  (change)="
                    changeHoursForDate(
                      friday,
                      item!.id,
                      item!.name,
                      'friday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                />
              </div>
            </div>
          </td>
          <td
            class="mobileSizing20 d-flex justify-content-around m-0 p-0"
            style="
              border-right: 1px solid #eaecf0;
              border-left: 1px solid #eaecf0;
            "
          >
            <div class="col d-flex justify-content-end me-2">
              <div
                class="col text-center d-flex align-middle justify-content-center"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  placeholder="0"
                  [ngModel]="
                    item.marks[5]?.decimalHours &&
                    item.marks[5]?.decimalHours?.toFixed(2) !== '0.00'
                      ? item.marks[5]?.decimalHours?.toFixed(2)
                      : ''
                  "
                  class="col-12 weekInput"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0, 3);
                   } if (+(this.value.replace(',', '.' ))>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  id="{{ project!.name }}//{{ item?.name }}//saturday"
                  (change)="
                    changeHoursForDate(
                      saturday,
                      item!.id,
                      item!.name,
                      'saturday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                />
              </div>
            </div>
            <div class="col d-flex justify-content-end me-2">
              <div
                class="col text-center taskTitle d-flex align-middle justify-content-center"
                style="max-width: 100px"
              >
                <input
                  autocomplete="off"
                  placeholder="0"
                  [ngModel]="
                    item.marks[6]?.decimalHours &&
                    item.marks[6]?.decimalHours?.toFixed(2) !== '0.00'
                      ? item.marks[6]?.decimalHours?.toFixed(2)
                      : ''
                  "
                  class="col-12 weekInput"
                  onkeyup="if((this.value[2] !== '.' && this.value[2] !== ',') && (this.value[1] !== '.' && this.value[1] !== ',')) {
                   this.value=this.value.substring(0, 3);
                   }if (+this.value.replace(',', '.' )>23.99 ){
            this.value = '23.99';
            }"
                  oninput="this.value = this.value.replace(/[^0-9.,]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(\,,*?)\,,*/g, '$1').replace(/(\.\d{2}).+/g, '$1').replace(/(\,\d{2}).+/g, '$1').replace(/(\,,*?)\..*/g, '$1').replace(/(\..*?)\,,*/g, '$1');"
                  name="mondayInput"
                  id="{{ project!.name }}//{{ item?.name }}//sunday"
                  (change)="
                    changeHoursForDate(
                      sunday,
                      item!.id,
                      item!.name,
                      'sunday',
                      project!.id,
                      project!.name,
                      project!.projectTasks
                    )
                  "
                />
              </div>
            </div>
          </td>

          <td
            class="mobileSizing10 d-flex align-items-center justify-content-center total"
          >
            <div class="w-75 d-flex align-items-center justify-content-end">
              <label
                style="color: #475467"
                class="totalFont my-2"
                id="{{ project!.name }}//{{ item?.name }}//total"
              >
                {{ item.total.toFixed(2) }}
              </label>
            </div>
          </td>
        </tr>
      </div>

      <tr class="d-flex h-100 hideOnBigScreen" style="padding: 0 !important">
        <td
          width="100%"
          class="ps-2 bottomTitleBackground d-flex smallTemplate"
        >
          <label
            class="my-2 bottomTitle d-flex align-middle"
            style="color: #475467"
            >Usage</label
          >
        </td>
      </tr>
      <tr
        class="d-flex h-100"
        style="
          padding: 0 !important;
          min-height: 57px;
          border-bottom: 1px solid #eaecf0;
        "
        [class.lastBorderNone]="i == projects?.length! - 1"
      >
        <td
          width="20%"
          class="ps-2 bottomTitleBackground d-flex smallTemplate hideOnSmallScreen"
        >
          <label
            class="ps-2 my-2 bottomTitle d-flex align-middle"
            style="color: #475467"
            >{{ "usage" | transloco }}
          </label>
        </td>
        <td class="mobileSizing50 d-flex justify-content-around p-0">
          <div
            class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//mondayTotal"
              name="mondayTotal"
              >{{ TotalForDay(project.projectTasks, 0) }}</label
            >
          </div>
          <div
            class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//tuesdayTotal"
              name="tuesdayTotal"
              >{{ TotalForDay(project.projectTasks, 1) }}</label
            >
          </div>
          <div
            class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//wednesdayTotal"
              name="wednesdayTotal"
              >{{ TotalForDay(project.projectTasks, 2) }}</label
            >
          </div>
          <div
            class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//thursdayTotal"
              name="thursdayTotal"
              >{{ TotalForDay(project.projectTasks, 3) }}</label
            >
          </div>
          <div
            class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//fridayTotal"
              name="fridayTotal"
            >
              {{ TotalForDay(project.projectTasks, 4) }}</label
            >
          </div>
        </td>
        <td class="mobileSizing20 d-flex justify-content-around p-0">
          <div
            class="col h-100 weekendBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//saturdayTotal"
              name="saturdayTotal"
              >{{ TotalForDay(project.projectTasks, 5) }}</label
            >
          </div>

          <div
            class="col h-100 weekendBottomTotal d-flex align-items-center justify-content-end pe-2"
          >
            <label
              style="color: #475467"
              id="{{ project!.name }}//sundayTotal"
              name="sundayTotal"
              >{{ TotalForDay(project.projectTasks, 6) }}</label
            >
          </div>
        </td>

        <td
          class="mobileSizing10 bottomTotal d-flex align-items-center justify-content-center totalFont"
          [class.border_radius_bottom]="i == projects?.length! - 1"
        >
          <div class="w-75 d-flex align-items-center justify-content-end">
            <label
              style="color: #475467"
              class="totalFont"
              id="{{ project!.name }}//bottomTotal"
              name="bottomTotal"
              >{{ (+project.totalHours).toFixed(2) }}</label
            >
          </div>
        </td>
      </tr>
    </div>
  </tbody>
</table>
<table
  class="mt-5 table table-borderless"
  style="background-color: #f2f4f7 !important"
>
  <thead style="padding: 0 !important" class="w-100 d-flex align-items-center">
    <tr
      class="d-flex w-100 h-100"
      style="padding: 0 !important; min-height: 57px"
    >
      <th
        width="20%"
        class="ps-2 bottomTitleBackground d-flex smallTemplate hideOnSmallScreen"
      >
        <label
          class="ps-2 my-2 bottomTitle d-flex align-middle"
          style="color: #475467"
          >Samlet
        </label>
      </th>
      <th class="mobileSizing50 d-flex justify-content-around p-0">
        <div
          class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="mondayTotal">{{
            MondayGenTotal.toFixed(2)
          }}</label>
        </div>
        <div
          class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="tuesdayTotal">{{
            TuesdayGenTotal.toFixed(2)
          }}</label>
        </div>
        <div
          class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="wednesdayTotal">{{
            WednesdayGenTotal.toFixed(2)
          }}</label>
        </div>
        <div
          class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="thursdayTotal">{{
            ThursdayGenTotal.toFixed(2)
          }}</label>
        </div>
        <div
          class="col dayBottomTotal d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="fridayTotal">
            {{ FridayGenTotal.toFixed(2) }}</label
          >
        </div>
      </th>
      <th class="mobileSizing20 d-flex justify-content-around p-0">
        <div
          style="background-color: #ffe6d5"
          class="dayBottomTotal col h-100 d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="saturdayTotal">{{
            SaturdayGenTotal.toFixed(2)
          }}</label>
        </div>
        <div
          style="background-color: #ffe6d5"
          class="dayBottomTotal col h-100 d-flex align-items-center justify-content-end pe-2"
        >
          <label style="color: #475467" name="sundayTotal">{{
            SundayGenTotal.toFixed(2)
          }}</label>
        </div>
      </th>
      <th
        class="mobileSizing10 d-flex align-items-center justify-content-end totalFont"
        style="
          background-color: #f9dbaf;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
        "
      >
        <div class="w-75 d-flex align-items-center justify-content-center">
          <label style="color: #475467" class="totalFont" name="bottomTotal">{{
            weekTotal.toFixed(2)
          }}</label>
        </div>
      </th>
    </tr>
  </thead>
</table>
