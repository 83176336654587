import { BaseRepository, DatalistOption } from './abstract/base.repository';
import { SortOption } from '../modules/shared/pipes/sort.pipe';
import { Injectable } from '@angular/core';
import { Time } from '@angular/common';
import { Dayjs } from 'dayjs';

export interface IOutOfOfficeStatus {
  id: string;
  userId?: string;
  type: string;
  from: Date;
  to: Date;
  timeOffset: number;
}

export interface IOutOfOfficeStatusForm {
  type: string;
  dateFrom: Date;
  dateTo?: Date;
  timeFrom?: Time | Date;
  timeTo?: Time | Date;
  userId?: string;
}

export interface IUserStatusShown {
  userName: string;
  userId: string;
  statDayRecords: statDayRecord[];
}

export interface statDayRecord {
  status?: string;
  day?: Date;
  startTime: Date;
  endTime: Date;
  id: string;
}

export interface IDateTransmit {
  startDate: Dayjs;
  endDate: Dayjs;
  timeOffset: number;
}

export const outOfOfficeStatusOptions: SortOption[] = [];

@Injectable({ providedIn: 'root' })
export default class OutOfOfficeStatusRepository extends BaseRepository<IOutOfOfficeStatus> {
  constructor() {
    super('outOfOfficeStatus', outOfOfficeStatusOptions);
  }
}

export const OutTypes: DatalistOption[] = [
  { label: 'outOfOffice', value: 'outOfOffice' },
  { label: 'sick', value: 'sick' },
  { label: 'vacation', value: 'vacation' },
  { label: 'workFromHome', value: 'workFromHome' },
];
