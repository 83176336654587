import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantMsGraphOptions } from 'app/api/models/tenant/TenantMsGraphOptions';
import { TenantsService } from 'app/state/tenants.service';

@Component({
  selector: 'app-tenant-ms-graph-edit-page',
  templateUrl: './tenant-ms-graph-edit-page.component.html',
  styleUrls: ['./tenant-ms-graph-edit-page.component.scss'],
})
export class TenantMsGraphEditPageComponent {
  constructor(public service: TenantsService, private route: ActivatedRoute) {}

  msGraphOptions: TenantMsGraphOptions | null = null;
  id: string | null = null;
  submitErrors: string[] | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;
        if (this.id) {
          this.service.loadMsGraphOptions(this.id).subscribe((x) => {
            this.msGraphOptions = x;
          });
        }
      },
    });
  }

  updateMsGraphOptions(msGraphOptions: Partial<TenantMsGraphOptions>) {
    this.submitErrors = null;
    if (this.id) {
      this.service.updateMsGraphOptions(this.id, msGraphOptions).subscribe({
        complete: () => {
          window.history.back();
          //this.router.navigate(['/']);
        },
        error: (data) => (this.submitErrors = data),
      });
    }
  }

  sendTestEmail(options: Partial<TenantMsGraphOptions>) {
    this.submitErrors = null;
    this.service.sendTestEmail(options).subscribe({
      complete: () => {
        window.alert('Success');
      },
      error: (data) => {
        console.log(data);
      },
    });
  }
}
