<div class="mt-3 row d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-900">
    <h2 class="page-title">{{ "whistleblow" | transloco }}</h2>
    <span class="text-muted">{{ "whistleAlert" | transloco }}</span>
    <div
      class="container-fluid blowContainer mt-3 d-flex justify-content-center align-items-center"
    >
      <button id="myBtn" class="btn blowButon">
        <ng-container i18n="@@addWhistleblow">
          {{ "blowTheWhistle" | transloco }}
          <svg
            class="ms-2"
            width="18"
            height="19.71"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 7.00008V11.0001M10 15.0001H10.01M1 6.94153V15.0586C1 15.4013 1 15.5726 1.05048 15.7254C1.09515 15.8606 1.16816 15.9847 1.26463 16.0893C1.37369 16.2077 1.52345 16.2909 1.82297 16.4573L9.22297 20.5684C9.50658 20.726 9.64838 20.8047 9.79855 20.8356C9.93146 20.863 10.0685 20.863 10.2015 20.8356C10.3516 20.8047 10.4934 20.726 10.777 20.5684L18.177 16.4573C18.4766 16.2909 18.6263 16.2077 18.7354 16.0893C18.8318 15.9847 18.9049 15.8606 18.9495 15.7254C19 15.5726 19 15.4013 19 15.0586V6.94153C19 6.59889 19 6.42756 18.9495 6.27477C18.9049 6.13959 18.8318 6.01551 18.7354 5.91082C18.6263 5.79248 18.4766 5.70928 18.177 5.54288L10.777 1.43177C10.4934 1.27421 10.3516 1.19543 10.2015 1.16454C10.0685 1.13721 9.93146 1.13721 9.79855 1.16454C9.64838 1.19543 9.50658 1.27421 9.22297 1.43177L1.82297 5.54288C1.52345 5.70928 1.37369 5.79248 1.26463 5.91082C1.16816 6.01551 1.09515 6.13959 1.05048 6.27477C1 6.42756 1 6.59889 1 6.94153Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </ng-container>
      </button>
    </div>
    <div id="myModal" class="modal">
      <div class="responsive">
        <div class="modal-content">
          <div class="d-flex modalHeader">
            <div
              class="d-flex justify-content-center align-items-center BlowModalIcon"
            >
              <svg
                width="18"
                height="19.71"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 6.85604V10.856M10 14.856H10.01M1 6.79749V14.9146C1 15.2572 1 15.4286 1.05048 15.5814C1.09515 15.7165 1.16816 15.8406 1.26463 15.9453C1.37369 16.0636 1.52345 16.1468 1.82297 16.3132L9.22297 20.4244C9.50658 20.5819 9.64838 20.6607 9.79855 20.6916C9.93146 20.7189 10.0685 20.7189 10.2015 20.6916C10.3516 20.6607 10.4934 20.5819 10.777 20.4244L18.177 16.3132C18.4766 16.1468 18.6263 16.0636 18.7354 15.9453C18.8318 15.8406 18.9049 15.7165 18.9495 15.5814C19 15.4286 19 15.2572 19 14.9146V6.79749C19 6.45484 19 6.28352 18.9495 6.13072C18.9049 5.99555 18.8318 5.87146 18.7354 5.76678C18.6263 5.64844 18.4766 5.56524 18.177 5.39883L10.777 1.28772C10.4934 1.13017 10.3516 1.05139 10.2015 1.0205C10.0685 0.993166 9.93146 0.993166 9.79855 1.0205C9.64838 1.05139 9.50658 1.13017 9.22297 1.28772L1.82297 5.39883C1.52345 5.56524 1.37369 5.64844 1.26463 5.76678C1.16816 5.87146 1.09515 5.99555 1.05048 6.13072C1 6.28352 1 6.45484 1 6.79749Z"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="w-100 titleModal">
              <h5>{{ "blowTheWhistle" | transloco }}</h5>
              <span class="headerModalDescription">{{
                "enterWhatYouWantToReportHere" | transloco
              }}</span>
            </div>
            <span class="close">
              <svg
                width="12"
                height="12"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13M1 1L13 13"
                  stroke="#667085"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div class="marginsModal">
            <form
              [formGroup]="editForm"
              (ngSubmit)="onSubmit()"
              enctype="multipart/form-data"
            >
              <div class="form-group form-floating">
                <div class="row">
                  <label
                    style="
                      font-family: 'Inter', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                    "
                    class="col-2"
                    i18n="@@title"
                    >{{ "title" | transloco }}</label
                  >

                  <div class="pe-3 col-12 col-sm-10 col-lg-10 col-md-10">
                    <div
                      class="d-flex form-floating inputsModals"
                      [appValidateState]="editForm.get('title')"
                    >
                      <input
                        id="title"
                        formControlName="title"
                        type="text"
                        class="borderRadius8 form-control p-2"
                        placeholder="Title"
                        i18n-placeholder="@@title"
                        autocomplete="off"
                      />
                      <label
                        for="title"
                        style="
                          font-family: 'Inter', sans-serif;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 20px; /* identical to box height, or 143% */ /* Gray/700 */
                          color: #344054;
                        "
                        i18n="@@egSomething"
                        >{{ "egSomething" | transloco }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <label
                    style="
                      font-family: 'Inter', sans-serif;
                      font-weight: 500;
                      font-size: 14px;
                      line-height: 20px;
                    "
                    class="col-2"
                    i18n="@@Description"
                    >{{ "description" | transloco }}</label
                  >
                  <div class="pe-3 col-12 col-sm-10 col-lg-10 col-md-10">
                    <div
                      class="d-flex form-floating inputsModals"
                      [appValidateState]="editForm.get('description')"
                    >
                      <input
                        id="description"
                        style="min-height: 100px"
                        formControlName="description"
                        type="text"
                        class="borderRadius8 form-control"
                        placeholder="{{ 'description' | transloco }}"
                        i18n-placeholder="@@description"
                        autocomplete="off"
                      />
                      <label
                        for="description"
                        i18n="@@WriteAFewSentences"
                        style="
                          font-family: 'Inter', sans-serif;
                          font-weight: 400;
                          font-size: 14px;
                          line-height: 20px; /* identical to box height, or 143% */ /* Gray/700 */
                          color: #344054;
                        "
                        >{{ "writeAFewSentences" | transloco }}</label
                      >
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <label
                    class="col-2 re displayNoneOnSmallScreens"
                    i18n="@@uploadDescription"
                    >{{ "uploadFile" | transloco }}</label
                  >
                  <div
                    class="me-3 d-flex align-items-center justify-content-center fileModalIcon"
                  >
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.6667 2.99984H7.4C5.15979 2.99984 4.03969 2.99984 3.18404 3.43581C2.43139 3.8193 1.81947 4.43123 1.43597 5.18388C1 6.03952 1 7.15963 1 9.39984V20.5998C1 22.84 1 23.9602 1.43597 24.8158C1.81947 25.5685 2.43139 26.1804 3.18404 26.5639C4.03969 26.9998 5.15979 26.9998 7.4 26.9998H19.6667C20.9066 26.9998 21.5266 26.9998 22.0353 26.8635C23.4156 26.4937 24.4938 25.4155 24.8637 24.0351C25 23.5264 25 22.9065 25 21.6665M22.3333 9.6665V1.6665M18.3333 5.6665H26.3333M11 10.3332C11 11.8059 9.80609 12.9998 8.33333 12.9998C6.86057 12.9998 5.66667 11.8059 5.66667 10.3332C5.66667 8.86041 6.86057 7.6665 8.33333 7.6665C9.80609 7.6665 11 8.86041 11 10.3332ZM16.9867 14.8907L5.7082 25.1439C5.07382 25.7206 4.75663 26.009 4.72857 26.2588C4.70425 26.4753 4.78727 26.69 4.95091 26.8339C5.13971 26.9998 5.56837 26.9998 6.42571 26.9998H18.9413C20.8602 26.9998 21.8196 26.9998 22.5732 26.6775C23.5193 26.2728 24.2729 25.5191 24.6776 24.5731C25 23.8195 25 22.86 25 20.9411C25 20.2955 25 19.9727 24.9294 19.672C24.8407 19.2942 24.6706 18.9403 24.431 18.635C24.2403 18.3921 23.9883 18.1904 23.4841 17.7871L19.7544 14.8034C19.2499 14.3997 18.9976 14.1979 18.7197 14.1267C18.4748 14.0639 18.2172 14.072 17.9767 14.1501C17.7039 14.2387 17.4648 14.456 16.9867 14.8907Z"
                        stroke="#475467"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div class="col pe-3 displayNoneOnSmallScreens">
                    <div
                      class="d-flex form-floating inputsModals displayNoneOnSmallScreens"
                    >
                      <input
                        id="file"
                        class="borderRadius8 form-control mt-3"
                        type="file"
                      />
                      <label for="file" i18n="@@WhistleFile"
                        >{{ "clickToUploadOrDrag" | transloco }}
                      </label>
                    </div>
                  </div>
                  <div class="pt-2 col-4 h-100 displayNoneOnBigScreens">
                    <div
                      class="ps-2 pt-2"
                      style="
                        /* Base/White */
                        height: 40px;
                        width: 152px;
                        background: #ffffff; /* Gray/300 */
                        border: 1px solid #d0d5dd; /* Shadow/xs */
                        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                        border-radius: 8px;
                      "
                    >
                      <svg
                        for="custom-file-input"
                        width="20"
                        height="18"
                        viewBox="0 0 20 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.66663 12.3333L9.99996 9M9.99996 9L13.3333 12.3333M9.99996 9V16.5M16.6666 12.9524C17.6845 12.1117 18.3333 10.8399 18.3333 9.41667C18.3333 6.88536 16.2813 4.83333 13.75 4.83333C13.5679 4.83333 13.3975 4.73833 13.3051 4.58145C12.2183 2.73736 10.212 1.5 7.91663 1.5C4.46485 1.5 1.66663 4.29822 1.66663 7.75C1.66663 9.47175 2.36283 11.0309 3.48908 12.1613"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {{ "uploadFile" | transloco }}
                      <input
                        id="custom-file-input"
                        type="file"
                        class="custom-file-input"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-3 d-flex align-items-center anonymous py4onbig"
                >
                  <label
                    class="col-3 col-sm-4 col-md-4 col-lg-4"
                    i18n="@@Anonymous"
                    >{{ "anonymous" | transloco }}</label
                  >
                  <label class="col-1 toggle offsetMobile">
                    <input
                      class="toggle-checkbox"
                      type="checkbox"
                      id=" isAnonymous"
                      formControlName="isAnonymous"
                    />
                    <div class="toggle-switch"></div>
                  </label>
                  <span
                    class="col-6 ps-3 toggle-label anonDescription displayNoneOnSmallScreens"
                    >{{ "100Anonymous" | transloco }}</span
                  >
                </div>
              </div>
              <div class="showOnBigScreen row d-flex my-2">
                <div class="my-2 col-6">
                  <button
                    id="cancel"
                    class="w-100 btn shadow-sm cancelButton"
                    i18n="@@cancelWhistleBlow"
                  >
                    {{ "cancel" | transloco }}
                  </button>
                </div>
                <div class="my-2 col-6">
                  <button
                    type="submit"
                    class="w-100 btn shadow-sm sendButton"
                    i18n="@@saveWhistleBlow"
                  >
                    {{ "send" | transloco }}
                  </button>
                </div>
              </div>
              <div
                class="showOnSmallScreen d-flex row justify-content-around my-2"
              >
                <button
                  type="submit"
                  class="mt-2 btn shadow-sm col-12 col-sm-6 col-md-6 col-lg-6 sendButton"
                  i18n="@@saveWhistleBlow"
                >
                  {{ "send" | transloco }}
                </button>
                <button
                  id="cancel"
                  class="mt-2 btn shadow-sm col-12 col-sm-5 col-md-5 col-lg-5 cancelButton"
                  i18n="@@cancelWhistleBlow"
                >
                  {{ "cancel" | transloco }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
