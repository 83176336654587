<div class="row page-header d-flex justify-content-center user-select-none">
  <div class="col-12 col-lg-11 col-xl-10 max-1200 row">
    <div class="col-9 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title">
        {{ "outOfOffice" | transloco }}
      </h2>
      <span class="text-muted"
        >{{ "manageYour" | transloco }} {{ "outOfOffice" | transloco }}
        {{ "status" | transloco }}</span
      >
    </div>
    <div class="col-3 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end">
      <a
        routerLink="/out-of-office-status/new"
        class="btn d-flex btn-lg orange-dark text-white"
      >
        <img src="assets/icons/icon-add.svg" alt="Add" />
        <span class="text-btn-add"> {{ "add" | transloco }}</span>
      </a>
    </div>
    <app-out-of-office-status-grid
      (transmitDates)="transmitDates($event)"
      [dataSetter]="data"
    ></app-out-of-office-status-grid>
  </div>
</div>
