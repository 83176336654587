import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentTypesService } from '../../../../state/documenttype.service';
import {
  DocumentTypesRepository,
  IDocumentType,
} from '../../../../state/documenttype.repository';

@Component({
  selector: 'app-documenttype-editpage',
  templateUrl: './documenttype-editpage.component.html',
  styleUrls: ['./documenttype-editpage.component.scss'],
})
export class DocumenttypeEditpageComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private repo: DocumentTypesRepository,
    private service: DocumentTypesService
  ) {}

  id!: string;
  documenttype: IDocumentType | null = null;

  ngOnInit(): void {
    this.route.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id')!;

        this.service
          .loadOneDocumenttype(`${value.get('id')}`)
          .subscribe((x) => {
            this.documenttype = x;
          });
      },
    });
  }
}
