import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { SideBarItem } from '../../modules/core/components/sidebar/sidebarItems/sideMenuItem';
import {
  SIDEBAR_ADMIN_ITEMS,
  SIDEBAR_IGNITE_ITEMS,
  SIDEBAR_MAIN_ITEMS,
} from 'app/helpers/constants/sideBarItems';
import { GeneralAppDataDto } from 'app/api/models/auth/sideBarInfoDto';
import { TenantFeatures } from 'app/state/feature.repository';
import { getTenantsAsDataList } from 'app/helpers/arrayToDataListOptonConverter';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import { AuthRepository } from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { UiRepository } from 'app/state/ui.repository';
import { IMenuItem } from 'app/modules/core/components/menu-item/menu-item.component';
import { DocumentTypesRepository } from 'app/state/documenttype.repository';
import { DocumentTypesService } from 'app/state/documenttype.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GdprConfirmationComponent } from 'app/modules/shared/components/gdpr-confirmation/gdpr-confirmation.component';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { TenantsService } from 'app/state/tenants.service';

@Injectable({
  providedIn: 'root',
})
export class GeneralDataRepository {
  constructor(
    public env: EnvState,
    private auth: AuthService,
    public doctyperepo: DocumentTypesRepository,
    public doctypeservice: DocumentTypesService,
    public doctypesRepo: DocumentTypesRepository,
    private modalService: NgbModal,
    private tenantservice: TenantsService,
    private authRepo: AuthRepository
  ) {}
  private needToBeUpdated: boolean = true;

  private activeTenantFeaturesSubject: BehaviorSubject<Array<TenantFeatures>> =
    new BehaviorSubject<Array<TenantFeatures>>([]);

  private allMobileMenuItemsSubject: BehaviorSubject<Array<IMenuItem>> =
    new BehaviorSubject<Array<IMenuItem>>([]);

  private allMainSideMenuItemsSubject: BehaviorSubject<Array<SideBarItem>> =
    new BehaviorSubject<Array<SideBarItem>>([]);

  private allIgniteSideMenuItemsSubject: BehaviorSubject<Array<SideBarItem>> =
    new BehaviorSubject<Array<SideBarItem>>([]);

  private allAdminSideMenuItemsSubject: BehaviorSubject<Array<SideBarItem>> =
    new BehaviorSubject<Array<SideBarItem>>([]);

  private tenantsOptionsSubject: BehaviorSubject<Array<DatalistOption>> =
    new BehaviorSubject<Array<DatalistOption>>([]);

  private allGeneralDataSubject: BehaviorSubject<GeneralAppDataDto> =
    new BehaviorSubject<GeneralAppDataDto>(new GeneralAppDataDto());

  public activeTenantFeatures$: Observable<Array<TenantFeatures>> =
    this.activeTenantFeaturesSubject.asObservable();

  public allMobileMenuItems$: Observable<Array<IMenuItem>> =
    this.allMobileMenuItemsSubject.asObservable();

  public allMainSideMenuItems$: Observable<Array<SideBarItem>> =
    this.allMainSideMenuItemsSubject.asObservable();

  public allIgniteSideMenuItems$: Observable<Array<SideBarItem>> =
    this.allIgniteSideMenuItemsSubject.asObservable();

  public allAdminSideMenuItems$: Observable<Array<SideBarItem>> =
    this.allAdminSideMenuItemsSubject.asObservable();

  public tenantsOptions$: Observable<Array<DatalistOption>> =
    this.tenantsOptionsSubject.asObservable();

  public allGeneralData$: Observable<GeneralAppDataDto> =
    this.allGeneralDataSubject.asObservable();

  private allMobileMenuItems: Array<IMenuItem> = [];
  private allMainSideMenuItems: Array<SideBarItem> = [];
  private allIgniteSideMenuItems: Array<SideBarItem> = [];
  private allAdminSideMenuItems: Array<SideBarItem> = [];
  private tenantsOptions: Array<DatalistOption> = [];

  private generalAppData: GeneralAppDataDto = new GeneralAppDataDto();

  private isImpersonatingMode: boolean = false;

  updateAllGeneralData(forceUpdate: boolean = false) {
    if (this.needToBeUpdated || forceUpdate) {
      //load documents
      this.doctypeservice
        .loadShown()
        .subscribe((x) => x.filter((x) => x.isShownOnSideMenu == true));

      //get all info ( user, features, tenants)
      const tenantId = localStorage.getItem('activeTenantId');

      let data = this.auth.getGeneralAppDataForUser();

      if (tenantId) {
        data = this.auth.getGeneralAppDataForUser(tenantId!);
      }

      //check for impersonating
      this.authRepo.isImpersonating$.subscribe((x) => {
        this.isImpersonatingMode = x;
      });

      data.subscribe((responce) => {
        if (responce) {
          this.tenantsOptions = getTenantsAsDataList(responce.tenants);
          this.generalAppData = responce;
          this.generalAppData.tenantSet =
            !!localStorage.getItem('activeTenantId');

          this.getDisplayedSidebarItems(this.generalAppData);
          this.getDisplayedPhoneMenuItems(this.generalAppData);

          this.updateAllMainSideMenuItems(this.allMainSideMenuItems);
          this.updateAllIgniteSideMenuItems(this.allIgniteSideMenuItems);
          this.updateAllAdminSideMenuItems(this.allAdminSideMenuItems);
          this.updateTenantOptions(this.tenantsOptions);
          this.updateActiveTenantFeatures(responce.features);
          this.updateAllMobileMenuItems(this.allMobileMenuItems);
          this.loadTenantLogo();
          this.allGeneralDataSubject.next(this.generalAppData);
          this.needToBeUpdated = false;
        }
      });
    }
  }

  loadTenantLogo() {
    var tenantId = localStorage.getItem('activeTenantId');
    if (tenantId) {
      this.tenantservice.loadTenantDocument(tenantId).subscribe((y) => {
        if (y.documentPath) {
          this.generalAppData.imageLogosrc = y.documentPath;
          this.generalAppData.logoDownloaded = true;
          this.allGeneralDataSubject.next(this.generalAppData);
        } else {
          this.generalAppData.imageLogosrc = '/assets/img/header_logo.png';
          this.generalAppData.logoDownloaded = true;
          this.allGeneralDataSubject.next(this.generalAppData);
        }
      });
    }
  }

  updateActiveTenantFeatures(items: Array<TenantFeatures>): void {
    localStorage.setItem('activeTenantFeatures', items.toString());
    this.activeTenantFeaturesSubject.next(items);
  }

  updateAllMobileMenuItems(items: Array<IMenuItem>): void {
    this.allMobileMenuItems = [];
    this.allMobileMenuItemsSubject.next(items);
  }

  updateAllMainSideMenuItems(items: Array<SideBarItem>): void {
    this.allMainSideMenuItems = [];
    this.allMainSideMenuItemsSubject.next(items);
  }

  updateAllIgniteSideMenuItems(items: Array<SideBarItem>): void {
    this.allIgniteSideMenuItems = [];
    this.allIgniteSideMenuItemsSubject.next(items);
  }

  updateAllAdminSideMenuItems(items: Array<SideBarItem>): void {
    this.allAdminSideMenuItems = [];
    this.allAdminSideMenuItemsSubject.next(items);
  }

  updateTenantOptions(items: Array<DatalistOption>): void {
    this.tenantsOptions = [];
    this.tenantsOptionsSubject.next(items);
  }

  getDisplayedPhoneMenuItems(generalAppDataDto: GeneralAppDataDto) {
    const tenantFeatures = generalAppDataDto.features;
    this.allMobileMenuItems = [];

    if (tenantFeatures.includes(TenantFeatures.Sprints)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.Sprints.toLowerCase(),
        link: '/sprints',
        imageLink: `assets/icons/sidebar/colaborations.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.ToolBox)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.ToolBox.toLowerCase(),
        link: 'toolbox/areas',
        imageLink: `assets/icons/icon-${TenantFeatures.ToolBox.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.PersonalPlan)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.PersonalPlan.toLowerCase(),
        link: TenantFeatures.PersonalPlan.toLowerCase(),
        imageLink: `assets/icons/icon-${TenantFeatures.PersonalPlan.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (
      tenantFeatures.includes(TenantFeatures.PersonalPlan) &&
      generalAppDataDto.isCoach
    ) {
      this.allMobileMenuItems.push({
        name: 'personalcoach',
        link: '/personalcoach',
        imageLink: `assets/icons/icon-${TenantFeatures.PersonalPlan.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.Email)) {
      this.allMobileMenuItems.push({
        name: 'notifications',
        link: '/notifications/received',
        imageLink: 'assets/icons/recMailsMobile.svg',
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.News)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.News.toLowerCase(),
        link: '/posts',
        imageLink: `assets/icons/icon-${TenantFeatures.News.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.Calendar)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.Calendar.toLowerCase(),
        link: '/calendar',
        imageLink: `assets/icons/icon-${TenantFeatures.Calendar.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.TimeLogs)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.TimeLogs.toLowerCase(),
        link: TenantFeatures.TimeLogs.toLowerCase(),
        imageLink: `assets/icons/icon-${TenantFeatures.TimeLogs.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.Whistleblow)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.Whistleblow.toLowerCase(),
        link: TenantFeatures.Whistleblow.toLowerCase(),
        imageLink: `assets/icons/icon-${TenantFeatures.Whistleblow.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.Messages)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.Messages.toLowerCase(),
        link: TenantFeatures.Messages.toLowerCase(),
        imageLink: `assets/icons/icon-${TenantFeatures.Messages.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.Maps)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.Maps.toLowerCase(),
        link: TenantFeatures.Maps.toLowerCase(),
        imageLink: `assets/icons/icon-${TenantFeatures.Maps.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.Apv)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.Apv.toLowerCase(),
        link: TenantFeatures.Apv.toLowerCase(),
        imageLink: `assets/icons/icon-${TenantFeatures.Apv.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (tenantFeatures.includes(TenantFeatures.OutOfOfficeStatus)) {
      this.allMobileMenuItems.push({
        name: TenantFeatures.OutOfOfficeStatus.toLowerCase(),
        link: 'out-of-office-status',
        imageLink: `assets/icons/icon-${TenantFeatures.OutOfOfficeStatus.toLowerCase()}.svg`,
        subItems: undefined,
        disabled: false,
      });
    }

    if (
      tenantFeatures.includes(TenantFeatures.Documents) &&
      generalAppDataDto.isTenantAdmin
    ) {
      this.doctyperepo.all$.subscribe((dts) => {
        dts
          .filter((dt) => dt.isShownOnSideMenu)
          .map((dt) => {
            this.allMobileMenuItems.push({
              name:
                dt.name.length < 16 ? dt.name : dt.name.slice(0, 15) + '...',
              link: TenantFeatures.Documents.toLowerCase() + `/${dt.id}`,
              imageLink: `assets/icons/icon-${TenantFeatures.Documents.toLowerCase()}.svg`,
              subItems: undefined,
              disabled: false,
            });
          });
      });
    }
  }

  getDisplayedSidebarItems(sidebarInfoDto: GeneralAppDataDto) {
    this.allMainSideMenuItems = [];
    const tenantFeatures = sidebarInfoDto.features;
    this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.homeMain);

    if (tenantFeatures.includes(TenantFeatures.Feed)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.feed);
    }

    if (tenantFeatures.includes(TenantFeatures.Sprints)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.colaborationsMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Onboarding)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.onboardingMain);
    }

    if (tenantFeatures.includes(TenantFeatures.ToolBox)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.toolBoxMain);
    }

    if (tenantFeatures.includes(TenantFeatures.PersonalPlan)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.personalplanMain);
    }

    if (
      tenantFeatures.includes(TenantFeatures.PersonalPlan) &&
      sidebarInfoDto.isCoach
    ) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.personalcoachMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Email)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.receivedemailsMain);
    }

    if (tenantFeatures.includes(TenantFeatures.News)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.newsMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Calendar)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.calendarMain);
    }

    if (tenantFeatures.includes(TenantFeatures.OutOfOfficeStatus)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.outOfOfficeMain);
    }

    if (tenantFeatures.includes(TenantFeatures.TimeLogs)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.timelogsMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Whistleblow)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.whistleblowMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Messages)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.messagesMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Maps)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.mapMain);
    }

    if (tenantFeatures.includes(TenantFeatures.Apv)) {
      this.allMainSideMenuItems.push(SIDEBAR_MAIN_ITEMS.apvMain);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Documents) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.doctyperepo.all$.subscribe((docs) => {
        docs.filter((x) => x.isShownOnSideMenu);
        docs.forEach((doc) => {
          this.allMainSideMenuItems.push({
            routerLink: TenantFeatures.Documents.toLowerCase() + `/${doc.id}`,
            svgPath: 'assets/icons/sidebar/documents.svg',
            title:
              doc.name.length < 16 ? doc.name : doc.name.slice(0, 15) + '...',
          });
        });
      });
    }
    //Ignite -----------------------------------------------------

    if (
      tenantFeatures.includes(TenantFeatures.Sprints) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.colaborations);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Onboarding) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.onboarding);
    }
    if (
      tenantFeatures.includes(TenantFeatures.ToolBox) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.toolBoxAdmin);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Email) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.emails);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Marketplace) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.marketplace);
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.billing);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Apv) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.apvAdmin);
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.apvAnswers);
    }
    if (
      tenantFeatures.includes(TenantFeatures.Documents) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.documents);
    }

    if (
      tenantFeatures.includes(TenantFeatures.News) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.newsAdmin);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Calendar) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.calendarAdmin);
    }

    if (
      tenantFeatures.includes(TenantFeatures.TimeLogs) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.timelogsAdmin);
      this.allIgniteSideMenuItems.push(
        SIDEBAR_IGNITE_ITEMS.timelogsOverviewAdmin
      );
    }

    if (
      tenantFeatures.includes(TenantFeatures.Whistleblow) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.whistleblowAdmin);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Messages) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.messagesAdmin);
    }

    if (
      tenantFeatures.includes(TenantFeatures.Maps) &&
      sidebarInfoDto.isTenantAdmin
    ) {
      this.allIgniteSideMenuItems.push(SIDEBAR_IGNITE_ITEMS.clients);
    }

    //Admin -----------------------------------------------------

    if (sidebarInfoDto.isSuperAdmin) {
      this.allAdminSideMenuItems.push(SIDEBAR_ADMIN_ITEMS.tenants);
    }

    if (sidebarInfoDto.isAnyAdmin) {
      this.allAdminSideMenuItems.push(SIDEBAR_ADMIN_ITEMS.users);
      this.allAdminSideMenuItems.push(SIDEBAR_ADMIN_ITEMS.userGroups);
    }

    if (sidebarInfoDto.tenantSet && sidebarInfoDto.isAnyAdmin) {
      this.allAdminSideMenuItems.push(SIDEBAR_ADMIN_ITEMS.reports);
    }
  }
}
