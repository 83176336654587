import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent {
  @Input() item!: IMenuItem;
  @Output() subMenuOpen = new EventEmitter<IMenuItem[]>();

  handleClick() {
    this.subMenuOpen.emit(this.item.subItems);
  }
}

export interface IMenuItem {
  name: string;
  link: string;
  imageLink: string;
  subItems: IMenuItem[] | undefined;
  disabled: boolean;
}
