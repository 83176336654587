<div>
  <div *ngIf="!isForUser && !isForReply && !userEdit">
    <mat-form-field class="w-100 file-inpt;">
      <div class="p-0">
        <mat-toolbar style="background-color: white !important">
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon cursor-pointer"
            (click)="fileInput.click()"
          >
            <img src="assets/img/fileInptIco.svg" />
          </div>
          <input
            class="ps-2 inputWrapper displayNoneOnSmallScreens rounded"
            readonly
            (click)="fileInput.click()"
            matInput
            [value]="
              selectedFileNames.length > 0 ? selectedFileNames : placeholder
            "
          />

          <div class="pt-2 displayNoneOnBigScreens">
            <div
              class="form-floating file-inpt-sm d-flex align-items-center ps-2"
              (click)="fileInput.click()"
            >
              <label class="label" for="custom-file-input">
                <img src="assets/img/fileinpt.svg" />
                <span> {{ "uploadFile" | transloco }}</span>
              </label>
            </div>
          </div>
          <div
            *ngIf="selectedFileNames && showDefaultDelete"
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon cursor-pointer ms-3"
            (click)="deleteFile()"
          >
            <img src="assets/img/delete.svg" />
          </div>
        </mat-toolbar>

        <input
          style="display: none !important"
          #fileInput
          type="file"
          id="fileInput"
          name="fileInput"
          accept="{{ fileType }}"
          (change)="selectFiles($event)"
        />
      </div>
    </mat-form-field>
  </div>

  <div *ngIf="isForUser">
    <mat-form-field class="w-100 justify-content-between">
      <div class="p-0">
        <mat-toolbar>
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon cursor-pointer"
            (click)="fileInput.click()"
          >
            <img src="assets/img/fileInptIco.svg" />
          </div>
          <input
            class="ps-2 inputWrapper displayNoneOnSmallScreens rounded"
            readonly
            (click)="fileInput.click()"
            matInput
            [value]="selectedFileNames.length ? selectedFileNames : placeholder"
          />
          <div
            *ngIf="selectedFileNames.length"
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon cursor-pointer"
            (click)="deleteFile()"
          >
            <img src="assets/img/delete.svg" />
          </div>
        </mat-toolbar>

        <input
          style="display: none !important"
          #fileInput
          type="file"
          id="fileInput"
          name="fileInput"
          accept="image/*,
          .csv,
           application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-excel,
            text/plain, .pdf
            "
          (change)="selectFiles($event)"
        />
      </div>
    </mat-form-field>
  </div>
  <div *ngIf="isForReply">
    <mat-form-field style="display: none" class="w-100 justify-content-between">
      <div class="p-0">
        <mat-toolbar>
          <div
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon cursor-pointer"
            (click)="fileInput.click()"
          >
            <img src="assets/img/fileInptIco.svg" />
          </div>
          <input
            class="ps-2 inputWrapper displayNoneOnSmallScreens rounded"
            readonly
            (click)="fileInput.click()"
            matInput
            [value]="selectedFileNames.length ? selectedFileNames : placeholder"
          />
          <div
            *ngIf="selectedFileNames.length"
            class="d-flex me-3 align-items-center justify-content-center fileModalIcon cursor-pointer"
            (click)="deleteFile()"
          ></div>
        </mat-toolbar>

        <input
          style="display: none !important"
          #fileInput
          type="file"
          id="fileInput"
          name="fileInput"
          accept="image/*,
          .csv,
           application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            application/vnd.ms-excel,
            text/plain, .pdf
            "
          (change)="selectFiles($event)"
        />
      </div>
    </mat-form-field>
    <button
      *ngIf="!selectedFileNames.length"
      (click)="fileInput.click()"
      class="btn btn-orange"
      title="Upload File"
    >
      <img src="assets/img/paperclip.svg" />
    </button>
    <button
      *ngIf="selectedFileNames.length"
      (click)="deleteFile()"
      class="btn btn-orange"
      title="Delete File"
    >
      <img style="color: white" src="assets/img/delete_15x15_white.svg" />
    </button>
  </div>

  <div *ngIf="userEdit">
    <div class="d-flex p-0">
      <div
        class="d-flex me-3 align-items-center justify-content-center fileModalIcon"
      >
        <img src="assets/img/fileInptIco.svg" />
      </div>
      <div class="d-flex form-floating col file-inpt">
        <input
          formControlName="picture"
          type="file"
          class="form-control mt-3"
          [value]="selectedFileNames.length ? selectedFileNames : placeholder"
          accept="{{ fileType }}"
          (change)="selectFiles($event)"
        />
        <label for="file">{{ "clickToUpload" | transloco }} </label>
      </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="selectedFileNames">
      <div class="pe-2">{{ selectedFileNames }}</div>
      <div (click)="deleteFile()" class="cursor-pointer">
        <img src="assets/img/trash.svg" />
      </div>
    </div>
  </div>

  <div *ngIf="showFileError && !isForReply && !isForUser" style="color: red">
    {{ fileError }}
  </div>
</div>
