import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  IOutOfOfficeStatus,
  IOutOfOfficeStatusForm,
  OutTypes,
} from '../../../../state/out-of-office-status.repository';
import { OutOfOfficeStatusService } from '../../../../state/out-of-office-status.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRepository } from '../../../../state/auth.repository';
import { User } from '../../../../state/users.repository';
import { DatePipe } from '@angular/common';
import * as dayjs from 'dayjs';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-out-of-office-status-page-form',
  templateUrl: './out-of-office-status-form.component.html',
  styleUrls: ['./out-of-office-status-form.component.scss'],
})
export class OutOfOfficeStatusFormComponent implements OnInit {
  editForm: UntypedFormBuilder | any;
  outTypes: DatalistOption[] = OutTypes;
  oneDay: boolean = true;
  currentUserId: string | null = null;
  statusId: string = '';
  usersOptions: DatalistOption[] | null = null;
  status: IOutOfOfficeStatusForm | null = null;
  deleteConfirmation: boolean = false;
  controlDefaultValue: dayjs.Dayjs = dayjs(new Date())
    .set('hours', 9.0)
    .set('minutes', 0);
  isEditForm$ = false;

  @Input() set editedStatus(value: IOutOfOfficeStatusForm | null) {
    if (!value) {
      this.status = null;
    } else {
      this.status = value;
      this.oneDay = !value.dateTo;
      this.isEditForm$ = false;
    }
    this.formCreate();
  }

  @Output() submitStatus = new EventEmitter<Partial<IOutOfOfficeStatus>>();

  @Input() set users(value: DatalistOption[] | null) {
    if (!value) {
      this.usersOptions = null;
    } else {
      this.usersOptions = value;
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public repo: AuthRepository,
    public datePipe: DatePipe,
    private route: ActivatedRoute,
    public service: OutOfOfficeStatusService,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.currentUserId = this.repo.getId();
    this.route.params.subscribe((r) => {
      if (r['id']) {
        this.statusId = r['id'];
        this.isEditForm$ = true;
      }
    });
  }

  formCreate() {
    this.editForm = this.formBuilder.group({
      oneDayCheckbox: this.oneDay,
      outType: [
        this.status?.type ?? this.outTypes[0].value,
        Validators.required,
      ],
      dateFrom: [
        this.datePipe.transform(
          this.status ? this.status.dateFrom : new Date(),
          'yyyy-MM-dd'
        ),
        Validators.required,
      ],
      dateTo: this.datePipe.transform(
        this.status
          ? this.status.dateTo
          : this.controlDefaultValue.add(1, 'day').toDate(),
        'yyyy-MM-dd'
      ),
      timeFrom: this.datePipe.transform(
        this.status
          ? this.status.timeFrom?.toString()
          : this.controlDefaultValue.toDate(),
        'HH:mm'
      ),
      timeTo: this.datePipe.transform(
        this.status
          ? this.status.timeTo?.toString()
          : this.controlDefaultValue.add(9, 'hour').toDate(),
        'HH:mm'
      ),
      userSelect: this.status?.userId,
    });
  }

  onSubmit(): void {
    if (this.editForm && !this.editForm.valid) {
      this.formUpdated();
      this.editForm.markAllAsTouched();
      return;
    }
    const outType = this.editForm.value?.outType.toString();
    const dateFrom = this.editForm.value?.dateFrom;
    const dateTo = this.editForm.value?.dateTo;
    const timeFrom = this.editForm.value?.timeFrom;
    const timeTo = this.editForm.value?.timeTo;
    const userId = this.status
      ? this.status.userId
      : this.editForm.value?.userSelect?.toString();
    const from = this.oneDay
      ? new Date(dateFrom + ' ' + timeFrom)
      : new Date(dateFrom + ' 00:00');
    const to = this.oneDay
      ? new Date(dateFrom + ' ' + timeTo)
      : new Date(dateTo + ' 23:59');
    this.submitStatus.emit({
      type: outType,
      from,
      to,
      userId,
    });
  }

  deleteStatus(id: string) {
    this.service.removeStatus(id).subscribe((res) => {
      this.router.navigate(['out-of-office-status']);
    });
  }

  formUpdated() {
    if (this.oneDay) {
      if (!this.editForm.controls['timeFrom'].value) {
        this.editForm.controls['timeFrom'].setErrors({ required: true });
      }
      if (!this.editForm.controls['timeTo'].value) {
        this.editForm.controls['timeTo'].setErrors({ required: true });
      }
      if (
        this.editForm.controls['timeTo'].value <=
        this.editForm.controls['timeFrom'].value
      ) {
        this.editForm.controls['timeTo'].setErrors('Invalid end time!');
      }
    } else {
      if (
        this.editForm.controls['dateTo'].value <=
        this.editForm.controls['dateFrom'].value
      ) {
        this.editForm.controls['dateTo'].setErrors('Invalid end date!');
      }
    }
  }

  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  checkValue($event: any) {
    this.oneDay = $event.target.checked;
    this.resetForm();
    this.formUpdated();
  }

  resetForm() {
    this.editForm.controls['dateTo'].value = this.datePipe.transform(
      this.status?.dateTo
        ? this.status.dateTo
        : this.status?.dateFrom
        ? dayjs(this.status.dateFrom).add(1, 'days').toDate()
        : this.controlDefaultValue.add(1, 'day').toDate(),
      'yyyy-MM-dd'
    );
    this.editForm.controls['timeFrom'].value = this.datePipe.transform(
      this.status?.timeFrom
        ? this.status.timeFrom?.toString()
        : this.controlDefaultValue.toDate(),
      'HH:mm'
    );
    this.editForm.controls['timeTo'].value = this.datePipe.transform(
      this.status?.timeTo
        ? this.status.timeTo?.toString()
        : this.controlDefaultValue.add(9, 'hours').toDate(),
      'HH:mm'
    );
  }
}
