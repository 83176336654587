import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  AuthRepository,
  IdentityError,
  Office365Settings,
  getStoredToken,
} from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { TenantsRepository } from 'app/state/tenants.repository';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import { HttpClient } from '@angular/common/http';
import { DatalistOption } from 'app/state/abstract/base.repository';

@Component({
  selector: 'app-office365auth',
  templateUrl: './office365auth.component.html',
  styleUrls: ['./office365auth.component.scss'],
})
export class Office365authComponent implements OnInit, AfterViewInit {
  title: any;
  code?: string | null = null;
  skip = false;
  error: string | null = null;
  testsLocation = 'localhost:9876';
  settings$: Observable<Office365Settings> | null = null;
  tenantsOptions: DatalistOption[] | null = null;
  userExists = false;
  loginForm = this.formBuilder.group({
    tenantId: [''],
  });
  submitErrors: IdentityError[] | null = null;
  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public env: EnvState,
    private http: HttpClient,
    // private tenantsService: TenantsService,
    public tenantRepo: TenantsRepository
  ) {
    this.code = this.route.snapshot.queryParams['code'];
  }

  ngOnInit(): void {
    this.code = this.route.snapshot.queryParams['code'];
    if (this.code) {
      this.auth.loginOffice365Auth(this.code).subscribe({
        next: (value) => {
          if (!value) {
            this.tenantsOptions = null;
          }
          if (value.user) {
            this.userExists = true;
          }
          if (value.user?.tenants) {
            this.tenantRepo.setTenants(value.user?.tenants);
            if (value.user?.tenants.length > 1) {
              this.tenantsOptions = value.user?.tenants.map((x: any) => ({
                value: x.id,
                label: x.name,
              }));
            } else if (value.user?.tenants.length === 1) {
              this.updateValue('tenantId', value.user?.tenants[0].id);
              this.setActiveTenant();
            } else {
              this.skip = true;
              this.setActiveTenant();
            }
          }
        },
        error: (data) => {
          this.submitErrors = data;
          this.router.navigate(['signin/:error']);
        },
      });
    }
  }

  ngAfterViewInit(): void {}
  setActiveTenant(): void {
    let isSuperadmin = localStorage.getItem('isSuperadmin');

    localStorage.setItem('activeTenantId', this.loginForm.value.tenantId);
    let token = getStoredToken();
    this.auth
      .setActiveTenant(token!, this.skip ? null : this.loginForm.value.tenantId)
      .subscribe({
        complete: () => {
          localStorage.removeItem('userExists');
          localStorage.removeItem('isSuperadmin');
          this.router.navigate(['/home']);
        },
        error: (err) => this.renderError(err),
      });
  }

  private renderError(err: any) {
    this.title = err.error;
    setTimeout(this.showTooltip);
  }

  updateValue(control: string, value: any) {
    const controlObject = this.loginForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }

  private showTooltip() {
    var tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltiptriggerList.map(function (e: any) {
      return new bootstrap.Tooltip(e);
    });
  }

  getErrors(errors: IdentityError[]): string[] | undefined {
    if (errors && errors.length > 0) {
      let simpleErrors = errors.map((x) => {
        return x.description;
      });
      return simpleErrors;
    }
    return;
  }
}

// this.tenantsService.load().subscribe();
// this.tenantSub = this.tenantRepo.tenants$.subscribe(value => {
//   if (!value) {
//     this.tenantsOptions = null;
//   } else {
//     this.tenantsOptions = value.map(x => ({
//       value: x.id,
//       label: x.name
//     }));
//   }
// });
