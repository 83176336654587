<div class="row page-header d-flex justify-content-center">
  <div class="col-12 col-lg-11 col-xl-10 max-950 row">
    <div class="col-6 mt-sm-4 mb-sm-3 p-0">
      <h2 class="page-title" i18n="Tenants label">
        {{ "tenants" | transloco }}
      </h2>
    </div>
    <div
      *ngIf="auth.isAnyAdmin$ | async"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <p-button
        label="{{ 'add' | transloco }}"
        icon="pi pi-plus"
        [routerLink]="['/tenants/new']"
      />
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <app-tenant-list
      *ngIf="!(repo.isLoading$ | async)"
      class="sm-m p-0 m-0 mb-2"
      style="width: 100%"
      [innerWidth]="innerWidth"
      [tenants]="allTenants"
      [isDeleteDisabled]="
        !(auth.isSuperAdmin$ | async) && !(auth.isPartnerManager$ | async)
      "
      (delete)="delete($event)"
      (clone)="handleCloneClick($event); (refreshData)"
    ></app-tenant-list>
  </div>
</div>
