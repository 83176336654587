<div class="large mt-3">
  <div class="d-flex justify-content-between">
    <div class="">
      <button
        (click)="pageChange.emit(page - 1)"
        [disabled]="page === 1"
        [class.text-muted]="page === 1"
        class="btn button-back"
      >
        <img src="assets/img/arrow-left.svg" />
        <span *ngIf="innerWidth > 1050" class="txt-bold">{{
          "previous" | transloco
        }}</span>
      </button>
    </div>

    <div class="only-large" role="group">
      <button
        *ngFor="let pageNumber of calculatePages()"
        class="btn"
        class="button-page"
        [class.current-button-page]="page === pageNumber"
        (click)="pageChange.emit(pageNumber)"
      >
        {{ pageNumber }}
      </button>
    </div>
    <div class="">
      <button
        class="btn button-next"
        (click)="pageChange.emit(page + 1)"
        [disabled]="page === pages.length || pages.length === 0"
        [class.text-muted]="page === pages.length"
      >
        <span class="txt-bold">{{ "next" | transloco }}</span
        ><img src="assets/img/arrow-right.svg" />
      </button>
    </div>
  </div>
</div>

<div class="small">
  <div class="d-flex">
    <div class="me-2">
      <button
        class="btn button-back"
        (click)="pageChange.emit(page - 1)"
        [disabled]="page === 1"
        [class.text-muted]="page === 1"
      >
        <img src="assets/img/arrow-left.svg" />
      </button>
    </div>
    <div class="ms-2">
      <button
        class="btn button-next"
        (click)="pageChange.emit(page + 1)"
        [disabled]="page === pages.length"
        [class.text-muted]="page === pages.length || pages.length === 0"
      >
        <span class="txt-bold">{{ "next" | transloco }}</span
        ><img src="assets/img/arrow-right.svg" />
      </button>
    </div>
  </div>
</div>
