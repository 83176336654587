import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss'],
})
export class ConfirmDeleteComponent {
  preConfirmText: string = 'youAreAboutToDeleteTheFollowingEntry';
  confirmText: string = 'pleaseConfirmYourActionByClickingDelete';
  confirmBtnText: string = 'delete';

  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Input() set isRestore(value: boolean) {
    if (value) {
      this.preConfirmText = 'youAreAboutToRestore';
      this.confirmText = 'pleaseConfirmYourActionByClickingConfirm';
      this.confirmBtnText = 'confirm';
    }
  }
  @Input() deleteCandidate: string = '';
  constructor() {}
}
