import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Post } from 'app/state/posts.repository';
import { PostsService } from 'app/state/posts.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-news-info',
  templateUrl: './news-info.component.html',
  styleUrls: ['./news-info.component.scss'],
})
export class NewsInfoComponent implements OnInit {
  readonly DEFAULT_IMAGE = 'assets/img/user_default.svg';
  readonly DEFAULT_POST_IMAGE = 'assets/img/preview.jpg';
  post: Post | null = null;
  id: string | null = null;
  submitErrors: string[] | null = null;

  sanitizedUrl: any | undefined = undefined;

  constructor(
    private postService: PostsService,
    private activatedRoute: ActivatedRoute,
    public blobService: BlobStorageService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        this.postService
          .loadOneForAdmin(`${this.id}`, ['read'])
          .subscribe((x) => {
            this.post = x;
            if (
              this.post.documents &&
              this.post.documents[0] &&
              this.post.documents[0].videoUrl
            ) {
              this.sanitizedUrl = this.sanitizeUrl(
                this.post.documents[0].videoUrl
              );
            }
          });
      },
    });
  }

  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
