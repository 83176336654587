<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-12 col-lg-11 col-xl-10 max-900 row p-md-2 p-lg-0 p-0">
    <div class="col-6 mt-sm-4 mb-sm-3 p-sm-0 ps-2">
      <h2 class="page-title" i18n="Label users">
        {{ "notificationsList" | transloco }}
      </h2>
    </div>
    <div
      *ngIf="innerWidth > 1050"
      [hidden]="innerWidth < 1050"
      class="col-6 mt-sm-4 mb-sm-3 p-0 d-flex justify-content-end"
    >
      <a
        routerLink="/admin/timelogs/project/new"
        class="btn btn-lg orange-dark text-white"
        style="justify-content: space-between"
      >
        <svg
          width="14"
          height="15"
          viewBox="0 0 14 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.00008 1.66699V13.3337M1.16675 7.50033H12.8334"
            stroke="white"
            stroke-width="1.66667"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span class="text-btn-add" i18n="@@addProject">{{
          "addProject" | transloco
        }}</span>
      </a>
    </div>

    <!-- <div class="container mb-3">
      <div class="row">
        <div class="col-sm">
          <button
            style="width: 100%; height: 100%"
            (click)="changePageType(1)"
            [disabled]="curPageType === 1"
            class="btn btn-orange"
          >
            {{ "myNotifications" | transloco }}
          </button>
        </div>
        <div class="col-sm">
          <button
            style="width: 100%; height: 100%"
            (click)="changePageType(2)"
            [disabled]="curPageType === 2"
            class="btn btn-orange"
          >
            {{ "notificationsFromOtherUsers" | transloco }}
          </button>
        </div>
        <div class="col-sm">
          <button
            style="width: 100%; height: 100%"
            (click)="changePageType(3)"
            [disabled]="curPageType === 3"
            class="btn btn-orange"
          >
            {{ "otherUsersNotifications" | transloco }}
          </button>
        </div>
      </div>
    </div> -->

    <div class="pe-1 ps-1 p-sm-0 d-flex justify-content-between">
      <app-search
        class="col-auto"
        style="min-width: 100%"
        (searchChange)="setFilter($event)"
      ></app-search>
    </div>
    <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
    <div class="mb-2 box mt-3 m-0 p-0 row" style="width: 100%">
      <div class="box-name-header" style="width: 100%">
        <div class="mt-1 ms-1 row pt-1 m-0 p-0">
          <div class="col-sm-4 col-4">
            <span class="txt">{{ "name" | transloco }} </span>
          </div>
          <div class="col-sm-4 col-4" style="text-align: start">
            <span class="txt">{{ "description" | transloco }} </span>
          </div>

          <div class="col-sm-4 col-4" style="text-align: end">
            <span class="txt">{{ "subscriptionType" | transloco }} </span>
          </div>
        </div>
      </div>
      <br />

      <div *ngIf="curPageType === 1">
        <div
          *ngFor="let project of repo.page$ | async"
          class="row brd"
          style="margin-left: 0.1rem"
        >
          <div class="col-sm-4 col-4 pt-3 pb-3 d-flex align-items-center psm0">
            <div class="txt-name">
              <div class="text-break">
                {{ project.name && project.name | slice : 0 : 50 }}
                <span *ngIf="project.name && project.name.length > 50"
                  >...</span
                >
              </div>
            </div>
          </div>
          <div class="col-sm-6 col-6 d-flex align-items-center text-break">
            <span class="role"
              >{{ project.note && project.note | slice : 0 : 50
              }}<span *ngIf="project.note && project.note.length > 50"
                >...</span
              >
            </span>
          </div>

          <div class="col-2 d-flex align-items-center justify-content-end">
            <div style="min-width: 170px">
              <app-datalist-select
                [options]="options"
                [multiple]="false"
                class="form-floating"
                [activeValue]="getSubStatues(project.id)"
                (activeValueChange)="changeOpt($event, project.id)"
              >
              </app-datalist-select>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="curPageType == 2">
        <div
          *ngFor="let project of repo.page$ | async"
          class="row brd"
          style="margin-left: 0.1rem"
        >
          <div class="col-sm">
            <div class="txt-name">
              <div class="text-break">
                {{ project.name && project.name | slice : 0 : 50 }}
                <span *ngIf="project.name && project.name.length > 50"
                  >...</span
                >
              </div>
            </div>
          </div>
          <div class="col-sm">
            <span class="role"
              >{{ project.note && project.note | slice : 0 : 50
              }}<span *ngIf="project.note && project.note.length > 50"
                >...</span
              >
            </span>
          </div>

          <div class="col-sm">
            <div>
              <app-datalist-select
                [options]="userOptions"
                [multiple]="true"
                class="form-floating"
                [activeValue]="getSubStatues(project.id)"
                (activeValueChange)="changeOpt($event, project.id)"
              >
              </app-datalist-select>
            </div>
          </div>

          <div class="col-sm">
            <div style="min-width: 170px">
              <app-datalist-select
                [options]="options"
                [multiple]="false"
                class="form-floating"
                [activeValue]="getSubStatues(project.id)"
                (activeValueChange)="changeOpt($event, project.id)"
              >
              </app-datalist-select>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div class="box-pagination-footer">
        <app-custom-pagination
          [innerWidth]="innerWidth"
          [page]="(repo.pageNumber$ | async) || 1"
          [total]="(repo.paginationData$ | async)?.lastPage || 0"
          (pageChange)="
            service.loadFilterPage(searchFilter, $event).subscribe()
          "
        ></app-custom-pagination>

        <div
          class="w-100 d-flex justify-content-end add"
          *ngIf="innerWidth < 1050"
        >
          <a
            routerLink="/admin/timelogs/project/new"
            class="btn btn-lg orange-dark text-white"
            style="justify-content: space-around"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.99996 1.16699V12.8337M1.16663 7.00033H12.8333"
                stroke="white"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span
              style="font-size: 14px"
              class="text-btn-add"
              i18n="@@addProject"
              >Add Project</span
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
