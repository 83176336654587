import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.scss'],
})
export class CustomPaginationComponent implements OnInit {
  @Input() innerWidth = 0;
  @Input() page: number = 1;
  @Input() set total(length: number) {
    this.pages = Array.from({ length }, (_, i) => i + 1);
  }
  @Output() pageChange = new EventEmitter<number>();

  pages: number[] = [];

  constructor() {}

  ngOnInit(): void {}

  calculatePages() {
    if (this.pages.length > 5) {
      let statrSlice = this.page > 2 ? this.page - 3 : 0;
      let endSlice = this.page > 2 ? this.page + 3 : 6;

      if (this.pages.length - 3 < this.page) {
        statrSlice = this.pages.length - 6;
      }

      return this.pages.slice(statrSlice, endSlice);
    }

    return this.pages;
  }
}
