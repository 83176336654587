import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApvComponent } from './apv/apv.component';
import { ApvFormComponent } from './apv-form/apv-form.component';
import { ApvPostComponent } from './apv-post/apv-post.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ApvEditComponent } from './apv-edit/apv-edit.component';
import { ApvAdminComponent } from './apv-admin/apv-admin.component';
import { ApvAnswerComponent } from './apv-answer/apv-answer.component';
import { ApvAnswerFormComponent } from './apv-answer-form/apv-answer-form.component';
import { ApvMyanswersComponent } from './apv-myanswers/apv-myanswers.component';
import { ApvAnswerAdminComponent } from './apv-answer-admin/apv-answer-admin.component';
import { TranslocoRootModule } from 'app/transloco-root.module';

@NgModule({
  declarations: [
    ApvComponent,
    ApvFormComponent,
    ApvPostComponent,
    ApvEditComponent,
    ApvAdminComponent,
    ApvAnswerComponent,
    ApvAnswerFormComponent,
    ApvMyanswersComponent,
    ApvAnswerAdminComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CKEditorModule,
    TranslocoRootModule,
  ],
})
export class ApvModule {}
