<div *ngIf="isLoading">
  <app-spinner></app-spinner>
</div>
<div *ngIf="!isLoading">
  <h2 class="mb-4" *ngIf="!id" i18n="Create tenant label">
    {{ "createTenant" | transloco }}
  </h2>
  <h2 class="mb-4" *ngIf="id" i18n="Edit tenant label">
    {{ "editTenant" | transloco }}
  </h2>
  <div *ngIf="tenant" class="row mb-4">
    <div class="col-12">
      <app-tenant-form
        *ngIf="!(featureRepo.isLoading$ | async)"
        [editedTenant]="tenant"
        [features]="featureRepo.all$ | async"
        (tenantSubmit)="createTenant($event)"
      ></app-tenant-form>
      <h2 *ngIf="featureRepo.isLoading$ | async">
        <app-spinner></app-spinner>
      </h2>
      <app-error-alert
        title="{{ 'couldNotSubmitTheTenantProfile' | transloco }}"
        i18n-title="Tenant profile submit error alert"
        [errors]="submitErrors"
      ></app-error-alert>
    </div>
  </div>
  <div *ngIf="!tenant" class="row mb-4">
    <div class="col-12">
      <app-tenant-form
        *ngIf="!(featureRepo.isLoading$ | async)"
        [features]="featureRepo.all$ | async"
        (tenantSubmit)="createTenant($event)"
      ></app-tenant-form>
      <h2 *ngIf="featureRepo.isLoading$ | async">
        <app-spinner></app-spinner>
      </h2>
      <app-error-alert
        title="{{ 'couldNotSubmitTheTenantProfile' | transloco }}"
        i18n-title="Tenant profile submit error alert"
        [errors]="submitErrors"
      ></app-error-alert>
    </div>
  </div>
</div>
