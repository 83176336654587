import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CLIENT_PATHS } from './helpers/constants/paths.core.constants';
import { AuthGuard } from './guards/auth.guard';
import { ApphomelayoutComponent } from './modules/core/layouts/apphomelayout/apphomelayout.component';
import { TenantFeatures } from './state/feature.repository';
import { UserRoles } from './state/auth.repository';

const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: CLIENT_PATHS.reports,
        loadChildren: () =>
          import('./modules/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
        },
      },
      {
        path: CLIENT_PATHS.personalPlan,
        loadChildren: () =>
          import('./modules/my-personal-plan/personalplan.module').then(
            (m) => m.PersonalPlanModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.PersonalPlan,
        },
      },
      {
        path: CLIENT_PATHS.personalCoach,
        loadChildren: () =>
          import('./modules/personalCoach/personalCoachModule').then(
            (m) => m.PersonalCoachModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.PersonalPlan,
        },
      },
      {
        path: CLIENT_PATHS.deleting,
        loadChildren: () =>
          import('./modules/permanentlyDelete/permanently-delete.module').then(
            (m) => m.PermanentlyDeleteModule
          ),
        canActivate: [AuthGuard],
        data: {
          authorize: [],
        },
      },
      {
        path: CLIENT_PATHS.emails,
        loadChildren: () =>
          import('./modules/emails/emails.module').then((m) => m.EmailsModule),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.Email,
        },
      },
      {
        path: CLIENT_PATHS.usergroups,
        loadChildren: () =>
          import('./modules/usergroup/usergroup.module').then(
            (m) => m.UsergroupModule
          ),
        canActivate: [AuthGuard],
        data: {},
      },
      {
        path: CLIENT_PATHS.sprints,
        loadChildren: () =>
          import('./modules/sprints/sprints.module').then(
            (m) => m.SprintsModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.Sprints,
        },
      },
      {
        path: CLIENT_PATHS.calendar,
        loadChildren: () =>
          import('./modules/calendar/calendar.module').then(
            (m) => m.CalendarModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.Calendar,
        },
      },
      {
        path: CLIENT_PATHS.feed,
        loadChildren: () =>
          import('./modules/feed/feed.module').then((m) => m.FeedModule),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.Feed,
        },
      },
      {
        path: CLIENT_PATHS.onboarding,
        loadChildren: () =>
          import('./modules/onboarding/onboarding.module').then(
            (m) => m.OnboardingModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.Onboarding,
        },
      },
      {
        path: CLIENT_PATHS.toolbox,
        loadChildren: () =>
          import('./modules/toolbox/toolbox.module').then(
            (m) => m.ToolboxModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.ToolBox,
        },
      },
      {
        path: CLIENT_PATHS.mediaLibrary,
        loadChildren: () =>
          import('./modules/mediaLibrary/media-library.module').then(
            (m) => m.MediaLibraryModule
          ),
        canActivate: [AuthGuard],
        data: {
          feature: TenantFeatures.ToolBox,
        },
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
