import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-period-select',
  templateUrl: './period-select.component.html',
  styleUrls: ['./period-select.component.scss'],
})
export class PeriodSelectComponent implements OnInit {
  constructor(private formBuilder: UntypedFormBuilder) {}
  editForm: UntypedFormBuilder | any;
  @Input() defaultValues: Partial<ITimeStamp> | null = null;
  @Output() timeStampSubmit = new EventEmitter<Partial<ITimeStamp>>();
  ngOnInit(): void {
    this.editForm = this.formBuilder.group({
      from: [this.defaultValues?.from?.toISOString().slice(0, 16)],
      till: [this.defaultValues?.till?.toISOString().slice(0, 16)],
    });
  }
  datesValid: boolean = true;
  submit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    this.timeStampSubmit.emit({
      from: this.editForm.value?.from,
      till: this.editForm.value?.till,
    });
  }
  checkValidity(): boolean {
    if (
      this.editForm.value?.from &&
      this.editForm.value?.till &&
      this.editForm.value?.from >= this.editForm.value?.till
    ) {
      this.datesValid = false;
      return false;
    }
    this.datesValid = true;
    return true;
  }
  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
export interface ITimeStamp {
  id?: string;
  from?: Date;
  till?: Date;
}
