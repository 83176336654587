<div class="m-0 p-0 row h-100 w-100 vw-100">
  <div
    class="marginOnBigScreen col-11 col-md-11 col-lg-4 col-xl-4 mx-auto text-center mb-100px"
    style="z-index: 999 !important"
  >
    <img width="200" [alt]="env.name" src="/assets/img/header_logo_short.png" />
    <h3 class="mb-4" i18n="Welcome Agian Label">
      {{ "welcomeAgain" | transloco }}
    </h3>
    <p class="text-muted mb-4" i18n="Sign in label">
      {{ "signInNowByEntering" | transloco }}<br />{{
        "yourEmailAndPassword" | transloco
      }}
    </p>
    <form
      class="d-grid gap-2 mb-4"
      [formGroup]="loginForm"
      (ngSubmit)="submit()"
    >
      <div
        class="form-group form-floating"
        [class.is-invalid]="
          loginForm.get('email')?.errors && loginForm.get('email')?.touched
        "
      >
        <input
          formControlName="email"
          type="text"
          class="border8 inpt"
          id="email"
          placeholder="{{ 'email' | transloco }}"
          i18n-placeholder="Email placeholder @@email"
        />
      </div>
      <div
        class="form-group form-floating"
        [class.is-invalid]="
          loginForm.get('password')?.errors &&
          loginForm.get('password')?.touched
        "
      >
        <input
          formControlName="password"
          type="password"
          class="border8 inpt"
          id="password"
          placeholder="{{ 'password' | transloco }}"
          i18n-placeholder="Password placeholder @@password"
        />

        <button
          type="button"
          class="btn btn-link text-danger tooltip-btn"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          id="passworderror"
          [hidden]="!title"
          [title]="title"
        >
          <i class="bi bi-info-circle"></i>
        </button>
      </div>
      <div
        class="form-check float-start d-flex align-items-center justify-content-between"
      >
        <div class="border8">
          <input
            class="form-check-input"
            formControlName="rememberMe"
            id="rememberMe"
            type="checkbox"
          />
          <label
            class="float-start"
            for="rememberMe"
            i18n="Remember user label"
            >{{ "rememberMe" | transloco }}</label
          >
        </div>
        <div>
          <p class="mb-0">
            <a
              routerLink="/forgotpassword"
              class="btn btn-link"
              style="
                font-family: 'Inter', sans-serif;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
              "
              i18n="Button forgot password"
              >{{ "forgotYourPassword" | transloco }}</a
            >
          </p>
        </div>
      </div>
      <button
        type="submit"
        [disabled]="repo.isLoading$ | async"
        class="btn btn-orange border8 btntxt btn-default btn-block mt-3"
        style="background-color: #ff4405 !important; z-index: 3"
      >
        <ng-container
          class="textBold"
          *ngIf="!(repo.isLoading$ | async)"
          i18n="Button sign in"
          >{{ "signIn" | transloco }}</ng-container
        >
        <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      </button>
    </form>
    <app-error-alert
      *ngIf="error"
      title="{{ 'alreadyLoginedByAnotherMicrosoftAccount' | transloco }}"
      i18n-title="Already logined by another microsoft account"
      errors="error"
    >
    </app-error-alert>

  </div>
  <div
    class="col-12 col-md-12 col-lg-12 col-xl-6 showOnBigScreen d-flex align-items-center justify-content-center sideBackground flex-column"
  >
    <h3 class="text-white">We light the spark inside you.</h3>
    <h5 class="text-white">
      <br />
      IGNTR is the inspiring force behind your personal and professional
      development.
      <br />
      <br />
      Leading you towards a transformation that creates lasting results.
    </h5>
  </div>
</div>
