import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Post } from 'app/state/posts.repository';
import { PostsService } from '../../../../state/posts.service';
import { FileSetttings } from 'app/shared/data.service';
import {
  UserGroup,
  UserGroupsRepository,
} from 'app/state/usergroup.repository';
import {
  ITimeStamp,
  PeriodSelectComponent,
} from 'app/modules/base/components/period-select/period-select.component';
import { ImageVideoComponentComponent } from 'app/modules/base/components/image-video-component/image-video-component.component';
import { DatalistOption } from 'app/state/abstract/base.repository';
import { UserGroupsService } from 'app/api/services/usergroup.service';

declare var ClassicEditor: any;

@Component({
  selector: 'app-news-form',
  templateUrl: './news-form.component.html',
  styleUrls: ['./news-form.component.scss'],
})
export class NewsFormComponent implements OnInit, AfterViewInit {
  editForm: UntypedFormBuilder | any;
  editor: any;
  @ViewChild(ImageVideoComponentComponent) documentForm:
    | ImageVideoComponentComponent
    | undefined;
  @ViewChild(PeriodSelectComponent) periodSelectForm:
    | PeriodSelectComponent
    | undefined;
  userGroupsOptions: DatalistOption[] | null = null;
  @Input() editedPost: Partial<Post> | null = null;
  @Output() newsSubmit = new EventEmitter<Partial<Post>>();

  DEFAULT_NEWS = 'assets/img/news.jpg';
  doctype: 'image' | 'video' = 'image';
  constructor(
    @Inject(DOCUMENT) private document: any,
    private formBuilder: UntypedFormBuilder,
    private postService: PostsService,
    public userGroupsService: UserGroupsService,
    public userGroupsRepository: UserGroupsRepository
  ) {}
  timestamp: ITimeStamp | null = null;
  ngOnInit() {
    this.timestamp = {
      from: this.editedPost?.showFrom,
      till: this.editedPost?.showTo,
    };
    this.editForm = this.formBuilder.group({
      title: [this.editedPost?.title, Validators.required],
      editor: this.editedPost?.content,
      video: this.editedPost?.video,
      previewImage: this.editedPost?.previewImage,
      userGroupIds: [this.editedPost?.userGroupIds],
      createdById: [this.editedPost?.createdById],
      updatedById: [this.editedPost?.updatedById],
    });
  }

  groupsOptions: DatalistOption[] | null = null;
  groupsOptionsDeleted: DatalistOption[] | null = null;
  @Input() set deletedGroups(value: UserGroup[] | null) {
    if (!value) {
      this.groupsOptionsDeleted = null;
    } else {
      this.groupsOptionsDeleted = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }

  @Input() set groups(value: UserGroup[] | null) {
    if (!value) {
      this.groupsOptions = null;
    } else {
      this.groupsOptions = value.map((x) => ({
        value: x.id,
        label: x.name,
      }));
    }
  }
  ngAfterViewInit() {
    if (typeof ClassicEditor !== 'function') {
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.src = '/assets/js/ckeditor.js';
      this.document.body.appendChild(script);
      script.onload = () => this.initEditor();
    } else {
      this.initEditor();
    }
  }

  private initEditor() {
    ClassicEditor.create(document.querySelector('#editor'), {
      toolbar: [
        'heading',
        'bold',
        'italic',
        '|',
        'undo',
        'redo',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }).then((newEditor: any) => {
      if (this.editedPost?.content) {
        newEditor.setData(this.editedPost?.content);
      }
      this.editor = newEditor;
    });
  }

  onSubmit() {
    if (this.editForm && !this.editForm.valid) {
      this.editForm.markAllAsTouched();
      return;
    }
    if (!this.documentForm?.checkValidity()) {
      return;
    }
    if (!this.periodSelectForm?.checkValidity()) {
      return;
    }
    const showFrom = this.periodSelectForm?.editForm.value?.from;
    const showTo = this.periodSelectForm?.editForm.value?.till;
    const documents = [this.documentForm.onSubmit()];
    const title = this.editForm.value?.title;
    const content = this.editor.getData();
    const video = this.editForm.value?.video;
    const previewImage = this.editForm.value?.previewImage;
    const userGroupIds = this.editForm.value?.userGroupIds;
    const createdById = this.editForm.value?.createdById;
    const updatedById = this.editForm.value?.updatedById;

    this.newsSubmit.emit({
      title,
      showFrom,
      showTo,
      content,
      video,
      previewImage,
      userGroupIds,
      documents,
      documentIds: this.editedPost?.documentIds,
      createdById,
      updatedById,
    });
  }

  updateFile(settings: Partial<FileSetttings>, type: string) {
    switch (type) {
      case 'video': {
        this.editForm.controls['video'].setValue(settings.uploadedFileName);
        break;
      }
      case 'image': {
        this.editForm.controls['previewImage'].setValue(
          settings.uploadedFileName
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  deleteFile(type: string) {
    switch (type) {
      case 'video': {
        this.editForm.controls['video'].setValue('');
        break;
      }
      case 'image': {
        this.editForm.controls['previewImage'].setValue('');
        break;
      }
      default: {
        break;
      }
    }
  }
  updateValue(control: string, value: any) {
    const controlObject = this.editForm?.get(control);
    controlObject?.setValue(value);
    controlObject?.markAsTouched();
  }
}
