<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="col-12 mb-3">
    <div class="form-floating mb-2" [appValidateState]="editForm.get('name')">
      <div class="inpt-title" i18n="@@Name">{{ "name" | transloco }}</div>
      <input
        id="name"
        class="inpt"
        formControlName="name"
        type="text"
        placeholder="Name"
        i18n-placeholder="@@Name"
        autocomplete="off"
      />
    </div>
    <div class="form-floating mb-2" [appValidateState]="editForm.get('email')">
      <div class="inpt-title" for="email" i18n="@@Email">
        {{ "email" | transloco }}
      </div>
      <input
        id="email"
        class="inpt"
        formControlName="email"
        type="text"
        placeholder="Email"
        i18n-placeholder="@@email"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-2"
      [appValidateState]="editForm.get('phoneNumber')"
    >
      <div class="inpt-title" for="phoneNumber" i18n="@@phoneNumber">
        {{ "phone" | transloco }}
      </div>
      <input
        id="phoneNumber"
        class="inpt"
        formControlName="phoneNumber"
        type="text"
        placeholder="Phone Number"
        i18n-placeholder="@@phoneNumber"
        autocomplete="off"
      />
    </div>
  </div>
  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
      {{ "save" | transloco }}
    </button>
  </div>
</form>
