<router-outlet></router-outlet>
<app-offline-label></app-offline-label>
<app-pwa-install-button class="bg-orange"></app-pwa-install-button>
<app-unsynced-changes
  *ngIf="features.offlineChanges"
  [titleResolver]="resolveActionTitle"
></app-unsynced-changes>
<app-session-over-state
  *ngIf="isSessionRefreshModalDisplayed$ | async"
></app-session-over-state>

<div *ngIf="videoUploadData?.isInProgress" class="toggle_div">
  <div class="overlaytext">
    <div style="text-align: center; z-index: 999 !important; color: white">
      <div>
        Uploading file. Please wait. Progress:
        <span style="color: white">{{ videoUploadData?.progress }}%</span>
      </div>
      <div style="color: white" class="spinner-border" role="status"></div>
    </div>
  </div>
</div>
