import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { EnvState } from 'app/modules/shared/helpers/env-state';
import { AuthRepository, Office365Settings } from 'app/state/auth.repository';
import { AuthService } from 'app/state/auth.service';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationService } from 'app/api/services/notificationService';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  title: any;
  error: string | null = null;
  testsLocation = 'localhost:9876';
  path = '';
  settings$: Observable<Office365Settings> | null = null;

  loginForm = this.formBuilder.group({
    email: ['', [Validators.email, Validators.required]],
    password: ['', Validators.required],
    rememberMe: false,
  });

  constructor(
    private auth: AuthService,
    public repo: AuthRepository,
    private route: ActivatedRoute,
    public router: Router,
    private formBuilder: UntypedFormBuilder,
    public env: EnvState,
    private http: HttpClient,
    public service: TranslocoService,
    public notificationsservice: NotificationService
  ) {
    repo.returnUrl$ = this.route.queryParamMap.pipe(
      map((params) => params.get('returnUrl') || '/')
    );
  }

  ngOnInit(): void {}

  loadSettings() {
    this.path = '';
    this.http.get<Office365Settings>('/api/auth/office').subscribe({
      next: (x) => {
        if (x) {
          this.path =
            `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${x.clientId}&response_type=code&redirect_uri=` +
            x.url +
            `/office365auth&response_mode=query&scope=${x.scope}&state=12345`;
        }
      },
      complete: () => {
        if (this.path) {
          document.location.href = this.path;
        }
      },
    });
  }

  private showTooltip() {
    var tooltiptriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltiptriggerList.map(function (e: any) {
      return new bootstrap.Tooltip(e);
    });
  }

  private renderError(err: any) {
    this.title = err.error;
    setTimeout(this.showTooltip);
  }

  submit() {
    this.auth
      .login(
        this.loginForm.value.email,
        this.loginForm.value.password,
        this.loginForm.value.rememberMe
      )
      .subscribe({
        next: (responce) => {
          if (responce.user) {
            this.repo.setUser(responce.user);
            this.service.setActiveLang(responce?.user?.language ?? 'en');
          }
          this.repo.setToken(responce.token);
          this.router.navigate(['active-tenant-page']);
        },
        error: (error) => {
          this.renderError(error);
        },
      });

    const FCMToken =  localStorage.getItem('firebaseToken');
    this.notificationsservice.sendTokenToServer(FCMToken!).subscribe();
  }
}
