<form
  [formGroup]="editForm"
  (ngSubmit)="onSubmit()"
  enctype="multipart/form-data"
>
  <div class="form-group mb-3 row p-0">
    <div class="form-floating mb-3" [appValidateState]="editForm.get('title')">
      <div class="inpt-title" for="title" i18n="@@title">
        {{ "title" | transloco }}
      </div>
      <input
        id="title"
        formControlName="title"
        type="text"
        class="inpt"
        placeholder="Title"
        i18n-placeholder="@@title"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('descripion')"
    >
      <div class="inpt-title" for="description" i18n="@@description">
        {{ "description" | transloco }}
      </div>
      <textarea
        id="description"
        formControlName="description"
        type="text"
        class="inpt inpt-lg minHeight100"
        placeholder="Description"
        i18n-placeholder="@@title"
        autocomplete="off"
      ></textarea>
    </div>
    <div class="col-12 col-md-6 form-floating mb-3">
      <div class="inpt-title" for="clientCode" i18n="@@clientCode">
        {{ "client" | transloco }} {{ "code" | transloco }}
      </div>
      <input
        class="inpt"
        id="clientCode"
        formControlName="clientCode"
        type="text"
        placeholder="Client Code"
        i18n-placeholder="@@ClientCode"
        autocomplete="off"
      />
    </div>
    <div class="col-12 col-md-6 form-floating mb-3">
      <div class="inpt-title" for="clientSince" i18n="@@clientSince">
        {{ "client" | transloco }} {{ "since" | transloco }}
      </div>
      <input
        id="clientSince"
        formControlName="clientSince"
        class="inpt"
        type="date"
        placeholder="clientSince"
        i18n-placeholder="@@clientSince"
        autocomplete="off"
      />
    </div>
    <div
      class="form-floating mb-3"
      [appValidateState]="editForm.get('address')"
      *ngIf="locationCheck()"
    >
      <div class="inpt-title" for="address" i18n="@@CompanyAddress">
        {{ "address" | transloco }}
      </div>
      <input
        id="address"
        formControlName="address"
        class="inpt"
        ngx-google-places-autocomplete
        [options]="myOptions"
        placeholder="Address"
        #palceRef="ngx-places"
        (onAddressChange)="updateCompanyLocation($event)"
        i18n-placeholder="Title placeholder @@CompanyAddress"
      />
    </div>
    <div class="col-12 col-md-6 form-floating mb-2">
      <div class="inpt-title" for="acquiredBy" i18n="@@acquiredBy">
        {{ "acquiredBy" | transloco }}
      </div>
      <input
        id="acquiredBy"
        formControlName="acquiredBy"
        class="inpt"
        type="text"
        placeholder="Acquired By"
        i18n-placeholder="@@acquiredBy"
        autocomplete="off"
      />
    </div>
    <div class="col-12 col-md-6 form-floating mb-2">
      <div class="inpt-title" for="aeAdvisor" i18n="@@AEAdvisor">
        {{ "AEAdvisor" | transloco }}
      </div>
      <input
        class="form-check-input"
        id="aeAdvisor"
        formControlName="aeAdvisor"
        type="checkbox"
        placeholder="AE Advisor"
        i18n-placeholder="@@AEAdvisor"
        autocomplete="off"
      />
    </div>
    <div class="col-12 col-md-6 form-group form-floating mb-2">
      <app-datalist-select
        class=""
        [activeValue]="editForm?.value.fillColor"
        [options]="ColorsOptions"
        (activeValueChange)="updateValue('fillColor', $event)"
        [multiple]="false"
        label="Color"
        i18n-label="Color"
      ></app-datalist-select>
    </div>
    <!--<div class="col-12  d-flex justify-content-between align-items-center  mb-2">
      <h6 class="mainfont">Cell Phones</h6>
      <div class="form-group form-floating   ">
        <button type="button" (click)="addNewCellPhone()" class="btn btn-md-lg btn-orange shadow-sm" i18n="Add button @@addCellPhone"><i class="bi bi-plus"></i> Add Phone</button>
      </div>
    </div>-->
    <!--<div class="mt-1" formArrayName="cellPhones">
      <div class="row align-items-center" *ngFor="let phone of getCellPhonesControls(); let i = index">
        <div [formGroupName]="i" class="col-10 col-md-11">
          <div class="form-floating mb-2 " [class.is-invalid]="phone.get('phoneNumber')?.hasError('required') && phone.get('phoneNumber')?.touched">
            <div class="inpt-title" for="" i18n="@@phoneNumber">Phone Number</div>
            <input id="Phone Number" class="inpt" formControlName="phoneNumber" type="text" placeholder="Phone Number" i18n-placeholder="@@phoneNumber" autocomplete="off" />
          </div>
        </div>
        <div class="d-flex justify-content-end mt-2 col-2 col-md-1 ">
          <button style="flex:none" class="btn btn-danger " (click)="deleteCellPhone(i)">
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
    </div>-->
  </div>
  <!--<div class="col-12  d-flex justify-content-between align-items-center  mb-3">
    <h6 class="mainfont">Company Contacts</h6>
    <div class="form-group form-floating  d-grid ">
      <button type="button" (click)="addNewContact()" class="btn btn-md-lg btn-orange shadow-sm" i18n="Add button @@addContact"><i class="bi bi-plus"></i> Add Contact</button>
    </div>
  </div>-->
  <!--<div class="mt-1" formArrayName="contacts">
    <div class="" *ngFor="let contact of getContactControls(); let i = index">
      <div class="d-flex mb-3">
        <div style="width:90%" (click)="showContact(i, contact.get('shown')?.value)">
          <div class="align-items-center form-floating form-group" [class.is-invalid]="contact.invalid && contact.touched" style="min-height:50px;">
            <div class="locationtitle text-break w-100 d-flex align-items-center" style="min-height:50px">

              <div class="m-2" style=" color: white !important; ">
                <svg style="text-decoration-color:white" *ngIf="contact.get('shown')?.value == true" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg style="color:white" *ngIf="contact.get('shown')?.value == false" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              {{contact.get('name')?.value}}
            </div>
          </div>
        </div>
        <div style="" class=" col d-flex justify-content-lg-end justify-content-center ms-1">
          <button class="btn btn-danger " (click)="deleteContact(i)">
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div class="row mb-2 " *ngIf="contact.get('shown')?.value == true">
        <div [formGroupName]="i" class="col-12">
          <div class="form-floating mb-2 " [class.is-invalid]="contact.get('name')?.hasError('required') && contact.get('name')?.touched">
            <div class="inpt-title" for="" i18n="@@Name">Name</div>
            <input id="Name" class="inpt" formControlName="name" type="text" placeholder="Name" i18n-placeholder="@@Name" autocomplete="off" />
          </div>
          <div class="form-floating mb-2" [class.is-invalid]="contact.get('email')?.hasError('required') && contact.get('email')?.touched">
            <div class="inpt-title" for="email" i18n="@@Email">Email</div>
            <input id="email" class="inpt" formControlName="email" type="text" placeholder="Email" i18n-placeholder="@@email" autocomplete="off" />
          </div>
          <div class="form-floating mb-2">
            <div class="inpt-title" for="phoneNumber" i18n="@@phoneNumber">Phone Number</div>
            <input id="phoneNumber" class="inpt" formControlName="phoneNumber" type="text" placeholder="Phone Number" i18n-placeholder="@@phoneNumber" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <h5 class="mainfont">{{ "client" | transloco }} {{ "notes" | transloco }}</h5>
  <div class="form-floating mb-2">
    <div class="inpt-title" for="pricing" i18n="@@pricing">
      {{ "pricing" | transloco }}
    </div>
    <textarea
      class="mb-1 inpt-lg inpt inpt-2height"
      id="pricing"
      formControlName="pricing"
      type="text"
      placeholder="Pricing"
      i18n-placeholder="@@Pricing"
      autocomplete="off"
    ></textarea>
  </div>
  <div formGroupName="invoice" class="form-floating mb-2">
    <h6 class="mainfont mb-1">{{ "invoice" | transloco }}</h6>
    <div class="row px-1">
      <div class="col-12 col-md-6">
        <div class="inpt-title" for="billTo" i18n="@@billTo">
          {{ "billTo" | transloco }}
        </div>
        <input
          class="mb-1 inpt"
          id="billTo"
          formControlName="billTo"
          type="text"
          placeholder="Bill To"
          i18n-placeholder="@@billTo"
          autocomplete="off"
        />
      </div>
      <div class="col-12 col-md-6">
        <div class="inpt-title" for="email" i18n="@@email">
          {{ "emailTo" | transloco }}
        </div>
        <input
          class="mb-1 inpt"
          id="email"
          formControlName="email"
          type="text"
          placeholder="Email to"
          i18n-placeholder="@@emailTo"
          autocomplete="off"
        />
      </div>
      <!--<div class="col-12">
        <div class=" inpt-title" for="description" i18n="@@description">Description</div>
        <textarea class="mb-1 inpt-lg inpt" id="description" formControlName="description" type="text" placeholder="Description" i18n-placeholder="@@Description" autocomplete="off"></textarea>
      </div>-->
    </div>
  </div>

  <div class="form-floating mb-3">
    <div class="inpt-title" for="notes" i18n="@@notes">
      {{ "notes" | transloco }}
    </div>
    <textarea
      class="inpt-lg inpt"
      id="notes"
      formControlName="notes"
      type="text"
      placeholder="Notes"
      i18n-placeholder="@@notes"
      autocomplete="off"
    ></textarea>
  </div>

  <!--<div class="col-12 d-flex justify-content-between align-items-center mb-3">
    <h5 class="mainfont">Locations</h5>
    <div class="form-group form-floating  d-grid ">
      <button type="button" (click)="addNewLocation()" class="btn btn-md-lg btn-orange shadow-sm" i18n="Add button @@addLocation"><i class="bi bi-plus"></i> Add Location</button>
    </div>
  </div>

  <div class="mt-1" formArrayName="companyLocations">
    <div class="" *ngFor="let item of getControls(); let i = index">
      <div class="d-flex mb-3">
        <div style="width:90%" (click)="showLocation(i, item.get('shown')?.value)">
          <div class="align-items-center form-floating form-group" [class.is-invalid]="item.invalid && item.touched" style="min-height:50px;">
            <div class="locationtitle w-100 d-flex align-items-center" style="min-height:50px">

              <div class="m-2" style=" color: white !important; ">
                <svg style="text-decoration-color:white" *ngIf="item.get('shown')?.value == true" width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L7 7L13 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <svg style="color:white" *ngIf="item.get('shown')?.value == false" width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 13L7 7L1 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              {{item.get('locationTitle')?.value}}
            </div>
          </div>
        </div>
        <div style="" class=" col d-flex justify-content-lg-end justify-content-center ms-1">
          <button class="btn btn-danger " (click)="deleteLocation(i)">
            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>
      </div>
      <div class=" mb-2 " *ngIf="item.get('shown')?.value == true">
        <div [formGroupName]="i" class="row">
          <div class="form-group form-floating mb-3" [class.is-invalid]="item.get('locationTitle')?.value === 0 && item.get('locationTitle')?.touched">
            <div class="inpt-title">Title</div>
            <input id="locationTitle/{{i+1}}" formControlName="locationTitle" class="inpt" placeholder="Title" i18n-placeholder="Title placeholder @@title" (change)="handleTitleChange(i+1)" autocomplete="off" />
          </div>
          <div class="form-group form-floating mb-3" [class.is-invalid]="(item.get('longitude')?.value === 0 || item.get('latitude')?.value === 0) && item.get('locationAddress')?.touched" *ngIf="locationCheck()">
            <div class="inpt-title">Address</div>
            <input id="location/{{i+1}}" formControlName="locationAddress" class="inpt" ngx-google-places-autocomplete [options]='myOptions' placeholder="Address" #palceRef="ngx-places" (onAddressChange)="handleAddressChange($event,i+1)" i18n-placeholder="Title placeholder @@CompanyAddress" />
          </div>
          <div class="col-12 col-md-6 form-group form-floating mb-2" [class.is-invalid]="item.get('defaultRadius')?.hasError('required') && item.get('defaultRadius')?.touched">
            <app-datalist-select class=""
                                 [activeValue]="item.get('defaultRadius')?.value"
                                 [options]="CirclesOptions"
                                 (activeValueChange)="changeCircleRadius($event,i+1)"
                                 [multiple]="false"
                                 label="Default radius on map" i18n-label="Default radius on map"></app-datalist-select>
          </div>
          <div class="form-floating mb-2">
            <div class="inpt-title" for="" i18n="@@LastEventNotes">Notes</div>
            <textarea id="LastEventDescription" class="inpt inpt-lg" formControlName="lastEventDescription" type="text" placeholder="Notes" i18n-placeholder="@@LastEventNotes" autocomplete="off"></textarea>
          </div>
          <div class="form-floating mb-2">
            <div class="inpt-title" for="LastEventDate" i18n="@@LastEventDate">Last Event Date</div>
            <input id="LastEventDate" class="inpt" formControlName="lastEventDate" type="date" placeholder="Last Event Date" i18n-placeholder="@@LastEventDate" autocomplete="off" />
          </div>
        </div>
      </div>
    </div>
  </div>-->

  <div class="form-group form-floating h-100 pb-3 d-grid">
    <button type="submit" class="btn btn-orange shadow-sm" i18n="@@save">
      {{ "save" | transloco }}
    </button>
  </div>
</form>
