import { SafeResourceUrl } from '@angular/platform-browser';

export class MediaLibraryFileDto {
  id: string = '';
  fileUrl: string = '';
  sanitazedFileUrl?: SafeResourceUrl;
  fileName: string = '';
  size: number = 0;
  isSelected: boolean = false;
  isPersonaFile: boolean = false;
  mediaType: MediaFileMediaType = MediaFileMediaType.Other;
}

export enum MediaFileMediaType {
  Image = 1,
  Video = 2,
  Sound = 3,
  Other = 0,
}
