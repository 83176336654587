import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ISasToken } from 'app/shared/azure-blob/azureStorage';
import {
  BlobCompressService,
  CompressedImagesDto,
} from 'app/shared/azure-blob/blob-compress.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { DataService, FileSetttings } from 'app/shared/data.service';
import { IUploadProgress } from 'app/state/abstract/base.repository';
import { Observable, combineAll, from, map } from 'rxjs';
import * as uuid from 'uuid';

@Component({
  selector: 'app-blob-file-uploader',
  templateUrl: './blob-file-uploader.component.html',
  styleUrls: ['./blob-file-uploader.component.scss'],
})
export class BlobFileUploaderComponent {
  @Input() placeholder: string = 'Select file';
  @Input() fileType: string = 'any/*';
  @Input() fileTypeValidationString: string = 'any';
  @Input() hidePlaceholder: boolean = false;
  @Input() withCompression: boolean = false;
  @Input() showDefaultDelete: boolean = false;
  @Input() selectedFileNames: string = '';
  @Input() isForUser: boolean = false;
  @Input() isForReply: boolean = false;
  @Input() userEdit: boolean = false;
  @Output() FileSubmit = new EventEmitter<Partial<FileSetttings>>();
  @Output() compressImagesSubmit = new EventEmitter<
    Partial<CompressedImagesDto>
  >();
  @Output() delete = new EventEmitter<void>();

  fileName: string = '';
  uploadProgress$: Observable<IUploadProgress[]> | undefined;
  progressInfo: any;
  fileError: string = '';
  showFileError: boolean = false;
  selectedFile?: any;

  constructor(
    public dataService: DataService,
    private blobStorage: BlobStorageService,
    private blobCompressor: BlobCompressService
  ) {}

  selectFiles(event: any): void {
    this.showFileError = false;
    this.progressInfo = undefined;
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      if (this.fileTypeValidationString != 'any') {
        if (
          !this.selectedFile.type.includes(this.fileTypeValidationString) &&
          this.fileType.includes('mp3')
            ? !this.selectedFile.type.includes('audio')
            : false
        ) {
          this.fileError = `Only ${this.fileTypeValidationString} files allowed`;
          this.showFileError = true;
          return;
        }
        if (this.selectedFile.size > 1000000000) {
          this.fileError = `${this.fileTypeValidationString}  size more than 1 GB is not allowed`;
          this.showFileError = true;
          return;
        }
      } else {
        if (
          !this.selectedFile.type.includes('image') &&
          !this.selectedFile.type.includes('text') &&
          !this.selectedFile.type.includes('pdf') &&
          !this.selectedFile.type.includes('csv') &&
          !this.selectedFile.type.includes('document')
        ) {
          this.fileError = `File format is not allowed`;
          this.showFileError = true;
          return;
        }
        if (this.selectedFile.size > 25000000) {
          this.fileError = `${this.fileTypeValidationString}  size more than 25 MB is not allowed`;
          this.showFileError = true;
          return;
        }
      }
    }

    this.uploadProgress$ = this.upload(this.selectedFile);
    this.uploadProgress$?.subscribe();
  }

  upload(file: File): any {
    this.progressInfo = { value: 0, fileName: file.name };

    this.dataService.changeVal('videUploadData$', {
      progress: this.progressInfo.value,
      isInProgress: true,
    });

    if (file) {
      this.dataService.changeVal('isUploading$', true);
      let filename = uuid.v4().toString() + file.name;
      const accessToken: ISasToken = {
        container: 'a2app',
        filename: filename,
        storageAccessToken: this.blobStorage.a2appeuropeStorageAccessToken,
        storageUri: this.blobStorage.a2appeuropeStorageUri,
      };

      this.fileName = filename;

      return this.blobStorage.uploadToBlobStorage(accessToken, file).pipe(
        map((progress: any) => {
          if (
            progress == 100 &&
            this.withCompression &&
            file.type?.includes('image')
          ) {
            this.blobCompressor.compressImage(file, filename).subscribe({
              next: (x) => {
                this.compressImagesSubmit.emit(x);
                return this.mapProgress(file!, progress);
              },
              error: () => {
                return this.mapProgress(file!, 100);
              },
            });
          } else {
            return this.mapProgress(file!, progress);
          }
          return;
        })
      );
    }
  }

  private mapProgress(file: File, progress: number): IUploadProgress {
    if (progress != 100) {
      this.dataService.changeVal('videUploadData$', {
        progress: progress,
        isInProgress: true,
      });
    } else {
      this.dataService.changeVal('videUploadData$', {
        progress: 100,
        isInProgress: false,
      });
      this.progressInfo = undefined;
      this.dataService.changeVal('isUploading$', false);
      this.FileSubmit.emit({
        uploadedFileName: this.fileName,
        uploadedFilePath: this.fileName,
      });
    }
    return {
      filename: file.name,
      progress: progress,
    };
  }

  deleteFile() {
    this.selectedFileNames = '';
    this.selectedFile = undefined;
    this.delete.emit();
  }
}
