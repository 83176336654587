<div *ngIf="showPageContent" class="m-0 p-0 row h-100 w-100 vw-100">
  <div
    class="marginOnBigScreen col-11 col-md-11 col-lg-4 col-xl-4 mx-auto text-center mb-100px"
    *ngVar="settings$ | async as settings"
    style="z-index: 999 !important"
  >
    <img
      width="200"
      class="mb-4"
      [alt]="env.name"
      src="/assets/img/header_logo_short.png"
    />
    <p class="text-muted" i18n="Sign in label">
      {{ "choose" | transloco }}<br />
      {{ "yourAT" | transloco }}
    </p>
    <form
      class="d-grid gap-2 mb-4"
      [formGroup]="loginForm"
      (ngSubmit)="setActiveTenant(loginForm.value.tenantId)"
    >
      <div class="form-floating mb-3">
        <app-datalist-select
          class=""
          [activeValue]="loginForm.value.tenantId"
          [options]="tenantsOptions"
          (activeValueChange)="updateValue('tenantId', $event)"
          [multiple]="false"
          label="Active Tenant"
          i18n-label="Active Tenant"
        ></app-datalist-select>
      </div>
      <button
        type="submit"
        class="btn btn-orange border8 btntxt btn-default btn-block mt-3"
        style="background-color: #ff4405 !important; z-index: 3"
      >
        <ng-container
          class="textBold"
          i18n="Button sign in"
          >{{ "submit" | transloco }}</ng-container
        >
      </button>
      <button
        *ngIf="isSuperAdmin"
        type="submit"
        (click)="skip()"
        class="btn btn-secondary border8 btntxt btn-default btn-block mt-3"
        style="background-color: #6e6e6e !important; z-index: 3"
      >
        <ng-container
          class="textBold"
          i18n="Button sign in"
          >Skip</ng-container
        >
      </button>
    </form>
    <app-error-alert
      *ngIf="error"
      title="To login by microsoft 365, please log out of other Microsoft services in this browser and try again"
      i18n-title="Already logined by another microsoft account"
      errors="error"
    >
    </app-error-alert>
  </div>
</div>
