import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { UiRepository } from 'app/state/ui.repository';
import { BackNavigationService } from 'app/helpers/services/backButtonNavigationService';

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.component.html',
  styleUrls: ['./headermenu.component.scss'],
})
export class HeadermenuComponent implements OnInit {
  page: string | null = null;
  showBackArrow: boolean = false

  constructor(
    public navigationService: BackNavigationService,
    public repo: UiRepository,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {
    this.route.queryParams.subscribe(x =>
      this.initShowBackArrow());
  }

  backnav() {
    this.navigationService.goBack();
  }

  ngOnInit(): void {

  }

  isRoute(route: string) {
    return this.router.url.includes(route);
  }

  initShowBackArrow() {
    this.showBackArrow = !this.isRoute('/home') ;
  }
}
