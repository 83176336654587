import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DocumentSubmit } from 'app/api/models/base/DocumentSubmit';
import { CompressedImagesDto } from 'app/shared/azure-blob/blob-compress.service';
import { BlobStorageService } from 'app/shared/azure-blob/blob-storage.service';
import { FileSetttings } from 'app/shared/data.service';
import { IDocument } from 'app/state/document.repository';
const UrlReg = /^(?:https?:\/\/|\/\/)(?=\S*$)/;

@Component({
  selector: 'app-image-video-component',
  templateUrl: './image-video-component.component.html',
  styleUrls: ['./image-video-component.component.scss'],
})
export class ImageVideoComponentComponent implements OnInit {
  Form: UntypedFormBuilder | any;

  @Output() submit = new EventEmitter<Partial<DocumentSubmit>>();
  @Input() entity?: Partial<IDocument>;

  videoType: 'upload' | 'url' = 'upload';
  constructor(
    private formBuilder: UntypedFormBuilder,
    public blobService: BlobStorageService
  ) {}
  ngOnInit(): void {
    this.Form = this.formBuilder.group({
      id: [this.entity?.id],
      video: [this.entity?.video],
      videoUrl: [this.entity?.videoUrl, [Validators.pattern(UrlReg)]],
      image: [this.entity?.image],
      altText: [this.entity?.altText],
      hasCompressedImage320: [this.entity?.hasCompressedImage320],
      hasCompressedImage800: [this.entity?.hasCompressedImage800],
      isFileOnRightBlob: [this.entity?.isFileOnRightBlob],
    });
    this.setVideosInForm();
  }

  setVideosInForm() {
    switch (this.entity?.type) {
      case 'video': {
        this.Form.controls['video'].setValue(this.entity.video);
        this.Form.controls['videoUrl'].setValue(null);
        break;
      }
      case 'videoUrl': {
        this.Form.controls['video'].setValue(null);
        this.Form.controls['videoUrl'].setValue(this.entity.videoUrl);
        break;
      }
      default: {
        break;
      }
    }
  }

  setVideotype(val: 'url' | 'upload') {
    this.videoType = val;
    this.setVideosInForm();
  }

  deleteFile(type: string) {
    switch (type) {
      case 'video': {
        this.Form.controls['video'].setValue(null);
        break;
      }
      case 'image': {
        this.Form.controls['image'].setValue(null);
        this.Form.controls['hasCompressedImage320'].setValue(false);
        this.Form.controls['hasCompressedImage800'].setValue(false);
        break;
      }
      default: {
        break;
      }
    }
  }

  updateFile(settings: Partial<FileSetttings>, type: string) {
    switch (type) {
      case 'video': {
        this.Form.controls['video'].setValue(settings.uploadedFileName);
        break;
      }
      case 'image': {
        this.Form.controls['image'].setValue(settings.uploadedFileName);
        break;
      }
      default: {
        break;
      }
    }
    this.Form.controls['isFileOnRightBlob'].setValue(true);
  }

  changeHasCompressedImage(value: CompressedImagesDto) {
    this.Form.controls['hasCompressedImage320'].setValue(
      value.hasCompressedImage320
    );
    this.Form.controls['hasCompressedImage800'].setValue(
      value.hasCompressedImage800
    );
  }

  onSubmit() {
    const video = this.Form.value?.video;
    const videoUrl = this.Form.value?.videoUrl;
    const image = this.Form.value?.image;
    return {
      type: video ? 'video' : videoUrl ? 'videoUrl' : 'image',
      id: this.Form.value?.id,
      video: video,
      videoUrl: this.Form.value?.videoUrl,
      image: image,
      altText: this.Form.value?.altText,
      hasCompressedImage320: this.Form.value?.hasCompressedImage320,
      hasCompressedImage800: this.Form.value?.hasCompressedImage800,
      isFileOnRightBlob: this.Form.value?.isFileOnRightBlob,
    } as Partial<DocumentSubmit>;
  }

  checkValidity(): boolean {
    if (this.Form && this.Form.valid) {
      this.Form.markAllAsTouched();
      return true;
    }
    return false;
  }
}
