<div class="row page-header d-flex justify-content-center me-sm-1 ms-sm-1">
  <div class="col-xl-8 col-lg-11 col-12 row p-md-2 p-lg-0 p-0">
    <app-timelogs-navbuttons></app-timelogs-navbuttons>

    <div>
      <app-datalist-select
        id="weeksSelect"
        [options]="MonthsOptions"
        [multiple]="false"
        class=" "
        label=""
        [(activeValue)]="selectedMonth"
        (activeValueChange)="changeinput($event)"
      >
      </app-datalist-select>
    </div>

    <div class="w-100 px-3">
      <table
        class="mt-3 table table-borderless w-100"
        style="border-bottom: 0px !important; border-radius: 12px"
      >
        <thead
          class="w-100 d-flex align-items-center tabhead hideOnSmallScreen"
        >
          <tr
            class="d-flex w-100 h-100 hide"
            style="padding: 0 !important; min-height: 70px"
          >
            <th
              width="50%"
              class="d-flex align-items-center justify-content-center"
            >
              <div
                class="w-75 d-flex align-items-center justify-content-start"
                style="color: black"
              >
                {{ "tasks" | transloco }}
              </div>
            </th>
            <th
              width="50%"
              class="d-flex align-items-center justify-content-center"
            >
              <div
                class="w-75 d-flex align-items-center justify-content-end"
                style="color: black"
              >
                {{ "total" | transloco }}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <div
            *ngFor="let project of projects"
            style="width: 100%; padding: 0 !important"
          >
            <tr
              class="d-flex hideOnBigScreen py-1"
              style="background-color: #dcdcdc"
            >
              <td
                width="100%"
                class="pt-1 d-flex align-items-center align-middle taskTitle smallTemplate text-break justify-content-center"
              >
                <div style="font-weight: bold">
                  {{ project?.projectName | slice : 0 : 20 }}
                </div>
              </td>
            </tr>
            <tr
              class="d-flex w-100 p-0 px-4 border-bottom"
              style="background-color: #dcdcdc"
            >
              <td
                width="20%"
                class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen p-2 text-break"
              >
                <div style="font-weight: bold">
                  {{ project?.projectName | slice : 0 : 20 }}
                </div>
              </td>
              <td
                class="hideOnSmallScreen p-3 col dayTitle d-flex align-items-center justify-content-end"
                style="font-weight: bold"
              >
                {{ project.projectTotal }}
              </td>
              <td
                class="hideOnBigScreen pb-2 col dayTitle d-flex align-items-center justify-content-center"
                style="font-weight: bold"
              >
                {{ project.projectTotal }}
              </td>
            </tr>
            <tr
              *ngFor="let task of project.projectTasks"
              class="d-flex w-100 p-0 px-4 border-bottom"
            >
              <td
                width="50%"
                class="ps-2 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate text-break justify-content-start hideOnBigScreen"
              >
                <div>
                  {{ task?.taskName | slice : 0 : 20 }}
                </div>
              </td>
              <td
                width="20%"
                class="ps-4 d-flex align-items-center align-middle taskTitle mb-1 smallTemplate hideOnSmallScreen p-2 text-break"
              >
                <div>
                  {{ task?.taskName | slice : 0 : 20 }}
                </div>
              </td>
              <td
                class="hideOnSmallScreen p-3 col dayTitle d-flex align-items-center justify-content-end"
              >
                {{ task.taskTotalTime }}
              </td>
              <td
                class="hideOnBigScreen p-2 col dayTitle d-flex align-items-center justify-content-end"
              >
                {{ task.taskTotalTime }}
              </td>
            </tr>
          </div>
        </tbody>
      </table>
    </div>
  </div>
</div>
