<div class="row top-20">
  <div
    class="col-12 text-center bg-secondary text-white position-relative py-4"
  >
    <div
      class="coverimg position-absolute top-0 start-0 h-100 w-100 opacity-4"
      [style.background-image]="
        'url(' + (background || DEFAULT_BACKGROUND) + ')'
      "
    >
      <img [src]="background || DEFAULT_BACKGROUND" alt="" />
    </div>
    <app-user-card [preventClick]="true"></app-user-card>
  </div>
</div>
