import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'app/guards/auth.guard';
import { ApphomelayoutComponent } from '../modules/core/layouts/apphomelayout/apphomelayout.component';
import { AppinnerlayoutComponent } from '../modules/core/layouts/appinnerlayout/appinnerlayout.component';
import { UserRoles } from '../state/auth.repository';
import { ApvComponent } from '../modules/apv/apv/apv.component';
import { ApvPostComponent } from '../modules/apv/apv-post/apv-post.component';
import { ApvEditComponent } from '../modules/apv/apv-edit/apv-edit.component';
import { ApvAdminComponent } from '../modules/apv/apv-admin/apv-admin.component';
import { ApvAnswerComponent } from '../modules/apv/apv-answer/apv-answer.component';
import { ApvMyanswersComponent } from '../modules/apv/apv-myanswers/apv-myanswers.component';
import { ApvAnswerAdminComponent } from '../modules/apv/apv-answer-admin/apv-answer-admin.component';
import { TenantFeatures } from '../state/feature.repository';
const routes: Routes = [
  {
    path: '',
    component: ApphomelayoutComponent,
    canActivateChild: [AuthGuard],

    children: [
      {
        path: 'apv',
        component: ApvComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Apv,
        },
      },
      {
        path: 'admin/apv/new',
        component: ApvPostComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Apv,
        },
      },
      {
        path: 'admin/apv/:id',
        component: ApvEditComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Apv,
        },
      },
      {
        path: 'admin/apv',
        component: ApvAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Apv,
        },
      },
      {
        path: 'apv/answer/:id',
        component: ApvAnswerComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Apv,
        },
      },
      {
        path: 'admin/apv/personal/:id',
        component: ApvMyanswersComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin, UserRoles.User],
          feature: TenantFeatures.Apv,
        },
      },
      {
        path: 'admin/apv-answers',
        component: ApvAnswerAdminComponent,
        canActivate: [AuthGuard],
        data: {
          authorize: [UserRoles.TenantAdmin],
          feature: TenantFeatures.Apv,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class ApvRoutingModule {}
