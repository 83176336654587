<div
  class="m-0 p-0 row h-100 w-100 vw-100"
  *ngIf="tenantsOptions && tenantsOptions.length > 1"
>
  <div
    class="marginOnBigScreen col-11 col-md-11 col-lg-4 col-xl-4 mx-auto pt-5 text-center mb-100px"
    *ngVar="settings$ | async as settings"
    style="z-index: 999 !important"
  >
    <img
      width="55"
      height="38"
      class="mb-4"
      [alt]="env.name"
      src="/assets/img/igntr.png"
    />
    <p *ngIf="userExists" class="text-muted mb-4" i18n="Sign in label">
      Choose<br />your active tenant
    </p>
    <form
      *ngIf="userExists"
      class="d-grid gap-2 mb-4"
      [formGroup]="loginForm"
      (ngSubmit)="setActiveTenant()"
    >
      <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      <div *ngIf="!(tenantRepo.isLoading$ | async)" class="form-floating mb-3">
        <app-datalist-select
          class=""
          [activeValue]="loginForm.value.tenantId"
          [options]="tenantsOptions"
          (activeValueChange)="updateValue('tenantId', $event)"
          [multiple]="false"
          label="Active Tenant"
          i18n-label="Active Tenant"
        ></app-datalist-select>
      </div>
      <button
        type="submit"
        [disabled]="repo.isLoading$ | async"
        class="btn btn-orange border8 btntxt btn-default btn-block mt-3"
        style="background-color: #ff4405 !important; z-index: 3"
      >
        <ng-container
          class="textBold"
          *ngIf="!(repo.isLoading$ | async)"
          i18n="Button sign in"
          >{{ "submit" | transloco }}</ng-container
        >
        <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      </button>
      <button
        type="submit"
        [disabled]="repo.isLoading$ | async"
        (click)="skip = true"
        class="btn btn-secondary border8 btntxt btn-default btn-block mt-3"
        style="background-color: #6e6e6e !important; z-index: 3"
      >
        <ng-container
          class="textBold"
          *ngIf="!(repo.isLoading$ | async)"
          i18n="Button sign in"
          >{{ "skip" | transloco }}</ng-container
        >
        <app-spinner *ngIf="repo.isLoading$ | async"></app-spinner>
      </button>
    </form>
    <div *ngIf="submitErrors">
      <app-error-alert
        title="Could not login by microsoft 365, please click 'Go back' button on your browser, log out of Microsoft services and try again"
        i18n-title="Could not login by microsoft 365"
        [errors]="getErrors(submitErrors)"
      ></app-error-alert>
    </div>
    <!-- <div class="col-12 col-md-12 col-lg-12 col-xl-6  showOnBigScreen" style="background-color: #EA4E26; position:relative; z-index:1!important">
      <div class="h-100 w-100" style="position: relative;">
        <img class="imageAllan" src="/assets/img/AllanImage.png" />
        <img class="imageAllanSign" src="/assets/img/AllanSign.png" />
        <span class="titleAllan text-white"  style=" font-family: 'Inter' , sans-serif; font-size: 16px; line-height: 24px; ">
          <span style="font-weight: 600; ">Allan Kirkeby Andreasen</span><br />Projektleder & chef
        </span>
      </div>
    </div> -->
  </div>
</div>
