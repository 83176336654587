import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { EnvState } from '../../shared/helpers/env-state';
import { Router } from '@angular/router';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  constructor(private env: EnvState, private router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let requestUrl = request.url;
    if (!requestUrl.startsWith('/')) {
      requestUrl = `/${requestUrl}`;
    }
    const apiReq = request.clone({ url: `${this.env.apiUrl}${request.url}` });
    return next.handle(apiReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          // Redirect user to a specific page
          this.router.navigate(['unauthorized']);
        }
        return throwError(error);
      })
    );
  }
}
