import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { IApv, ApvRepository } from 'app/state/apv.repository';
import { ApvService } from 'app/state/apv.service';
import { AuthRepository } from 'app/state/auth.repository';
import { UrlIdResolverService } from '../../shared/services/url-id-resolver.service';

@Component({
  selector: 'app-apv-myanswers',
  templateUrl: './apv-myanswers.component.html',
  styleUrls: ['./apv-myanswers.component.scss'],
})
export class ApvMyanswersComponent implements OnInit {
  id: string | null = null;

  constructor(
    public authRepo: AuthRepository,
    public repo: ApvRepository,
    public service: ApvService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private urlId: UrlIdResolverService
  ) {}

  answers: IApv[] = [];

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (value) => {
        this.id = value.get('id');
        this.load(this.id!);
      },
    });
  }
  load(id: string) {
    if (this.id == 'my') {
      this.service.loadPersonal().subscribe((x) => {
        this.answers = x;
      });
    } else {
      this.service.loadPersonalById(this.id!).subscribe((x) => {
        this.answers = x;
      });
    }
  }
}
