import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateNewNotificationDto } from '../models/notifications/createNewNotificationDto';

const API = '/api/notifications';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private http: HttpClient) {}

  createSprintNotification(notificationDto: CreateNewNotificationDto) {
    return this.http.post(`${API}/create/sprint`, notificationDto);
  }

  createToolboxNotification(notificationDto: CreateNewNotificationDto) {
    return this.http.post(`${API}/create/toolbox`, notificationDto);
  }

  sendTokenToServer(token: string) {
    return this.http.post(`${API}/save-token`, { token });
  }
}
