import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class BackNavigationService {
  private history: string[] = ['/'];

  constructor(private router: Router, private location: Location) {}

  public goBack() {
    if (this.history.length == 1) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([this.history.pop()]);
    }
  }

  public navigateAndSetBackUrl(
    url: string,
    backPath: string,
    savePreviousUrl: boolean = true
  ): any {
    this.history.push(backPath);
    if (savePreviousUrl) {
      const currentUrl = this.location.path();
      const newUrl = `${currentUrl}/${url}`;
      this.router.navigateByUrl(newUrl);
      return;
    }
    this.router.navigateByUrl(url);
  }
}
