import { Injectable, OnInit } from '@angular/core';
import { UserGroupsService } from 'app/api/services/usergroup.service';
import { UsersService } from 'app/api/services/users.service';
import { BehaviorSubject } from 'rxjs';

export type FileSetttings = {
  uploadedFilePath?: string;
  uploadedFileName?: string;
};

export interface VideUploadData {
  progress?: number;
  isInProgress?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DataService {
  constructor(public userGroupsService: UserGroupsService) {}
  public activeUserGroups$ =
    this.userGroupsService.getActiveUserGroupIds() as BehaviorSubject<string[]>;

  public uploadedFilePath: BehaviorSubject<FileSetttings> = new BehaviorSubject(
    {}
  );

  public curUserLang: BehaviorSubject<string> = new BehaviorSubject('en');

  isUploading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  videUploadData$: BehaviorSubject<VideUploadData> = new BehaviorSubject({});

  [key: string]: any;

  public changeVal(varName: string, val: any) {
    this[varName].next(val);
  }
}
