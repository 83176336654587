<div class="minHeight90">
  <button
    *ngIf="!hidden"
    id="hideButton"
    class="btn me-2 d-flex align-items-center justify-content-center fullscreenButton"
    (click)="handleMapFullScreen()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-fullscreen"
      viewBox="0 0 16 16"
    >
      <path
        d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"
      />
    </svg>
  </button>
  <button
    *ngIf="hidden"
    id="showButton"
    class="mt-1 mt-md-4 me-md-3 btn me-2 d-flex align-items-center justify-content-center fullscreenButton"
    (click)="handleMapFullScreen()"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-fullscreen-exit"
      viewBox="0 0 16 16"
    >
      <path
        d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"
      />
    </svg>
  </button>
  <div
    *ngIf="!hidden"
    class="row d-flex justify-content-center minHeight50px"
    id="topMenu"
  >
    <div class="row d-flex justify-content-center" id="topMenu">
      <div class="col-2 d-flex flex-column text-break">
        <label>{{ "name:" | transloco }}</label>
        <label class="oneLineText">
          {{ selectedCompany?.title | slice : 0 : 40 }}
          <ng-container
            *ngIf="
              selectedCompany?.title?.length &&
              selectedCompany?.title?.length! >= 40
            "
            >..</ng-container
          >
        </label>
      </div>
      <div class="col-2 d-flex flex-column text-break">
        <label>{{ "aeAdvisor:" | transloco }}</label>
        <label *ngIf="selectedCompany" class="oneLineText">
          {{ selectedCompany?.aeAdvisor == true ? "Yes" : "No" }}
        </label>
      </div>
      <div class="col-4 d-flex flex-column text-break">
        <label>{{ "address:" | transloco }}</label>
        <label class="oneLineText">
          {{ selectedCompany?.address | slice : 0 : 40 }}
          <ng-container
            *ngIf="
              selectedCompany?.address?.length &&
              selectedCompany?.address?.length! >= 40
            "
            >..</ng-container
          >
        </label>
      </div>
      <div class="col-2 d-flex flex-column text-break">
        <label>{{ "clientSince:" | transloco }}</label>
        <label class="oneLineText">
          {{ selectedCompany?.formatedClientSince | slice : 0 : 40 }}
          <ng-container
            *ngIf="
              selectedCompany?.formatedClientSince?.length &&
              selectedCompany?.formatedClientSince?.length! >= 40
            "
            >..</ng-container
          >
        </label>
      </div>
      <div class="col-2 d-flex flex-column text-break">
        <label>{{ "acquiredBy:" | transloco }}</label>
        <label class="oneLineText">
          {{ selectedCompany?.acquiredBy | slice : 0 : 40 }}
          <ng-container
            *ngIf="
              selectedCompany?.acquiredBy?.length &&
              selectedCompany?.acquiredBy?.length! >= 40
            "
            >..</ng-container
          >
        </label>
      </div>
    </div>
  </div>
  <div class="row mt-2 minHeight70">
    <div class="col-4" *ngIf="!hidden" style="max-width: 262px">
      <div class="d-flex flex-column">
        <div class="d-flex align-items-center">
          <input
            id="searchInput"
            class="my-2 inpt"
            (change)="searchChange()"
            placeholder="{{ 'search..' | transloco }}"
          />
        </div>
        <div class="d-flex mb-2">
          <div>
            <label class="col-1 toggle offsetMobile">
              <input
                id="enableEditing"
                class="toggle-checkbox"
                type="checkbox"
                [(ngModel)]="enableEditing"
                (change)="hidePopUps(enableEditing)"
              />
              <div class="toggle-switch"></div>
            </label>
          </div>
          <div class="ms-2">{{ "edit" | transloco }}</div>
        </div>
        <div class="d-flex mb-2">
          <input
            id="SelectAll"
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="allSelected"
            (change)="selectAll(allSelected)"
          />

          <div class="ms-2">{{ "select" | transloco }}</div>
        </div>
        <div *ngFor="let company of companies; let comi = index">
          <div (click)="changeSelectedCompany(comi)">
            <div class="d-flex">
              <div class="me-1 d-flex align-items-center">
                <input
                  [(ngModel)]="company.allCompaniesSelected"
                  type="checkbox"
                  (change)="
                    ShowCompanyAllLocations(company.allCompaniesSelected, comi)
                  "
                  class="form-check-input"
                />
              </div>
              <div class="me-1 d-flex align-items-center">
                <button
                  class="btn p-1 d-flex align-items-center"
                  (click)="showCompanyLocation(company.id)"
                  *ngIf="!company.locationsHiden"
                  id="{{ company.id }}/shown"
                >
                  <svg
                    width="14"
                    height="8"
                    viewBox="0 0 14 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L7 7L13 1"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button
                  class="btn p-1 d-flex align-items-center"
                  *ngIf="company.locationsHiden"
                  id="{{ company.id }}/hiden"
                  (click)="hideCompanyLocation(company.id)"
                >
                  <svg
                    width="8"
                    height="14"
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13L7 7L1 1"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
              <div
                class="pointer locationTitle d-flex align-items-center oneLineText"
                title="{{ company.title | slice : 0 : 100 }}"
              >
                {{ company.title }}
              </div>
            </div>
            <div
              *ngFor="
                let location of company.companyLocations;
                let loci = index
              "
            >
              <div
                class="d-flex align-items-center minHeight30px"
                [class.pl25px]="!enableEditing"
                *ngIf="!company.locationsHiden"
              >
                <button
                  class="p-1 btn d-flex align-items-center justify-content-center"
                  (click)="clickchange(comi, loci)"
                  *ngIf="enableEditing"
                >
                  <svg
                    width="15"
                    height="15"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 3.99998H5.8C4.11984 3.99998 3.27976 3.99998 2.63803 4.32696C2.07354 4.61458 1.6146 5.07353 1.32698 5.63801C1 6.27975 1 7.11983 1 8.79998V17.2C1 18.8801 1 19.7202 1.32698 20.362C1.6146 20.9264 2.07354 21.3854 2.63803 21.673C3.27976 22 4.11984 22 5.8 22H14.2C15.8802 22 16.7202 22 17.362 21.673C17.9265 21.3854 18.3854 20.9264 18.673 20.362C19 19.7202 19 18.8801 19 17.2V13M6.99997 16H8.67452C9.1637 16 9.40829 16 9.63846 15.9447C9.84254 15.8957 10.0376 15.8149 10.2166 15.7053C10.4184 15.5816 10.5914 15.4086 10.9373 15.0627L20.5 5.49998C21.3284 4.67156 21.3284 3.32841 20.5 2.49998C19.6716 1.67156 18.3284 1.67155 17.5 2.49998L7.93723 12.0627C7.59133 12.4086 7.41838 12.5816 7.29469 12.7834C7.18504 12.9624 7.10423 13.1574 7.05523 13.3615C6.99997 13.5917 6.99997 13.8363 6.99997 14.3255V16Z"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div class="me-1 d-flex align-items-center">
                  <input
                    class="form-check-input"
                    [(ngModel)]="location.checked"
                    type="checkbox"
                    (change)="ShowLocation(location.checked, location.id, comi)"
                  />
                </div>
                <div
                  class="me-1 d-flex align-items-center locationTitle pointer"
                  title="{{ location.title | slice : 0 : 100 }}"
                >
                  {{ location.title }}
                </div>
                <div
                  *ngIf="locationIndex === loci && companyIndex === comi"
                  class="changeRadius"
                >
                  <app-datalist-select
                    class=""
                    [activeValue]="location.radius"
                    [options]="CirclesOptions"
                    [multiple]="false"
                    (activeValueChange)="
                      changeCircleRadius(location.id, $event)
                    "
                  ></app-datalist-select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col p-0 m-0" style="position: relative">
      <input
        id="mapSearch"
        type="search"
        class="mapsinpt mt-2 inpt"
        ngx-google-places-autocomplete
        [options]="myOptions"
        #palceRef="ngx-places"
        (onAddressChange)="ChangeLocationMapBySearch($event)"
        (change)="ClearMapSearch()"
      />

      <agm-map
        [latitude]="lat"
        dropzone=""
        [longitude]="lng"
        [disableDefaultUI]="false"
        [(zoom)]="zoom"
        [zoomControl]="false"
        (mapClick)="mapClicked($event)"
        class="mapSize"
        (tilesLoaded)="loadAllAndSetValue()"
        [class.mapFullScreen]="hidden"
      >
        <agm-marker
          *ngFor="let m of markers; let i = index"
          (markerClick)="clickedMarker(m.label, i)"
          [latitude]="m.lat"
          [longitude]="m.lng"
          [label]="{
            text: m.label,
            color: '#3878C7',
            fontWeight: 'bold',
            fontSize: '15px',
            fontFamily: ''
          }"
          [markerDraggable]="m.draggable"
          (dragEnd)="markerDragEnd(m, $event)"
        >
          <agm-info-window class="scr">
            <div>{{ m.lastEventDesc }}</div>
            <div>{{ m.lastEventDate }}</div>
            <div
              *ngIf="enableEditing"
              class=""
              style="
                z-index: 99999 !important;
                min-height: 100px;
                min-width: 200px;
              "
            >
              <app-datalist-select
                class="maplist"
                [activeValue]="m.radius"
                [options]="CirclesOptions"
                [multiple]="false"
                (activeValueChange)="changeCircleRadius(m.id, $event)"
              ></app-datalist-select>
            </div>
          </agm-info-window>
        </agm-marker>
        <agm-circle
          *ngFor="let c of circles; let i = index"
          [latitude]="c.lat"
          [longitude]="c.lng"
          [radius]="c.radius"
          [circleDraggable]="c.draggable"
          [fillColor]="c.fillColor"
        >
        </agm-circle>
      </agm-map>
    </div>
  </div>
</div>
